import { lazy } from 'react';

// 引入 code split 之后，被 split 出来的 chunk 在主应用的域名下会加载失败
// 具体的解决方法是修改在这种环境下（主应用加载子应用）的 public path
// @see: https://github.com/umijs/qiankun/issues/137
export const Welcome = lazy(() => import('./Welcome'));

export const OperationLog = lazy(() => import('./OperationLog'));

export const DroToBeListing = lazy(() => import('./DroToBeListing'));
export const DroLiseted = lazy(() => import('./DroListed'));

export const DroToBeListingV1_5 = lazy(() => import('./DroToBeListing/v1_5'));
export const DroLisetedV1_5 = lazy(() => import('./DroListed/v1_5'));

export const ToBeSentEmail = lazy(() => import('./ToBeSentEmail'));
export const SentEmail = lazy(() => import('./SentEmail'));

export const ContractList = lazy(() => import('./Contract/ContractList'));
export const ContractDetail = lazy(() => import('./Contract/ContractDetail'));

export const InvestList = lazy(() => import('./Invsestment/InvestmentList'));
export const InvestPeriodList = lazy(() => import('./Invsestment/InvestmentPeriodList'));

export const SQLEdit = lazy(() => import('./SQL/SQLEdit'));
export const SQLAudit = lazy(() => import('./SQL/SQLAudit'));

export const OrderDRO = lazy(() => import('./Order/dro'));
export const OrderDRP = lazy(() => import('./Order/drp'));
export const RoleManageList = lazy(() => import('./RolesManage/RoleManage/RoleManageList'));
export const UserManageList = lazy(() => import('./RolesManage/UserManage/UserManageList'));

export const ArmValidation = lazy(() => import('./ArmManagement/ArmValidation'));
export const MemberDeposit = lazy(() => import('./Deposit/member'));
export const SpvDeposit = lazy(() => import('./Deposit/spv'));
export const SpvVirtualAccount = lazy(() => import('./Deposit/spv-va'));
export const FundAllocation = lazy(() => import('./Deposit/fund'));

export const ExchangeRates = lazy(() => import('./ExchangeRates'));
