import { Space, App } from 'antd';
import { useState } from 'react';
import { OrderDrpWithdraw } from './OrderDrpWithdraw';
import { ActionType } from '@ant-design/pro-components';

export const OrderDrpOpBtns = (props: { record: any; action: any; ctx: any }) => {
  const { record } = props;
  const { message } = App.useApp();
  const [withdrawOpen, setWithdrawOpen] = useState(false);

  const tableAction: ActionType = props.ctx?.actionRef.current;

  const onClickDetail = () => {
    setWithdrawOpen(true);
  };

  const onFinish = () => {
    message.success('撤单成功');
    tableAction?.reload();
    closeDialog();
  };

  const closeDialog = () => {
    setWithdrawOpen(false);
  };

  // 撮合状态=待撮合&订单状态=有效
  if (!(record.captureStatus === '1' && record.orderStatus === '1')) {
    return null;
  }

  return (
    <Space>
      {/* TODO: 使用 a 标签一直有一个警告，但是用 span 又没有 antd 的样式 */}
      <a onClick={onClickDetail}>撤单</a>

      {withdrawOpen && <OrderDrpWithdraw record={record} onFinish={onFinish} onCancel={closeDialog} />}
    </Space>
  );
};
