import { Dropdown as OriginalDropdown } from 'antd';
import { withSingleChild } from '../../utils/hoc';
const Dropdown = (props) => {
    const innerProps = {};
    if (props.__designMode === 'design') {
        innerProps.open = true;
    }
    return createElement(OriginalDropdown, { ...props, ...innerProps });
};
export default withSingleChild(Dropdown, ['children', 'overlay']);
