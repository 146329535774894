import { getFileIcon, parseOssFileName } from '@/utils/oss-util';
import { Button, Image, Space } from 'antd';
import { FC, ReactNode, useState } from 'react';

interface ButtonImageProps {
  src: string;
  fileName?: string;
  fileIcon?: boolean | ReactNode;
}

/**
 * 链接形式的图片预览
 */
export const ButtonImage: FC<ButtonImageProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const fileIcon = props.fileIcon ?? true;

  const fileName = props.fileName || parseOssFileName(props.src);

  let icon: ReactNode = null;
  if (fileIcon) {
    icon = typeof fileIcon === 'boolean' ? getFileIcon('image') : fileIcon;
  }

  return (
    <span>
      <a onClick={() => setVisible(true)}>
        <Space size={4}>
          {icon}
          {fileName}
        </Space>
      </a>
      <Image
        width={200}
        style={{ display: 'none' }}
        wrapperStyle={{ display: 'inline' }}
        // src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png?x-oss-process=image/blur,r_50,s_50/quality,q_1/resize,m_mfit,h_200,w_200"
        src={props.src}
        preview={{
          visible,
          src: props.src,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </span>
  );
};
