import useMciRequest from '@/api/useMciRequest';
import { Button, Space, App } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContractModify } from './ContractModify';
import { ContractChannel } from './ContractChannel';
import { ActionType } from '@ant-design/pro-components';
import { access } from '@mci-fe/user-auth';

export const ContractOpBtns = (props: { record: any; action: any; ctx: any }) => {
  const { record } = props;
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [moidfyOpen, setModifyOpen] = useState(false);
  const [channelOpen, setChannelOpen] = useState(false);

  // TOOD: 操作列的 action 和 protable 的 action 有点重名了
  const tableAction: ActionType = props.ctx?.actionRef.current;

  const onClickDetail = () => {
    // console.log('record', record);

    // 注意这个id是列表的主键id，不是 申请主体的orgId
    navigate(`/contractManagement/detail/${record.id}`);
  };

  const closeDialog = () => {
    setModifyOpen(false);
    setChannelOpen(false);
  };

  // -- modify --
  const onClickModify = () => {
    setModifyOpen(true);
  };

  const onModifyFinish = () => {
    message.success('修改完成');
    tableAction?.reload();
    closeDialog();
  };

  const onModifyCancel = () => {
    closeDialog();
  };

  // -- channel --
  const onClickRelateChannel = () => {
    setChannelOpen(true);
  };

  const onChannelFinish = () => {
    // table 并不会展现，因此不需要 reload
    message.success('关联渠道完成');
    closeDialog();
  };

  const onChannelCancel = () => {
    closeDialog();
  };

  return (
    <Space>
      {/* TODO: 使用 a 标签一直有一个警告，但是用 span 又没有 antd 的样式 */}
      {access.sentSignDetail && <a onClick={onClickDetail}>详情</a>}
      {access.sentSignEdit && <a onClick={onClickModify}>修改</a>}
      {access.sentSignAssociation && <a onClick={onClickRelateChannel}>关联渠道</a>}

      {moidfyOpen && <ContractModify record={record} onFinish={onModifyFinish} onCancel={onModifyCancel} />}
      {channelOpen && <ContractChannel record={record} onFinish={onChannelFinish} onCancel={onChannelCancel} />}

      {/* TEST */}
      {/* {record.id === 0 && <ContractModify />} */}
      {/* {record.id === 0 && <ContractChannel />} */}
    </Space>
  );
};
