import { Descriptions, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { AdminFileResp } from '@/api/types';
import useMciRequest from '@/api/useMciRequest';
import { useParams } from 'react-router-dom';
import { PreviewFile } from '@/components/PreviewFile';
import dayjs from 'dayjs';
import { log } from '@mci-fe/logan-web';

export default function PCFCashFlow(props: { appId: string }) {
  const { appId } = props;
  const { loading, data: resp } = useMciRequest(
    'getAdminV1FilesList_mcexpOperation',
    {
      queryParams: {
        appId: appId!,
        businessType: 17
      },
    },
    {
      isJoinGlobalLoading: false,
    },
  );

  const pdfList = resp?.data || [];
  
  const columns: TableProps<AdminFileResp>['columns'] = [
    {
      title: '合同pdf',
      render(record) {
        return <PreviewFile url={record?.fileId} width={300} />;
      },
    },
    {
      title: '上传时间',
      dataIndex: 'createTime',
      render(record){
        return <div>{ dayjs(dayjs(record)).format('YYYY-MM-DD HH:mm:ss')}</div>;
      } 
    },
  ];
  return (
    <Descriptions
      layout="vertical"
      column={1}
      items={[
        {
          key: '1',
          label: '门店与主体签约合同',
          children: <PreviewFile  url={pdfList[0]?.fileId} width={300} />,
        },
        { 
          key: '2',
          label: '历史合同PDF列表',
          children: (
            <Table
              columns={columns}
              dataSource={pdfList.slice(1) || []}
              style={{ width: '100%' }}
              loading={loading}
            />
          ),
        },
      ]}
    />
  );
}
