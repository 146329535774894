import React from 'react';
import { Modal, Radio, Space, Button, Tag } from 'antd';
import type { RadioChangeEvent } from 'antd';
import styled from '@emotion/styled';

const envs = ['dev1', 'dev2', 'dev3', 'dev4', 'stg1', 'stg2', 'stg3', 'stg4', 'uat1', 'prod'] as const;

const StyledRadioGroup = styled(Radio.Group)`
  width: 100%;
`;

const StyledSpace = styled(Space)`
  width: 100%;
  display: flex;
`;

const EnvItem = styled.div`
  padding: 8px;
  margin: 0 -8px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const EnvItemContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledRadio = styled(Radio)`
  width: 100%;
`;

interface EnvSelectModalProps {
  defaultEnv: string;
  open: boolean;
  onClose: () => void;
  onCancel: () => void;
  onOk: (selectedEnv: string) => void;
}

const EnvSelectModal: React.FC<EnvSelectModalProps> = ({ defaultEnv, open, onClose, onCancel, onOk }) => {
  const [selectedEnv, setSelectedEnv] = React.useState<string>(defaultEnv);

  const handleEnvChange = (e: RadioChangeEvent) => {
    setSelectedEnv(e.target.value);
  };

  const handleOk = () => {
    onOk(selectedEnv);
    onClose();
  };

  return (
    <Modal
      title="请选择接口环境"
      open={open}
      onCancel={onCancel}
      centered
      closeIcon={<span className="modal-close-x">✕</span>}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          取消
        </Button>,
        <Button key="submit" type="primary" onClick={handleOk} disabled={selectedEnv === defaultEnv}>
          确定
        </Button>,
      ]}>
      <StyledRadioGroup onChange={handleEnvChange} value={selectedEnv}>
        <StyledSpace direction="vertical">
          {envs.map((env) => (
            <EnvItem key={env}>
              <EnvItemContent>
                <StyledRadio value={env} checked={env === selectedEnv}>
                  {env}
                </StyledRadio>
                {env === defaultEnv && <Tag color="green">当前环境</Tag>}
              </EnvItemContent>
            </EnvItem>
          ))}
        </StyledSpace>
      </StyledRadioGroup>
    </Modal>
  );
};

export default EnvSelectModal;
