import { DepositReviewInfo } from '@/api/types';
import { useState } from 'react';
import { ModalFundFlow } from '@/components/ModalFundFlow';
import { AccessConfigType } from '@/config/access';
import { useAccess } from '@mci-fe/user-auth';

export default function EditFundFlow({ record, ctx }: any) {
  const [modalStatus, setModalStatus] = useState(false);
  const access = useAccess<AccessConfigType>();
  const editEvent = () => {
    setModalStatus(true);
  };
  const onCloseEvent = (type?: string) => {
    if (type === 'success') {
      ctx.actionRef.current.reload(); // 刷新
    }
    setModalStatus(false);
  };
  return (
    <>
      {record.approvalStatus === 2 && access.fundAllocation_edit && <a onClick={editEvent}>编辑</a>}
      {modalStatus && (
        <ModalFundFlow isShowModal={modalStatus} rowData={record} modalTitle="编辑" closeEvent={onCloseEvent}></ModalFundFlow>
      )}
    </>
  );
}
