import { Button, Form, Input, message, Modal, Upload, UploadProps, Image, UploadFile } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useAccess } from '@mci-fe/user-auth';
import { AccessConfigType } from '@/config/access';
import { ArrowRightOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getFilesUrl_exKernelAdmin,
  postCurrencyExchangeUpdate_exKernelAdmin,
  postFilesUpload_exKernelAdmin,
} from '@/api/services';
import { EXCHANGE_TYPE_MAP } from '@/constants';

const STATUS_MAP = ['FINANCE_AUDIT', 'OPERATION_AUDIT', 'COMPLETED'];

export default function DispatchExchange({ record, ctx }: any) {
  const [fileList, setFileList] = useState<any[]>([]);
  const [moduleVisible, setModuleVisible] = useState(false);
  const [currentStatus, setCurrentStatus] = useState('');
  const [currentRate, setCurrentRate] = useState(record.exchangeRate || 0);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const access = useAccess<AccessConfigType>();
  const [modal, contextHolder] = Modal.useModal();
  const [form] = Form.useForm();

  const showModal = () => {
    setCurrentStatus(STATUS_MAP[1]);
    setModuleVisible(true);
  };

  const isEdit = useMemo(() => {
    const systemAuth = access.exchangeRates_edit && record.createdFrom === 'SYSTEM';
    const manualAuth = access.exchangeRates_editList && record.createdFrom === 'MANUAL';
    return (systemAuth || manualAuth) && record.status === 'FINANCE_AUDIT';
  }, [access.exchangeRates_edit, access.exchangeRates_editList, record.createdFrom, record.status]);

  const isOk = useMemo(() => {
    const systemAuth = access.exchangeRates_ok && record.createdFrom === 'SYSTEM';
    const manualAuth = access.exchangeRates_approve && record.createdFrom === 'MANUAL';
    return (systemAuth || manualAuth) && record.status === 'OPERATION_AUDIT';
  }, [access.exchangeRates_approve, access.exchangeRates_ok, record.createdFrom, record.status]);

  const isReturn = useMemo(() => {
    const systemAuth = access.exchangeRates_return && record.createdFrom === 'SYSTEM';
    const manualAuth = access.exchangeRates_reject && record.createdFrom === 'MANUAL';
    return (systemAuth || manualAuth) && record.status === 'OPERATION_AUDIT';
  }, [access.exchangeRates_reject, access.exchangeRates_return, record.createdFrom, record.status]);

  const handleSubmit = (values: any) => {
    modal.confirm({
      title: '请确认以下信息',
      content: (
        <div>
          <p>换汇工单编号：{record?.serialNo ?? '--'}</p>
          {record?.exchangeType === EXCHANGE_TYPE_MAP['换入'] && (
            <div className="flex items-center">
              <div>
                <p className="text-16--bold">换出币种</p>
                <p>
                  {values?.balanceFrom ?? '--'} {record?.currencyFrom ?? '--'}
                </p>
              </div>
              <div className="px-24">
                <ArrowRightOutlined className="text-24" size={32} />
              </div>
              <div>
                <p className="text-16--bold">换入币种</p>
                <p>
                  {record.balanceTo} {record?.currencyTo ?? '--'}
                </p>
              </div>
            </div>
          )}
          {record?.exchangeType === EXCHANGE_TYPE_MAP['换出'] && (
            <div className="flex items-center">
              <div>
                <p className="text-16--bold">换出币种</p>
                <p>
                  {record?.balanceFrom ?? '--'} {record?.currencyFrom ?? '--'}
                </p>
              </div>
              <div className="px-24">
                <ArrowRightOutlined className="text-24" size={32} />
              </div>
              <div>
                <p className="text-16--bold">换入币种</p>
                <p>
                  {values.balanceTo} {record?.currencyTo ?? '--'}
                </p>
              </div>
            </div>
          )}
        </div>
      ),
      onOk: async () => {
        await postCurrencyExchangeUpdate_exKernelAdmin({
          requestBody: {
            ...record,
            ...values,
            exchangeRate: currentRate,
            voucher: fileList.map((item) => item.filePath),
            status: currentStatus,
          },
        });
        message.success('操作成功');
        setTimeout(() => {
          ctx.actionRef.current.reload();
        }, 0);
        setModuleVisible(false);
      },
    });
  };

  const calculateExchangeRate = (e: any) => {
    const inAmount = e.target.value;
    if (!inAmount) {
      setCurrentRate(0);
      return;
    }
    if (record?.exchangeType === EXCHANGE_TYPE_MAP['换入']) {
      setCurrentRate((Math.round((+record.balanceTo / +inAmount) * 10000) / 10000).toFixed(4));
    }
    if (record?.exchangeType === EXCHANGE_TYPE_MAP['换出']) {
      setCurrentRate((Math.round((+inAmount / +record.balanceFrom) * 10000) / 10000).toFixed(4));
    }
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      handleSubmit(values);
    } catch (error) {
      console.log('Validation failed:', error);
    }
  };

  const handleCancel = () => {
    setModuleVisible(false);
  };

  const updateFileList = (fileList: any) => {
    setFileList(fileList);
    form.setFieldsValue({
      voucher: fileList,
    });
  };

  const fetchFilelUrl = async (filePath: string) => {
    const duration = 3600 * 1000 * 24;
    return getFilesUrl_exKernelAdmin({
      queryParams: { duration, filePath },
    });
  };

  const customRequest: UploadProps['customRequest'] = async (options: any) => {
    const uid = options.file.uid;
    const res = await postFilesUpload_exKernelAdmin({
      requestBody: {
        file: options.file,
      },
    });
    const currentFileList = [{ ...res.data, uid }];
    let newFileList = [...fileList, ...currentFileList];
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      if (item.uid === uid) {
        item.fileName = res.data!.fileName!;
        item.fileType = res.data!.fileType!;
        item.filePath = res.data!.filePath!;
        break;
      }
    }
    updateFileList(newFileList);

    const filePath = res.data!.filePath!;
    const resUrl = await fetchFilelUrl(filePath);

    newFileList = [...fileList, ...currentFileList];
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      if (item.uid === uid) {
        item.url = resUrl.data;
        item.status = 'done';
        break;
      }
    }
    updateFileList(newFileList);
  };

  const onValueChange = async () => {
    const currentFileList = [...(record.voucher || [])];

    const newFileList = await Promise.all(
      currentFileList.map(async (item): Promise<any> => {
        if (item?.url) return item;
        const res = await fetchFilelUrl(item!);
        const match1 = res.data?.match(/\/([^\/?]+\.[a-zA-Z0-9]+)(?=\?|$)/);
        const name = match1 ? match1[1] : null;
        const match = res.data?.match(/OSSAccessKeyId=([^&]*)/);
        const uuid = match ? match[1] : '';
        const uid = uuid;
        return { filePath: item, url: res.data, name, uid, status: 'done' };
      }),
    );

    updateFileList(newFileList);
  };
  // 同意换汇
  const handleAgree = () => {
    setCurrentStatus(STATUS_MAP[2]);
    modal.confirm({
      title: '提示',
      content: '确认同意换汇？',
      onOk: async () => {
        await postCurrencyExchangeUpdate_exKernelAdmin({
          requestBody: {
            ...record,
            status: STATUS_MAP[2],
          },
        });
        message.success('操作成功');
        setTimeout(() => {
          ctx.actionRef.current.reload();
        }, 0);
        setModuleVisible(false);
      },
    });
  };

  // 退回换汇
  const handleReturn = () => {
    setCurrentStatus(STATUS_MAP[0]);
    modal.confirm({
      title: '提示',
      content: '确认退回换汇？',
      onOk: async () => {
        await postCurrencyExchangeUpdate_exKernelAdmin({
          requestBody: {
            ...record,
            status: STATUS_MAP[0],
          },
        });
        message.success('操作成功');
        setTimeout(() => {
          ctx.actionRef.current.reload();
        }, 0);
        setModuleVisible(false);
      },
    });
  };

  useEffect(() => {
    onValueChange();
  }, [record.voucher]);

  useEffect(() => {
    if (record?.exchangeType === EXCHANGE_TYPE_MAP['换出']) {
      calculateExchangeRate({
        target: {
          value: record.balanceTo,
        },
      });
    } else {
      calculateExchangeRate({
        target: {
          value: record.balanceFrom,
        },
      });
    }
  }, []);

  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url ?? '');
    setPreviewOpen(true);
  };

  return (
    <>
      {isEdit && (
        <Button type="link" onClick={showModal}>
          编辑
        </Button>
      )}
      {isOk && (
        <Button type="link" onClick={handleAgree}>
          同意
        </Button>
      )}
      {isReturn && (
        <Button type="link" onClick={handleReturn}>
          退回
        </Button>
      )}
      {!isEdit && !isOk && !isReturn && '--'}
      <Modal title="换汇" width={600} open={moduleVisible} onOk={handleOk} onCancel={handleCancel}>
        <Form
          initialValues={record}
          form={form}
          labelCol={{
            span: 6,
          }}>
          <Form.Item label="工单号" name="serialNo" rules={[{ required: true }]}>
            <span>{record?.serialNo ?? '--'}</span>
          </Form.Item>
          <Form.Item label="交易账户" name="accountId" rules={[{ required: true }]}>
            <span>{record?.accountId ?? '--'}</span>
          </Form.Item>
          {record?.exchangeType === EXCHANGE_TYPE_MAP['换入'] && (
            <>
              <Form.Item label="换入金额" name="balanceTo" rules={[{ required: true }]}>
                <span>
                  {record?.balanceTo ?? '--'} {record?.currencyTo ?? '--'}
                </span>
              </Form.Item>
              <Form.Item label="换出币种" name="currencyFrom">
                <span>{record?.currencyFrom ?? '--'}</span>
              </Form.Item>
              <Form.Item
                label="换出金额"
                name="balanceFrom"
                rules={[
                  { required: true, message: '请输入换出金额' },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: '请输入正确的金额',
                  },
                ]}>
                <Input onChange={calculateExchangeRate} />
              </Form.Item>
            </>
          )}
          {record?.exchangeType === EXCHANGE_TYPE_MAP['换出'] && (
            <>
              <Form.Item label="换出金额" name="balanceFrom" rules={[{ required: true }]}>
                <span>
                  {record?.balanceFrom ?? '--'} {record?.currencyFrom ?? '--'}
                </span>
              </Form.Item>
              <Form.Item label="换入币种" name="currencyTo">
                <span>{record?.currencyTo ?? '--'}</span>
              </Form.Item>
              <Form.Item
                label="换入金额"
                name="balanceTo"
                rules={[
                  { required: true, message: '请输入换入金额' },
                  {
                    pattern: /^\d+(\.\d{1,2})?$/,
                    message: '请输入正确的金额',
                  },
                ]}>
                <Input onChange={calculateExchangeRate} />
              </Form.Item>
            </>
          )}
          <Form.Item label="实际汇率" name="exchangeRate">
            <span>{currentRate ?? '--'}</span>
          </Form.Item>
          <Form.Item label="市场汇率" name="marketExchangeRate">
            <span>{record.marketExchangeRate ?? '--'}</span>
          </Form.Item>
          <Form.Item
            label="转出银行账户"
            name="bankAccountFrom"
            rules={[{ required: true, message: '请输入转出银行账户' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="转入银行账户"
            name="bankAccountTo"
            rules={[{ required: true, message: '请输入转入银行账户' }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="兑换单（Payslip）"
            name="voucher"
            dependencies={['fileList']}
            rules={[{ required: true, message: '请上传兑换单', type: 'array', min: 1 }]}>
            <Upload
              customRequest={customRequest}
              onRemove={(file) => {
                updateFileList(fileList?.filter((f: any) => f.uid !== file.uid));
              }}
              fileList={fileList}
              onPreview={handlePreview}
              listType="picture-card"
              maxCount={5}>
              <button style={{ border: 0, background: 'none' }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>点击上传</div>
              </button>
            </Upload>
            {previewImage && (
              <Image
                wrapperStyle={{ display: 'none' }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(''),
                }}
                src={previewImage}
              />
            )}
          </Form.Item>
          <Form.Item label="备注" name="remark">
            <Input maxLength={200} />
          </Form.Item>
        </Form>
      </Modal>
      {contextHolder}
    </>
  );
}
