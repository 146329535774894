import { apply } from 'json-logic-js';
import { merge } from 'lodash';
import { z } from 'zod';
export function isBackendDataValid(res) {
    const res1Schema = z.object({
        data: z.array(z.record(z.any())),
        total: z.number(),
    });
    const res2Schema = z.object({
        data: z.array(z.record(z.any())),
        totalCount: z.number(),
    });
    const resSchema = z.union([res1Schema, res2Schema]);
    try {
        resSchema.parse(res);
    }
    catch (error) {
        console.error(error);
        return false;
    }
    return true;
}
export const patchDependenciesJsonLogic = (cols, formType) => {
    const tempCols = merge([], cols);
    tempCols.forEach((col) => {
        col.logics?.forEach((logicItem) => {
            const { functionString, logicFor, logic, truthy, falsy, dependencies = [], formTypes = ['createForm', 'editForm'], } = logicItem;
            if (!formTypes?.includes(formType))
                return;
            if (!dependencies?.length || !logicFor)
                return;
            const isFunctionString = !!functionString;
            const updateOfEvalFunction = (...args) => {
                try {
                    return eval(`(${functionString})`)(...args);
                }
                catch (error) {
                    console.error('logicFunction error:', error);
                    return {};
                }
            };
            if (logicFor === 'columns') {
                const columnsUpdate = (data) => {
                    if (apply(logic, data)) {
                        return truthy || [];
                    }
                    return falsy || [];
                };
                Object.assign(col, {
                    valueType: 'dependency',
                    name: dependencies,
                    columns: isFunctionString ? updateOfEvalFunction : columnsUpdate,
                });
            }
            else {
                const propsUpdate = (form) => {
                    if (apply(logic, form.getFieldsValue())) {
                        return truthy || {};
                    }
                    return falsy || {};
                };
                Object.assign(col, {
                    dependencies,
                    [logicFor]: isFunctionString ? updateOfEvalFunction : propsUpdate,
                });
            }
        });
        delete col.logics;
    });
    return tempCols;
};
export const isActionColumn = (column) => column.title === '操作' || column.dataIndex === '__action__';
export function checkBeforeRequest(apiMap, serviceName) {
    if (typeof serviceName !== 'string') {
        throw new Error('serviceName must be string');
    }
    if (typeof apiMap[serviceName] !== 'function') {
        throw new Error(`Can not find service('${serviceName}') in apiMap, please check`);
    }
    return true;
}
export const transformSchema = (props) => {
    const res = { ...props };
    const traverser = (obj) => {
        for (const key in obj) {
            const isArray = Array.isArray(obj[key]);
            if (isArray) {
                obj[key].forEach((item) => {
                    traverser(item);
                });
                continue;
            }
            const isObject = typeof obj[key] === 'object';
            if (!isObject)
                continue;
            if (obj[key] && obj[key].type === 'JSFunction' && typeof obj[key].value === 'string') {
                obj[key] = eval(`(${obj[key].value})`);
                continue;
            }
            traverser(obj[key]);
        }
    };
    traverser(res);
    return res;
};
export function extractHttpMethodFromServiceName(serviceName) {
    if (!serviceName) {
        return null;
    }
    const pattern = /^(post|get|put|delete|patch|head)\w*/i;
    const match = serviceName.match(pattern);
    return match ? match[1].toLowerCase() : null;
}
