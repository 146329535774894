import { DatePicker, Form, InputNumber, Modal, Select, App, Input } from 'antd';
import { FundTransferBill } from '@/api/types';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useMciRequest from '@/api/useMciRequest';
import { TradingBillNoResp } from '@/api/types';
import { ProTable } from '@ant-design/pro-components';
import {
  postFundFundTranferSave_exKernelAdmin,
  postFundFundTranferUpdate_exKernelAdmin,
  postFilesDelete_exKernelAdmin,
} from '@/api/services';
import { BUSINESS_TYPE_MAP } from '@/pages/Deposit/member/constants';
import FileUpload, { TUploadFile } from '@/pages/Deposit/fund/components/FileUpload';
import { useCurrency } from '@/hooks/useCurrency';

type TModalFlowProps = {
  rowData?: FundTransferBill;
  isShowModal?: boolean;
  modalTitle: string;
  isDetail?: boolean;
  closeEvent: (type?: string) => void;
};

export const ModalFundFlow = (props: TModalFlowProps) => {
  console.log('props', props);
  const { isShowModal, rowData, modalTitle, closeEvent } = props;
  const { data: associatedNoRes } = useMciRequest('getHsbcDepositGetTradingBillNoList_exKernelAdmin', undefined);
  const { data: accountRes } = useMciRequest('getFundFundTranferGetArmAccountInfoList_exKernelAdmin', undefined);
  const [associatedNoOptions, setAssociatedNoOptions] = useState<{ label: string; value: string }[]>([]);
  const [accountOptions, setAccountOptions] = useState<{ label: string; value: string }[]>([]);
  const [tableData, setTableData] = useState<TradingBillNoResp[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalForm] = Form.useForm();
  const { message } = App.useApp();
  const [isRemoving, setIsRemoving] = useState(false);
  const [modalApi, modalContextHolder] = Modal.useModal();
  const { currencyList } = useCurrency({ labelKey: 'label', valueKey: 'label' });
  const [feeAmount, setFeeAmount] = useState<any>();

  const onFormValuesChange = (changedValues: any, allValues: any) => {
    if ('feeAmount' in changedValues) {
      setFeeAmount(changedValues.feeAmount ?? undefined); // 更新 feeAmount 的值
    }
  };

  const getFeeCurrencyRules = () => {
    if (props.isDetail) return [{ required: true, message: '请选择' }];
    return [undefined, '', null].includes(feeAmount)
      ? [{ required: false, message: '请选择' }]
      : [{ required: true, message: '请选择' }];
  };

  const handleOk = () => {
    const data = modalForm.getFieldsValue();

    modalForm
      .validateFields()
      .then(() => {
        const request = rowData?.id ? postFundFundTranferUpdate_exKernelAdmin : postFundFundTranferSave_exKernelAdmin;

        const params = {
          ...data,
          tradeDate: dayjs(data.tradeDate).format('YYYY-MM-DD'),
          associatedNo: data.associatedNo.join(','),
        };


        const paymentAccountName = accountOptions.find((item) => item.value === data.paymentAccountName)?.label ?? '';
        Object.assign(params, { paymentAccountName });

        // 编辑提交将审批状态重新扭转为0-待审核
        if (rowData?.id) {
          params['approvalStatus'] = 0;
          params['id'] = rowData.id;
          params['feeAmount'] = params['feeAmount'] || '';
          params['feeCurrency'] = params['feeCurrency'] || '';
        }

        if (data?.attachmentFile?.length) {
          const attachmentFile =
            data?.attachmentFile?.map((item: any) => {
              // 省略了一些不必要的 upload 信息

              if (!item.filePath) {
                throw new Error('文件路径不存在');
              }

              return {
                fileName: item.fileName,
                filePath: item.filePath,
                fileType: item.fileType,
              };
            }) ?? [];

          if (attachmentFile.length) params['attachmentFile'] = attachmentFile;
        }

        console.log('params', params);
        
        setLoading(true);

        request({
          requestBody: params,
        })
          .then(() => {
            message.success('操作成功');
            closeEvent?.('success');
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch(() => {});
  };

  const closeModal = (type?: string) => {
    closeEvent?.(type);
  };
  const handleCancel = () => {
    closeModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleAssociatedNoChange = (value: string[]) => {
    const list: TradingBillNoResp[] = [];
    value.forEach((billNo: string) => {
      const detail = associatedNoRes?.data?.find((item) => item.tradingBillNo === billNo);
      if (detail) list.push(detail);
    });
    setTableData(list);
  };

  const getDisabled = () => {
    return props.isDetail ? true : false;
  };

  const removeFile = async (file: TUploadFile) => {
    setIsRemoving(true);

    let newAttachment: TUploadFile[] = [...(modalForm?.getFieldValue('attachmentFile') || [])];

    for (let i = 0; i < newAttachment.length; i++) {
      if (newAttachment[i].filePath === file.filePath) {
        newAttachment[i].status = 'uploading';
        break;
      }
    }

    modalForm?.setFieldsValue({ attachmentFile: newAttachment });

    let errMsg = '';
    try {
      const res = await postFilesDelete_exKernelAdmin({
        requestBody: {
          filePath: file.filePath,
        },
      });

      if (res.code !== '0000') {
        errMsg = res.message!;
      }
    } catch (error) {
      errMsg = error + '';
    }

    if (errMsg) {
      message.error(`删除失败: ${errMsg}`);
    } else {
      const attachmentFile = modalForm?.getFieldValue('attachmentFile') || [];
      const newAttachment = attachmentFile.filter((item: any) => item.filePath !== file.filePath);

      // TODO: 验证
      modalForm!.setFieldsValue({
        attachmentFile: newAttachment,
      });
    }
    setIsRemoving(false);
  };

  const handleRemoveFile = (file: TUploadFile) => {
    if (isRemoving) {
      message.error('正在删除中，请稍后');
      return false;
    }
    modalApi.confirm({
      title: '提示',
      content: '确认删除该附件？此行为不可撤销！',
      onOk: () => {
        removeFile(file);
      },
    });
    return false; // 通过异步删除
  };

  const onPayerAcctChange = (val: string, field: 'paymentAccount' | 'paymentAccountName') => {
    const acct = accountOptions.find((item)=> item.value === val);
    modalForm.setFieldValue(field, acct?.value);
  };
  useEffect(() => {
    if (associatedNoRes?.data?.length) {
      const options = associatedNoRes.data.map((item) => ({
        label: item.tradingBillNo!,
        value: item.tradingBillNo!,
      }));
      setAssociatedNoOptions(options);
    }
  }, [associatedNoRes]);

  useEffect(() => {
    if (accountRes?.data?.length) {
      const options = accountRes.data.map((item) => ({
        label: item.acctName!,
        value: item.acctNo!,
      }));

      setAccountOptions(options);

      if (rowData?.id) {
        const associatedNo = rowData.associatedNo?.split(',') ?? [];
        modalForm.setFieldsValue({
          ...rowData,
          associatedNo,
          paymentAccountName: rowData.paymentAccount,
          tradeDate: dayjs(rowData.tradeDate),
          fundsCrossBorder: rowData.fundsCrossBorder?.toString(),
        });
        handleAssociatedNoChange(associatedNo);
        setFeeAmount(rowData.feeAmount ?? ''); // 更新 feeAmount 的值
      }
    }
  }, [accountRes]);

  return (
    <>
      <Modal
        title={modalTitle}
        width={900}
        open={isShowModal}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={loading}
        cancelButtonProps={{
          style: {
            visibility: props.isDetail ? 'hidden' : 'initial'
          }
        }}
        okButtonProps={{
          style: {
            visibility: props.isDetail ? 'hidden' : 'initial'
          }
        }}
       >
          
        <Form {...formItemLayout} form={modalForm} onValuesChange={onFormValuesChange}>
          <p style={{ marginTop: '25px', fontWeight: 800 }}>关联出入金单号</p>
          <Form.Item label="关联出入单号" name="associatedNo" rules={[{ required: true, message: '请选择' }]}>
            <Select
              mode="multiple"
              allowClear
              onChange={handleAssociatedNoChange}
              options={associatedNoOptions}
              showSearch
              filterOption={filterOption}
              disabled={getDisabled()}
            />
          </Form.Item>

          <ProTable
            pagination={false}
            size="small"
            bordered
            columnEmptyText="--"
            toolBarRender={false}
            rowKey="id"
            search={false}
            columns={[
              {
                title: '会员名称',
                dataIndex: 'memberName',
                width: 120,
              },
              {
                title: '会员ID',
                dataIndex: 'memberId',
              },
              {
                title: '交易账户',
                dataIndex: 'tradingAccount',
              },
              {
                title: '出入金场景',
                dataIndex: 'businessType',
                valueType: 'select',
                valueEnum: BUSINESS_TYPE_MAP,
              },
              {
                title: '金额',
                dataIndex: 'amount',
                valueType: {
                  type: 'money',
                  moneySymbol: false,
                  precision: 2,
                },
              },
              {
                title: '币种',
                dataIndex: 'currency',
                width: 70,
              },
              {
                title: '出入金日期',
                dataIndex: 'depositDate',
                width: 120,
              },
            ]}
            dataSource={tableData}
            style={{ width: '100%' }}
          />

          <p style={{ marginTop: '50px', fontWeight: 800 }}>资金调拨信息</p>

          <Form.Item label="是否资金跨境" name="fundsCrossBorder" rules={[{ required: true, message: '请选择' }]}>
            <Select
              allowClear
              options={[
                { label: '是', value: '1' },
                { label: '否', value: '0' },
              ]}
              showSearch
              filterOption={filterOption}
              disabled={getDisabled()}
            />
          </Form.Item>
          <Form.Item
            label="付款账户名称"
            name="paymentAccountName"
            rules={[{ required: true, message: '请选择' }]}
           >
            <Select
              allowClear
              options={accountOptions}
              showSearch
              filterOption={filterOption}
              disabled={getDisabled()}
              onChange={(val)=> onPayerAcctChange(val, 'paymentAccount')}
            />
          </Form.Item>
          <Form.Item label="付款账号" name="paymentAccount" rules={[{ required: true, message: '请选择' }]}>
            <Select
              allowClear
              options={accountOptions}
              fieldNames={{ label: 'value', value: 'value' }}
              showSearch
              filterOption={filterOption}
              disabled={getDisabled()}
              onChange={(val)=> onPayerAcctChange(val, 'paymentAccountName')}
            />
          </Form.Item>
          <Form.Item label="收款账户名称" name="receivingAccountName" rules={[{ required: true, message: '请输入' }]}>
            <Input allowClear style={{ width: '100%' }} disabled={getDisabled()} />
          </Form.Item>
          <Form.Item label="收款账号" name="receivingAccount" rules={[{ required: true, message: '请输入' }]}>
            <Input allowClear style={{ width: '100%' }} disabled={getDisabled()} />
          </Form.Item>
          <Form.Item label="资金调拨金额" name="amount" rules={[{ required: true, message: '请输入' }]}>
            <InputNumber style={{ width: '100%' }} min={0} precision={2} disabled={getDisabled()} />
          </Form.Item>
          <Form.Item label="资金调拨币种" name="currency" rules={[{ required: true, message: '请选择' }]}>
            <Select
              allowClear
              options={currencyList ?? []}
              showSearch
              filterOption={filterOption}
              disabled={getDisabled()}
            />
          </Form.Item>
          <Form.Item label="资金调拨日期" name="tradeDate" rules={[{ required: true, message: '请选择' }]}>
            <DatePicker allowClear style={{ width: '100%' }} disabled={getDisabled()} />
          </Form.Item>
          <Form.Item label="手续费金额" name="feeAmount">
            <InputNumber style={{ width: '100%' }} min={0} precision={2} disabled={getDisabled()} />
          </Form.Item>
          <Form.Item label="手续费币种" name="feeCurrency" rules={getFeeCurrencyRules()}>
            <Select
              allowClear
              options={currencyList ?? []}
              showSearch
              filterOption={filterOption}
              disabled={getDisabled()}
            />
          </Form.Item>
          <Form.Item label="上传附件" name="attachmentFile">
            <FileUpload onRemove={handleRemoveFile} maxCount={1} disabled={getDisabled()} limit={{ fileSizeMB: 50 }} />
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
    </>
  );
};
