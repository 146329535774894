// NOTE: 执行 npm run api 命令的时候，请把下面的代码注释掉，否则会报错
import { resolveServerName, getAppModeFromServerName } from '@/utils';

export type AppMode = 'local' | 'development' | 'staging' | 'uat' | 'production';
type Config = {
  // 生成代码所存放的目录。
  dir: string;
  url?: string;
  projectId?: number;
  urls: {
    /**
     * serviceName（必填） - ID 性质的字符串，在数组中必须具备唯一性。语义上对应后端的一个 api 服务域名
     */
    serviceName: string;
    projectId?: number;
    /**
     * url（必填） - 获取符合 openapi 规范的接口描述文档 url。可以是用相对路径引用的本地文件。
     */
    url?: string;
    /**
     * whitelist(选填) - 需要生成 api 代码的 path。用于筛选出到目前为止，app 所消费的 api 接口，避免生成大量 unused 的代码
     * 如果不填，则会为所提供的接口描述文档中所有的 api 生成相应的前端代码
     */
    whitelist?: string[];
  }[];
};

export const config = {
  // 默认是 './src/api'，如无特殊情况，都不需要修改
  dir: './src/api',
  projectId: 4491517,
  // TODO: 目前生成的 api 代码，没有经过 prettier，而且还有多余的 import，有空 fix 下
  urls: [
    {
      serviceName: 'ex-kernel-admin' as const,
    },
    {
      serviceName: 'mci-toolbox' as const,
      projectId: 4234057,
    },
    {
      serviceName: 'mcexp-operation' as const,
    },
    {
      serviceName: 'mci-dict' as const,
    },
    {
      serviceName: 'mci-user-auth-admin-server' as const,
    },
    {
      serviceName: 'mci-arm' as const,
      projectId: 809138,
    },
    {
      serviceName: 'mci-pay-finance' as const,
      projectId: 809138,
    },
  ],
} satisfies Config;

/** 微服务的名称 */
type ServiceName = (typeof config)['urls'][number]['serviceName'];

const SERVICE_2_GATEWAY_MAP = {
  'ex-kernel-admin': 'admin',
  'mcexp-operation': 'operation',
  'mci-dict': 'dict',
  'mci-user-auth-admin-server': 'user-auth-admin',
  'mci-toolbox': 'toolbox',
  'mci-arm': 'mci-arm',
  'mci-pay-finance': 'pay-finance',
} as { [P in ServiceName]: string };

export function getAxiosBaseUrl(serviceName: ServiceName, mode: AppMode, serverName: string): string {
  let axiosBaseUrl = '';
  // 因为 api.config.ts 文件当前不支持导入（会导致 `npm run api`失败），所以通过 sessionStorage 来跟它通讯
  // TODO: 从源头上去解决问题：要么 api.config.ts 文件支持导入，要么 getAxiosBaseUrl 函数得到的是已经 resolved 的 serverName
  serverName = resolveServerName();
  mode = getAppModeFromServerName(serverName);

  const localServerName = 'dev1';

  switch (serviceName) {
    case 'ex-kernel-admin':
      switch (mode) {
        case 'local':
          if (serverName === 'localMock') {
            axiosBaseUrl = 'http://localhost:4523/m1/809138-0-f6db2245';
          } else if (serverName === 'remoteMock') {
            // axiosBaseUrl = 'https://mock.apifox.com/m1/4234057-0-92849440';

            // TODO: 读取不到环境变量了，jianlin 的配置是从 CLI 传递的，其深的配置是从 dotenv 传递的
            axiosBaseUrl = `https://admin.mcexp-${localServerName}.mcisaas.com/admin`;

            // 何露
            // axiosBaseUrl = 'http://172.22.11.216:28080/admin';
          }
          break;
        case 'development':
        case 'staging':
        case 'uat':
          axiosBaseUrl = `https://admin.mcexp-${serverName}.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        case 'production':
          axiosBaseUrl = `https://admin.microconnect.cn/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        default:
          break;
      }
      break;
    case 'mcexp-operation':
    case 'mci-dict':
    case 'mci-user-auth-admin-server':
      switch (mode) {
        case 'local':
          if (serverName === 'localMock') {
            // axiosBaseUrl = 'http://localhost:4523/m1/809138-0-f6db2245';
          } else if (serverName === 'remoteMock') {
            // axiosBaseUrl = `https://api.mcexp-dev1.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
            axiosBaseUrl = `https://api.mcexp-${localServerName}.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;

            // NOTE: 这是1.5版本的旧版本的地址，如果是2.0的需求用上面那个
            // axiosBaseUrl = `https://exp-admin.mcexp-${localServerName}.mci/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          }
          break;
        case 'development':
        case 'staging':
          axiosBaseUrl = `https://api.mcexp-${serverName}.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        case 'uat':
          axiosBaseUrl = `https://api.mcexp-cnuat1.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        case 'production':
          axiosBaseUrl = `https://apicnprd.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        default:
          break;
      }

      break;
    case 'mci-toolbox':
      // TODO: mci-toolbox 是在另外的一个api host 的

      switch (mode) {
        case 'local':
          if (serverName === 'localMock') {
            axiosBaseUrl = `https://api.mcp-dev1.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          } else if (serverName === 'remoteMock') {
            axiosBaseUrl = `https://api.mcp-${localServerName}.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          }
          break;
        case 'development':
        case 'staging':
        case 'uat':
          axiosBaseUrl = `https://api.mcp-${serverName}.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        case 'production':
          axiosBaseUrl = `https://apimcp.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        default:
          break;
      }
      break;
    case 'mci-arm':
      switch (mode) {
        case 'local':
          axiosBaseUrl = `https://apps-gw.mcexp-dev1.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        case 'development':
        case 'staging':
        case 'uat':
          axiosBaseUrl = `https://apps-gw.mcexp-${serverName}.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}`;
          break;
        case 'production':
          axiosBaseUrl = `https://apps-gw-mcexp.microconnect.cn/mci-arm`;
      }
      break;
    case 'mci-pay-finance':
      switch (mode) {
        case 'local':
          if (serverName === 'localMock') {
            axiosBaseUrl = 'http://localhost:4523/m1/809138-0-default';
          } else if (serverName === 'remoteMock') {
            axiosBaseUrl = 'https://mock.apifox.com/m1/4234057-0-default';
          }
          break;
        case 'development':
        case 'staging':
        case 'uat':
          axiosBaseUrl = `https://${serverName}-api.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}/`;
          break;
        case 'production':
          axiosBaseUrl = `https://api.mcisaas.com/${SERVICE_2_GATEWAY_MAP[serviceName]}/`;
          break;
        default:
          break;
      }
      break;
    default:
      throw new Error(`unknown service name: ${serviceName}`);
  }

  return axiosBaseUrl;
}
