import { AdminFileResp } from '@/api/types';
import {
  FileType,
  canPreviewFileType,
  downloadFile,
  downloadOssFileAsBlob,
  getFileIcon,
  getFileType,
  getOssInfoByFileId,
  getPreviewDocxUrl,
  isAbsUrl,
  openFileInNewTab,
  parseOssFileName,
} from '@/utils/oss-util';
import { Image, Modal, Skeleton, Space, Spin, Tooltip, Typography, message } from 'antd';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { ButtonImage } from './ButtonImage';
import { FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';

export interface PreviewFileProps {
  url?: string;

  fileName?: string;

  fileIcon?: boolean | ReactNode;

  /**
   * mode 浏览模式
   *
   * - preview 预览模式（图片，docx，pdf会尝试预览）
   * - download 下载模式（docx，pdf会下载，图片不变）
   * - tab 新标签打开
   */
  mode?: 'preview' | 'download' | 'tab';

  /**
   * 缩略图空值
   *
   * 有时候图文混排时候，会有点丑，可以强制让图片展示位文字，而不是缩略图
   */
  noThumb?: boolean;

  noDownloadHint?: boolean;

  width?: number;
}

const defaultProps: PreviewFileProps = {
  mode: 'preview',
  width: 150,
  fileIcon: true,
};

// 一些测试用的地址，很难找的调试数据，很重要，勿删
export const mock_PreviewFile = {
  abs: {
    image: 'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png',
  },
  toolbox: {
    image: 'mci-pub-upload-dev1/public/0c42a2ea72914658a1ba018652412c2bbasketball.png',
    docx: 'mci-pub-upload-dev1/public/202404/869271f7029b4559b688a07804cf3478源代码.docx',
    xlsx: 'mci-pub-upload-dev1/public/4429580db1234c6aa23f359e0b137d51file_example_XLSX_10.xlsx',
    pdf: 'mci-pub-upload-dev1/public/202405/91a177f5507d460aa00aea814c3d08fc068-藕田路店.pdf',
    mp4: 'mci-pub-upload-dev1/public/7a91726b8add4f3da35cedc972ac84dcfile_example_MP4_480_1_5MG.mp4',
    pdf_stg3:
      'mci-pub-upload-stg3/numa-pay-platform/receipt/cncb/9f3ca193f69a413a852cb80d6be9f98f20240605SG0D0004890001000001.pdf',
    // - 示例实际 ossUrl
    // https://mci-pub-upload-stg3.oss-cn-shenzhen.aliyuncs.com/numa-pay-platform/receipt/cmb/983087238345015326exInvest4707349986492868689.pdf?Expires=1717641529&OSSAccessKeyId=LTAI5tDKjMnXVz8d8ZZ9Nejw&Signature=38ROajhPjJLz9MgolJBdgrlUHtI%3D
  },

  // 交易所的配置
  exmtOss: {
    image: '1795275191548567553',
    xlsx: '1798259690094399489',
    pdf: '1795343348971839490',
  },
};

/**
 * 文件预览
 *
 * 默认附带一个预览的触发器
 *
 * 支持的路径
 * - 支持 mci-toolbox 的路径
 * - 支持交易所的文件id模式的路径
 * - 支持绝对路径
 */
export const PreviewFile: FC<PreviewFileProps> = (props) => {
  const fullProps: PreviewFileProps = { ...defaultProps, ...props };
  const mode = fullProps.mode;
  const url = fullProps.url;

  // -- TEST --
  // const url = mock_PreviewFile.toolbox.xlsx;
  // const url = mock_PreviewFile.exmtOss.xlsx;

  const width = fullProps.width!;

  const [ossUrl, setOssUrl] = useState('');
  const [hasError, setHasError] = useState(false);
  const ossInfo = useRef<AdminFileResp>(); // 只有使用filekey模式时候才会存在
  const [isPreview, setIsPreview] = useState(false);

  // -- url 的 meta 信息
  const isAbs = url ? isAbsUrl(url) : false;

  let fileType: FileType = 'unknown';
  if (url) {
    if (isAbs) {
      fileType = getFileType(url);
    } else if (ossInfo.current) {
      fileType = getFileType(ossInfo.current.fileName!);

      if (fileType === 'unknown') {
        console.error(`unknown fileType, fileName:${ossInfo.current.fileName}`);
      }
    }
  }

  useEffect(() => {
    // 每次 url 变化都要重新获取 ossUrl
    setOssUrl('');

    // 每次变化重新尝试获取 ossInfo
    setHasError(false);

    if (!url) return;

    if (!isAbs) {
      getOssInfoByFileId(url)
        .then((info) => {
          ossInfo.current = info;
          setOssUrl(info.ossUrl!);
        })
        .catch((err) => {
          setHasError(true);
          // 记录不存在
          console.error(err);
        });
    } else {
      setOssUrl(url);
    }
  }, [props.url]);

  const getContent = () => {
    const getImageContent = () => {
      if (fullProps.noThumb) {
        return <ButtonImage src={ossUrl} />;
      }
      return <Image width={width} src={ossUrl} />;
    };

    const getNotImageContent = () => {
      const fileName = fullProps.fileName || ossInfo.current?.fileName || parseOssFileName(url!);
      const Text = Typography.Text;

      const onClick = () => {
        const preview = (absUrl: string) => {
          // 如果不能预览，默认进度兜底下载
          let canPreview = canPreviewFileType(fileType);

          // NOTE: 新版交易所的文件oss不支持预览，官方微软的也不行，但是旧版的可以，暂时关闭，有空排查下
          if (fileType === 'docx') {
            canPreview = false;
          }

          if (mode === 'preview' && canPreview) {
            setIsPreview(true);
          } else if (mode === 'tab') {
            openFileInNewTab(absUrl);
          } else {
            // downloadFile(absUrl, ossInfo.current?.fileName ?? '');
            downloadOssFileAsBlob(absUrl, fileName);

            // TODO: 浏览器有的会阻止多个文件下载，看看能否获取到这个信息
            if (!fullProps.noDownloadHint) {
              message.success('触发文件下载');
            }
          }
        };

        isAbs ? preview(url!) : getOssInfoByFileId(url!).then((ossInfo) => preview(ossInfo.ossUrl!));
      };

      let icon: ReactNode = null;
      if (fullProps.fileIcon) {
        icon = typeof fullProps.fileIcon === 'boolean' ? getFileIcon(fileType) : fullProps.fileIcon;
      }

      return (
        <Tooltip title={fileName}>
          <Text style={{ maxWidth: width }} ellipsis={true}>
            <a onClick={onClick}>
              <Space size={4}>
                {icon}
                {fileName}
              </Space>
            </a>
          </Text>
        </Tooltip>
      );
    };

    const onCloseModal = () => {
      setIsPreview(false);
    };

    // 1. 如果是绝对路径直接展示 Image
    // 2. 没拿到 ossInfo 前展示 loading
    // 3. 如果是图片类型，展示 Image，否则展示 a 标签
    const getModalContent = () => {
      if (!ossUrl) {
        return <Skeleton.Button active />;
      }

      // 绝对路径没有 ossInfo，不需要等待
      if (isAbs) {
        return fileType === 'image' ? getImageContent() : getNotImageContent();
      }

      if (!ossInfo.current) {
        return <Skeleton.Button active />;
      }

      return fileType === 'image' ? getImageContent() : getNotImageContent();
    };

    const getIframeSrc = () => {
      let src = '';
      // 一些内置的媒体类型，是可以直接展示的
      if (fileType === 'pdf' || fileType === 'video' || fileType === 'audio') {
        src = ossUrl;
      } else {
        src = getPreviewDocxUrl(ossUrl);
      }

      return src;
    };

    return (
      <>
        {isPreview && (
          <Modal
            // NOTE: 这个要前置，因为iframe需要合适的类型合适的时机才能触发
            // open={isPreview}
            open={true}
            onOk={onCloseModal}
            onCancel={onCloseModal}
            okText="关闭"
            closeIcon={false}
            footer={(originNode, extra) => {
              return <extra.OkBtn />;
            }}
            style={{ width: '80vw', minWidth: 1200 }}
            // onCancel={props.onCancel}
          >
            <iframe style={{ border: 'none', height: '80vh' }} src={getIframeSrc()} width="100%" height="100%" />
          </Modal>
        )}

        {getModalContent()}
      </>
    );
  };

  // TODO: 为什么不能直接返回字符串？会报错
  return url && !hasError ? getContent() : <>--</>;
};

export const previewFileColumnRender = (value: any, record?: any) => {
  const isStr = typeof value === 'string';
  const urls: string[] = value ? (isStr ? [value] : value) : [];

  return (
    <Space wrap align="start">
      {urls.length
        ? urls.map((url, index) => {
            return <PreviewFile key={index} url={url} />;
          })
        : '--'}
    </Space>
  );
};

/**
 * 自定义一些预览参数
 *
 * 只支持全局配置，因此 url fileName 会被忽略
 */
export const getPreviewFileColumnRenderWithOptions = (opts: PreviewFileProps) => {
  return (value: any, record?: any) => {
    const isStr = typeof value === 'string';
    const urls: string[] = value ? (isStr ? [value] : value) : [];

    return (
      <Space wrap align="start">
        {urls.length
          ? urls.map((url, index) => {
              return <PreviewFile {...opts} key={index} url={url} />;
            })
          : '--'}
      </Space>
    );
  };
};
