import { toNumLocaleString } from '@mci-fe/utils/math';

/**
 * -- 格式化类 --
 *
 * 针对 columns 配置
 * 空值返回 undefined 让内部使用 emptyText
 *
 */
class Formatter {

  /**
   * 格式化金钱
   * 
   * 保留小数，并配置千分位
   * 
   * 原版的 columns valueType.money 会有几个问题
   * - 自动加上货币符号
   * - 如果不做任何配置，无论如何都保留两位小数
   * - valueType 配置的 precision 不会生效
   * - filedProps.precision 会生效，但是超过3位如果尾部有0，最少可以展示2位
   *   如果 <= 2 ，按照配置为准 
   */
  moneyColumn(precision = 2) {
    // column 的 renderText 会传递 record 作为第二个参数，需要柯里化
    return (n: string | number) => this.moneyToFixed(n, precision);
  }

  moneyToFixed(n: string | number, precision = 2) {
    return toNumLocaleString(n, precision);
  }
}

export const formatter = new Formatter();


/**
 * -- 格式化类 --
 *
 * uuid，前端生成唯一id
 *
 */
export const uuidCreate = () => {
  const hexDigits = "0123456789abcdef";
  const s: string[] = [];
  
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  
  s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((parseInt(s[19], 16) & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  const uuid = s.join("");
  return uuid;
};