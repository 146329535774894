import { ProColumns, ProTable, ProTableProps, ActionType } from '@ant-design/pro-components';
import { sleep } from '@mci-fe/utils';
import { TabsProps, Tabs, Button, Tooltip } from 'antd';
import { FC, useRef, useState } from 'react';
import { OrderManualCancel } from '../OrderManualFromIOAOSO/OrderManualCancel';
import { OrderManualOrder } from '../OrderManualFromIOAOSO/OrderManualOrder';
import { postAdminV1OrderManualBlockTradeList_mcexpOperation } from '@/api/services';

/**
 * 订单管理-手工下单-大宗交易
 */
export const OrderManualFromBlockTrade: FC = () => {
  const [loading, setLoading] = useState(false);

  const [openManualCancel, setOpenManualCancel] = useState(false);
  const [batchType, setBatchType] = useState('canCancel');
  const [openManualOrder, setOpenManualOrder] = useState(false);
  const [currPageSize, setCurrPageSize] = useState(10);

  const refTable = useRef<ActionType>();
  const tableLoading = loading;

  // TODO: ts type
  const selectedRows = useRef<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const columns: ProColumns<any>[] = [
    {
      title: '序号',
      dataIndex: 'index',
      valueType: 'indexBorder',
      width: 80,
      fixed: 'left',
    },
    {
      title: '下单日期',
      dataIndex: 'createTime',
      valueType: 'date',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '批次号',
      dataIndex: 'batchId',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '订单号',
      dataIndex: 'orderCodeName',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '产品编码',
      dataIndex: 'productCode',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '卖方会员ID',
      dataIndex: 'memberCodeSell',
      fixed: 'left',
      hideInSearch: true,
    },
    {
      title: '卖方会员名称',
      dataIndex: 'identificationNameSell',
      hideInSearch: true,
    },
    {
      title: '产品分类',
      dataIndex: 'productType',
      valueEnum: {
        1: { text: 'DRO' },
        2: { text: 'DRP' },
      },
      hideInSearch: true,
    },
    {
      title: '卖方交易账号',
      dataIndex: 'tradingAccountCodeSell',
      hideInSearch: true,
    },
    {
      title: '交易金额',
      dataIndex: 'amount',
      hideInSearch: true,
    },
    {
      title: '交易份额',
      dataIndex: 'shares',
      hideInSearch: true,
    },
    {
      title: '币种',
      dataIndex: 'currency',
      hideInSearch: true,
    },
    {
      title: '买方会员ID',
      dataIndex: 'memberCodeBuy',
      hideInSearch: true,
    },
    {
      title: '买方会员名称',
      dataIndex: 'identificationNameBuy',
      hideInSearch: true,
    },
    {
      title: '买方交易账号',
      dataIndex: 'tradingAccountCodeBuy',
      hideInSearch: true,
    },
    {
      title: '审批状态',
      dataIndex: 'auditStatus',
      valueType: 'select',
      valueEnum: {
        0: '待审批',
        1: '通过',
        2: '审批驳回',
        3: '取消',
      },
      hideInSearch: true,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatus',
      valueType: 'select',
      valueEnum: {
        '-1': '下单失败',
        '1': '有效',
        '5': '取消',
        '4': '未成交',
        '2': '部分成交',
        '3': '全部成交',
      },
      hideInSearch: true,
      render: (text, record) => {
        return record.orderStatus === '-1' ? (
          <Tooltip placement="topLeft" title={record.orderMsg || '暂无原因'}>
            <span>{text}</span>
          </Tooltip>
        ) : (
          <span>{text}</span>
        );
      },
    },
    {
      title: '更新时间',
      dataIndex: 'updateTime',
      hideInSearch: true,
      valueType: 'dateTime',
    },
    {
      title: '申请人',
      dataIndex: 'applyUser',
      hideInSearch: true,
    },
    {
      title: '审批人',
      dataIndex: 'auditUser',
      hideInSearch: true,
    },
    // -- search --
    {
      title: '下单时间',
      dataIndex: 'createTime',
      valueType: 'dateRange',
      hideInTable: true,
    },
    {
      title: '批次号',
      dataIndex: 'batchId',
      hideInTable: true,
    },
    {
      title: '审批状态',
      dataIndex: 'auditStatusList',
      valueType: 'select',
      valueEnum: {
        0: '待审批',
        1: '通过',
        2: '审批驳回',
        3: '取消',
      },
      fieldProps: {
        mode: 'multiple',
      },
      hideInTable: true,
    },
    {
      title: '卖方会员名称',
      dataIndex: 'identificationNameSell',
      hideInTable: true,
    },
    {
      title: '买方会员名称',
      dataIndex: 'identificationNameBuy',
      hideInTable: true,
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatusList',
      valueType: 'select',
      valueEnum: {
        '-1': '下单失败',
        '1': '有效',
        '5': '取消',
        '4': '未成交',
        '2': '部分成交',
        '3': '全部成交',
      },
      fieldProps: {
        mode: 'multiple',
      },
      hideInTable: true,
    },
    {
      title: '产品编码',
      dataIndex: 'productCodeList',
      valueType: 'select',
      fieldProps: {
        placeholder: '多个编码之间用空格或者英文逗号分隔',
        mode: 'tags',
        tokenSeparators: [' ', ','],
        open: false,
      },
      hideInTable: true,
      ellipsis: true,
    },
    {
      title: '产品分类',
      dataIndex: 'productTypeList',
      valueType: 'select',
      valueEnum: {
        1: 'DRO',
        2: 'DRP',
      },
      fieldProps: {
        mode: 'multiple',
      },
      hideInTable: true,
    },
  ];

  const clearSelectedRows = () => {
    // TODO: 有一个 actionRef 貌似也可以清空，看看是否可以
    setSelectedRowKeys([]);
  };

  const request: ProTableProps<any, any>['request'] = async (params) => {
    setLoading(true);

    // string 转 int
    if (params.auditStatusList) {
      params.auditStatusList = params.auditStatusList.map((item: string) => parseInt(item));
    }
    if (params.orderStatusList) {
      params.orderStatusList = params.orderStatusList.map((item: string) => parseInt(item));
    }
    if (params.productTypeList) {
      params.productTypeList = params.productTypeList.map((item: string) => parseInt(item));
    }

    let error = '';
    const resp = await postAdminV1OrderManualBlockTradeList_mcexpOperation({
      requestBody: {
        page: params.current,
        size: params.pageSize,
        createTimeStart: params.createTime?.[0],
        createTimeEnd: params.createTime?.[1],
        batchId: params.batchId,
        auditStatusList: params.auditStatusList,
        identificationNameSell: params.identificationNameSell,
        identificationNameBuy: params.identificationNameBuy,
        productCodeList: params.productCodeList,
        productTypeList: params.productTypeList,
        orderStatusList: params.orderStatusList,
      },
    }).catch((e) => (error = e.message));

    setLoading(false);

    if (error) {
      return {
        data: [],
        total: 0,
        success: true,
      };
    }
    if (resp?.data?.content?.length > 0) {
      resp.data.content = resp.data.content.map((item: any) => {
        return {
          ...item,
          orderStatus: item.orderStatus?.toString(),
        };
      });
    }

    return {
      data: resp?.data?.content || [],
      total: resp?.data?.page!.totalElements || 0,
      success: true,
    };
  };

  // 打开批量操作弹窗
  const onBatchClick = (type: string) => {
    setOpenManualCancel(true);
    setBatchType(type);
  };

  // 关闭批量操作弹窗
  const closeManualCancel = () => {
    setOpenManualCancel(false);
    setBatchType('');
  };

  // 关闭批量下单弹窗
  const closeManualOrder = () => {
    setOpenManualOrder(false);
  };

  // 刷新表格
  const refreshTable = () => {
    clearSelectedRows();
    refTable?.current?.reload();
  };

  const toolBarRender: ProTableProps<any, any>['toolBarRender'] = (actions, { selectedRows }) => {
    const haveSelected = selectedRows && selectedRows.length > 0;

    return [
      <Button disabled={!haveSelected} onClick={() => onBatchClick('canCancel')} loading={tableLoading} key="cancel">
        批量取消
      </Button>,
      <Button disabled={!haveSelected} onClick={() => onBatchClick('canAudit')} loading={tableLoading} key="audit">
        批量审批
      </Button>,
      <Button disabled={!haveSelected} onClick={() => onBatchClick('canRevoke')} loading={tableLoading} key="revoke">
        批量撤单
      </Button>,
      <Button onClick={() => setOpenManualOrder(true)} loading={tableLoading} key="order">
        批量下单
      </Button>,
    ];
  };

  return (
    <>
      <ProTable
        actionRef={refTable}
        cardBordered
        columns={columns}
        size="small"
        scroll={{ x: 2800 }}
        tableAlertRender={false}
        rowKey="id"
        toolBarRender={toolBarRender}
        search={{
          defaultCollapsed: false,
          labelWidth: 'auto',
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (keys, rows) => {
            setSelectedRowKeys(keys);
            selectedRows.current = rows;
          },
        }}
        request={request}
        pagination={{
          pageSize: currPageSize,
          onShowSizeChange: (current, selectedSize) => {
            setCurrPageSize(selectedSize);
          },
          pageSizeOptions: [10, 20, 50, 100, 200, 500],
        }}
      />
      {openManualCancel && (
        <OrderManualCancel
          type={batchType}
          selectedRows={selectedRows.current}
          onCancel={closeManualCancel}
          refreshTable={refreshTable}
        />
      )}
      {openManualOrder && <OrderManualOrder onCancel={closeManualOrder} refreshTable={refreshTable} />}
    </>
  );
};
