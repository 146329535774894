import { DroToBeListing } from '@/pages';
import { SystemAuthDto, RoleInfoDto } from '@mci-fe/user-auth';
export const accessConfig = {
  canResend(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出资 重发
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000501');
  },
  canConfirm(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出资 确认
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000502');
  },
  canImportFlow(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出入金 导入流水
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000505');
  },
  canExportFlow(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出入金 导出流水
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000507');
  },
  canEditFlow(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出入金 编辑
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000506');
  },
  canDepositAuditButton(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出入金 通过不通过
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000503');
  },
  canExportDepositFlow(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出入金 通过不通过
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000507');
  },
  // 财务
  isFinance(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return roleInfoList.some((item) => String(item.roleCode) === '10000004');
  },
  // 运营
  isOperate(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return roleInfoList.some((item) => String(item.roleCode) === '10000001');
  },
  canRetry(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    // 出入金 重试
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000504');
  },
  //待上架查询
  droToBeListingView(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000701');
  },

  //待上架导出
  droToBeListingExport(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000702');
  },

  //待上架查看详情
  droToBeListingDetail(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000703');
  },

  //待上架已阅知
  droToBeListingRead(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000704');
  },

  //已上架查询
  droListedView(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000801');
  },

  //已上架导出
  droListedExport(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000802');
  },

  //已上架查看详情
  droListedDetail(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000803');
  },

  //已上架下架
  droListedDown(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000804');
  },

  //待发送邮件查询
  toBeSentView(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000901');
  },

  //待发送邮件审核
  toBeSentAudit(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000902');
  },

  //待发送邮件删除
  toBeSentDel(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100000903');
  },

  //已发送邮件查看
  sentDetail(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001001');
  },

  //已发送邮件查询
  sentView(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001002');
  },

  //签约主体查询
  sentSignView(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001101');
  },

  //签约主体新增
  sentSignAdd(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001102');
  },

  //签约主体详情
  sentSignDetail(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001103');
  },

  //签约主体修改
  sentSignEdit(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001104');
  },

  //签约主体关联渠道
  sentSignAssociation(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001105');
  },

  //角色授权
  saveUserRole(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001502');
  },
  // 角色管理新增
  roleAdd(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001602');
  },

  // 角色管理修改
  roleEdit(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && item.authCode === '100001603');
  },

  // 是否是财务人员
  isFinancialRole(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return roleInfoList.some((item) => String(item.roleCode) === '10000004');
  },

  // 是否是运营人员
  isOperatorRole(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return roleInfoList.some((item) => String(item.roleCode) === '10000001');
  },

  // SPV 出入金 - 导入流水
  canImportFlow_spv(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002102');
  },

  // SPV 出入金 - 审核
  canAuditFlow_spv(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002104');
  },

  // SPV VA 入金 - 认款
  canConfirmFlow_spv_va(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002202');
  },
  // 换汇列表-同意
  exchangeRates_ok(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002301');
  },
  // 换汇列表-退回
  exchangeRates_return(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002302');
  },
  // 换汇列表-编辑
  exchangeRates_edit(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002303');
  },
  // ARM验收报告 - 验收权限
  armReport_accept(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002501');
  },
  // ARM验收报告 - 查看权限
  armReport_view(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002502');
  },

  // 系统换汇
  exchangeRates_systemFx(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002304');
  },

  // 人工换汇
  exchangeRates_manualFx(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002305');
  },

  // 同意
  exchangeRates_approve(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002306');
  },

  // 退回
  exchangeRates_reject(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002307');
  },

  // 换汇列表 - 编辑
  exchangeRates_editList(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002308');
  },
  // 换汇列表 - 新增
  exchangeRates_addList(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002309');
  },
  // 资金调拨-审批
  fundAllocation_approval(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002401');
  },
  // 资金调拨-录入
  fundAllocation_import(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002402');
  },
  // 资金调拨-导出
  fundAllocation_export(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002403');
  },
  // 资金调拨-编辑
  fundAllocation_edit(roleInfoList: RoleInfoDto[], authCodeList: SystemAuthDto[]) {
    return authCodeList.some((item) => item.authType === 'BUTTON' && String(item.authCode) === '100002404');
  },
};

export type AccessConfigType = typeof accessConfig;
