import { useState } from 'react';
import { ModalFlow } from '@/components/ModalFlow';
import { AccessConfigType } from '@/config/access';
import { useAccess } from '@mci-fe/user-auth';

export default function EditFlow({ record, ctx }: any) {
  const [modalStatus, setModalStatus] = useState(false);
  const access = useAccess<AccessConfigType>();
  const editEvent = () => {
    setModalStatus(true);
  };
  const onCloseEvent = (type?: string) => {
    if (type === 'success') {
      ctx.actionRef.current.reload(); // 刷新
    }
    setModalStatus(false);
  };
  return (
    <>
      {record?.legalStatus === -1 && access.canEditFlow && access.isOperate && <a onClick={editEvent}>编辑</a>}
      {modalStatus && (
        <ModalFlow isShowModal={modalStatus} rowData={record} modalTitle="编辑" closeEvent={onCloseEvent}></ModalFlow>
      )}
    </>
  );
}
