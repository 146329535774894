import { createElement } from 'react';
import { Button } from '@alifd/next';
import './index.scss';
const ColorfulButton = function ColorfulButton({ type = 'primary', color, style = {}, ...otherProps }) {
    const _style = style || {};
    if (color) {
        _style.backgroundColor = color;
    }
    const _otherProps = otherProps || {};
    _otherProps.style = _style;
    return (createElement(Button, { type: type, ..._otherProps }, "fusion button"));
};
ColorfulButton.displayName = 'ColorfulButton';
export default ColorfulButton;
