import { App, Button } from 'antd';
import { useState, MutableRefObject } from 'react';
import { ContractModify } from './ContractModify';
import { ActionType } from '@ant-design/pro-components';
import { ContractEntity } from '@/api/types';
import { ContractChannel } from './ContractChannel';

export default function ContractAddBtn({ actionRef }: { actionRef: MutableRefObject<ActionType> }) {
  const [open, setOpen] = useState(false);
  const { message } = App.useApp();
  const [channelOpen, setChannelOpen] = useState(false);
  const [record, setRecord] = useState<ContractEntity>();

  // TODO: 待验证
  const onChannelFinish = () => {
    // table 并不会展现，因此不需要 reload
    message.success('关联渠道完成');
    setChannelOpen(false);
    setRecord(undefined);
  };

  const onChannelCancel = () => {
    setChannelOpen(false);
    setRecord(undefined);
  };

  const onFinish = (contractEntity?: ContractEntity) => {
    message.success('新增成功');
    actionRef.current?.reload();
    setOpen(false);
    setRecord(contractEntity);
    setChannelOpen(true);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}>
        新增
      </Button>
      {/* TODO: 现在 cmd + click 代码跳转每次要有两个，看能否优化下  */}
      {open && <ContractModify onFinish={onFinish} onCancel={onCancel} />}

      {channelOpen && <ContractChannel record={record} onFinish={onChannelFinish} onCancel={onChannelCancel} />}
    </>
  );
}
