import '../../styles/index.scss';
const configProvider = {
    api: {}
};
export const setConfigProvider = (config)=>{
    Object.assign(configProvider, config);
};
export const getConfigProvider = ()=>{
    return Object.assign({}, configProvider);
};
