import { Modal as OriginalModal } from 'antd';
const Modal = (props) => {
    const innerProps = {};
    if (props.__designMode === 'design') {
        innerProps.open = true;
    }
    return createElement(OriginalModal, { ...props, ...innerProps });
};
Modal.info = OriginalModal.info;
Modal.success = OriginalModal.success;
Modal.error = OriginalModal.error;
Modal.warning = OriginalModal.warning;
Modal.warn = OriginalModal.warn;
Modal.confirm = OriginalModal.confirm;
export default Modal;
