import { getEnumBatchQuery_mciDict, getEnumQuery_mciDict } from '@/api/services';
import { ProSchemaValueEnumMap, ProSchemaValueEnumObj, ProSchemaValueEnumType } from '@ant-design/pro-components';
import { useState, useEffect } from 'react';

export type EnumDict = Record<string /** enum key */, string>;

/**
 * 枚举工具
 *
 * 需要一个全局的空置的枚举
 * 不然不同弹窗用到相同的枚举需要重复请求
 */
export class EnumUtil {
  static init() {
    if (typeof window !== 'undefined') {
      if (window.enumUtil) {
        console.warn('EnumUtil has been initialized');
        return;
      }
      const enumUtil = new EnumUtil();
      window.enumUtil = enumUtil;
      window.useEnum = _useEnum;

      enumUtil.preload();
    }
  }

  cacheMap: Record<string /** enum dict key */, EnumDict> = {};

  preloadKeys: string[] = [
    // 期数 出资状态
    'FUNDING_INVEST_PERIOD',

    // DRO 出资状态
    'FUNDING_INVEST_DRO',
  ];

  async preload() {
    let error = '';

    const resp = await getEnumBatchQuery_mciDict({
      queryParams: {
        types: this.preloadKeys,
      },
      // preload 的错误不提示
      configOverride: {
        isShowHint: false,
      },
    }).catch((e) => {
      error = e.message;
    });

    if (error) {
      console.error('[enum] preload error, ', error);
      return;
    }

    resp!.data!.forEach((item) => {
      const dictType = item.type!;
      if (!this.cacheMap[dictType]) {
        this.cacheMap[dictType] = {};
      }
      this.cacheMap[dictType][item.enumValue!] = item.enumDesc!;
    });

    console.log('[enum] preload done', this.cacheMap);
  }

  async loadKey(key: string, force: boolean = false) {
    if (this.cacheMap[key] && !force) {
      return;
    }
    this.cacheMap[key] = {};

    const resp = await getEnumQuery_mciDict({
      queryParams: {
        type: key,
      },
    });

    resp.data!.forEach((item) => {
      this.cacheMap[key][item.enumValue!] = item.enumDesc!;
    });
  }

  async getKey(key: string, force: boolean = false) {
    if (!this.cacheMap[key] || force) {
      await this.loadKey(key, force);
    }
    return this.cacheMap[key];
  }
}

export interface UseEnumOptions {
  force?: boolean;
  clone?: boolean;

  // 可以转换为 {text: '枚举1', status: }
  transform?: (dict: EnumDict) => any;
}

const _useEnum = (key: string, opts: UseEnumOptions = {}) => {
  const [enumDict, setEnumDict] = useState<ProSchemaValueEnumObj>({});

  // 无特别需要，其实一般用不上，比如搜索过早点开一进来展开可能是空的
  const [loading, setLoading] = useState(true);

  const { force = false, clone = false } = opts;

  useEffect(() => {
    if (!enumUtil) {
      console.error('enumUtil is not initialized');
      return;
    }
    enumUtil.getKey(key, force).then((dict) => {
      setLoading(false);
      // TODO: react 提供的 setState 是否默认就是克隆的？
      let obj = clone ? { ...dict } : dict;
      obj = opts.transform ? opts.transform(obj) : obj;
      setEnumDict(obj);
    });
  }, []);

  // TODO: 接入 error 方便业务处理
  return { enumDict, loading };
};

declare global {
  var enumUtil: EnumUtil;
  var useEnum: typeof _useEnum;
}
