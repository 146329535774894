import { Tag } from 'antd';

type TaskType = '出金' | '入金' | '划出';

export function TaskFormatter({ record, value }: { value: TaskType; record: any }) {
  const taskType = record.taskType as TaskType;
  // TODO: 这个动态枚举可能没法做了，因为是后端下拉的
  const value2TagColor = {
    ['出金']: 'orange',
    ['入金']: 'blue',
    ['划出']: 'red'
  };
  return <Tag color={value2TagColor[taskType] || 'default'}>{value}</Tag>;
}
