import useMciRequest from '@/api/useMciRequest';
import { Space, App } from 'antd';
import { PopoverConfirm } from './PopoverConfirm';
import { access, useUserInfo } from '@mci-fe/user-auth';

export const FundAllocationApproval = (props: { record: any; action: any; ctx: any }) => {
  const { record } = props;
  const { runAsync } = useMciRequest(
    'postFundFundTranferUpdate_exKernelAdmin',
    {
      requestBody: {
        id: record?.id,
      },
    },
    { manual: true },
  );
  const { message } = App.useApp();
  /**
   * 执行确认
   *
   * ok 转换为 0-待审核，1-审核通过，2-审核不通过
   */
  const handleConfirm = async (approvalStatus: 1 | 2) => {
    let error: string = '';
    // 状态 0-待审核 1-审核通过 2-审核不通过
    await runAsync({
      requestBody: {
        id: record?.id,
        approvalStatus
      },
    }).catch((e) => {
      error = e.message;
    });

    if (error) {
      return;
    }

    message.success('处理成功');

    props.ctx.actionRef.current?.reload();
  };

  // 数据核验通过 & 状态为待审核 & 有审核权限 & 角色为财务 皆满足则审核相关按钮可见
  const getApprovalBtnsVisible = () => {
    if (record.dataState === 1 && record?.approvalStatus === 0 && access.fundAllocation_approval && access.isFinance) {
      return true;
    }
    return false;
  };

  return (
    <>
      {getApprovalBtnsVisible() && (
        <Space>
          <PopoverConfirm
            onConfirm={() => {
              handleConfirm(1);
            }}
            triggerText="通过"
            description="确定通过？"
            buttonHeight="auto"
          />
          <PopoverConfirm
            onConfirm={() => {
              handleConfirm(2);
            }}
            triggerText="不通过"
            description="确定不通过？"
            buttonHeight="auto"
          />
        </Space>
      )}
    </>
  );
};
