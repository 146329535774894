import { PopoverConfirm } from '@/pages/Deposit/member/PopoverConfirm';
import { Space, App } from 'antd';
import { putAdminV1DroOffShelf_mcexpOperation } from '@/api/services';
import useMciRequest from '@/api/useMciRequest';
import { access } from '@mci-fe/user-auth';

export default function OffShelf({ record, ctx }: any) {
  const { message } = App.useApp();
  const { loading, runAsync: offShelfEvent } = useMciRequest('putAdminV1DroOffShelf_mcexpOperation', undefined, {
    manual: true,
  });
  const handleConfirm = () => {
    offShelfEvent({
      queryParams: {
        droCode: record?.droCode,
      },
    }).then(() => {
      message.success('操作成功');
      ctx.actionRef.current.reload(); // 刷新
    });
  };
  return (
    <>
      {record?.canOffShelf && access.droListedDown && (
        <Space>
          <PopoverConfirm
            onConfirm={handleConfirm}
            triggerText="下架"
            title="下架"
            description="下架后不可恢复，请确认！"
            buttonHeight="auto"
          />
        </Space>
      )}
    </>
  );
}
