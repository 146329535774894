import { Button, message, Tooltip } from 'antd';
import { downloadOssFileAsBlob, getOssFileName } from '@/utils/oss-util';
import { getFileGetFullPath_mciArm } from '@/api/services';

export default function SpvFiles({ record }: any) {
  const fetchFilelUrl = async (filePath: string) => {
    return getFileGetFullPath_mciArm({
      queryParams: { filePath },
    });
  };
  const handleDownload = async (filePath: string, name: string) => {
    try {
      const url = await fetchFilelUrl(filePath);
      await downloadOssFileAsBlob(url.data!, name);
      message.success('操作成功');
    } catch (error) {
      message.error('下载失败，请重试');
    }
  };
  return (
    <div className="max-w-full">
      {record?.filePaths?.map?.((file: any) => {
        const cleanedUrl = file.split('?')[0];
        const name = getOssFileName(cleanedUrl);
        return (
          <Tooltip title={name}>
            <Button className="w-full" type="link" onClick={() => handleDownload(file, name)}>
              <span
                className="w-full"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}>
                {name}
              </span>
            </Button>
          </Tooltip>
        );
      }) ?? '--'}
    </div>
  );
}
