import ReactRenderer from '@alilc/lowcode-react-renderer';
import { AssetLoader } from '@alilc/lowcode-utils';
import { MciPageProProvider, MciProTable } from '../../index';
import * as antd from 'antd';
import { forwardRef, createElement } from 'react';
function withWrap(Comp) {
    return forwardRef(function (props, ref) {
        const { forwardRef, ...restProps } = props;
        return createElement(Comp, { ref: ref, ...restProps });
    });
}
export default function LCRenderer(props) {
    const { components, schema, ...restProps } = props;
    const componentList = components || LCRenderer.components;
    return (createElement(MciPageProProvider, { ...restProps, components: componentList },
        createElement(ReactRenderer, { schema: schema, components: componentList })));
}
LCRenderer.components = {
    MciProTable: withWrap(MciProTable)
};
LCRenderer.init = async (loadLib = false) => {
    if (loadLib) {
        const libraryAsset = ['https://cdnjs.cloudflare.com/ajax/libs/dayjs/1.11.10/dayjs.min.js'];
        const assetLoader = new AssetLoader();
        await assetLoader.load(libraryAsset);
    }
    const win = window;
    win.antd = antd;
    win.ReactDOM = window.ReactDom;
};
