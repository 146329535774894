import { Button } from 'antd';
import { ModalFundFlow } from '@/components/ModalFundFlow';
import { useState } from 'react';
import { AccessConfigType } from '@/config/access';
import { useAccess } from '@mci-fe/user-auth';

export const ImportFundFlow = (props: any) => {
  const { actionRef } = props;
  const [modalStatus, setModalStatus] = useState(false);
  const access = useAccess<AccessConfigType>();
  const importEvent = () => {
    setModalStatus(true);
  };
  const onCloseEvent = (type?: string) => {
    if (type === 'success') {
      actionRef && actionRef.current?.reload(); // 刷新
    }
    setModalStatus(false);
  };
  return (
    <>
      {access.fundAllocation_import && (
        <>
          <Button type="primary" onClick={importEvent}>
            录入资金调拨单
          </Button>
          {modalStatus && (
            <ModalFundFlow isShowModal={modalStatus} modalTitle="录入资金调拨单" closeEvent={onCloseEvent}></ModalFundFlow>
          )}
        </>
      )}
    </>
  );
};
