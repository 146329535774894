import { useState } from 'react';
import { ModalFundFlow } from '@/components/ModalFundFlow';

export default function FundAllocationDetail({ record, ctx }: any) {
  const [modalStatus, setModalStatus] = useState(false);
  return (
    <>
      <a onClick={()=> setModalStatus(true)}>详情</a>
      {modalStatus && (
        <ModalFundFlow isShowModal={modalStatus} rowData={record} modalTitle="详情" isDetail={true} closeEvent={()=> setModalStatus(false)}></ModalFundFlow>
      )}
    </>
  );
}
