import { Alert, Space, Table } from 'antd';
import type { TableProps } from 'antd';
import { YearMonthIncome } from '@/api/types';
import useMciRequest from '@/api/useMciRequest';
import { useParams } from 'react-router-dom';

type MonthInfo = Pick<YearMonthIncome, 'income' | 'type'>;
interface ReshapedItem {
  year: number;
  jan: MonthInfo;
  feb: MonthInfo;
  mar: MonthInfo;
  apr: MonthInfo;
  may: MonthInfo;
  jun: MonthInfo;
  jul: MonthInfo;
  aug: MonthInfo;
  sep: MonthInfo;
  oct: MonthInfo;
  nov: MonthInfo;
  dec: MonthInfo;
}

const columns: TableProps<ReshapedItem>['columns'] = [
  { title: '类目', dataIndex: 'year' },
  { title: '一月', dataIndex: 'jan' },
  { title: '二月', dataIndex: 'feb' },
  { title: '三月', dataIndex: 'mar' },
  { title: '四月', dataIndex: 'apr' },
  { title: '五月', dataIndex: 'may' },
  { title: '六月', dataIndex: 'jun' },
  { title: '七月', dataIndex: 'jul' },
  { title: '八月', dataIndex: 'aug' },
  { title: '九月', dataIndex: 'sep' },
  { title: '十月', dataIndex: 'oct' },
  { title: '十一月', dataIndex: 'nov' },
  { title: '十二月', dataIndex: 'dec' },
].map((item) => {
  if (item.dataIndex === 'year') return item;
  return {
    ...item,
    render(value: MonthInfo) {
      const { income, type } = value;

      if (typeof type === 'number') {
        const isReportFromCustomer = type === 0;

        return (
          <>
            <div
              className={
                isReportFromCustomer
                  ? 'absolute top-0 left-0 bottom-0 right-0 text-white flex items-center justify-center bg-[#ed7b2f] p-16'
                  : ''
              }>
              {income}元
            </div>
            {/* 下面这块内容用于撑开表格的宽度 */}
            {isReportFromCustomer && <div>{income}元</div>}
          </>
        );
      }
      return '--';
    },

    onCell(record) {
      return {
        className: 'relative',
      };
    },
  };
});

function reshapeList(list: YearMonthIncome[]): ReshapedItem[] {
  if (!list || list.length === 0) return [];
  const result: ReshapedItem[] = [];
  const map: { [key: string]: ReshapedItem } = {};

  list.forEach((item) => {
    const year = item.year!;
    const month = item.month!;

    if (!map[year]) {
      map[year] = {
        year,
        jan: {},
        feb: {},
        mar: {},
        apr: {},
        may: {},
        jun: {},
        jul: {},
        aug: {},
        sep: {},
        oct: {},
        nov: {},
        dec: {},
      };
    }

    const monthKey = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'][
      month - 1
    ] as keyof Omit<ReshapedItem, 'year'>;

    map[year][monthKey] = {
      income: item.income,
      type: item.type,
    };
  });

  for (const key in map) {
    result.push(map[key]);
  }

  return result;
}

export default function HistoricalRevenue(props: { appId: string }) {
  const { appId } = props;
  const { loading, data: resp } = useMciRequest(
    'getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation',
    {
      pathParams: {
        appId: appId!,
      },
    },
    {
      isJoinGlobalLoading: false,
    },
  );
  const { yearMonthIncomeList } = resp?.data || {};

  return (
    <Space direction="vertical" className="w-full">
      <Alert message="注意" description="带有颜色标底的数据为“企业自主填写”，请谨慎核实。" type="warning" showIcon />
      <Table
        bordered
        columns={columns}
        dataSource={yearMonthIncomeList ? reshapeList(yearMonthIncomeList) : []}
        style={{ width: '100%' }}
        loading={loading}
      />
    </Space>
  );
}
