import { ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import { BetaSchemaForm, ProDescriptions, ProTable, TableDropdown } from '@ant-design/pro-components';
import { Button, Drawer, Flex, Popover, Spin, message, Space, Tabs, Modal } from 'antd';
import { Fragment, createElement, useEffect, useRef, useState } from 'react';
import { useMciProProvider } from '../config-provider/mci';
import './index.scss';
import { isBackendDataValid, patchDependenciesJsonLogic, isActionColumn, checkBeforeRequest, extractHttpMethodFromServiceName, } from './utils';
import { sleep } from '@mci-fe/utils';
function PopoverConfirm(props) {
    const [isOpen, setIsOpen] = useState(false);
    const close = () => {
        setIsOpen(false);
    };
    const open = () => {
        setIsOpen(true);
    };
    useEffect(function () {
        document.body.addEventListener('click', close);
        return () => {
            document.body.removeEventListener('click', close);
        };
    }, []);
    const title = props.title || '提示';
    const description = props.description;
    const cancelText = props.cancelText || '取消';
    const confirmText = props.confirmText || '确定';
    const triggerText = props.triggerText;
    return (createElement(Popover, { key: "remove", title: createElement(Flex, { align: "center", gap: 4 },
            createElement(ExclamationCircleFilled, { style: { color: '#faad14' } }),
            title), content: createElement(Flex, { vertical: true, gap: 16 },
            createElement("div", { style: { whiteSpace: 'pre-wrap' } }, description),
            createElement(Flex, { align: "center", justify: "flex-end", gap: 8 },
                createElement(Button, { size: "small", type: "default", onClick: close }, cancelText),
                createElement(Button, { size: "small", type: "primary", onClick: () => {
                        props?.onConfirm();
                        close();
                    } }, confirmText))), trigger: "click", open: isOpen },
        createElement(Button, { onClick: (e) => {
                e.stopPropagation();
                open();
            }, disabled: props.disabled, type: "link", style: { padding: 0 } }, triggerText)));
}
const loadedDataSourceMap = {};
function useLoadDataSource(ctx, dataSource) {
    const [loading, setLoading] = useState(true);
    const [dataMap, setDataMap] = useState({});
    const [errorMap, setErrorMap] = useState({});
    const keys = dataSource ? Object.keys(dataSource) : [];
    let count = 0;
    const checkFinish = () => {
        if (count === keys.length) {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (!keys.length) {
            setLoading(false);
            return;
        }
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const config = dataSource[key];
            const { serviceName, params } = config;
            const httpMethod = config.httpMethod?.toLowerCase() || extractHttpMethodFromServiceName(serviceName) || 'get';
            if (!['get', 'post'].includes(httpMethod)) {
                throw new Error(`Unsupport http method: '${httpMethod}'`);
            }
            let promise;
            if (loadedDataSourceMap[serviceName]) {
                promise = new Promise((resolve) => {
                    console.log(`使用缓存数据 sourceName: ${serviceName}`);
                    resolve(loadedDataSourceMap[serviceName]);
                });
            }
            else if (config.mockRes) {
                promise = new Promise(async (resolve) => {
                    console.warn('临时强制本地代码mock数据');
                    await sleep(200);
                    resolve(config.mockRes);
                });
            }
            else {
                promise = ctx.apiMap[serviceName]({
                    [httpMethod === 'get' ? 'queryParams' : 'requestBody']: params,
                });
            }
            promise
                .then((res) => {
                if (config.cache) {
                    console.warn('枚举缓存暂不支持，有待优化');
                }
                if (config.postTransformer) {
                    res = config.postTransformer(res);
                }
                setDataMap((prev) => {
                    return {
                        ...prev,
                        [key]: res.data,
                    };
                });
                count++;
                checkFinish();
            })
                .catch((e) => {
                console.error('loadDataSource error: ', e);
                setErrorMap((prev) => {
                    return {
                        ...prev,
                        [key]: e,
                    };
                });
                checkFinish();
            });
        }
    }, []);
    return { loading, dataMap, errorMap };
}
export default function MciProTable(props) {
    const { service, columnSetting, tableSetting, toolBarActions, dataSource, rowSelection } = props;
    const rowKey = tableSetting?.rowKey;
    const { create, edit, query, remove, view } = service;
    const actionRef = useRef();
    const ctx = useMciProProvider();
    const [isDetailDrawerOpen, setIsDetailDrawerOpen] = useState(false);
    const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false);
    const [currentDetailItem, setCurrentDetailItem] = useState();
    const createFormRef = useRef();
    const editFormRef = useRef();
    const searchFormRef = useRef();
    const [searchForm, setSearchForm] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const curEditRecordRef = useRef(null);
    const [pageSize, setPageSize] = useState(tableSetting.pageSize);
    const [modalData, setMode] = useState({
        isOpen: false,
    });
    const [currentPageIndex, setCurrentPageIndex] = useState(1);
    const [rowSelectionMap, setRowSelectionMap] = useState(() => {
        const m = new Map();
        m.set(1, {
            selectedRowKeys: [],
            selectedRows: [],
        });
        return m;
    });
    const [isManualLoading, setIsManualLoading] = useState(false);
    const isCreateConfigured = !!create;
    const { loading: dataSourceLoading, dataMap: dataSourceMap, errorMap: dataSourceErrorMap, } = useLoadDataSource(ctx, dataSource);
    const totalSelectedRowKeys = [];
    const totalSelectedRows = [];
    for (const { selectedRowKeys, selectedRows } of rowSelectionMap.values()) {
        totalSelectedRowKeys.push(...selectedRowKeys);
        totalSelectedRows.push(...selectedRows);
    }
    const currentSelectedRowsCount = rowSelectionMap.get(currentPageIndex)?.selectedRowKeys.length || 0;
    const totalSelectedRowsCount = totalSelectedRowKeys.length;
    const isLoading = dataSourceLoading || isManualLoading;
    const isRowSelectionConfigured = !!rowSelection;
    const { page, size, ...restSearchForm } = searchForm;
    let hasViewAction = false;
    const componentsCtx = {
        actionRef,
        status: {
            loading: isLoading,
            isDeleting,
            isManualLoading,
            setIsManualLoading,
        },
    };
    const newColumnSetting = (columnSetting || []).map((column) => {
        const actionsConfig = column.actions;
        if (isActionColumn(column)) {
            column.hideInSearch = true;
        }
        if (isActionColumn(column) && Array.isArray(actionsConfig)) {
            if (!hasViewAction) {
                hasViewAction = actionsConfig.includes('view');
            }
            const actionsConfigFull = actionsConfig.map((action) => {
                if (typeof action !== 'string')
                    return action;
                const map = {
                    edit: {
                        key: 'edit',
                        name: '编辑',
                    },
                    view: {
                        key: 'view',
                        name: '查看',
                    },
                    remove: {
                        key: 'remove',
                        name: '删除',
                    },
                };
                if (!map[action]) {
                    console.error(`未知的 action 配置: ${action}`);
                }
                return map[action];
            });
            column.render = (_, record) => {
                let actionsFull = actionsConfigFull.map((action) => {
                    switch (action.key) {
                        case 'edit':
                            return {
                                key: 'edit',
                                name: action.name,
                                onClick: () => {
                                    action.onClick?.(record, ctx);
                                    curEditRecordRef.current = { ...record };
                                    setIsEditDrawerOpen(true);
                                    setTimeout(() => {
                                        editFormRef.current.setFieldsValue({ ...record });
                                    }, 400);
                                },
                            };
                        case 'view':
                            return {
                                key: 'view',
                                name: action.name,
                                onClick: () => {
                                    action.onClick?.(record, ctx);
                                    setCurrentDetailItem({ ...record });
                                },
                            };
                        case 'remove':
                            return {
                                key: 'remove',
                                name: action.name,
                                onClick: async () => {
                                    action.onClick?.(record, ctx);
                                    if (!remove) {
                                        throw new Error('Need remove service to support delete action');
                                    }
                                    const idFieldName = remove.idFieldName || 'id';
                                    const idFieldVal = record[idFieldName];
                                    if (idFieldVal !== 0 && !idFieldVal) {
                                        throw new Error('发送删除请求的时候，id参数值不能为空');
                                    }
                                    if (remove.serviceName && checkBeforeRequest(ctx.apiMap, remove.serviceName)) {
                                        setIsDeleting(true);
                                        await ctx.apiMap[service.remove.serviceName]({
                                            requestBody: { [idFieldName]: idFieldVal },
                                        }).catch((e) => {
                                            console.error(e);
                                            setIsDeleting(false);
                                        });
                                        setIsDeleting(false);
                                        actionRef.current?.reload();
                                    }
                                },
                            };
                        default:
                            return {
                                ...action,
                                onClick: () => {
                                    action.onClick?.(record, ctx);
                                },
                            };
                    }
                });
                const finalActions = [];
                const menuItems = [];
                for (let i = 0; i < actionsFull.length; i++) {
                    const action = actionsFull[i];
                    let name = action.name;
                    const onClick = action.onClick;
                    const key = 'action-' + i;
                    const nameRenderer = action.nameRenderer;
                    const disabled = action.disabled;
                    if (nameRenderer) {
                        name = ctx.actionMap[nameRenderer]({ record, action, ctx: componentsCtx });
                    }
                    else {
                        if (typeof name === 'string') {
                            if (name === '删除') {
                                name = (createElement(PopoverConfirm, { key: key, onConfirm: onClick, description: remove.confirmText || '确定要删除这条数据吗?', triggerText: "\u5220\u9664" }));
                            }
                            else if (action.popOver) {
                                name = (createElement(PopoverConfirm, { key: key, disabled: disabled, ...action.popOver, onConfirm: onClick, triggerText: name }));
                            }
                            else {
                                name = (createElement(Button, { key: key, disabled: disabled, onClick: onClick, style: { padding: 0 }, type: "link" }, name));
                            }
                        }
                    }
                    if (i < 3) {
                        finalActions.push(name);
                    }
                    else {
                        menuItems.push({ name, key });
                    }
                }
                if (menuItems.length > 0) {
                    finalActions.push(createElement(TableDropdown, { menus: menuItems }));
                }
                return finalActions;
            };
        }
        if (column.cellRenderer) {
            column.render = ctx.cellRendererMap[column.cellRenderer];
        }
        else {
        }
        if (dataSource && column.valueEnumDataSource && !dataSourceLoading) {
            const path = column.valueEnumDataSource.split('.');
            if (dataSource[path[0]]) {
                let value = dataSourceMap;
                for (let i = 0; i < path.length; i++) {
                    value = value[path[i]];
                }
                if (column.valueEnumDataSourceTransformer) {
                    value = column.valueEnumDataSourceTransformer(value);
                }
                column.valueEnum = value;
            }
            else {
                console.warn('valueEnum 缺少 dataSource 配置，使用默认值');
            }
        }
        if (column.formItemRenderer) {
            column.renderFormItem = ctx.formItemRendererMap[column.formItemRenderer];
        }
        return column;
    });
    const columnSetting4ProDescriptions = newColumnSetting
        .map((column) => {
        const { ...rest } = column;
        return rest;
    })
        .filter((column) => !isActionColumn(column));
    const createFormColumns = patchDependenciesJsonLogic(columnSetting, 'createForm').filter((col) => col.hideInCreateForm !== true);
    const editFormColumns = patchDependenciesJsonLogic(columnSetting, 'editForm').filter((col) => col.hideInEditForm !== true);
    const clearAllSelected = () => {
        const newRowSelectionMap = new Map(rowSelectionMap.entries());
        newRowSelectionMap.forEach((value, key, map) => {
            map.set(key, {
                selectedRowKeys: [],
                selectedRows: [],
            });
        });
        setRowSelectionMap(newRowSelectionMap);
    };
    const search = query.noFilter
        ? false
        : {
            labelWidth: query.searchConfig?.labelWidth ?? 100,
            defaultCollapsed: query.searchConfig?.defaultCollapsed ?? false,
        };
    useEffect(() => {
        query.searchConfig?.onGetFormRef?.(searchFormRef);
    }, []);
    const getViewConfig = () => {
        const uiMode = view?.uiMode || 'flow';
        const drawerWidth = view?.drawer?.width || (uiMode === 'flow' ? 500 : 800);
        const result = {
            uiMode,
            tabs: view?.tabs,
            drawer: {
                width: drawerWidth,
            },
        };
        return result;
    };
    const viewConfig = getViewConfig();
    const getViewDrawer = () => {
        const getFlowView = () => {
            return (createElement(ProDescriptions, { dataSource: currentDetailItem, columns: columnSetting4ProDescriptions, column: 1, emptyText: "--" }));
        };
        const getHierarchicalView = () => {
            const tabs = viewConfig.tabs || [];
            const tabItems = tabs.map((tab) => {
                const TabPane = () => {
                    const [isLoading, setIsLoading] = useState(true);
                    const [dataSource, setDataSource] = useState({});
                    useEffect(() => {
                        if (tab.renderer)
                            return;
                        let promise;
                        if (tab.mockRes) {
                            promise = new Promise(async (resolve) => {
                                console.warn('临时强制本地代码mock数据');
                                await sleep(200);
                                resolve(tab.mockRes);
                            });
                        }
                        else {
                            let params = tab.params;
                            if (!params && tab.paramsTransformer) {
                                params = tab.paramsTransformer(currentDetailItem);
                            }
                            const httpMethod = extractHttpMethodFromServiceName(tab.serviceName) || 'get';
                            promise = ctx.apiMap[tab.serviceName]({
                                [httpMethod === 'get' ? 'queryParams' : 'requestBody']: params,
                            });
                        }
                        promise
                            .then((res) => {
                            setDataSource(res.data);
                            setIsLoading(false);
                        })
                            .catch((e) => {
                            console.error('error: ', e);
                            setIsLoading(false);
                        });
                    }, []);
                    const getProDescriptions = () => {
                        const column = tab.columnCount || 2;
                        const layout = 'vertical';
                        if (!tab.sectionConfig) {
                            return (createElement(ProDescriptions, { dataSource: dataSource, columns: tab.columnSetting, column: column, layout: layout, emptyText: "--" }));
                        }
                        const sectionConfig = tab.sectionConfig;
                        const descriptionArr = sectionConfig.map((section) => {
                            const columns = tab.columnSetting.filter((col) => {
                                if (!col.sectionKey) {
                                    console.error('配置 sectionConfig 后 columnSetting 中的 sectionKey 不能为空, col:', col);
                                }
                                return col.sectionKey === section.key;
                            });
                            return (createElement(ProDescriptions, { key: section.key, dataSource: dataSource, columns: columns, column: column, layout: layout, title: section.title, emptyText: "--" }));
                        });
                        return descriptionArr;
                    };
                    const getCustomRenderComp = () => {
                        const renderFunc = ctx.tabPanelRendererMap[tab.renderer];
                        if (!renderFunc) {
                            console.error(`找不到 tab renderer: ${tab.renderer}`);
                            return null;
                        }
                        return renderFunc({ tab, record: currentDetailItem });
                    };
                    return tab.renderer ? (getCustomRenderComp()) : (createElement(Spin, { spinning: isLoading }, !isLoading && getProDescriptions()));
                };
                return {
                    key: tab.tabName,
                    label: tab.tabName,
                    children: createElement(TabPane, null),
                };
            });
            return (createElement(Tabs, { items: tabItems, onChange: (key) => {
                } }));
        };
        return (hasViewAction && (createElement(Drawer, { title: "\u67E5\u770B\u8BE6\u60C5", width: viewConfig.drawer.width, open: isDetailDrawerOpen, onClose: () => setIsDetailDrawerOpen(false) }, viewConfig.uiMode === 'flow' ? getFlowView() : getHierarchicalView())));
    };
    const toolBarRender = !tableSetting.hideToolBar
        ? () => [
            isCreateConfigured ? (createElement(BetaSchemaForm, { formRef: createFormRef, layoutType: "ModalForm", className: "mci-pro-table-create", columns: createFormColumns, onFinish: async (values) => {
                    let tempValues = { ...values };
                    if (create.transformer) {
                        tempValues = create.transformer(values);
                    }
                    createFormRef.current?.resetFields();
                    await ctx.apiMap[create.serviceName]({
                        requestBody: tempValues,
                    });
                    message.success('新建操作成功');
                    actionRef.current?.reload();
                    return true;
                }, trigger: createElement(Button, { key: "button", icon: createElement(PlusOutlined, null), type: "primary" }, "\u65B0\u5EFA") })) : null,
            toolBarActions?.map((action, index) => {
                if (typeof action === 'string') {
                    const serviceConfig = service[action];
                    const isConfigured = ctx.toolBarActionMap?.[action] && serviceConfig?.serviceName;
                    const props = {
                        key: index,
                        serviceName: isConfigured ? serviceConfig.serviceName : '',
                        searchForm,
                        actionRef,
                        ...ctx,
                    };
                    if (isConfigured && action === 'export') {
                        const _serviceConfig = serviceConfig;
                        props.btnText = _serviceConfig.btnText;
                        props.accessible = _serviceConfig.accessible;
                    }
                    return createElement(ctx.toolBarActionMap?.[action], props);
                }
                else {
                    let props = {};
                    if (ctx.actionFunctions && action.clickAction) {
                        props.onClick = ctx.actionFunctions[action.clickAction];
                    }
                    return createElement(Button, {
                        key: index,
                        ...action.props,
                        ...props,
                    }, action.text);
                }
            }),
        ]
        : false;
    useEffect(function () {
        if (currentDetailItem) {
            setIsDetailDrawerOpen(true);
        }
    }, [currentDetailItem]);
    useEffect(function () {
        clearAllSelected();
    }, [JSON.stringify(restSearchForm), pageSize]);
    return (createElement(Fragment, null,
        createElement(Spin, { spinning: isLoading }, !isLoading && (createElement(ProTable, { scroll: { x: tableSetting.scrollX }, columns: newColumnSetting, actionRef: actionRef, cardBordered: true, request: async (params) => {
                if (!query.mockRes && !checkBeforeRequest(ctx.apiMap, query.serviceName)) {
                    return;
                }
                if (query.params) {
                    params = { ...params, ...query.params };
                }
                if (query.paramPreprocessor) {
                    params = query.paramPreprocessor(params, ctx);
                }
                let res;
                if (query.mockRes) {
                    console.warn('临时强制本地代码mock数据');
                    await sleep(200);
                    res = query.mockRes;
                }
                else {
                    const httpMethod = query.httpMethod?.toLowerCase() || extractHttpMethodFromServiceName(query.serviceName) || 'get';
                    if (!['get', 'post'].includes(httpMethod)) {
                        throw new Error(`Unsupported http method: '${httpMethod}'`);
                    }
                    res = await ctx.apiMap[query.serviceName]({
                        [httpMethod === 'get' ? 'queryParams' : 'requestBody']: params,
                    });
                }
                setSearchForm(params);
                const hadTransformerConfig = typeof query.transformer === 'function';
                if (!hadTransformerConfig) {
                    if (!isBackendDataValid(res)) {
                        throw new Error('后端返回的数据不符合规范');
                    }
                }
                else {
                    res = query.transformer(res);
                    if (!isBackendDataValid(res)) {
                        throw new Error('你所配置的 transformer 返回的数据不符合规范');
                    }
                }
                return res;
            }, onRequestError: (e) => {
                console.error(e);
            }, columnEmptyText: "--", columnsState: {
                persistenceKey: 'pro-table-singe-demos',
                persistenceType: 'localStorage',
                defaultValue: {
                    option: { fixed: 'right', disable: true },
                },
            }, rowKey: rowKey || rowSelection?.rowKey || 'id', search: search, defaultSize: "small", options: {
                setting: {
                    listsHeight: 400,
                },
            }, form: {
                syncToUrl: false,
                labelWrap: true,
                ...query.searchConfig?.formConfig,
            }, formRef: searchFormRef, pagination: {
                pageSize,
                onChange: (page, pageSize) => {
                    setCurrentPageIndex(page);
                    setPageSize(pageSize);
                },
            }, dateFormatter: "string", headerTitle: tableSetting.title, toolBarRender: toolBarRender, rowSelection: isRowSelectionConfigured
                ? {
                    onChange(selectedRowKeys, selectedRows) {
                        setRowSelectionMap((m) => {
                            const _m = new Map(m.entries());
                            _m.set(currentPageIndex, {
                                selectedRowKeys,
                                selectedRows,
                            });
                            return _m;
                        });
                    },
                    selectedRowKeys: totalSelectedRowKeys,
                }
                : false, tableAlertRender: ({ selectedRowKeys, selectedRows, onCleanSelected: clearCurrentPageSelected }) => {
                return (createElement(Space, { size: 24 },
                    createElement("span", null,
                        "\u5F53\u524D\u5206\u9875\uFF1A\u5DF2\u9009 ",
                        currentSelectedRowsCount,
                        " \u9879",
                        currentSelectedRowsCount > 0 ? (createElement("a", { style: { marginInlineStart: 8 }, onClick: clearCurrentPageSelected }, "\u53D6\u6D88\u9009\u62E9")) : null),
                    createElement("span", null, "|"),
                    createElement("span", null,
                        "\u603B\u5171\u9009\u62E9\uFF1A",
                        totalSelectedRowKeys.length,
                        " \u9879",
                        totalSelectedRowsCount > 0 ? (createElement("a", { style: { marginInlineStart: 8 }, onClick: () => {
                                clearCurrentPageSelected();
                                clearAllSelected();
                            } }, "\u53D6\u6D88\u9009\u62E9")) : null)));
            }, tableAlertOptionRender: () => {
                if (isRowSelectionConfigured && rowSelection.actionRenderers?.length > 0) {
                    return (createElement(Space, { size: 16 }, rowSelection.actionRenderers.map((actionRenderer) => {
                        if (!ctx.rowSelectionActionMap[actionRenderer]) {
                            throw Error(`Can\'t not find action renderer('${actionRenderer}') in rowSelectionActionMap, did you forget to inject?`);
                        }
                        return createElement(ctx.rowSelectionActionMap[actionRenderer], {
                            key: actionRenderer,
                            selectedRows: totalSelectedRows,
                            clearAllSelected: clearAllSelected,
                            reload: actionRef.current?.reload,
                        });
                    })));
                }
                return null;
            } }))),
        createElement(BetaSchemaForm, { className: "mci-pro-table-edit", open: isEditDrawerOpen, formRef: editFormRef, layoutType: "ModalForm", columns: editFormColumns, onOpenChange: (state) => {
                if (state === false) {
                    editFormRef.current?.resetFields();
                    setIsEditDrawerOpen(false);
                }
            }, onFinish: async (values) => {
                if (!curEditRecordRef.current)
                    return true;
                let tempValues = { ...curEditRecordRef.current, ...values };
                if (edit.transformer) {
                    tempValues = edit.transformer(tempValues);
                }
                await ctx.apiMap[edit.serviceName]({
                    requestBody: tempValues,
                });
                editFormRef.current?.resetFields();
                actionRef.current?.reload();
                setIsEditDrawerOpen(false);
                message.success('编辑操作成功');
                return true;
            } }),
        getViewDrawer(),
        createElement(Modal, { open: modalData.isOpen })));
}
