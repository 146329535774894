import { ProColumns, ProTable } from '@ant-design/pro-components';
import { sleep } from '@mci-fe/utils';
import { Tabs, TabsProps } from 'antd';
import { createContext, FC, useState } from 'react';
import OrderDRPFromIO from './OrderDRPFromIO';
import OrderDRPFromSO from './OrderDRPFromSO';
import OrderDRPFromBlock from './OrderDRPFromBlock';

interface IDrpContext {
  TabValue: string;
}

export const DRPContext = createContext<IDrpContext>({
  TabValue: 'FromIOAO',
});

export const OrderDRP: FC = () => {
  const [currentDrpTab, setCurrentDrpTab] = useState('FromIOAO');
  const items: TabsProps['items'] = [
    {
      key: 'FromIOAO',
      label: 'IO/Reinvest订单',
      children: <OrderDRPFromIO />,
    },
    {
      key: 'FromSO',
      label: 'SO订单',
      children: <OrderDRPFromSO />,
    },
    {
      key: 'FromBlock',
      label: '大宗交易订单',
      children: <OrderDRPFromBlock />,
    },
  ];

  const onChange = (key: string) => {
    setCurrentDrpTab(key);
  };

  return (
    <DRPContext.Provider value={{TabValue: currentDrpTab}}>
      {/* TODO: Tab切换的时候若是需要初始化页面就添加destroyInactiveTabPane属性，destroyInactiveTabPane={true} */}
      <Tabs defaultActiveKey="FromIOAO" items={items} onChange={onChange} />
    </DRPContext.Provider>
  );
};
