import { Tooltip } from 'antd';

export function EmailsFormatter({ record, value }: { value: React.ReactNode; record: any }) {
  const emailAddresses = record.toMail as string[];

  if (!emailAddresses) {
    return null;
  }

  if (emailAddresses.length === 1) return <span>{emailAddresses[0]}</span>;

  return <Tooltip title={emailAddresses.join('；')}>{emailAddresses[0] + '；...'}</Tooltip>;
}
