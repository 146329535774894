import { ProColumns } from '@ant-design/pro-components';

/**
 * 手工下单列表字段
 */

// 产品分类-三级选择
export const options = [
  {
    label: 'DRO',
    value: 1,
    children: [
      {
        label: 'IO/AO',
        value: 1,
      },
      {
        label: 'SO',
        value: 3,
        children: [
          {
            label: '买',
            value: 1,
          },
          {
            label: '卖',
            value: 2,
          },
        ],
      },
    ],
  },
  {
    label: 'DRP',
    value: 2,
    children: [
      {
        label: 'IO',
        value: 1,
      },
      {
        label: 'SO',
        value: 3,
        children: [
          {
            label: '买',
            value: 1,
          },
          {
            label: '卖',
            value: 2,
          },
        ],
      },
    ],
  },
];

export const columns: ProColumns<any>[] = [
  {
    title: '序号',
    dataIndex: 'index',
    valueType: 'indexBorder',
    width: 60,
    fixed: 'left',
  },
  {
    title: '下单日期',
    dataIndex: 'createTime',
    valueType: 'date',
    fixed: 'left',
    width: 100,
    hideInSearch: true,
  },
  {
    title: '批次号',
    dataIndex: 'batchId',
    fixed: 'left',
    width: 170,
    hideInSearch: true,
  },
  {
    title: '订单号',
    dataIndex: 'orderCodeName',
    fixed: 'left',
    width: 150,
    hideInSearch: true,
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
    fixed: 'left',
    width: 170,
    hideInSearch: true,
  },
  {
    title: '会员ID',
    dataIndex: 'memberCode',
    fixed: 'left',
    width: 170,
    hideInSearch: true,
  },
  {
    title: '会员名称',
    dataIndex: 'identificationName',
    hideInSearch: true,
  },
  {
    title: '产品分类',
    dataIndex: 'productType',
    valueType: 'select',
    valueEnum: {
      1: { text: 'DRO' },
      2: { text: 'DRP' },
    },
    hideInSearch: true,
  },
  {
    title: '交易账号',
    dataIndex: 'tradingAccountCode',
    hideInSearch: true,
  },
  {
    title: '交易类型',
    dataIndex: 'offeringStage',
    width: 150,
    valueType: 'select',
    valueEnum: {
      1: { text: 'IO' },
      2: { text: 'AO' },
      3: { text: 'SO' },
    },
    hideInSearch: true,
  },
  {
    title: '交易方向',
    dataIndex: 'orderDirection',
    width: 100,
    valueType: 'select',
    valueEnum: {
      0: '卖',
      1: '买',
    },
    hideInSearch: true,
  },
  {
    title: '单价',
    dataIndex: 'price',
    hideInSearch: true,
  },
  {
    title: '份额',
    dataIndex: 'shares',
    hideInSearch: true,
  },
  {
    title: '币种',
    dataIndex: 'currency',
    hideInSearch: true,
  },
  {
    title: '审批状态',
    dataIndex: 'auditStatus',
    valueType: 'select',
    valueEnum: {
      0: '待审批',
      1: '通过',
      2: '审批驳回',
      3: '取消',
    },
    hideInSearch: true,
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    valueType: 'select',
    valueEnum: {
      '-1': '下单失败',
      '1': '有效',
      '5': '取消',
      '4': '未成交',
      '2': '部分成交',
      '3': '全部成交',
    },
    hideInSearch: true,
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    hideInSearch: true,
    valueType: 'dateTime',
  },
  {
    title: '申请人',
    dataIndex: 'applyUser',
    hideInSearch: true,
  },
  {
    title: '审批人',
    dataIndex: 'auditUser',
    hideInSearch: true,
  },
  // -- search --
  {
    title: '下单时间',
    dataIndex: 'createTime',
    valueType: 'dateRange',
    hideInTable: true,
  },
  {
    title: '批次号',
    dataIndex: 'batchId',
    hideInTable: true,
  },
  {
    title: '审批状态',
    dataIndex: 'auditStatusList',
    valueType: 'select',
    valueEnum: {
      0: '待审批',
      1: '通过',
      2: '审批驳回',
      3: '取消',
    },
    fieldProps: {
      mode: 'multiple',
    },
    hideInTable: true,
  },
  {
    title: '会员名称',
    dataIndex: 'identificationName',
    hideInTable: true,
  },
  {
    title: '交易账号',
    dataIndex: 'tradingAccountCode',
    hideInTable: true,
  },
  {
    title: '产品编码',
    dataIndex: 'productCodeList',
    valueType: 'select',
    fieldProps: {
      placeholder: '多个编码之间用空格或者英文逗号分隔',
      mode: 'tags',
      tokenSeparators: [' ', ','],
      open: false,
    },
    hideInTable: true,
    ellipsis: true,
  },
  {
    title: '产品分类',
    dataIndex: 'productDeal',
    valueType: 'cascader',
    fieldProps: {
      multiple: true,
      options: options
    },
    hideInTable: true,
  },
];