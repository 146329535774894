import { DROActionProps } from '@/types';
import { useState, FC, useEffect } from 'react';
import { Drawer } from 'antd';
import DroDetail from '@/components/DroDetail';
import { access } from '@mci-fe/user-auth';

export default function Jump2DetailAction(props: DROActionProps) {
  const [open, setOpen] = useState(false);
  const { record } = props;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const status = props.action?.nameRendererProps?.status;

  const canView = () => {
    if (status === 'droToBeListing' && access.droToBeListingDetail) {
      return true;
    }
    if (status === 'droListed' && access.droListedDetail) {
      return true;
    }
    return false;
  };

  return (
    <>
      {canView() && (
        <>
          <a
            onClick={() => {
              showDrawer();
            }}>
            查看详情
          </a>
          <Drawer title="详情" onClose={onClose} open={open} width={'50vw'} destroyOnClose>
            <DroDetail appId={record.appId!} />
          </Drawer>
        </>
      )}
    </>
  );
}
