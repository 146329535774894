import { useEffect, useState } from 'react';
import useMciRequest from '@/api/useMciRequest';

type TKey = 'desc' | 'label';

export const useCurrency = ({
  labelKey,
  valueKey
}: {
  labelKey?: TKey;
  valueKey?: TKey
} = {}) => {
  const { data } = useMciRequest('getNouserDictionaryMapping_exKernelAdmin', {
    queryParams: {
      keys: ['FUNDTRANFER_CURRENCY'],
    }
  });

  const [currencyList, setCurrencyList] = useState<{label: string; value: string;}[]>([]);
  const [currencyMap, setCurrencyMap] = useState<Record<string, string>>();

  useEffect(()=> {
    if (data?.data) {
      const list = Object.values(data.data)[0].map((item: {label: string; desc: string;})=> (
        {
          label: labelKey ? item[labelKey as keyof typeof item] : item.desc,
          value: valueKey ? item[valueKey as keyof typeof item] : item.label
        }
      ));
      
      let map = {} as Record<string, string>;
      Object.values(data.data)[0].forEach((item: {label: string; desc: string;})=> {
        map[valueKey ? item[valueKey as keyof typeof item] : item.label] = labelKey ? item[labelKey as keyof typeof item] : item.desc;
      });

      setCurrencyList(list);
      setCurrencyMap(map);
    }
  }, [data]);

  return {
    currencyList,
    currencyMap
  };
};
