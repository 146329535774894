//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

//@ts-ignore
import qs from "qs";
import type { AxiosRequestConfigWithExtra } from "./types";
import type { SwaggerResponse } from "./config";
import { Http } from "./httpRequest";

import type {
  PostFilesUploadQueryParams,
  GetFilesDownloadQueryParams,
  GetFilesUrlQueryParams,
  GetDroSpecsUserQueryParams,
  GetAdminUserBaseinfoQueryParams,
  GetUserBaseinfoQueryParams,
  GetNouserDictionaryMappingQueryParams,
  GetAdminOperationLogQueryQueryParams,
  GetOperationLogQueryQueryParams,
  GetAdminOperationLogExportQueryParams,
  GetOperationLogExportQueryParams,
  PostDroShelveOffShelveQueryParams,
  GetHsbcDepositGetTradingAccountToListQueryParams,
  Key,
  FoDroSpec,
  ResultVOCommonFileDTO,
  PdfCreateReq,
  ResultVOUserBaseDto,
  ResultVOPdfCreateRsp,
  MemberAccountRelationReq,
  MemberAuthorisedPeopleReq,
  ResultVOMapListDictionaryVo,
  MailSendReq,
  DepositTransferData,
  ResultVO,
  ResultVOListConfigResp,
  DeleteFileReq,
  ResultVOBoolean,
  ResultVOSimplePageMybatisImplDroPendingListVo,
  ResultVOString,
  DroPendingListPo,
  ResultVOListConfigColumn,
  ResultSimplePageMybatisImplCurrencyExchangeJournalResp,
  TemplateMailSendReq,
  ConfigInfoReq,
  CurrencyFxQueryReq,
  SendBatchReq,
  CurrencyExchangeJournalReq,
  ResultCurrencyExchangeDealResp,
  ResultVOSimplePageMybatisImplFundTransferBill,
  HsbcDepositInfoUpdateRsp,
  MailContentDetailDTO,
  ResultVOSimplePageMybatisImplDroListVo,
  FundTransferBillQueryReq,
  DepositReviewInfo,
  ResultVOMailContentDetailDTO,
  DroListPo,
  ResultVOVoid,
  FundTransferBillSaveReq,
  ResultVOSimplePageMybatisImplDepositReviewInfo,
  MailContentListReq,
  ResultVOSimplePageMybatisImplMapT,
  ResultVOSimplePageMybatisImplMailContentListDTO,
  FundTransferBillUpdateReq,
  DepositReviewInfoRsp,
  ResultVOListAccountInfoResp,
  ManualFxReq,
  DepositReviewReq,
  DeleteMailReq,
  ConfigListReq,
  ResultVOListArmAccountInfoData,
  ResultListShareOrderInfoResp,
  DeleteAttachmentDTO,
  Result,
  UpdateTradingAccountReq,
  ResultVOListTradingBillNoResp,
  ResultVOListAccountToInfoResp,
  AddConfigReq,
  ResultVOListMemberShipResp,
  ResultVOSimplePageMybatisImplRecheckRecordResp,
  DelConfigReq,
  ResultVOSimplePageMybatisImplOperationLogResp,
  EditColumnReq,
  RecheckRecordReq,
  AuditReq,
  GetNouserOssDefaultPolicyQueryParams,
  GetOssV2DefaultPolicyQueryParams,
  GetOssV2BucketAccessUrlQueryParams,
  GetOssPolicyByDirQueryParams,
  PostOssV2ImageModerationQueryParams,
  PostOssV2TextModerationQueryParams,
  PostOssV2FileDeleteQueryParams,
  ResultOssUploadPolicy,
  ResultURL,
  FilePathInput,
  ResultString,
  PostApiV1InvestScheduleRiskCheckQueryParams,
  GetApiV1InvestTestGenerateQueryParams,
  GetAdminV1InvestPeriodOperateListQueryParams,
  GetAdminV1InvestFailMsgDicListQueryParams,
  PostApiV1ShelfFilesUploadQueryParams,
  GetApiV1ShelfFilesDownloadQueryParams,
  PostApiV1DroOnShelfScheduleQueryParams,
  PutAdminV1DroOffShelfQueryParams,
  PostAdminV1DroSendDroSpecQueryParams,
  PutAdminV1DroSendDroSpecQueryParams,
  GetAdminV1FilesListQueryParams,
  GetAdminV1OrderBatchBatchOrderTemplateListQueryParams,
  PostAdminV1OrderBatchBatchOrderUploadQueryParams,
  PostSpvPushToExchangeV1QueryParams,
  PostSpacPushToExchangeV1QueryParams,
  PostRbcPushToExchangeV1QueryParams,
  PostRbcEsignSigningCompletionCallbackQueryParams,
  PostSpacMtQuerySpacSponsorQueryParams,
  PostSpacMtQuerySpacReqQueryParams,
  PostSpacQuerySpacSponsorForOperationQueryParams,
  PostSpacQuerySpacForOperationQueryParams,
  GetAdminRepoInvestNeedAddSignatureQueryParams,
  PostAdminRepoInvestDetailQueryParams,
  CommonResultString,
  ContractChannelInfoReq,
  DroAppReq,
  FundingInvestResultCallback,
  ResultListContractChannelInfoRsp,
  FundingInvestRetryReq,
  StoreReq,
  FundingInvestUpdateReq,
  ResultAdminFileResp,
  ResultObject,
  ResultFileResp,
  FundingPeriodInvestReq,
  FundingInvestConfirmReq,
  CloseDroReq,
  ResultListAdminFileResp,
  ApproveRecordReq,
  ContractEntityReq,
  ResultSimplePageMybatisImplContractEntity,
  ResultSimplePageMybatisImplDroSpecQueryResp,
  DroAppStoreBaseReq,
  DroSpecQueryReq,
  ResultSimplePageMybatisImplRepoInvestApprovalVo,
  DroAppStoreModelReq,
  ResultBoolean,
  ResultSimplePageMybatisImplFundingInvestResp,
  FundingDroInvestReq,
  ResultSimplePageMybatisImplFundingPeriodInvestResp,
  ResultSimplePageMybatisImplShelfDroApplyQueryResp,
  ResultListDroSurviveStatusResp,
  ResultContractEntity,
  ExDirectOrderReq,
  ResultSimplePageMybatisImplExDirectOrderRsp,
  RepoInvestApprovalReq,
  DroAppStoreAssociateReq,
  ResultSimplePageMybatisImplFundingInvestOperateResp,
  ShelfDroApplyQueryReq,
  ResultListFundingInvestFailMsg,
  ResultListDivideRulesDto,
  ContractEntityInfoSaveReq,
  ResultDroAppDrcInfoResp,
  ResultDroSpecResp,
  DroAppPcfReq,
  ContractEntityInfoUpdateReq,
  ShelfDroApplyReq,
  ResultContractEntityInfoResp,
  ResultRepoInvestApprovalDetailVo,
  ResultListContractChannelRelation,
  ShelfDroDoCaptureReq,
  ExMatchOrderReq,
  ResultSimplePageMybatisImplExMatchOrderRsp,
  ContractChannelRelationReq,
  ResultListRepoInvestApprovalVo,
  DroAppIncomeReq,
  DataOperateLogReq,
  ShelfDroDoFundingReq,
  ResultSimplePageMybatisImplDataOperateLog,
  ResultListContractEntityInfoRsp,
  DroAppBrandBaseReq,
  ExCancelOrderReq,
  ResultContractChannelRsp,
  DroAppBrandInvestReq,
  ResultDroAppBrandInfoResp,
  ResultDroAppIncomeResp,
  ResultDroAppPcfResp,
  ResultSpvInfo,
  ResultSpacInfo,
  DroAppBrandManageReq,
  ExManualOrderReq,
  ResultSimplePageMybatisImplExManualOrderRsp,
  CreateSpacSponsorReq,
  DroAppBrandSystemReq,
  DroAppStoreFileReq,
  ResultListExBatchOrderRsp,
  ExBatchOrderReq,
  RbcInfoEntity,
  CommonResult,
  ModifySpacSponsorReq,
  ResultDroAppDetailResp,
  ExBatchCheckOrderReq,
  ExBatchCancelReq,
  SubmitSpacSponsorReq,
  ResultExManualOrderCheckRes,
  ResultListExTemplateType,
  ResultSpacOperatorInfo,
  CommonResultPageResultSpacSponsorBo,
  ResultOperatorInfo,
  ExManualOrderBatchPageParam,
  ResultSimplePageMybatisImplManualOrderBatchDetailDTO,
  DroAppUpdateReq,
  MtQuerySpacSponsorReq,
  ExManualOrderBatchReq,
  ResultExManualOrderBatchSumRsp,
  ExManualOrderBatchSumReq,
  ResultSimplePageMybatisImplExBlockTradeOrderRsp,
  ExBlockTradeOrderReq,
  RbcInfo,
  ResultSimplePageMybatisImplExManualBlockTradeOrderRsp,
  ExManualBlockTradeOrderReq,
  CreateSpacReq,
  ResultSimplePageMybatisImplManualBlockOrderBatchDetailDTO,
  ModifySpacReq,
  ResultExManualBlockTradeSumRsp,
  SubmitSpacReq,
  CommonResultPageResultSpacInfoV2,
  MtQuerySpacReq,
  OptionQuerySpacSponsorReq,
  OperationQuerySpacReq,
  RevenueProtocolReq,
  ResultContractGenerateResp,
  IoApplicationContractGenerateReq,
  ResultListSignatoryResp,
  ContractSigningReq,
  GetEnumQueryQueryParams,
  GetEnumBatchQueryQueryParams,
  GetDictionaryQueryQueryParams,
  ResultList,
  ResultListQueryEnumResp,
  GetRoleV1GetRoleInfoDetailsQueryParams,
  GetUserV1GetUserRoleInfoQueryParams,
  GetUserV1GetRoleByUserIdQueryParams,
  GetUserV1GetUserByRoleQueryParams,
  GetUserV1BatchGetUserByUserIdsQueryParams,
  GetSysV1GetSystemMenuListQueryParams,
  GetSysV1GetSystemMenuAuthVisibleListQueryParams,
  GetSysSystemListQueryParams,
  GetSysSystemNameSystemCodeQueryParams,
  ResultBooleanValidationAddGroup,
  RoleBaseReqValidationAddGroup,
  ResultUserBaseInfoResp,
  RoleInfoUpdateReq,
  SystemRoleReq,
  ResultSimplePageMybatisImplUserBaseInfoResp,
  UserBaseInfoReq,
  ResultRoleInfoDetailResp,
  ResultSimplePageMybatisImplSystemRoleInfoResp,
  ResultUserRoleInfoResp,
  ResultListRoleInfoDto,
  ResultListUserBaseResp,
  ResultListSystemAuthResp,
  UserRoleSaveReq,
  ResultListSystemRoleResp,
  ResultListSystemInfoResp,
  ResultSystemInfoResp,
  PostRboIncomeInfoManualUploadRboIncomeInfoQueryParams,
  PostFlowPushAcfQueryParams,
  PostFileUploadQueryParams,
  GetFileGetFullPathQueryParams,
  PostAcceptanceReportQueryInfoFlowAcceptanceReportListQueryParams,
  GetAcceptanceReportExportAcceptanceReportQueryParams,
  ResultListString,
  ResultBigDecimal,
  ResultInteger,
  ResultMapString,
  UploadRboIncomeReq,
  FlowEnterDto,
  QueryRboIncomeListReq,
  ResultSimplePageMybatisImplSaasIncomeInfo,
  ResultSimplePageMybatisImplFlowDetailDto,
  ApproveDto,
  FlowQueryDto,
  FlowInfoQueryDto,
  RboOriginalIUploadQueryReq,
  FlowUpdateDto,
  ResultSimplePageMybatisImplVADepositInfoQueryResp,
  VADepositInfoQueryReq,
  VADepositInfoReviewReq,
  ResultMapListString,
  VaSpvBindingDto,
  VaSpvQueryDto,
  ResultVaSpvRelationship,
  ResultListVaSpvRelationshipInfoDto,
  SpvFlowEnterDto,
  SpvFlowInfoQueryDto,
  RboShareIncmeQueryReq,
  ResultRboShareIncomeCheckResp,
  RboShareIncmeUploadReq,
  RboDownTemplateReq,
  ResultSimplePageMybatisImplRboShareIncomeListResp,
  RboShareIncmeListQueryReq,
  RboTotalAmountBetweenBusinessDateQueryReq,
  ResultFileDTO,
  ResultListChannelInfoDto,
  ResultListNameValuePairRsp,
  ResultAcctBaseInfo,
  RboQueryCondDto,
  ResultRboAcctNameAndFrequencyTypeDto,
  DivideConfigAddReq,
  DivideConfigDeleteReq,
  DivideConfigModifyReq,
  ResultSimplePageMybatisImplDivideConfigPageQueryDto,
  DivideConfigPageQueryReq,
  ResultDivideConfigAuthRsp,
  DivideConfigAuthReq,
  DivideConfigEnableReq,
  AuthStatusUpdateDto,
  MTSpv2RboBaseDto,
  ResultInfoFlowReportListResp,
  InfoFlowAcceptanceReportUpdateReq,
  ResultSimplePageMybatisImplArmAcceptanceReportDetailDto,
  ArmAcceptanceReportStatusQueryReq,
  InfoFlowAcceptanceReportAddReq,
  FundFlowAcceptanceRefreshReq,
  GetPayFinanceChannelPaymentTaskDownloadReceiptQueryParams,
  GetPayJobsCmbInvestQueryTransDetailQueryParams,
  GetAccountBaseQueryAccountInfoQueryParams,
  GetAccountBaseQueryAccountInfoDetailsQueryParams,
  PostAccountBaseDeleteAccountInfoQueryParams,
  PostAccountBaseDeleteAttachFilesQueryParams,
  ResultSimplePageMybatisImplFinanceAccountInfoDto,
  ResultFinanceAccountInfoDetailsDto,
  ModifyAccountInfoDetailDto,
  ModifyAccountInfoDetailsDto,
  UpdateBatchRecordStatusDto,
  UpdateUploadAttachmentssDto,
  QueryAccountInfoDto,
  ResultListBankTypeDto,
} from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== "production";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
  config?: AxiosRequestConfigWithExtra,
  configOverride?: AxiosRequestConfigWithExtra,
): AxiosRequestConfigWithExtra {
  return {
    ...config,
    ...configOverride,
    headers: {
      ...config?.headers,
      ...configOverride?.headers,
    },
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
  Object.keys(obj).forEach((key) => {
    const re = new RegExp(`{${key}}`, "i");
    path = path.replace(re, obj[key]);
  });

  return path;
}

function isFormData(obj: any) {
  // This checks for the append method which should exist on FormData instances
  return (
    (typeof obj === "object" &&
      typeof obj.append === "function" &&
      obj[Symbol.toStringTag] === undefined) ||
    obj[Symbol.toStringTag] === "FormData"
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
  if (isFormData(requestBody)) {
    return requestBody;
  }
  const formData = new FormData();

  Object.entries(requestBody).forEach(([key, value]) => {
    value && formData.append(key, value);
  });

  return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
  return qs.stringify(requestBody);
}

/**
 *
 * 导出验收报告
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-240929807
 */
export const getAcceptanceReportExportAcceptanceReport_mciArm = (options: {
  queryParams: GetAcceptanceReportExportAcceptanceReportQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAcceptanceReportExportAcceptanceReport_mciArm.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAcceptanceReportExportAcceptanceReport_mciArm.backendServiceName,
  );
};

getAcceptanceReportExportAcceptanceReport_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
getAcceptanceReportExportAcceptanceReport_mciArm.key =
  "/acceptanceReport/exportAcceptanceReport";

/**
 *
 * 分页查询账号信息
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115676
 */
export const getAccountBaseQueryAccountInfo_mciPayFinance = (
  options: {
    queryParams?: GetAccountBaseQueryAccountInfoQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplFinanceAccountInfoDto>
> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAccountBaseQueryAccountInfo_mciPayFinance.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAccountBaseQueryAccountInfo_mciPayFinance.backendServiceName,
  );
};

getAccountBaseQueryAccountInfo_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getAccountBaseQueryAccountInfo_mciPayFinance.key =
  "/account/base/query/accountInfo";

/**
 *
 * 查询账户信息详情
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115677
 */
export const getAccountBaseQueryAccountInfoDetails_mciPayFinance = (options: {
  queryParams: GetAccountBaseQueryAccountInfoDetailsQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultFinanceAccountInfoDetailsDto>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAccountBaseQueryAccountInfoDetails_mciPayFinance.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAccountBaseQueryAccountInfoDetails_mciPayFinance.backendServiceName,
  );
};

getAccountBaseQueryAccountInfoDetails_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getAccountBaseQueryAccountInfoDetails_mciPayFinance.key =
  "/account/base/query/accountInfoDetails";

/**
 *
 * 查询开户行下拉列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115685
 */
export const getAccountBaseQueryBankName_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListBankTypeDto>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getAccountBaseQueryBankName_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAccountBaseQueryBankName_mciPayFinance.backendServiceName,
  );
};

getAccountBaseQueryBankName_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getAccountBaseQueryBankName_mciPayFinance.key = "/account/base/query/bankName";

/**
 *
 * 操作日志导出
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186194709
 */
export const getAdminOperationLogExport_exKernelAdmin = (
  options: {
    queryParams?: GetAdminOperationLogExportQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOString>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminOperationLogExport_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminOperationLogExport_exKernelAdmin.backendServiceName,
  );
};

getAdminOperationLogExport_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getAdminOperationLogExport_exKernelAdmin.key = "/admin/operation/log/export";

/**
 *
 * 操作日志查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186194708
 */
export const getAdminOperationLogQuery_exKernelAdmin = (
  options: {
    queryParams?: GetAdminOperationLogQueryQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOSimplePageMybatisImplOperationLogResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminOperationLogQuery_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminOperationLogQuery_exKernelAdmin.backendServiceName,
  );
};

getAdminOperationLogQuery_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getAdminOperationLogQuery_exKernelAdmin.key = "/admin/operation/log/query";

/**
 *
 * 是否需要添加签
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243206907
 */
export const getAdminRepoInvestNeedAddSignature_mcexpOperation = (options: {
  queryParams: GetAdminRepoInvestNeedAddSignatureQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminRepoInvestNeedAddSignature_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminRepoInvestNeedAddSignature_mcexpOperation.backendServiceName,
  );
};

getAdminRepoInvestNeedAddSignature_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminRepoInvestNeedAddSignature_mcexpOperation.key =
  "/admin/repo/invest/need-add-signature";

/**
 *
 * 查询财务审批通过的出资清单列表接口【资金调拨页面调用】
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243369847
 */
export const getAdminRepoInvestRepoInvestApprovalList_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListRepoInvestApprovalVo>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getAdminRepoInvestRepoInvestApprovalList_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminRepoInvestRepoInvestApprovalList_mcexpOperation.backendServiceName,
  );
};

getAdminRepoInvestRepoInvestApprovalList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminRepoInvestRepoInvestApprovalList_mcexpOperation.key =
  "/admin/repo/invest/repoInvestApprovalList";

/**
 *
 * 用户信息查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186194700
 */
export const getAdminUserBaseinfo_exKernelAdmin = (
  options: {
    queryParams?: GetAdminUserBaseinfoQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOUserBaseDto>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminUserBaseinfo_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminUserBaseinfo_exKernelAdmin.backendServiceName,
  );
};

getAdminUserBaseinfo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getAdminUserBaseinfo_exKernelAdmin.key = "/admin/user/baseinfo";

/**
 *
 * 根据文件id返回文件名和url
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178406451
 */
export const getAdminV1FilesFileId_mcexpOperation = (options: {
  pathParams: {
    fileId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultAdminFileResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getAdminV1FilesFileId_mcexpOperation.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1FilesFileId_mcexpOperation.backendServiceName,
  );
};

getAdminV1FilesFileId_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getAdminV1FilesFileId_mcexpOperation.key = "/admin/v1/files/{fileId}";

/**
 *
 * 根据申请id和业务类型获取文件列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184751634
 */
export const getAdminV1FilesList_mcexpOperation = (options: {
  queryParams: GetAdminV1FilesListQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListAdminFileResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminV1FilesList_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1FilesList_mcexpOperation.backendServiceName,
  );
};

getAdminV1FilesList_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getAdminV1FilesList_mcexpOperation.key = "/admin/v1/files/list";

/**
 *
 * 获取出资失败消息字典
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422224
 */
export const getAdminV1InvestFailMsgDicList_mcexpOperation = (
  options: {
    queryParams?: GetAdminV1InvestFailMsgDicListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListFundingInvestFailMsg>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminV1InvestFailMsgDicList_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1InvestFailMsgDicList_mcexpOperation.backendServiceName,
  );
};

getAdminV1InvestFailMsgDicList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1InvestFailMsgDicList_mcexpOperation.key =
  "/admin/v1/invest/fail-msg-dic/list";

/**
 *
 * 查询出资日志
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422223
 */
export const getAdminV1InvestPeriodOperateList_mcexpOperation = (
  options: {
    queryParams?: GetAdminV1InvestPeriodOperateListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplFundingInvestOperateResp>
> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getAdminV1InvestPeriodOperateList_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1InvestPeriodOperateList_mcexpOperation.backendServiceName,
  );
};

getAdminV1InvestPeriodOperateList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1InvestPeriodOperateList_mcexpOperation.key =
  "/admin/v1/invest/period-operate/list";

/**
 *
 * excelOrderCheckTemplateList
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674525
 */
export const getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation =
  (options: {
    queryParams: GetAdminV1OrderBatchBatchOrderTemplateListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultListExTemplateType>> => {
    const { configOverride, __mock__, queryParams, headerParams } =
      options || {};

    return Http.getRequest(
      getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation.key,
      queryParams,
      undefined,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation.backendServiceName,
    );
  };

getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation.key =
  "/admin/v1/order/batch/batch-order-template-list";

/**
 *
 * 获取品牌信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184816935
 */
export const getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation =
  (options: {
    pathParams: {
      appId: string;
    };
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultDroAppBrandInfoResp>> => {
    const { configOverride, __mock__, pathParams, headerParams } =
      options || {};

    return Http.getRequest(
      template(
        getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      undefined,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation.backendServiceName,
    );
  };

getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/{appId}/brandInfo";

/**
 *
 * 获取drc档案信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178257934
 */
export const getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultDroAppDrcInfoResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(
      getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation.backendServiceName,
  );
};

getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/{appId}/drcInfo";

/**
 *
 * 获取营收信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178257936
 */
export const getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultDroAppIncomeResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(
      getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation.backendServiceName,
  );
};

getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/{appId}/income";

/**
 *
 * 获取现金流pcf
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178257937
 */
export const getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultDroAppPcfResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation.backendServiceName,
  );
};

getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/{appId}/pcf";

/**
 *
 * updateDroShareStatus
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-213931694
 */
export const getApiV1DroArmUpdateDroShareStatus_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getApiV1DroArmUpdateDroShareStatus_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1DroArmUpdateDroShareStatus_mcexpOperation.backendServiceName,
  );
};

getApiV1DroArmUpdateDroShareStatus_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getApiV1DroArmUpdateDroShareStatus_mcexpOperation.key =
  "/api/v1/droArm/updateDroShareStatus";

/**
 *
 * 查询dro详情
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178210299
 */
export const getApiV1DroDroCode_mcexpOperation = (options: {
  pathParams: {
    droCode: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultDroSpecResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getApiV1DroDroCode_mcexpOperation.key, pathParams),
    undefined,
    undefined,
    _CONSTANT4,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1DroDroCode_mcexpOperation.backendServiceName,
  );
};

getApiV1DroDroCode_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getApiV1DroDroCode_mcexpOperation.key = "/api/v1/dro/{droCode}";

/**
 *
 * 将2.0上架的dro同步到1.5系统（异步）
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-180570836
 */
export const getApiV1DroSyncDro_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getApiV1DroSyncDro_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1DroSyncDro_mcexpOperation.backendServiceName,
  );
};

getApiV1DroSyncDro_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getApiV1DroSyncDro_mcexpOperation.key = "/api/v1/dro/syncDro";

/**
 *
 * 将1.5的撮合状态同步回2.0
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-180570837
 */
export const getApiV1DroSyncIpoStatus_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getApiV1DroSyncIpoStatus_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1DroSyncIpoStatus_mcexpOperation.backendServiceName,
  );
};

getApiV1DroSyncIpoStatus_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getApiV1DroSyncIpoStatus_mcexpOperation.key = "/api/v1/dro/syncIpoStatus";

/**
 *
 * 生成出资列表-测试使用
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177837834
 */
export const getApiV1InvestTestGenerate_mcexpOperation = (options: {
  queryParams: GetApiV1InvestTestGenerateQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getApiV1InvestTestGenerate_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1InvestTestGenerate_mcexpOperation.backendServiceName,
  );
};

getApiV1InvestTestGenerate_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getApiV1InvestTestGenerate_mcexpOperation.key = "/api/v1/invest/test/generate";

/**
 *
 * 出资清单邮件发送
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186394556
 */
export const getApiV1MailSendInvestListMail_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getApiV1MailSendInvestListMail_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1MailSendInvestListMail_mcexpOperation.backendServiceName,
  );
};

getApiV1MailSendInvestListMail_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getApiV1MailSendInvestListMail_mcexpOperation.key =
  "/api/v1/mail/sendInvestListMail";

/**
 *
 * 出资结果邮件发送
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186394557
 */
export const getApiV1MailSendInvestResultMail_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getApiV1MailSendInvestResultMail_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1MailSendInvestResultMail_mcexpOperation.backendServiceName,
  );
};

getApiV1MailSendInvestResultMail_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getApiV1MailSendInvestResultMail_mcexpOperation.key =
  "/api/v1/mail/sendInvestResultMail";

/**
 *
 * 查询申请详细
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517163
 */
export const getApiV1ShelfDroAppliesAppId_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultDroAppDetailResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getApiV1ShelfDroAppliesAppId_mcexpOperation.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1ShelfDroAppliesAppId_mcexpOperation.backendServiceName,
  );
};

getApiV1ShelfDroAppliesAppId_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getApiV1ShelfDroAppliesAppId_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}";

/**
 *
 * 下载文件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422299
 */
export const getApiV1ShelfFilesDownload_mcexpOperation = (options: {
  queryParams: GetApiV1ShelfFilesDownloadQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getApiV1ShelfFilesDownload_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getApiV1ShelfFilesDownload_mcexpOperation.backendServiceName,
  );
};

getApiV1ShelfFilesDownload_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getApiV1ShelfFilesDownload_mcexpOperation.key = "/api/v1/shelf/files/download";

/**
 *
 * 根据签约主体code查询签约主体渠道关系
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530907
 */
export const getContractGetContractChannelRelationOrgId_mcexpOperation =
  (options: {
    pathParams: {
      /**
       *
       * orgId
       */
      orgId: string;
    };
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultListContractChannelRelation>> => {
    const { configOverride, __mock__, pathParams, headerParams } =
      options || {};

    return Http.getRequest(
      template(
        getContractGetContractChannelRelationOrgId_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      undefined,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      getContractGetContractChannelRelationOrgId_mcexpOperation.backendServiceName,
    );
  };

getContractGetContractChannelRelationOrgId_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getContractGetContractChannelRelationOrgId_mcexpOperation.key =
  "/contract/getContractChannelRelation/{orgId}";

/**
 *
 * 根据id签约主体以及银行账户信息详情
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530906
 */
export const getContractGetContractEntityByIdId_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * id
     */
    id: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultContractEntityInfoResp>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getContractGetContractEntityByIdId_mcexpOperation.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getContractGetContractEntityByIdId_mcexpOperation.backendServiceName,
  );
};

getContractGetContractEntityByIdId_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getContractGetContractEntityByIdId_mcexpOperation.key =
  "/contract/getContractEntityById/{id}";

/**
 *
 * 根据主体编码查询对应的渠道
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530911
 */
export const getContractListDivideChannelByOrgIdOrgId_mcexpOperation =
  (options: {
    pathParams: {
      /**
       *
       * 主体编码
       */
      orgId: string;
    };
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultContractChannelRsp>> => {
    const { configOverride, __mock__, pathParams, headerParams } =
      options || {};

    return Http.getRequest(
      template(
        getContractListDivideChannelByOrgIdOrgId_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      undefined,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      getContractListDivideChannelByOrgIdOrgId_mcexpOperation.backendServiceName,
    );
  };

getContractListDivideChannelByOrgIdOrgId_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getContractListDivideChannelByOrgIdOrgId_mcexpOperation.key =
  "/contract/listDivideChannelByOrgId/{orgId}";

/**
 *
 * 获取签约主体与银行信息等
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-197229849
 */
export const getContractListSubjectInfo_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListContractEntityInfoRsp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getContractListSubjectInfo_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getContractListSubjectInfo_mcexpOperation.backendServiceName,
  );
};

getContractListSubjectInfo_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getContractListSubjectInfo_mcexpOperation.key = "/contract/listSubjectInfo";

/**
 *
 * 查询订单账户信息列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-240155836
 */
export const getCurrencyExchangeGetShareOrderInfo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListShareOrderInfoResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getCurrencyExchangeGetShareOrderInfo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getCurrencyExchangeGetShareOrderInfo_exKernelAdmin.backendServiceName,
  );
};

getCurrencyExchangeGetShareOrderInfo_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getCurrencyExchangeGetShareOrderInfo_exKernelAdmin.key =
  "/currencyExchange/getShareOrderInfo";

/**
 *
 * 字典查询，包括行业、行政区域
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177274370
 */
export const getDictionaryQuery_mciDict = (
  options: {
    queryParams?: GetDictionaryQueryQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultList>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getDictionaryQuery_mciDict.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getDictionaryQuery_mciDict.backendServiceName,
  );
};

getDictionaryQuery_mciDict.backendServiceName = "mci-dict";
/** Key is end point string without base url */
getDictionaryQuery_mciDict.key = "/dictionary/query";

/**
 *
 * getDroSpecByCode
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606331
 */
export const getDroSpecsCode_exKernelAdmin = (options: {
  pathParams: {
    code: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<FoDroSpec>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getDroSpecsCode_exKernelAdmin.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getDroSpecsCode_exKernelAdmin.backendServiceName,
  );
};

getDroSpecsCode_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getDroSpecsCode_exKernelAdmin.key = "/droSpecs/{code}";

/**
 *
 * user
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606332
 */
export const getDroSpecsUser_exKernelAdmin = (
  options: {
    queryParams?: GetDroSpecsUserQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVO>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getDroSpecsUser_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getDroSpecsUser_exKernelAdmin.backendServiceName,
  );
};

getDroSpecsUser_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getDroSpecsUser_exKernelAdmin.key = "/droSpecs/user";

/**
 *
 * 批量枚举查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177823210
 */
export const getEnumBatchQuery_mciDict = (
  options: {
    queryParams?: GetEnumBatchQueryQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListQueryEnumResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getEnumBatchQuery_mciDict.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getEnumBatchQuery_mciDict.backendServiceName,
  );
};

getEnumBatchQuery_mciDict.backendServiceName = "mci-dict";
/** Key is end point string without base url */
getEnumBatchQuery_mciDict.key = "/enum/batch/query";

/**
 *
 * 枚举查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177274346
 */
export const getEnumQuery_mciDict = (
  options: {
    queryParams?: GetEnumQueryQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListQueryEnumResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getEnumQuery_mciDict.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getEnumQuery_mciDict.backendServiceName,
  );
};

getEnumQuery_mciDict.backendServiceName = "mci-dict";
/** Key is end point string without base url */
getEnumQuery_mciDict.key = "/enum/query";

/**
 *
 * getFullPath
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238363901
 */
export const getFileGetFullPath_mciArm = (options: {
  queryParams: GetFileGetFullPathQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultString>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getFileGetFullPath_mciArm.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getFileGetFullPath_mciArm.backendServiceName,
  );
};

getFileGetFullPath_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
getFileGetFullPath_mciArm.key = "/file/getFullPath";

/**
 *
 * 下载文件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605940
 */
export const getFilesDownload_exKernelAdmin = (options: {
  queryParams: GetFilesDownloadQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<number[]>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getFilesDownload_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getFilesDownload_exKernelAdmin.backendServiceName,
  );
};

getFilesDownload_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getFilesDownload_exKernelAdmin.key = "/files/download";

/**
 *
 * 获取文件url
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605942
 */
export const getFilesUrl_exKernelAdmin = (options: {
  queryParams: GetFilesUrlQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOString>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getFilesUrl_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getFilesUrl_exKernelAdmin.backendServiceName,
  );
};

getFilesUrl_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getFilesUrl_exKernelAdmin.key = "/files/url";

/**
 *
 * 调用ARM接口查询账户名称、账户号
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208524
 */
export const getFundFundTranferGetArmAccountInfoList_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOListArmAccountInfoData>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getFundFundTranferGetArmAccountInfoList_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getFundFundTranferGetArmAccountInfoList_exKernelAdmin.backendServiceName,
  );
};

getFundFundTranferGetArmAccountInfoList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getFundFundTranferGetArmAccountInfoList_exKernelAdmin.key =
  "/fund/fundTranfer/getArmAccountInfoList";

/**
 *
 * 查询会员id相关信息列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208542
 */
export const getHsbcDepositGetMemberShipList_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOListMemberShipResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getHsbcDepositGetMemberShipList_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getHsbcDepositGetMemberShipList_exKernelAdmin.backendServiceName,
  );
};

getHsbcDepositGetMemberShipList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getHsbcDepositGetMemberShipList_exKernelAdmin.key =
  "/hsbc/deposit/getMemberShipList";

/**
 *
 * 查询交易账户列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208539
 */
export const getHsbcDepositGetTradingAccountList_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOListAccountInfoResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getHsbcDepositGetTradingAccountList_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getHsbcDepositGetTradingAccountList_exKernelAdmin.backendServiceName,
  );
};

getHsbcDepositGetTradingAccountList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getHsbcDepositGetTradingAccountList_exKernelAdmin.key =
  "/hsbc/deposit/getTradingAccountList";

/**
 *
 * 查询收款方交易账号
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-239096047
 */
export const getHsbcDepositGetTradingAccountToList_exKernelAdmin = (
  options: {
    queryParams?: GetHsbcDepositGetTradingAccountToListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOListAccountToInfoResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getHsbcDepositGetTradingAccountToList_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getHsbcDepositGetTradingAccountToList_exKernelAdmin.backendServiceName,
  );
};

getHsbcDepositGetTradingAccountToList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getHsbcDepositGetTradingAccountToList_exKernelAdmin.key =
  "/hsbc/deposit/getTradingAccountToList";

/**
 *
 * 获取关联交易单
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208541
 */
export const getHsbcDepositGetTradingBillNoList_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOListTradingBillNoResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getHsbcDepositGetTradingBillNoList_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getHsbcDepositGetTradingBillNoList_exKernelAdmin.backendServiceName,
  );
};

getHsbcDepositGetTradingBillNoList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getHsbcDepositGetTradingBillNoList_exKernelAdmin.key =
  "/hsbc/deposit/getTradingBillNoList";

/**
 *
 * 根据id查询邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605947
 */
export const getMailContentId_exKernelAdmin = (options: {
  pathParams: {
    id: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOMailContentDetailDTO>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getMailContentId_exKernelAdmin.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailContentId_exKernelAdmin.backendServiceName,
  );
};

getMailContentId_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailContentId_exKernelAdmin.key = "/mail/content/{id}";

/**
 *
 * 直接根据ID发送邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605945
 */
export const getMailContentSendId_exKernelAdmin = (options: {
  pathParams: {
    id: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.getRequest(
    template(getMailContentSendId_exKernelAdmin.key, pathParams),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailContentSendId_exKernelAdmin.backendServiceName,
  );
};

getMailContentSendId_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailContentSendId_exKernelAdmin.key = "/mail/content/send/{id}";

/**
 *
 * DRO AO 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605954
 */
export const getMailInfoDroAo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDroAo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDroAo_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDroAo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDroAo_exKernelAdmin.key = "/mail/info/dro/ao";

/**
 *
 * DRO IO 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605953
 */
export const getMailInfoDroIo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDroIo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDroIo_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDroIo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDroIo_exKernelAdmin.key = "/mail/info/dro/io";

/**
 *
 * DRO SO 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605955
 */
export const getMailInfoDroSo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDroSo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDroSo_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDroSo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDroSo_exKernelAdmin.key = "/mail/info/dro/so";

/**
 *
 * DRP IO 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605956
 */
export const getMailInfoDrpIo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDrpIo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDrpIo_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDrpIo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDrpIo_exKernelAdmin.key = "/mail/info/drp/io";

/**
 *
 * DRP IO pledge 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605957
 */
export const getMailInfoDrpIoPledge_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDrpIoPledge_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDrpIoPledge_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDrpIoPledge_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDrpIoPledge_exKernelAdmin.key = "/mail/info/drp/io/pledge";

/**
 *
 * DRP reinvest 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605959
 */
export const getMailInfoDrpReinvest_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDrpReinvest_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDrpReinvest_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDrpReinvest_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDrpReinvest_exKernelAdmin.key = "/mail/info/drp/reinvest";

/**
 *
 * DRP reinvest pledge 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605960
 */
export const getMailInfoDrpReinvestPledge_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDrpReinvestPledge_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDrpReinvestPledge_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDrpReinvestPledge_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDrpReinvestPledge_exKernelAdmin.key =
  "/mail/info/drp/reinvest/pledge";

/**
 *
 * DRP SO 查询数据保存邮件内容
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605958
 */
export const getMailInfoDrpSo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoDrpSo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoDrpSo_exKernelAdmin.backendServiceName,
  );
};

getMailInfoDrpSo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoDrpSo_exKernelAdmin.key = "/mail/info/drp/so";

/**
 *
 * 正常联营结束-公告类邮件内容保存
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605963
 */
export const getMailInfoNoticeNormalEnd_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoNoticeNormalEnd_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoNoticeNormalEnd_exKernelAdmin.backendServiceName,
  );
};

getMailInfoNoticeNormalEnd_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoNoticeNormalEnd_exKernelAdmin.key = "/mail/info/notice/normalEnd";

/**
 *
 * 换店-公告类邮件内容保存
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605962
 */
export const getMailInfoNoticeReplaceStore_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoNoticeReplaceStore_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoNoticeReplaceStore_exKernelAdmin.backendServiceName,
  );
};

getMailInfoNoticeReplaceStore_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoNoticeReplaceStore_exKernelAdmin.key =
  "/mail/info/notice/replaceStore";

/**
 *
 * 闭店(提前结束联营)-公告类邮件内容保存
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605961
 */
export const getMailInfoNoticeStoreClosure_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoNoticeStoreClosure_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoNoticeStoreClosure_exKernelAdmin.backendServiceName,
  );
};

getMailInfoNoticeStoreClosure_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoNoticeStoreClosure_exKernelAdmin.key =
  "/mail/info/notice/storeClosure";

/**
 *
 * 终止合作-公告类邮件内容保存
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605964
 */
export const getMailInfoNoticeTerminationCooperation_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoNoticeTerminationCooperation_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoNoticeTerminationCooperation_exKernelAdmin.backendServiceName,
  );
};

getMailInfoNoticeTerminationCooperation_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoNoticeTerminationCooperation_exKernelAdmin.key =
  "/mail/info/notice/terminationCooperation";

/**
 *
 * 生成交易日邮件内容保存
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605965
 */
export const getMailInfoSave_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getMailInfoSave_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getMailInfoSave_exKernelAdmin.backendServiceName,
  );
};

getMailInfoSave_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getMailInfoSave_exKernelAdmin.key = "/mail/info/save";

/**
 *
 * e签宝回调补偿处理
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-213931690
 */
export const getNouserContractSignflownotifyRedo_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getNouserContractSignflownotifyRedo_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getNouserContractSignflownotifyRedo_mcexpOperation.backendServiceName,
  );
};

getNouserContractSignflownotifyRedo_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
getNouserContractSignflownotifyRedo_mcexpOperation.key =
  "/nouser/contract/signflownotify/redo";

/**
 *
 * 数据字典映射
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606335
 */
export const getNouserDictionaryMapping_exKernelAdmin = (
  options: {
    queryParams?: GetNouserDictionaryMappingQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOMapListDictionaryVo>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getNouserDictionaryMapping_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getNouserDictionaryMapping_exKernelAdmin.backendServiceName,
  );
};

getNouserDictionaryMapping_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getNouserDictionaryMapping_exKernelAdmin.key = "/nouser/dictionary/mapping";

/**
 *
 * 同步OA用户信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276399
 */
export const getNouserOaSyncDepartmentUser_mciUserAuthAdminServer = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getNouserOaSyncDepartmentUser_mciUserAuthAdminServer.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getNouserOaSyncDepartmentUser_mciUserAuthAdminServer.backendServiceName,
  );
};

getNouserOaSyncDepartmentUser_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getNouserOaSyncDepartmentUser_mciUserAuthAdminServer.key =
  "/nouser/oa/sync/department/user";

/**
 *
 * 获取默认的上传策略
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-161691199
 */
export const getNouserOssDefaultPolicy_mciToolbox = (options: {
  queryParams: GetNouserOssDefaultPolicyQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultOssUploadPolicy>> => {
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.getRequest(
    getNouserOssDefaultPolicy_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    getNouserOssDefaultPolicy_mciToolbox.backendServiceName,
  );
};

getNouserOssDefaultPolicy_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
getNouserOssDefaultPolicy_mciToolbox.key = "/nouser/oss/default/policy";

/**
 *
 * 操作日志导出
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606342
 */
export const getOperationLogExport_exKernelAdmin = (
  options: {
    queryParams?: GetOperationLogExportQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOString>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getOperationLogExport_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getOperationLogExport_exKernelAdmin.backendServiceName,
  );
};

getOperationLogExport_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getOperationLogExport_exKernelAdmin.key = "/operation/log/export";

/**
 *
 * 操作日志查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606341
 */
export const getOperationLogQuery_exKernelAdmin = (
  options: {
    queryParams?: GetOperationLogQueryQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOSimplePageMybatisImplOperationLogResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getOperationLogQuery_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getOperationLogQuery_exKernelAdmin.backendServiceName,
  );
};

getOperationLogQuery_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getOperationLogQuery_exKernelAdmin.key = "/operation/log/query";

/**
 *
 * NUMA OSS 上传策略,指定上传目录
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396521
 */
export const getOssPolicyByDir_mciToolbox = (options: {
  queryParams: GetOssPolicyByDirQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultOssUploadPolicy>> => {
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.getRequest(
    getOssPolicyByDir_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    getOssPolicyByDir_mciToolbox.backendServiceName,
  );
};

getOssPolicyByDir_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
getOssPolicyByDir_mciToolbox.key = "/oss/policy/byDir";

/**
 *
 * 生成 文件访问链接
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396524
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const getOssV2BucketAccessUrl_mciToolbox = (options: {
  queryParams: GetOssV2BucketAccessUrlQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultURL>> => {
  if (__DEV__) {
    console.warn(
      "getOssV2BucketAccessUrl_mciToolbox",
      "This endpoint deprecated and will be remove. Please use an alternative",
    );
  }
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.getRequest(
    getOssV2BucketAccessUrl_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    getOssV2BucketAccessUrl_mciToolbox.backendServiceName,
  );
};

getOssV2BucketAccessUrl_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
getOssV2BucketAccessUrl_mciToolbox.key = "/oss/v2/bucket/access/url";

/**
 *
 * NUMA OSS 默认上传策略
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396520
 */
export const getOssV2DefaultPolicy_mciToolbox = (options: {
  queryParams: GetOssV2DefaultPolicyQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultOssUploadPolicy>> => {
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.getRequest(
    getOssV2DefaultPolicy_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    getOssV2DefaultPolicy_mciToolbox.backendServiceName,
  );
};

getOssV2DefaultPolicy_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
getOssV2DefaultPolicy_mciToolbox.key = "/oss/v2/default/policy";

/**
 *
 * 渠道支付任务下载回单
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-151387589
 */
export const getPayFinanceChannelPaymentTaskDownloadReceipt_mciPayFinance = (
  options: {
    queryParams?: GetPayFinanceChannelPaymentTaskDownloadReceiptQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getPayFinanceChannelPaymentTaskDownloadReceipt_mciPayFinance.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getPayFinanceChannelPaymentTaskDownloadReceipt_mciPayFinance.backendServiceName,
  );
};

getPayFinanceChannelPaymentTaskDownloadReceipt_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getPayFinanceChannelPaymentTaskDownloadReceipt_mciPayFinance.key =
  "/pay-finance/channel/payment/task/download-receipt";

/**
 *
 * 渠道支付任务回盘
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-151252809
 */
export const getPayFinanceChannelPaymentTaskPayReturn_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getPayFinanceChannelPaymentTaskPayReturn_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getPayFinanceChannelPaymentTaskPayReturn_mciPayFinance.backendServiceName,
  );
};

getPayFinanceChannelPaymentTaskPayReturn_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getPayFinanceChannelPaymentTaskPayReturn_mciPayFinance.key =
  "/pay-finance/channel/payment/task/pay-return";

/**
 *
 * 财资刷数
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-188494128
 */
export const getPayFinanceFinancingBatchCalculate_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getPayFinanceFinancingBatchCalculate_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getPayFinanceFinancingBatchCalculate_mciPayFinance.backendServiceName,
  );
};

getPayFinanceFinancingBatchCalculate_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getPayFinanceFinancingBatchCalculate_mciPayFinance.key =
  "/pay-finance/financing/batch/calculate";

/**
 *
 * 招行流水明细查询
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-151370458
 */
export const getPayJobsCmbInvestQueryTransDetail_mciPayFinance = (
  options: {
    queryParams?: GetPayJobsCmbInvestQueryTransDetailQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getPayJobsCmbInvestQueryTransDetail_mciPayFinance.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getPayJobsCmbInvestQueryTransDetail_mciPayFinance.backendServiceName,
  );
};

getPayJobsCmbInvestQueryTransDetail_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
getPayJobsCmbInvestQueryTransDetail_mciPayFinance.key =
  "/pay/jobs/cmb/invest/queryTransDetail";

/**
 *
 * 获取角色以及角色对应权限详情
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201366307
 */
export const getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer = (options: {
  queryParams: GetRoleV1GetRoleInfoDetailsQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultRoleInfoDetailResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer.backendServiceName,
  );
};

getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer.key =
  "/role/v1/getRoleInfoDetails";

/**
 *
 * spac数据结构
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-239870290
 */
export const getSpacQuery_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultSpacInfo>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getSpacQuery_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSpacQuery_mcexpOperation.backendServiceName,
  );
};

getSpacQuery_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getSpacQuery_mcexpOperation.key = "/spac/query";

/**
 *
 * spac发起人数据结构
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-210903591
 */
export const getSpacQueryOperator_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultSpacOperatorInfo>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getSpacQueryOperator_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSpacQueryOperator_mcexpOperation.backendServiceName,
  );
};

getSpacQueryOperator_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getSpacQueryOperator_mcexpOperation.key = "/spac/query/operator";

/**
 *
 * spv数据结构
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-209933757
 */
export const getSpvQuery_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultSpvInfo>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getSpvQuery_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSpvQuery_mcexpOperation.backendServiceName,
  );
};

getSpvQuery_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getSpvQuery_mcexpOperation.key = "/spv/query";

/**
 *
 * spv发起人数据结构
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-209933120
 */
export const getSpvQueryOperator_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultOperatorInfo>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getSpvQueryOperator_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSpvQueryOperator_mcexpOperation.backendServiceName,
  );
};

getSpvQueryOperator_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
getSpvQueryOperator_mcexpOperation.key = "/spv/query/operator";

/**
 *
 * 查询所有系统角色信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201823204
 */
export const getSysRoleList_mciUserAuthAdminServer = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListSystemRoleResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getSysRoleList_mciUserAuthAdminServer.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSysRoleList_mciUserAuthAdminServer.backendServiceName,
  );
};

getSysRoleList_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getSysRoleList_mciUserAuthAdminServer.key = "/sys/role/list";

/**
 *
 * 模糊查询查询系统信息列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276403
 */
export const getSysSystemList_mciUserAuthAdminServer = (
  options: {
    queryParams?: GetSysSystemListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListSystemInfoResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getSysSystemList_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSysSystemList_mciUserAuthAdminServer.backendServiceName,
  );
};

getSysSystemList_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getSysSystemList_mciUserAuthAdminServer.key = "/sys/system/list";

/**
 *
 * 根据系统code查询系统名称
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276404
 */
export const getSysSystemNameSystemCode_mciUserAuthAdminServer = (options: {
  queryParams: GetSysSystemNameSystemCodeQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSystemInfoResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getSysSystemNameSystemCode_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSysSystemNameSystemCode_mciUserAuthAdminServer.backendServiceName,
  );
};

getSysSystemNameSystemCode_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getSysSystemNameSystemCode_mciUserAuthAdminServer.key =
  "/sys/system/name/systemCode";

/**
 *
 * 查询系统菜单列表（可授权的菜单项）
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276401
 */
export const getSysV1GetSystemMenuAuthVisibleList_mciUserAuthAdminServer =
  (options: {
    queryParams: GetSysV1GetSystemMenuAuthVisibleListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultListSystemAuthResp>> => {
    const { configOverride, __mock__, queryParams, headerParams } =
      options || {};

    return Http.getRequest(
      getSysV1GetSystemMenuAuthVisibleList_mciUserAuthAdminServer.key,
      queryParams,
      undefined,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      getSysV1GetSystemMenuAuthVisibleList_mciUserAuthAdminServer.backendServiceName,
    );
  };

getSysV1GetSystemMenuAuthVisibleList_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getSysV1GetSystemMenuAuthVisibleList_mciUserAuthAdminServer.key =
  "/sys/v1/getSystemMenuAuthVisibleList";

/**
 *
 * 查询系统菜单列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276400
 */
export const getSysV1GetSystemMenuList_mciUserAuthAdminServer = (options: {
  queryParams: GetSysV1GetSystemMenuListQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListSystemAuthResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getSysV1GetSystemMenuList_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getSysV1GetSystemMenuList_mciUserAuthAdminServer.backendServiceName,
  );
};

getSysV1GetSystemMenuList_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getSysV1GetSystemMenuList_mciUserAuthAdminServer.key =
  "/sys/v1/getSystemMenuList";

/**
 *
 * 用户信息查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606333
 */
export const getUserBaseinfo_exKernelAdmin = (
  options: {
    queryParams?: GetUserBaseinfoQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOUserBaseDto>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getUserBaseinfo_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserBaseinfo_exKernelAdmin.backendServiceName,
  );
};

getUserBaseinfo_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
getUserBaseinfo_exKernelAdmin.key = "/user/baseinfo";

/**
 *
 * 批量查询用户信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276398
 */
export const getUserV1BatchGetUserByUserIds_mciUserAuthAdminServer = (options: {
  queryParams: GetUserV1BatchGetUserByUserIdsQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListUserBaseResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getUserV1BatchGetUserByUserIds_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserV1BatchGetUserByUserIds_mciUserAuthAdminServer.backendServiceName,
  );
};

getUserV1BatchGetUserByUserIds_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getUserV1BatchGetUserByUserIds_mciUserAuthAdminServer.key =
  "/user/v1/batch/getUserByUserIds";

/**
 *
 * 获取用户基本信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276394
 */
export const getUserV1GetBaseInfo_mciUserAuthAdminServer = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultUserBaseInfoResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getUserV1GetBaseInfo_mciUserAuthAdminServer.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserV1GetBaseInfo_mciUserAuthAdminServer.backendServiceName,
  );
};

getUserV1GetBaseInfo_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getUserV1GetBaseInfo_mciUserAuthAdminServer.key = "/user/v1/getBaseInfo";

/**
 *
 * 根据用户ID获取用户角色
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276396
 */
export const getUserV1GetRoleByUser_mciUserAuthAdminServer = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListRoleInfoDto>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.getRequest(
    getUserV1GetRoleByUser_mciUserAuthAdminServer.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserV1GetRoleByUser_mciUserAuthAdminServer.backendServiceName,
  );
};

getUserV1GetRoleByUser_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getUserV1GetRoleByUser_mciUserAuthAdminServer.key = "/user/v1/getRoleByUser";

/**
 *
 * 根据用户ID获取用户角色[非token]
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201726047
 */
export const getUserV1GetRoleByUserId_mciUserAuthAdminServer = (options: {
  queryParams: GetUserV1GetRoleByUserIdQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListRoleInfoDto>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getUserV1GetRoleByUserId_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserV1GetRoleByUserId_mciUserAuthAdminServer.backendServiceName,
  );
};

getUserV1GetRoleByUserId_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getUserV1GetRoleByUserId_mciUserAuthAdminServer.key =
  "/user/v1/getRoleByUserId";

/**
 *
 * 根据用角色ID获取用户信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276397
 */
export const getUserV1GetUserByRole_mciUserAuthAdminServer = (
  options: {
    queryParams?: GetUserV1GetUserByRoleQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListUserBaseResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getUserV1GetUserByRole_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserV1GetUserByRole_mciUserAuthAdminServer.backendServiceName,
  );
};

getUserV1GetUserByRole_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getUserV1GetUserByRole_mciUserAuthAdminServer.key = "/user/v1/getUserByRole";

/**
 *
 * 获取用户角色权限信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276395
 */
export const getUserV1GetUserRoleInfo_mciUserAuthAdminServer = (
  options: {
    queryParams?: GetUserV1GetUserRoleInfoQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultUserRoleInfoResp>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.getRequest(
    getUserV1GetUserRoleInfo_mciUserAuthAdminServer.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    getUserV1GetUserRoleInfo_mciUserAuthAdminServer.backendServiceName,
  );
};

getUserV1GetUserRoleInfo_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
getUserV1GetUserRoleInfo_mciUserAuthAdminServer.key =
  "/user/v1/getUserRoleInfo";

/**
 *
 * 新增信息流验收报告
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-239648700
 */
export const postAcceptanceReportAddInfoFlowAcceptanceReport_mciArm =
  (options: {
    requestBody: InfoFlowAcceptanceReportAddReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAcceptanceReportAddInfoFlowAcceptanceReport_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAcceptanceReportAddInfoFlowAcceptanceReport_mciArm.backendServiceName,
    );
  };

postAcceptanceReportAddInfoFlowAcceptanceReport_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postAcceptanceReportAddInfoFlowAcceptanceReport_mciArm.key =
  "/acceptanceReport/addInfoFlowAcceptanceReport";

/**
 *
 * ARM验收状态查询
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-239648703
 */
export const postAcceptanceReportQueryArmAcceptanceReportStatus_mciArm =
  (options: {
    requestBody: ArmAcceptanceReportStatusQueryReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<
    SwaggerResponse<ResultSimplePageMybatisImplArmAcceptanceReportDetailDto>
  > => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAcceptanceReportQueryArmAcceptanceReportStatus_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAcceptanceReportQueryArmAcceptanceReportStatus_mciArm.backendServiceName,
    );
  };

postAcceptanceReportQueryArmAcceptanceReportStatus_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postAcceptanceReportQueryArmAcceptanceReportStatus_mciArm.key =
  "/acceptanceReport/queryArmAcceptanceReportStatus";

/**
 *
 * 查询信息流验收报告列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-239648701
 */
export const postAcceptanceReportQueryInfoFlowAcceptanceReportList_mciArm =
  (options: {
    queryParams: PostAcceptanceReportQueryInfoFlowAcceptanceReportListQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultInfoFlowReportListResp>> => {
    const { configOverride, __mock__, queryParams, headerParams } =
      options || {};

    return Http.postRequest(
      postAcceptanceReportQueryInfoFlowAcceptanceReportList_mciArm.key,
      queryParams,
      undefined,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAcceptanceReportQueryInfoFlowAcceptanceReportList_mciArm.backendServiceName,
    );
  };

postAcceptanceReportQueryInfoFlowAcceptanceReportList_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postAcceptanceReportQueryInfoFlowAcceptanceReportList_mciArm.key =
  "/acceptanceReport/queryInfoFlowAcceptanceReportList";

/**
 *
 * 信息流报告验收处理
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-239648702
 */
export const postAcceptanceReportUpdateInfoFlowAcceptanceReport_mciArm =
  (options: {
    requestBody: InfoFlowAcceptanceReportUpdateReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAcceptanceReportUpdateInfoFlowAcceptanceReport_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAcceptanceReportUpdateInfoFlowAcceptanceReport_mciArm.backendServiceName,
    );
  };

postAcceptanceReportUpdateInfoFlowAcceptanceReport_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postAcceptanceReportUpdateInfoFlowAcceptanceReport_mciArm.key =
  "/acceptanceReport/updateInfoFlowAcceptanceReport";

/**
 *
 * 删除账户信息
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115678
 */
export const postAccountBaseDeleteAccountInfo_mciPayFinance = (options: {
  queryParams: PostAccountBaseDeleteAccountInfoQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseDeleteAccountInfo_mciPayFinance.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseDeleteAccountInfo_mciPayFinance.backendServiceName,
  );
};

postAccountBaseDeleteAccountInfo_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseDeleteAccountInfo_mciPayFinance.key =
  "/account/base/delete/accountInfo";

/**
 *
 * 账户信息附件删除
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115679
 */
export const postAccountBaseDeleteAttachFiles_mciPayFinance = (options: {
  queryParams: PostAccountBaseDeleteAttachFilesQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseDeleteAttachFiles_mciPayFinance.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseDeleteAttachFiles_mciPayFinance.backendServiceName,
  );
};

postAccountBaseDeleteAttachFiles_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseDeleteAttachFiles_mciPayFinance.key =
  "/account/base/delete/attachFiles";

/**
 *
 * 导出账户信息到excel
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115684
 */
export const postAccountBaseExportExcel_mciPayFinance = (options: {
  requestBody: QueryAccountInfoDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseExportExcel_mciPayFinance.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseExportExcel_mciPayFinance.backendServiceName,
  );
};

postAccountBaseExportExcel_mciPayFinance.backendServiceName = "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseExportExcel_mciPayFinance.key = "/account/base/exportExcel";

/**
 *
 * 更新账户信息
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115680
 */
export const postAccountBaseModifyAccountInfo_mciPayFinance = (options: {
  requestBody: ModifyAccountInfoDetailDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseModifyAccountInfo_mciPayFinance.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseModifyAccountInfo_mciPayFinance.backendServiceName,
  );
};

postAccountBaseModifyAccountInfo_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseModifyAccountInfo_mciPayFinance.key =
  "/account/base/modify/accountInfo";

/**
 *
 * 更新账户记录状态
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115681
 */
export const postAccountBaseModifyRecordStatus_mciPayFinance = (options: {
  requestBody: ModifyAccountInfoDetailsDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseModifyRecordStatus_mciPayFinance.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseModifyRecordStatus_mciPayFinance.backendServiceName,
  );
};

postAccountBaseModifyRecordStatus_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseModifyRecordStatus_mciPayFinance.key =
  "/account/base/modify/recordStatus";

/**
 *
 * getAcctNameList
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115686
 */
export const postAccountBaseQueryAcctNameList_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListString>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseQueryAcctNameList_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseQueryAcctNameList_mciPayFinance.backendServiceName,
  );
};

postAccountBaseQueryAcctNameList_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseQueryAcctNameList_mciPayFinance.key =
  "/account/base/query/acct-name-list";

/**
 *
 * 批量更新复核记录状态
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115682
 */
export const postAccountBaseUpdateBatchRecordStatus_mciPayFinance = (options: {
  requestBody: UpdateBatchRecordStatusDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseUpdateBatchRecordStatus_mciPayFinance.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseUpdateBatchRecordStatus_mciPayFinance.backendServiceName,
  );
};

postAccountBaseUpdateBatchRecordStatus_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseUpdateBatchRecordStatus_mciPayFinance.key =
  "/account/base/update/batchRecordStatus";

/**
 *
 * 更新上传附件
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-237115683
 */
export const postAccountBaseUpdateUploadAttachments_mciPayFinance = (options: {
  requestBody: UpdateUploadAttachmentssDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAccountBaseUpdateUploadAttachments_mciPayFinance.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAccountBaseUpdateUploadAttachments_mciPayFinance.backendServiceName,
  );
};

postAccountBaseUpdateUploadAttachments_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postAccountBaseUpdateUploadAttachments_mciPayFinance.key =
  "/account/base/update/uploadAttachments";

/**
 *
 * 查询清单详情
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243206909
 */
export const postAdminRepoInvestDetail_mcexpOperation = (options: {
  queryParams: PostAdminRepoInvestDetailQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultRepoInvestApprovalDetailVo>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postAdminRepoInvestDetail_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminRepoInvestDetail_mcexpOperation.backendServiceName,
  );
};

postAdminRepoInvestDetail_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminRepoInvestDetail_mcexpOperation.key = "/admin/repo/invest/detail";

/**
 *
 * 出资清单列表下载
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243514360
 */
export const postAdminRepoInvestExport_mcexpOperation = (options: {
  requestBody: RepoInvestApprovalReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminRepoInvestExport_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminRepoInvestExport_mcexpOperation.backendServiceName,
  );
};

postAdminRepoInvestExport_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminRepoInvestExport_mcexpOperation.key = "/admin/repo/invest/export";

/**
 *
 * 查询出资清单列表接口
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243206908
 */
export const postAdminRepoInvestList_mcexpOperation = (options: {
  requestBody: RepoInvestApprovalReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplRepoInvestApprovalVo>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminRepoInvestList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminRepoInvestList_mcexpOperation.backendServiceName,
  );
};

postAdminRepoInvestList_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminRepoInvestList_mcexpOperation.key = "/admin/repo/invest/list";

/**
 *
 * 出资重试-单个
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243674332
 */
export const postAdminRepoInvestRetry_mcexpOperation = (options: {
  requestBody: string;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminRepoInvestRetry_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminRepoInvestRetry_mcexpOperation.backendServiceName,
  );
};

postAdminRepoInvestRetry_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminRepoInvestRetry_mcexpOperation.key = "/admin/repo/invest/retry";

/**
 *
 * 出资重试-批量
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243674333
 */
export const postAdminRepoInvestRetryBatch_mcexpOperation = (options: {
  requestBody: string[];
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminRepoInvestRetryBatch_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminRepoInvestRetryBatch_mcexpOperation.backendServiceName,
  );
};

postAdminRepoInvestRetryBatch_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminRepoInvestRetryBatch_mcexpOperation.key =
  "/admin/repo/invest/retry-batch";

/**
 *
 * test
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186194701
 */
export const postAdminUserTest_exKernelAdmin = (options: {
  requestBody: DepositTransferData;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminUserTest_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminUserTest_exKernelAdmin.backendServiceName,
  );
};

postAdminUserTest_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postAdminUserTest_exKernelAdmin.key = "/admin/user/test";

/**
 *
 * 已上架列表查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183805881
 */
export const postAdminV1DroDoList_mcexpOperation = (options: {
  requestBody: DroSpecQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplDroSpecQueryResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1DroDoList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1DroDoList_mcexpOperation.backendServiceName,
  );
};

postAdminV1DroDoList_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminV1DroDoList_mcexpOperation.key = "/admin/v1/dro/doList";

/**
 *
 * 已上架列表下载
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184548023
 */
export const postAdminV1DroExport_mcexpOperation = (options: {
  requestBody: DroSpecQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1DroExport_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1DroExport_mcexpOperation.backendServiceName,
  );
};

postAdminV1DroExport_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminV1DroExport_mcexpOperation.key = "/admin/v1/dro/export";

/**
 *
 * 发送dro信息给消息中间件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184852809
 */
export const postAdminV1DroSendDroSpec_mcexpOperation = (options: {
  queryParams: PostAdminV1DroSendDroSpecQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1DroSendDroSpec_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1DroSendDroSpec_mcexpOperation.backendServiceName,
  );
};

postAdminV1DroSendDroSpec_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminV1DroSendDroSpec_mcexpOperation.key = "/admin/v1/dro/sendDroSpec";

/**
 *
 * 出资列表-DRO维度
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177805645
 */
export const postAdminV1InvestDroList_mcexpOperation = (options: {
  requestBody: FundingDroInvestReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplFundingInvestResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1InvestDroList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1InvestDroList_mcexpOperation.backendServiceName,
  );
};

postAdminV1InvestDroList_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminV1InvestDroList_mcexpOperation.key = "/admin/v1/invest/dro/list";

/**
 *
 * 出资-确认
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422220
 */
export const postAdminV1InvestPeriodBatchConfirm_mcexpOperation = (options: {
  requestBody: FundingInvestConfirmReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1InvestPeriodBatchConfirm_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1InvestPeriodBatchConfirm_mcexpOperation.backendServiceName,
  );
};

postAdminV1InvestPeriodBatchConfirm_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1InvestPeriodBatchConfirm_mcexpOperation.key =
  "/admin/v1/invest/period/batch-confirm";

/**
 *
 * 出资-重发
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422219
 */
export const postAdminV1InvestPeriodBatchRetry_mcexpOperation = (options: {
  requestBody: FundingInvestRetryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1InvestPeriodBatchRetry_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1InvestPeriodBatchRetry_mcexpOperation.backendServiceName,
  );
};

postAdminV1InvestPeriodBatchRetry_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1InvestPeriodBatchRetry_mcexpOperation.key =
  "/admin/v1/invest/period/batch-retry";

/**
 *
 * 出资列表-期数维度
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422222
 */
export const postAdminV1InvestPeriodList_mcexpOperation = (options: {
  requestBody: FundingPeriodInvestReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplFundingPeriodInvestResp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1InvestPeriodList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1InvestPeriodList_mcexpOperation.backendServiceName,
  );
};

postAdminV1InvestPeriodList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1InvestPeriodList_mcexpOperation.key = "/admin/v1/invest/period/list";

/**
 *
 * 出资状态查询-更新状态
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422218
 */
export const postAdminV1InvestPeriodUpdateInvestStatus_mcexpOperation =
  (options: {
    requestBody: FundingInvestUpdateReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAdminV1InvestPeriodUpdateInvestStatus_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAdminV1InvestPeriodUpdateInvestStatus_mcexpOperation.backendServiceName,
    );
  };

postAdminV1InvestPeriodUpdateInvestStatus_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1InvestPeriodUpdateInvestStatus_mcexpOperation.key =
  "/admin/v1/invest/period/update-invest-status";

/**
 *
 * selectBatchBlockTradeOrderSum
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674539
 */
export const postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation =
  (options: {
    requestBody: ExManualOrderBatchSumReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultExManualBlockTradeSumRsp>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation.backendServiceName,
    );
  };

postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation.key =
  "/admin/v1/order/batch/batch-block-trade-order-sum";

/**
 *
 * selectBatchOrderList
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674527
 */
export const postAdminV1OrderBatchBatchOrderList_mcexpOperation = (options: {
  requestBody: ExManualOrderBatchPageParam;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplManualOrderBatchDetailDTO>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBatchBatchOrderList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBatchBatchOrderList_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBatchBatchOrderList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBatchOrderList_mcexpOperation.key =
  "/admin/v1/order/batch/batch-order-list";

/**
 *
 * selectPageExport
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674529
 */
export const postAdminV1OrderBatchBatchOrderListExport_mcexpOperation =
  (options: {
    requestBody: ExManualOrderBatchPageParam;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAdminV1OrderBatchBatchOrderListExport_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAdminV1OrderBatchBatchOrderListExport_mcexpOperation.backendServiceName,
    );
  };

postAdminV1OrderBatchBatchOrderListExport_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBatchOrderListExport_mcexpOperation.key =
  "/admin/v1/order/batch/batch-order-list-export";

/**
 *
 * selectBatchOrderSum
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674528
 */
export const postAdminV1OrderBatchBatchOrderSum_mcexpOperation = (options: {
  requestBody: ExManualOrderBatchSumReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultExManualOrderBatchSumRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBatchBatchOrderSum_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBatchBatchOrderSum_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBatchBatchOrderSum_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBatchOrderSum_mcexpOperation.key =
  "/admin/v1/order/batch/batch-order-sum";

/**
 *
 * excelOrderCheck
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674526
 */
export const postAdminV1OrderBatchBatchOrderUpload_mcexpOperation = (options: {
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file?: string;
  };
  queryParams: PostAdminV1OrderBatchBatchOrderUploadQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultExManualOrderCheckRes>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postAdminV1OrderBatchBatchOrderUpload_mcexpOperation.key,
    queryParams,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT2,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBatchBatchOrderUpload_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBatchBatchOrderUpload_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBatchOrderUpload_mcexpOperation.key =
  "/admin/v1/order/batch/batch-order-upload";

/**
 *
 * blockBatchSubmit
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674540
 */
export const postAdminV1OrderBatchBlockNewOrder_mcexpOperation = (options: {
  requestBody: ExManualOrderBatchReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBatchBlockNewOrder_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBatchBlockNewOrder_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBatchBlockNewOrder_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBlockNewOrder_mcexpOperation.key =
  "/admin/v1/order/batch/block-new-order";

/**
 *
 * selectBatchBlockTradeOrderList
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674538
 */
export const postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation =
  (options: {
    requestBody: ExManualOrderBatchPageParam;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<
    SwaggerResponse<ResultSimplePageMybatisImplManualBlockOrderBatchDetailDTO>
  > => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation.backendServiceName,
    );
  };

postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation.key =
  "/admin/v1/order/batch/block-trade-order-list";

/**
 *
 * cancelBlockTrade
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674534
 */
export const postAdminV1OrderBatchCancelBlockTrade_mcexpOperation = (options: {
  requestBody: ExBatchCancelReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBatchCancelBlockTrade_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBatchCancelBlockTrade_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBatchCancelBlockTrade_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchCancelBlockTrade_mcexpOperation.key =
  "/admin/v1/order/batch/cancel-block-trade";

/**
 *
 * manualBlockTradeBatchCancel
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674535
 */
export const postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation =
  (options: {
    requestBody: ExBatchOrderReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultBoolean>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation.backendServiceName,
    );
  };

postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation.key =
  "/admin/v1/order/batch/manual-block-trade-batch-cancel";

/**
 *
 * manualBlockTradeBatchCheck
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674536
 */
export const postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation =
  (options: {
    requestBody: ExBatchCheckOrderReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultBoolean>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation.backendServiceName,
    );
  };

postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation.key =
  "/admin/v1/order/batch/manual-block-trade-batch-check";

/**
 *
 * batchSubmit
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674530
 */
export const postAdminV1OrderBatchNewOrder_mcexpOperation = (options: {
  requestBody: ExManualOrderBatchReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBatchNewOrder_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBatchNewOrder_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBatchNewOrder_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBatchNewOrder_mcexpOperation.key =
  "/admin/v1/order/batch/new-order";

/**
 *
 * selectPageBlockTradeExport
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674533
 */
export const postAdminV1OrderBlockTradeListExport_mcexpOperation = (options: {
  requestBody: ExBlockTradeOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultObject>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBlockTradeListExport_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBlockTradeListExport_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBlockTradeListExport_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBlockTradeListExport_mcexpOperation.key =
  "/admin/v1/order/block-trade-list-export";

/**
 *
 * selectBlockTradeOrderPage
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674531
 */
export const postAdminV1OrderBlockTradeOrderList_mcexpOperation = (options: {
  requestBody: ExBlockTradeOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplExBlockTradeOrderRsp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderBlockTradeOrderList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderBlockTradeOrderList_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderBlockTradeOrderList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderBlockTradeOrderList_mcexpOperation.key =
  "/admin/v1/order/block-trade-order-list";

/**
 *
 * cancel
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674519
 */
export const postAdminV1OrderCancel_mcexpOperation = (options: {
  requestBody: ExCancelOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderCancel_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderCancel_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderCancel_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderCancel_mcexpOperation.key = "/admin/v1/order/cancel";

/**
 *
 * selectDirectPage
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674515
 */
export const postAdminV1OrderDirectList_mcexpOperation = (options: {
  requestBody: ExDirectOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplExDirectOrderRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderDirectList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderDirectList_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderDirectList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderDirectList_mcexpOperation.key = "/admin/v1/order/direct-list";

/**
 *
 * selectPageDirectExport
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674516
 */
export const postAdminV1OrderDirectListExport_mcexpOperation = (options: {
  requestBody: ExDirectOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultObject>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderDirectListExport_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderDirectListExport_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderDirectListExport_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderDirectListExport_mcexpOperation.key =
  "/admin/v1/order/direct-list-export";

/**
 *
 * selectManualBatch
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674521
 */
export const postAdminV1OrderManualBatch_mcexpOperation = (options: {
  requestBody: ExBatchCancelReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListExBatchOrderRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualBatch_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualBatch_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualBatch_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualBatch_mcexpOperation.key = "/admin/v1/order/manual-batch";

/**
 *
 * manualBatchCancel
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674522
 */
export const postAdminV1OrderManualBatchCancel_mcexpOperation = (options: {
  requestBody: ExBatchCancelReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualBatchCancel_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualBatchCancel_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualBatchCancel_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualBatchCancel_mcexpOperation.key =
  "/admin/v1/order/manual-batch-cancel";

/**
 *
 * manualBatchCheck
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674523
 */
export const postAdminV1OrderManualBatchCheck_mcexpOperation = (options: {
  requestBody: ExBatchCheckOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualBatchCheck_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualBatchCheck_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualBatchCheck_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualBatchCheck_mcexpOperation.key =
  "/admin/v1/order/manual-batch-check";

/**
 *
 * manualBatchRevocation
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674524
 */
export const postAdminV1OrderManualBatchRevocation_mcexpOperation = (options: {
  requestBody: ExBatchOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualBatchRevocation_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualBatchRevocation_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualBatchRevocation_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualBatchRevocation_mcexpOperation.key =
  "/admin/v1/order/manual-batch-revocation";

/**
 *
 * selectManualBlockTradeBatch
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674537
 */
export const postAdminV1OrderManualBlockTradeBatch_mcexpOperation = (options: {
  requestBody: ExBatchCancelReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListExBatchOrderRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualBlockTradeBatch_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualBlockTradeBatch_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualBlockTradeBatch_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualBlockTradeBatch_mcexpOperation.key =
  "/admin/v1/order/manual-block-trade-batch";

/**
 *
 * selectManualBlockTradePage
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674532
 */
export const postAdminV1OrderManualBlockTradeList_mcexpOperation = (options: {
  requestBody: ExManualBlockTradeOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplExManualBlockTradeOrderRsp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualBlockTradeList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualBlockTradeList_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualBlockTradeList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualBlockTradeList_mcexpOperation.key =
  "/admin/v1/order/manual-block-trade-list";

/**
 *
 * selectManualPage
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674520
 */
export const postAdminV1OrderManualList_mcexpOperation = (options: {
  requestBody: ExManualOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplExManualOrderRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderManualList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderManualList_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderManualList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderManualList_mcexpOperation.key = "/admin/v1/order/manual-list";

/**
 *
 * selectMatchPage
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674517
 */
export const postAdminV1OrderMatchList_mcexpOperation = (options: {
  requestBody: ExMatchOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplExMatchOrderRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderMatchList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderMatchList_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderMatchList_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderMatchList_mcexpOperation.key = "/admin/v1/order/match-list";

/**
 *
 * selectPageMatchExport
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188674518
 */
export const postAdminV1OrderMatchListExport_mcexpOperation = (options: {
  requestBody: ExMatchOrderReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultObject>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1OrderMatchListExport_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1OrderMatchListExport_mcexpOperation.backendServiceName,
  );
};

postAdminV1OrderMatchListExport_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1OrderMatchListExport_mcexpOperation.key =
  "/admin/v1/order/match-list-export";

/**
 *
 * 审核申请
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178257938
 */
export const postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * 申请id
     */
    appId: string;
  };
  requestBody: ShelfDroApplyReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.postRequest(
    template(
      postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation.backendServiceName,
  );
};

postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/{appId}/doAudit";

/**
 *
 * 待上架列表查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183805923
 */
export const postAdminV1ShelfDroAppliesDoList_mcexpOperation = (options: {
  requestBody: ShelfDroApplyQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplShelfDroApplyQueryResp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1ShelfDroAppliesDoList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1ShelfDroAppliesDoList_mcexpOperation.backendServiceName,
  );
};

postAdminV1ShelfDroAppliesDoList_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1ShelfDroAppliesDoList_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/doList";

/**
 *
 * 待上架列表下载
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184547234
 */
export const postAdminV1ShelfDroAppliesExport_mcexpOperation = (options: {
  requestBody: ShelfDroApplyQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postAdminV1ShelfDroAppliesExport_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postAdminV1ShelfDroAppliesExport_mcexpOperation.backendServiceName,
  );
};

postAdminV1ShelfDroAppliesExport_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postAdminV1ShelfDroAppliesExport_mcexpOperation.key =
  "/admin/v1/shelf/dro/applies/export";

/**
 *
 * 根据门店查询dro的分成规则
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422248
 */
export const postApiV1DroArmListShareRule_mcexpOperation = (options: {
  requestBody: StoreReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListDivideRulesDto>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1DroArmListShareRule_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1DroArmListShareRule_mcexpOperation.backendServiceName,
  );
};

postApiV1DroArmListShareRule_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1DroArmListShareRule_mcexpOperation.key =
  "/api/v1/droArm/listShareRule";

/**
 *
 * 根据门店查询dro存活状态
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422247
 */
export const postApiV1DroArmListStatusInfo_mcexpOperation = (options: {
  requestBody: StoreReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListDroSurviveStatusResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1DroArmListStatusInfo_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1DroArmListStatusInfo_mcexpOperation.backendServiceName,
  );
};

postApiV1DroArmListStatusInfo_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1DroArmListStatusInfo_mcexpOperation.key =
  "/api/v1/droArm/listStatusInfo";

/**
 *
 * 更新dro存活状态
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422249
 */
export const postApiV1DroArmSubmitAssociateEnd_mcexpOperation = (options: {
  requestBody: CloseDroReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1DroArmSubmitAssociateEnd_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1DroArmSubmitAssociateEnd_mcexpOperation.backendServiceName,
  );
};

postApiV1DroArmSubmitAssociateEnd_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1DroArmSubmitAssociateEnd_mcexpOperation.key =
  "/api/v1/droArm/submitAssociateEnd";

/**
 *
 * 撮合结果
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178210300
 */
export const postApiV1DroDoCapture_mcexpOperation = (options: {
  requestBody: ShelfDroDoCaptureReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1DroDoCapture_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1DroDoCapture_mcexpOperation.backendServiceName,
  );
};

postApiV1DroDoCapture_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postApiV1DroDoCapture_mcexpOperation.key = "/api/v1/dro/doCapture";

/**
 *
 * 出资结果
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178210301
 */
export const postApiV1DroDoFunding_mcexpOperation = (options: {
  requestBody: ShelfDroDoFundingReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1DroDoFunding_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1DroDoFunding_mcexpOperation.backendServiceName,
  );
};

postApiV1DroDoFunding_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postApiV1DroDoFunding_mcexpOperation.key = "/api/v1/dro/doFunding";

/**
 *
 * 定时调度，审核通过到达交易日后上架
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-182753856
 */
export const postApiV1DroOnShelfSchedule_mcexpOperation = (
  options: {
    queryParams?: PostApiV1DroOnShelfScheduleQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postApiV1DroOnShelfSchedule_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1DroOnShelfSchedule_mcexpOperation.backendServiceName,
  );
};

postApiV1DroOnShelfSchedule_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1DroOnShelfSchedule_mcexpOperation.key = "/api/v1/dro/onShelf-schedule";

/**
 *
 * 【资产端调用，前端不调用】根据arm融资主体ID、arm挂牌主体ID查询签约主体信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177421803
 */
export const postApiV1GetContractChannelInfo_mcexpOperation = (options: {
  requestBody: ContractChannelInfoReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListContractChannelInfoRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1GetContractChannelInfo_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1GetContractChannelInfo_mcexpOperation.backendServiceName,
  );
};

postApiV1GetContractChannelInfo_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1GetContractChannelInfo_mcexpOperation.key =
  "/api/v1/getContractChannelInfo";

/**
 *
 * 出资结果回调
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422112
 */
export const postApiV1InvestCallback_mcexpOperation = (options: {
  requestBody: FundingInvestResultCallback;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1InvestCallback_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1InvestCallback_mcexpOperation.backendServiceName,
  );
};

postApiV1InvestCallback_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postApiV1InvestCallback_mcexpOperation.key = "/api/v1/invest/callback";

/**
 *
 * 定时调度出资条件检查
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422113
 */
export const postApiV1InvestScheduleRiskCheck_mcexpOperation = (
  options: {
    queryParams?: PostApiV1InvestScheduleRiskCheckQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postApiV1InvestScheduleRiskCheck_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1InvestScheduleRiskCheck_mcexpOperation.backendServiceName,
  );
};

postApiV1InvestScheduleRiskCheck_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1InvestScheduleRiskCheck_mcexpOperation.key =
  "/api/v1/invest/schedule/risk-check";

/**
 *
 * 定时调度-更新出资状态
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183270099
 */
export const postApiV1InvestScheduleUpdateInvestStatus_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postApiV1InvestScheduleUpdateInvestStatus_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1InvestScheduleUpdateInvestStatus_mcexpOperation.backendServiceName,
  );
};

postApiV1InvestScheduleUpdateInvestStatus_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1InvestScheduleUpdateInvestStatus_mcexpOperation.key =
  "/api/v1/invest/schedule/update-invest-status";

/**
 *
 * 创建新申请
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183944616
 */
export const postApiV1ShelfDroApplies_mcexpOperation = (options: {
  requestBody: DroAppReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApiV1ShelfDroApplies_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1ShelfDroApplies_mcexpOperation.backendServiceName,
  );
};

postApiV1ShelfDroApplies_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postApiV1ShelfDroApplies_mcexpOperation.key = "/api/v1/shelf/dro/applies";

/**
 *
 * 删除申请
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517162
 */
export const postApiV1ShelfDroAppliesAppIdDoDelete_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.postRequest(
    template(
      postApiV1ShelfDroAppliesAppIdDoDelete_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1ShelfDroAppliesAppIdDoDelete_mcexpOperation.backendServiceName,
  );
};

postApiV1ShelfDroAppliesAppIdDoDelete_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1ShelfDroAppliesAppIdDoDelete_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/doDelete";

/**
 *
 * 提交申请到编辑状态
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517161
 */
export const postApiV1ShelfDroAppliesAppIdDoEdit_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.postRequest(
    template(
      postApiV1ShelfDroAppliesAppIdDoEdit_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1ShelfDroAppliesAppIdDoEdit_mcexpOperation.backendServiceName,
  );
};

postApiV1ShelfDroAppliesAppIdDoEdit_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1ShelfDroAppliesAppIdDoEdit_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/doEdit";

/**
 *
 * 提交申请到审核
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517160
 */
export const postApiV1ShelfDroAppliesAppIdDoSubmit_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, headerParams } = options || {};

  return Http.postRequest(
    template(
      postApiV1ShelfDroAppliesAppIdDoSubmit_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1ShelfDroAppliesAppIdDoSubmit_mcexpOperation.backendServiceName,
  );
};

postApiV1ShelfDroAppliesAppIdDoSubmit_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postApiV1ShelfDroAppliesAppIdDoSubmit_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/doSubmit";

/**
 *
 * 上传文件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177422298
 */
export const postApiV1ShelfFilesUpload_mcexpOperation = (options: {
  requestBody: {
    /**
     *
     * 文件内容
     * - Format: binary
     */
    file?: string;
  };
  queryParams: PostApiV1ShelfFilesUploadQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultFileResp>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postApiV1ShelfFilesUpload_mcexpOperation.key,
    queryParams,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT2,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApiV1ShelfFilesUpload_mcexpOperation.backendServiceName,
  );
};

postApiV1ShelfFilesUpload_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postApiV1ShelfFilesUpload_mcexpOperation.key = "/api/v1/shelf/files/upload";

/**
 *
 * 审批回调接口
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243489069
 */
export const postApprove_mcexpOperation = (options: {
  requestBody: ApproveRecordReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResult>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postApprove_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postApprove_mcexpOperation.backendServiceName,
  );
};

postApprove_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postApprove_mcexpOperation.key = "/approve";

/**
 *
 * 查询支付明细
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-151075749
 */
export const postChannelPaymentManageQueryDetail_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postChannelPaymentManageQueryDetail_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postChannelPaymentManageQueryDetail_mciPayFinance.backendServiceName,
  );
};

postChannelPaymentManageQueryDetail_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postChannelPaymentManageQueryDetail_mciPayFinance.key =
  "/channel/payment/manage/query-detail";

/**
 *
 * 审核
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506981
 */
export const postConfigAudit_exKernelAdmin = (options: {
  requestBody: AuditReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVO>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigAudit_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigAudit_exKernelAdmin.backendServiceName,
  );
};

postConfigAudit_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postConfigAudit_exKernelAdmin.key = "/config/audit";

/**
 *
 * 批量新增
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506977
 */
export const postConfigBatchAdd_exKernelAdmin = (options: {
  requestBody: AddConfigReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVO>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigBatchAdd_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigBatchAdd_exKernelAdmin.backendServiceName,
  );
};

postConfigBatchAdd_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postConfigBatchAdd_exKernelAdmin.key = "/config/batchAdd";

/**
 *
 * 批量删除
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506978
 */
export const postConfigBatchDel_exKernelAdmin = (options: {
  requestBody: DelConfigReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVO>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigBatchDel_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigBatchDel_exKernelAdmin.backendServiceName,
  );
};

postConfigBatchDel_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postConfigBatchDel_exKernelAdmin.key = "/config/batchDel";

/**
 *
 * 编辑
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506979
 */
export const postConfigEditColumn_exKernelAdmin = (options: {
  requestBody: EditColumnReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVO>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigEditColumn_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigEditColumn_exKernelAdmin.backendServiceName,
  );
};

postConfigEditColumn_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postConfigEditColumn_exKernelAdmin.key = "/config/editColumn";

/**
 *
 * 查询可配置的表字段信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506975
 */
export const postConfigGetConfigTableColumns_exKernelAdmin = (options: {
  requestBody: ConfigInfoReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOListConfigColumn>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigGetConfigTableColumns_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigGetConfigTableColumns_exKernelAdmin.backendServiceName,
  );
};

postConfigGetConfigTableColumns_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postConfigGetConfigTableColumns_exKernelAdmin.key =
  "/config/getConfigTableColumns";

/**
 *
 * 查询可配置的表信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506974
 */
export const postConfigGetConfigTableInfo_exKernelAdmin = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOListConfigResp>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postConfigGetConfigTableInfo_exKernelAdmin.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigGetConfigTableInfo_exKernelAdmin.backendServiceName,
  );
};

postConfigGetConfigTableInfo_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postConfigGetConfigTableInfo_exKernelAdmin.key = "/config/getConfigTableInfo";

/**
 *
 * 查询可配置的表信息中的记录
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506976
 */
export const postConfigGetList_exKernelAdmin = (options: {
  requestBody: ConfigListReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOSimplePageMybatisImplMapT>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigGetList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigGetList_exKernelAdmin.backendServiceName,
  );
};

postConfigGetList_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postConfigGetList_exKernelAdmin.key = "/config/getList";

/**
 *
 * 查询复核表记录
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-186506980
 */
export const postConfigGetRecheckRecord_exKernelAdmin = (options: {
  requestBody: RecheckRecordReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultVOSimplePageMybatisImplRecheckRecordResp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postConfigGetRecheckRecord_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postConfigGetRecheckRecord_exKernelAdmin.backendServiceName,
  );
};

postConfigGetRecheckRecord_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postConfigGetRecheckRecord_exKernelAdmin.key = "/config/getRecheckRecord";

/**
 *
 * 根据编码查询签约主体/银行账户信息历史变更版本记录
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530909
 */
export const postContractGetHistoryRecord_mcexpOperation = (options: {
  requestBody: DataOperateLogReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplDataOperateLog>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postContractGetHistoryRecord_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postContractGetHistoryRecord_mcexpOperation.backendServiceName,
  );
};

postContractGetHistoryRecord_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postContractGetHistoryRecord_mcexpOperation.key = "/contract/getHistoryRecord";

/**
 *
 * 查询签约主体列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530903
 */
export const postContractList_mcexpOperation = (options: {
  requestBody: ContractEntityReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplContractEntity>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postContractList_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postContractList_mcexpOperation.backendServiceName,
  );
};

postContractList_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postContractList_mcexpOperation.key = "/contract/list";

/**
 *
 * 新增签约主体以及银行账户信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530904
 */
export const postContractSave_mcexpOperation = (options: {
  requestBody: ContractEntityInfoSaveReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultContractEntity>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postContractSave_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postContractSave_mcexpOperation.backendServiceName,
  );
};

postContractSave_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postContractSave_mcexpOperation.key = "/contract/save";

/**
 *
 * 发起协议签署 接口
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-213931693
 */
export const postContractSignatureV1InitiateSigning_mcexpOperation = (options: {
  requestBody: ContractSigningReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListSignatoryResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postContractSignatureV1InitiateSigning_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postContractSignatureV1InitiateSigning_mcexpOperation.backendServiceName,
  );
};

postContractSignatureV1InitiateSigning_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postContractSignatureV1InitiateSigning_mcexpOperation.key =
  "/contract/signature/v1/initiate/signing";

/**
 *
 * 更新签约主体以及银行账户信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530905
 */
export const postContractUpdate_mcexpOperation = (options: {
  requestBody: ContractEntityInfoUpdateReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postContractUpdate_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postContractUpdate_mcexpOperation.backendServiceName,
  );
};

postContractUpdate_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postContractUpdate_mcexpOperation.key = "/contract/update";

/**
 *
 * 签约主体关联渠道关系
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177530908
 */
export const postContractUpdateContractChannelRelation_mcexpOperation =
  (options: {
    requestBody: ContractChannelRelationReq[];
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultBoolean>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postContractUpdateContractChannelRelation_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postContractUpdateContractChannelRelation_mcexpOperation.backendServiceName,
    );
  };

postContractUpdateContractChannelRelation_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postContractUpdateContractChannelRelation_mcexpOperation.key =
  "/contract/updateContractChannelRelation";

/**
 *
 * 申请合同生成 （未启用）
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-213931691
 */
export const postContractV1GenerateIoApplicationContract_mcexpOperation =
  (options: {
    requestBody: IoApplicationContractGenerateReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultContractGenerateResp>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postContractV1GenerateIoApplicationContract_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postContractV1GenerateIoApplicationContract_mcexpOperation.backendServiceName,
    );
  };

postContractV1GenerateIoApplicationContract_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postContractV1GenerateIoApplicationContract_mcexpOperation.key =
  "/contract/v1/generateIoApplicationContract";

/**
 *
 * IO 申请合同生成
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-213931692
 */
export const postContractV1GenerateJointOperationContract_mcexpOperation =
  (options: {
    requestBody: RbcInfo;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultContractGenerateResp>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postContractV1GenerateJointOperationContract_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postContractV1GenerateJointOperationContract_mcexpOperation.backendServiceName,
    );
  };

postContractV1GenerateJointOperationContract_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postContractV1GenerateJointOperationContract_mcexpOperation.key =
  "/contract/v1/generateJointOperationContract";

/**
 *
 * 分成方案协议生成
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-217470701
 */
export const postContractV1GenerateRevenueProtocol_mcexpOperation = (options: {
  requestBody: RevenueProtocolReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultContractGenerateResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postContractV1GenerateRevenueProtocol_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postContractV1GenerateRevenueProtocol_mcexpOperation.backendServiceName,
  );
};

postContractV1GenerateRevenueProtocol_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postContractV1GenerateRevenueProtocol_mcexpOperation.key =
  "/contract/v1/generateRevenueProtocol";

/**
 *
 * 手工换汇新增接口
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-241717110
 */
export const postCurrencyExchangeAddManualCurrencyExchange_exKernelAdmin =
  (options: {
    requestBody: ManualFxReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postCurrencyExchangeAddManualCurrencyExchange_exKernelAdmin.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postCurrencyExchangeAddManualCurrencyExchange_exKernelAdmin.backendServiceName,
    );
  };

postCurrencyExchangeAddManualCurrencyExchange_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postCurrencyExchangeAddManualCurrencyExchange_exKernelAdmin.key =
  "/currencyExchange/addManualCurrencyExchange";

/**
 *
 * 换汇列表查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-230917022
 */
export const postCurrencyExchangeList_exKernelAdmin = (options: {
  requestBody: CurrencyFxQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplCurrencyExchangeJournalResp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postCurrencyExchangeList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postCurrencyExchangeList_exKernelAdmin.backendServiceName,
  );
};

postCurrencyExchangeList_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postCurrencyExchangeList_exKernelAdmin.key = "/currencyExchange/list";

/**
 *
 * 换汇更新
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-241717109
 */
export const postCurrencyExchangeUpdate_exKernelAdmin = (options: {
  requestBody: CurrencyExchangeJournalReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultCurrencyExchangeDealResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postCurrencyExchangeUpdate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postCurrencyExchangeUpdate_exKernelAdmin.backendServiceName,
  );
};

postCurrencyExchangeUpdate_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postCurrencyExchangeUpdate_exKernelAdmin.key = "/currencyExchange/update";

/**
 *
 * 风控决策-测试
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-179265582
 */
export const postDecisionDecision1793913430569328642Execute_mcexpOperation =
  (options: {
    requestBody: { dro_code: string };
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<
    SwaggerResponse<{
      code: number;
      data: {
        createTime: number;
        creator: any;
        decisionRet: number;
        errorMsg: any;
        execId: string;
        execMode: number;
        execParam: { _cur_time: string; _exec_id: string; dro_code: string };
        execResult: {
          check_code: string;
          check_msg: string;
          check_result: string;
        }[];
        execStatus: string;
        flowId: string;
        flowName: string;
        id: string;
        spendTime: number;
        testMode: number;
        updater: any;
      };
      msg: string;
    }>
  > => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postDecisionDecision1793913430569328642Execute_mcexpOperation.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postDecisionDecision1793913430569328642Execute_mcexpOperation.backendServiceName,
    );
  };

postDecisionDecision1793913430569328642Execute_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postDecisionDecision1793913430569328642Execute_mcexpOperation.key =
  "/decision/decision/1793913430569328642/execute";

/**
 *
 * 入金信息列表导出
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-226548826
 */
export const postDepositInfoExportDepositInfo_mciArm = (options: {
  requestBody: VADepositInfoQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDepositInfoExportDepositInfo_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDepositInfoExportDepositInfo_mciArm.backendServiceName,
  );
};

postDepositInfoExportDepositInfo_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postDepositInfoExportDepositInfo_mciArm.key = "/depositInfo/exportDepositInfo";

/**
 *
 * VA入金信息列表查询
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-222150620
 */
export const postDepositInfoQueryDepositInfoList_mciArm = (options: {
  requestBody: VADepositInfoQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplVADepositInfoQueryResp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDepositInfoQueryDepositInfoList_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDepositInfoQueryDepositInfoList_mciArm.backendServiceName,
  );
};

postDepositInfoQueryDepositInfoList_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postDepositInfoQueryDepositInfoList_mciArm.key =
  "/depositInfo/queryDepositInfoList";

/**
 *
 * VA账户入金人工匹配
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-222150621
 */
export const postDepositInfoReviewDepositInfo_mciArm = (options: {
  requestBody: VADepositInfoReviewReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDepositInfoReviewDepositInfo_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDepositInfoReviewDepositInfo_mciArm.backendServiceName,
  );
};

postDepositInfoReviewDepositInfo_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postDepositInfoReviewDepositInfo_mciArm.key = "/depositInfo/reviewDepositInfo";

/**
 *
 * saveAndParseHsbcDepositInfo
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-225353322
 */
export const postDepositInfoSaveAndParseHsbcDepositInfo_mciArm = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postDepositInfoSaveAndParseHsbcDepositInfo_mciArm.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDepositInfoSaveAndParseHsbcDepositInfo_mciArm.backendServiceName,
  );
};

postDepositInfoSaveAndParseHsbcDepositInfo_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postDepositInfoSaveAndParseHsbcDepositInfo_mciArm.key =
  "/depositInfo/saveAndParseHsbcDepositInfo";

/**
 *
 * 下架DRO
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188078340
 */
export const postDroShelveOffShelve_exKernelAdmin = (
  options: {
    queryParams?: PostDroShelveOffShelveQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultVOVoid>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postDroShelveOffShelve_exKernelAdmin.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDroShelveOffShelve_exKernelAdmin.backendServiceName,
  );
};

postDroShelveOffShelve_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postDroShelveOffShelve_exKernelAdmin.key = "/dro/shelve/offShelve";

/**
 *
 * DRO 已上架导出
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188078339
 */
export const postDroShelveOnExport_exKernelAdmin = (options: {
  requestBody: DroListPo;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDroShelveOnExport_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDroShelveOnExport_exKernelAdmin.backendServiceName,
  );
};

postDroShelveOnExport_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postDroShelveOnExport_exKernelAdmin.key = "/dro/shelve/on/export";

/**
 *
 * DRO 已上架列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188078338
 */
export const postDroShelveOnList_exKernelAdmin = (options: {
  requestBody: DroListPo;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOSimplePageMybatisImplDroListVo>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDroShelveOnList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDroShelveOnList_exKernelAdmin.backendServiceName,
  );
};

postDroShelveOnList_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postDroShelveOnList_exKernelAdmin.key = "/dro/shelve/on/list";

/**
 *
 * DRO 待上架导出
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188078337
 */
export const postDroShelvePendingExport_exKernelAdmin = (options: {
  requestBody: DroPendingListPo;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDroShelvePendingExport_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDroShelvePendingExport_exKernelAdmin.backendServiceName,
  );
};

postDroShelvePendingExport_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postDroShelvePendingExport_exKernelAdmin.key = "/dro/shelve/pending/export";

/**
 *
 * DRO 待上架列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-188078336
 */
export const postDroShelvePendingList_exKernelAdmin = (options: {
  requestBody: DroPendingListPo;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOSimplePageMybatisImplDroPendingListVo>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postDroShelvePendingList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postDroShelvePendingList_exKernelAdmin.backendServiceName,
  );
};

postDroShelvePendingList_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postDroShelvePendingList_exKernelAdmin.key = "/dro/shelve/pending/list";

/**
 *
 * upload
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238363900
 */
export const postFileUpload_mciArm = (options: {
  requestBody: {
    /**
     *
     * - Format: binary
     */
    file: string;
  };
  queryParams: PostFileUploadQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultFileDTO>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postFileUpload_mciArm.key,
    queryParams,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT2,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFileUpload_mciArm.backendServiceName,
  );
};

postFileUpload_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFileUpload_mciArm.key = "/file/upload";

/**
 *
 * 删除文件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605941
 */
export const postFilesDelete_exKernelAdmin = (options: {
  requestBody: DeleteFileReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFilesDelete_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFilesDelete_exKernelAdmin.backendServiceName,
  );
};

postFilesDelete_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postFilesDelete_exKernelAdmin.key = "/files/delete";

/**
 *
 * 上传文件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605939
 */
export const postFilesUpload_exKernelAdmin = (options: {
  requestBody: {
    /**
 * 
 * //     * @param storePath 存储文件的路径
             //     * @param operator  操作人
             //     * @param platform  使用的对象存储服务，0-OSS，1-S3，不传则根据部署环境选择默认值
             //     * @param bizType   业务类型
 * - Format: binary
 */
    file: string;
  };
  queryParams?: PostFilesUploadQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOCommonFileDTO>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postFilesUpload_exKernelAdmin.key,
    queryParams,
    objToForm(requestBody),
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT2,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFilesUpload_exKernelAdmin.backendServiceName,
  );
};

postFilesUpload_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postFilesUpload_exKernelAdmin.key = "/files/upload";

/**
 *
 * 新增流水
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-211471361
 */
export const postFlowAdd_mciArm = (options: {
  requestBody: FlowEnterDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowAdd_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowAdd_mciArm.backendServiceName,
  );
};

postFlowAdd_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowAdd_mciArm.key = "/flow/add";

/**
 *
 * 审批
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-211471364
 */
export const postFlowApprove_mciArm = (options: {
  requestBody: ApproveDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowApprove_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowApprove_mciArm.backendServiceName,
  );
};

postFlowApprove_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowApprove_mciArm.key = "/flow/approve";

/**
 *
 * 导出
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-211594740
 */
export const postFlowExport_mciArm = (options: {
  requestBody: FlowQueryDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowExport_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowExport_mciArm.backendServiceName,
  );
};

postFlowExport_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowExport_mciArm.key = "/flow/export";

/**
 *
 * 流水列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-211594739
 */
export const postFlowList_mciArm = (options: {
  requestBody: FlowInfoQueryDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplFlowDetailDto>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowList_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowList_mciArm.backendServiceName,
  );
};

postFlowList_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowList_mciArm.key = "/flow/list";

/**
 *
 * approve
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234214603
 */
export const postFlowPushAcf_mciArm = (
  options: {
    queryParams?: PostFlowPushAcfQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultInteger>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postFlowPushAcf_mciArm.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowPushAcf_mciArm.backendServiceName,
  );
};

postFlowPushAcf_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowPushAcf_mciArm.key = "/flow/pushAcf";

/**
 *
 * SPV新增流水
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234214604
 */
export const postFlowSpvAdd_mciArm = (options: {
  requestBody: SpvFlowEnterDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowSpvAdd_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowSpvAdd_mciArm.backendServiceName,
  );
};

postFlowSpvAdd_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowSpvAdd_mciArm.key = "/flow/spvAdd";

/**
 *
 * SPV查询列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234214605
 */
export const postFlowSpvList_mciArm = (options: {
  requestBody: SpvFlowInfoQueryDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplFlowDetailDto>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowSpvList_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowSpvList_mciArm.backendServiceName,
  );
};

postFlowSpvList_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowSpvList_mciArm.key = "/flow/spvList";

/**
 *
 * 更新流水
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234245999
 */
export const postFlowUpdateFlow_mciArm = (options: {
  requestBody: FlowUpdateDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFlowUpdateFlow_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFlowUpdateFlow_mciArm.backendServiceName,
  );
};

postFlowUpdateFlow_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postFlowUpdateFlow_mciArm.key = "/flow/updateFlow";

/**
 *
 * 资金调拨单导出
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208523
 */
export const postFundFundTranferExportFundTransferBill_exKernelAdmin =
  (options: {
    requestBody: FundTransferBillQueryReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postFundFundTranferExportFundTransferBill_exKernelAdmin.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postFundFundTranferExportFundTransferBill_exKernelAdmin.backendServiceName,
    );
  };

postFundFundTranferExportFundTransferBill_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postFundFundTranferExportFundTransferBill_exKernelAdmin.key =
  "/fund/fundTranfer/exportFundTransferBill";

/**
 *
 * 资金调拨单列表查询
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-241728522
 */
export const postFundFundTranferFundTransferBillList_exKernelAdmin = (options: {
  requestBody: FundTransferBillQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOSimplePageMybatisImplFundTransferBill>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFundFundTranferFundTransferBillList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFundFundTranferFundTransferBillList_exKernelAdmin.backendServiceName,
  );
};

postFundFundTranferFundTransferBillList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postFundFundTranferFundTransferBillList_exKernelAdmin.key =
  "/fund/fundTranfer/fundTransferBillList";

/**
 *
 * 资金调拨单新增
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208521
 */
export const postFundFundTranferSave_exKernelAdmin = (options: {
  requestBody: FundTransferBillSaveReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFundFundTranferSave_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFundFundTranferSave_exKernelAdmin.backendServiceName,
  );
};

postFundFundTranferSave_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postFundFundTranferSave_exKernelAdmin.key = "/fund/fundTranfer/save";

/**
 *
 * 资金调拨单更新
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208522
 */
export const postFundFundTranferUpdate_exKernelAdmin = (options: {
  requestBody: FundTransferBillUpdateReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postFundFundTranferUpdate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postFundFundTranferUpdate_exKernelAdmin.backendServiceName,
  );
};

postFundFundTranferUpdate_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postFundFundTranferUpdate_exKernelAdmin.key = "/fund/fundTranfer/update";

/**
 *
 * 查询入金审核列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208535
 */
export const postHsbcDepositDepositReviewList_exKernelAdmin = (options: {
  requestBody: DepositReviewInfoRsp;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultVOSimplePageMybatisImplDepositReviewInfo>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postHsbcDepositDepositReviewList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postHsbcDepositDepositReviewList_exKernelAdmin.backendServiceName,
  );
};

postHsbcDepositDepositReviewList_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postHsbcDepositDepositReviewList_exKernelAdmin.key =
  "/hsbc/deposit/depositReviewList";

/**
 *
 * 导出-出入金流水
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208537
 */
export const postHsbcDepositExportDepositFlow_exKernelAdmin = (options: {
  requestBody: DepositReviewInfoRsp;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postHsbcDepositExportDepositFlow_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postHsbcDepositExportDepositFlow_exKernelAdmin.backendServiceName,
  );
};

postHsbcDepositExportDepositFlow_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postHsbcDepositExportDepositFlow_exKernelAdmin.key =
  "/hsbc/deposit/exportDepositFlow";

/**
 *
 * 导入入金流水
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208536
 */
export const postHsbcDepositImportDepositFlow_exKernelAdmin = (options: {
  requestBody: DepositReviewReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postHsbcDepositImportDepositFlow_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postHsbcDepositImportDepositFlow_exKernelAdmin.backendServiceName,
  );
};

postHsbcDepositImportDepositFlow_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postHsbcDepositImportDepositFlow_exKernelAdmin.key =
  "/hsbc/deposit/importDepositFlow";

/**
 *
 * sendDepositMail
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208540
 */
export const postHsbcDepositSendDepositMail_exKernelAdmin = (options: {
  requestBody: DepositReviewInfo;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postHsbcDepositSendDepositMail_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postHsbcDepositSendDepositMail_exKernelAdmin.backendServiceName,
  );
};

postHsbcDepositSendDepositMail_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postHsbcDepositSendDepositMail_exKernelAdmin.key =
  "/hsbc/deposit/sendDepositMail";

/**
 *
 * 通过ID更新入金审核状态
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208534
 */
export const postHsbcDepositUpdateById_exKernelAdmin = (options: {
  requestBody: HsbcDepositInfoUpdateRsp;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postHsbcDepositUpdateById_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postHsbcDepositUpdateById_exKernelAdmin.backendServiceName,
  );
};

postHsbcDepositUpdateById_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postHsbcDepositUpdateById_exKernelAdmin.key = "/hsbc/deposit/updateById";

/**
 *
 * 更新入金账户
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-238208538
 */
export const postHsbcDepositUpdateTradingAccount_exKernelAdmin = (options: {
  requestBody: UpdateTradingAccountReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOString>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postHsbcDepositUpdateTradingAccount_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postHsbcDepositUpdateTradingAccount_exKernelAdmin.backendServiceName,
  );
};

postHsbcDepositUpdateTradingAccount_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postHsbcDepositUpdateTradingAccount_exKernelAdmin.key =
  "/hsbc/deposit/updateTradingAccount";

/**
 *
 * 新增附件到邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605952
 */
export const postMailContentAddAttachment_exKernelAdmin = (options: {
  requestBody: DeleteAttachmentDTO;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentAddAttachment_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentAddAttachment_exKernelAdmin.backendServiceName,
  );
};

postMailContentAddAttachment_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentAddAttachment_exKernelAdmin.key = "/mail/content/add/attachment";

/**
 *
 * 删除邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605950
 */
export const postMailContentDelete_exKernelAdmin = (options: {
  requestBody: DeleteMailReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentDelete_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentDelete_exKernelAdmin.backendServiceName,
  );
};

postMailContentDelete_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentDelete_exKernelAdmin.key = "/mail/content/delete";

/**
 *
 * 删除邮件的附件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605951
 */
export const postMailContentDeleteAttachment_exKernelAdmin = (options: {
  requestBody: DeleteAttachmentDTO;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentDeleteAttachment_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentDeleteAttachment_exKernelAdmin.backendServiceName,
  );
};

postMailContentDeleteAttachment_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentDeleteAttachment_exKernelAdmin.key =
  "/mail/content/delete/attachment";

/**
 *
 * 查询邮件内容列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605948
 */
export const postMailContentList_exKernelAdmin = (options: {
  requestBody: MailContentListReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultVOSimplePageMybatisImplMailContentListDTO>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentList_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentList_exKernelAdmin.backendServiceName,
  );
};

postMailContentList_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentList_exKernelAdmin.key = "/mail/content/list";

/**
 *
 * 批量发送邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605946
 */
export const postMailContentSendBatch_exKernelAdmin = (options: {
  requestBody: SendBatchReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentSendBatch_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentSendBatch_exKernelAdmin.backendServiceName,
  );
};

postMailContentSendBatch_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentSendBatch_exKernelAdmin.key = "/mail/content/send/batch";

/**
 *
 * 发送普通邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605943
 */
export const postMailContentSendSimple_exKernelAdmin = (options: {
  requestBody: MailSendReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentSendSimple_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentSendSimple_exKernelAdmin.backendServiceName,
  );
};

postMailContentSendSimple_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentSendSimple_exKernelAdmin.key = "/mail/content/send-simple";

/**
 *
 * 发送模板邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605944
 */
export const postMailContentSendTemplate_exKernelAdmin = (options: {
  requestBody: TemplateMailSendReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentSendTemplate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentSendTemplate_exKernelAdmin.backendServiceName,
  );
};

postMailContentSendTemplate_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentSendTemplate_exKernelAdmin.key = "/mail/content/send-template";

/**
 *
 * 修改邮件内容,审批通过，并发送邮件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605949
 */
export const postMailContentUpdate_exKernelAdmin = (options: {
  requestBody: MailContentDetailDTO;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMailContentUpdate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMailContentUpdate_exKernelAdmin.backendServiceName,
  );
};

postMailContentUpdate_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postMailContentUpdate_exKernelAdmin.key = "/mail/content/update";

/**
 *
 * 分账和提现
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-235711873
 */
export const postMciArmSpacSpvDoDivideWithdraw_mciArm = (options: {
  requestBody: { [x in string | number]: any };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMciArmSpacSpvDoDivideWithdraw_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMciArmSpacSpvDoDivideWithdraw_mciArm.backendServiceName,
  );
};

postMciArmSpacSpvDoDivideWithdraw_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postMciArmSpacSpvDoDivideWithdraw_mciArm.key =
  "/mci-arm/spac/spv/do-divide-withdraw";

/**
 *
 * 会员账户关系更新
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-206895404
 */
export const postMemberAccountRelationSaveOrUpdate_exKernelAdmin = (options: {
  requestBody: MemberAccountRelationReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMemberAccountRelationSaveOrUpdate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMemberAccountRelationSaveOrUpdate_exKernelAdmin.backendServiceName,
  );
};

postMemberAccountRelationSaveOrUpdate_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postMemberAccountRelationSaveOrUpdate_exKernelAdmin.key =
  "/member/accountRelation/saveOrUpdate";

/**
 *
 * 会员授权人更新
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-206895489
 */
export const postMemberAuthorisedPeopleSaveOrUpdate_exKernelAdmin = (options: {
  requestBody: MemberAuthorisedPeopleReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postMemberAuthorisedPeopleSaveOrUpdate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postMemberAuthorisedPeopleSaveOrUpdate_exKernelAdmin.backendServiceName,
  );
};

postMemberAuthorisedPeopleSaveOrUpdate_exKernelAdmin.backendServiceName =
  "ex-kernel-admin";
/** Key is end point string without base url */
postMemberAuthorisedPeopleSaveOrUpdate_exKernelAdmin.key =
  "/member/authorisedPeople/saveOrUpdate";

/**
 *
 * 合同签署异步通知
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-213931689
 */
export const postNouserContractV3SignFlowNotify_mcexpOperation = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postNouserContractV3SignFlowNotify_mcexpOperation.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postNouserContractV3SignFlowNotify_mcexpOperation.backendServiceName,
  );
};

postNouserContractV3SignFlowNotify_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postNouserContractV3SignFlowNotify_mcexpOperation.key =
  "/nouser/contract/v3/sign-flow-notify";

/**
 *
 * 获取文件访问地址(支持图片加工)
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-161691200
 */
export const postNouserOssBucketAccessUrlV2_mciToolbox = (options: {
  requestBody: FilePathInput;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultURL>> => {
  const { configOverride, __mock__, requestBody } = options || {};

  return Http.postRequest(
    postNouserOssBucketAccessUrlV2_mciToolbox.key,
    undefined,
    requestBody,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    postNouserOssBucketAccessUrlV2_mciToolbox.backendServiceName,
  );
};

postNouserOssBucketAccessUrlV2_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
postNouserOssBucketAccessUrlV2_mciToolbox.key =
  "/nouser/oss/bucket/access/url/v2";

/**
 *
 * 查询门店基础信息
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-189449033
 */
export const postOpEntEntInfo12765_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postOpEntEntInfo12765_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postOpEntEntInfo12765_mciPayFinance.backendServiceName,
  );
};

postOpEntEntInfo12765_mciPayFinance.backendServiceName = "mci-pay-finance";
/** Key is end point string without base url */
postOpEntEntInfo12765_mciPayFinance.key = "/op/ent/entInfo/12765";

/**
 *
 * 生成文件访问链接(支持图片加工)
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396526
 */
export const postOssV2BucketAccessUrl_mciToolbox = (options: {
  requestBody: FilePathInput;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultURL>> => {
  const { configOverride, __mock__, requestBody } = options || {};

  return Http.postRequest(
    postOssV2BucketAccessUrl_mciToolbox.key,
    undefined,
    requestBody,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    postOssV2BucketAccessUrl_mciToolbox.backendServiceName,
  );
};

postOssV2BucketAccessUrl_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
postOssV2BucketAccessUrl_mciToolbox.key = "/oss/v2/bucket/access/url";

/**
 *
 * 删除文件
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396529
 */
export const postOssV2FileDelete_mciToolbox = (options: {
  queryParams: PostOssV2FileDeleteQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.postRequest(
    postOssV2FileDelete_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    postOssV2FileDelete_mciToolbox.backendServiceName,
  );
};

postOssV2FileDelete_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
postOssV2FileDelete_mciToolbox.key = "/oss/v2/file/delete/";

/**
 *
 * 图片内容检测
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396527
 */
export const postOssV2ImageModeration_mciToolbox = (options: {
  queryParams: PostOssV2ImageModerationQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultString>> => {
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.postRequest(
    postOssV2ImageModeration_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    postOssV2ImageModeration_mciToolbox.backendServiceName,
  );
};

postOssV2ImageModeration_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
postOssV2ImageModeration_mciToolbox.key = "/oss/v2/image/moderation/";

/**
 *
 * 文本内容检测
 * 文档地址：https://app.apifox.com/web/project/4234057/apis/api-166396528
 */
export const postOssV2TextModeration_mciToolbox = (options: {
  queryParams: PostOssV2TextModerationQueryParams;
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultString>> => {
  const { configOverride, __mock__, queryParams } = options || {};

  return Http.postRequest(
    postOssV2TextModeration_mciToolbox.key,
    queryParams,
    undefined,
    _CONSTANT4,
    overrideConfig(_CONSTANT3, { ...configOverride, __mock__ }),
    postOssV2TextModeration_mciToolbox.backendServiceName,
  );
};

postOssV2TextModeration_mciToolbox.backendServiceName = "mci-toolbox";
/** Key is end point string without base url */
postOssV2TextModeration_mciToolbox.key = "/oss/v2/text/moderation/";

/**
 *
 * 渠道支付任务
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-151176766
 */
export const postPayFinanceChannelPaymentTaskDoPay_mciPayFinance = (options: {
  requestBody: { [x in string | number]: any };
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postPayFinanceChannelPaymentTaskDoPay_mciPayFinance.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postPayFinanceChannelPaymentTaskDoPay_mciPayFinance.backendServiceName,
  );
};

postPayFinanceChannelPaymentTaskDoPay_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postPayFinanceChannelPaymentTaskDoPay_mciPayFinance.key =
  "/pay-finance/channel/payment/task/do-pay";

/**
 *
 * 推送扣款单给对账平台
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-189732170
 */
export const postPayJobsFinancePushByTime_mciPayFinance = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postPayJobsFinancePushByTime_mciPayFinance.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postPayJobsFinancePushByTime_mciPayFinance.backendServiceName,
  );
};

postPayJobsFinancePushByTime_mciPayFinance.backendServiceName =
  "mci-pay-finance";
/** Key is end point string without base url */
postPayJobsFinancePushByTime_mciPayFinance.key = "/pay/jobs/finance/pushByTime";

/**
 *
 * 生成pdf
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183605938
 */
export const postPdfCreate_exKernelAdmin = (options: {
  requestBody: PdfCreateReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVOPdfCreateRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postPdfCreate_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postPdfCreate_exKernelAdmin.backendServiceName,
  );
};

postPdfCreate_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postPdfCreate_exKernelAdmin.key = "/pdf/create";

/**
 *
 * e签宝联营协议签署完成回调
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-211667785
 */
export const postRbcEsignSigningCompletionCallback_mcexpOperation = (
  options: {
    queryParams?: PostRbcEsignSigningCompletionCallbackQueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postRbcEsignSigningCompletionCallback_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRbcEsignSigningCompletionCallback_mcexpOperation.backendServiceName,
  );
};

postRbcEsignSigningCompletionCallback_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postRbcEsignSigningCompletionCallback_mcexpOperation.key =
  "/rbc/esign/signingCompletionCallback";

/**
 *
 * 推送到交易所
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-211667784
 */
export const postRbcPushToExchangeV1_mcexpOperation = (
  options: {
    queryParams?: PostRbcPushToExchangeV1QueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultString>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postRbcPushToExchangeV1_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRbcPushToExchangeV1_mcexpOperation.backendServiceName,
  );
};

postRbcPushToExchangeV1_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postRbcPushToExchangeV1_mcexpOperation.key = "/rbc/pushToExchange/v1";

/**
 *
 * 展示rbc数据结构
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-211261981
 */
export const postRbcQuery_mcexpOperation = (options: {
  requestBody: RbcInfoEntity;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRbcQuery_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRbcQuery_mcexpOperation.backendServiceName,
  );
};

postRbcQuery_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postRbcQuery_mcexpOperation.key = "/rbc/query";

/**
 *
 * 导出历史收入
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-212149267
 */
export const postRboIncomeInfoExport_mciArm = (options: {
  requestBody: RboOriginalIUploadQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRboIncomeInfoExport_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRboIncomeInfoExport_mciArm.backendServiceName,
  );
};

postRboIncomeInfoExport_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRboIncomeInfoExport_mciArm.key = "/rboIncomeInfo/export";

/**
 *
 * manualUploadRboIncomeInfo
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-211707178
 */
export const postRboIncomeInfoManualUploadRboIncomeInfo_mciArm = (options: {
  requestBody: { [x in string | number]: any };
  queryParams: PostRboIncomeInfoManualUploadRboIncomeInfoQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postRboIncomeInfoManualUploadRboIncomeInfo_mciArm.key,
    queryParams,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRboIncomeInfoManualUploadRboIncomeInfo_mciArm.backendServiceName,
  );
};

postRboIncomeInfoManualUploadRboIncomeInfo_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postRboIncomeInfoManualUploadRboIncomeInfo_mciArm.key =
  "/rboIncomeInfo/manualUploadRboIncomeInfo";

/**
 *
 * queryRboIncomeInfoList
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-218883095
 */
export const postRboIncomeInfoQueryRboIncomeInfoList_mciArm = (options: {
  requestBody: QueryRboIncomeListReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplSaasIncomeInfo>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRboIncomeInfoQueryRboIncomeInfoList_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRboIncomeInfoQueryRboIncomeInfoList_mciArm.backendServiceName,
  );
};

postRboIncomeInfoQueryRboIncomeInfoList_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRboIncomeInfoQueryRboIncomeInfoList_mciArm.key =
  "/rboIncomeInfo/queryRboIncomeInfoList";

/**
 *
 * uploadRboIncomeInfo
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-210849700
 */
export const postRboIncomeInfoUploadRboIncomeInfo_mciArm = (options: {
  requestBody: UploadRboIncomeReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRboIncomeInfoUploadRboIncomeInfo_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRboIncomeInfoUploadRboIncomeInfo_mciArm.backendServiceName,
  );
};

postRboIncomeInfoUploadRboIncomeInfo_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRboIncomeInfoUploadRboIncomeInfo_mciArm.key =
  "/rboIncomeInfo/uploadRboIncomeInfo";

/**
 *
 * 下载模板
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234232439
 */
export const postRboShareIncomeDownShareIncomeTemplate_mciArm = (options: {
  requestBody: RboDownTemplateReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRboShareIncomeDownShareIncomeTemplate_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRboShareIncomeDownShareIncomeTemplate_mciArm.backendServiceName,
  );
};

postRboShareIncomeDownShareIncomeTemplate_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRboShareIncomeDownShareIncomeTemplate_mciArm.key =
  "/rbo/share/income/downShareIncomeTemplate";

/**
 *
 * 查询营业日期内SPV上报的收入数据
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234686020
 */
export const postRboShareIncomeGetTotalAmountByRboAndBusinessDate_mciArm =
  (options: {
    requestBody: RboTotalAmountBetweenBusinessDateQueryReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultBigDecimal>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postRboShareIncomeGetTotalAmountByRboAndBusinessDate_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postRboShareIncomeGetTotalAmountByRboAndBusinessDate_mciArm.backendServiceName,
    );
  };

postRboShareIncomeGetTotalAmountByRboAndBusinessDate_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postRboShareIncomeGetTotalAmountByRboAndBusinessDate_mciArm.key =
  "/rbo/share/income/getTotalAmountByRboAndBusinessDate";

/**
 *
 * 手工上报RBO分成收入信息
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234232438
 */
export const postRboShareIncomeManualUploadRboShareIncomeInfo_mciArm =
  (options: {
    requestBody: RboShareIncmeUploadReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultRboShareIncomeCheckResp>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postRboShareIncomeManualUploadRboShareIncomeInfo_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postRboShareIncomeManualUploadRboShareIncomeInfo_mciArm.backendServiceName,
    );
  };

postRboShareIncomeManualUploadRboShareIncomeInfo_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postRboShareIncomeManualUploadRboShareIncomeInfo_mciArm.key =
  "/rbo/share/income/manualUploadRboShareIncomeInfo";

/**
 *
 * 查询营业日期内是否已存在收入数据
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234232437
 */
export const postRboShareIncomeQueryRboShareIncomeByBusinessDate_mciArm =
  (options: {
    requestBody: RboShareIncmeQueryReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultBoolean>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postRboShareIncomeQueryRboShareIncomeByBusinessDate_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postRboShareIncomeQueryRboShareIncomeByBusinessDate_mciArm.backendServiceName,
    );
  };

postRboShareIncomeQueryRboShareIncomeByBusinessDate_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postRboShareIncomeQueryRboShareIncomeByBusinessDate_mciArm.key =
  "/rbo/share/income/queryRboShareIncomeByBusinessDate";

/**
 *
 * 分成收入上报列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-234686019
 */
export const postRboShareIncomeQueryRboShareIncomeList_mciArm = (options: {
  requestBody: RboShareIncmeListQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplRboShareIncomeListResp>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRboShareIncomeQueryRboShareIncomeList_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRboShareIncomeQueryRboShareIncomeList_mciArm.backendServiceName,
  );
};

postRboShareIncomeQueryRboShareIncomeList_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRboShareIncomeQueryRboShareIncomeList_mciArm.key =
  "/rbo/share/income/queryRboShareIncomeList";

/**
 *
 * 给spv绑定虚拟账号
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-225353705
 */
export const postRelationshipBinding_mciArm = (options: {
  requestBody: VaSpvBindingDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultVaSpvRelationship>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRelationshipBinding_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRelationshipBinding_mciArm.backendServiceName,
  );
};

postRelationshipBinding_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRelationshipBinding_mciArm.key = "/relationship/binding";

/**
 *
 * 查询VA的主账户列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-225353707
 */
export const postRelationshipQueryBankMasterAccount_mciArm = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultListString>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postRelationshipQueryBankMasterAccount_mciArm.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRelationshipQueryBankMasterAccount_mciArm.backendServiceName,
  );
};

postRelationshipQueryBankMasterAccount_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRelationshipQueryBankMasterAccount_mciArm.key =
  "/relationship/queryBankMasterAccount";

/**
 *
 * 查询账号信息
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-225353706
 */
export const postRelationshipQueryVaInfo_mciArm = (options: {
  requestBody: VaSpvQueryDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListVaSpvRelationshipInfoDto>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRelationshipQueryVaInfo_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRelationshipQueryVaInfo_mciArm.backendServiceName,
  );
};

postRelationshipQueryVaInfo_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postRelationshipQueryVaInfo_mciArm.key = "/relationship/query/va/info";

/**
 *
 * 根据系统编码获取角色列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201643479
 */
export const postRoleV1GetRoleInfoList_mciUserAuthAdminServer = (options: {
  requestBody: SystemRoleReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplSystemRoleInfoResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRoleV1GetRoleInfoList_mciUserAuthAdminServer.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRoleV1GetRoleInfoList_mciUserAuthAdminServer.backendServiceName,
  );
};

postRoleV1GetRoleInfoList_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
postRoleV1GetRoleInfoList_mciUserAuthAdminServer.key =
  "/role/v1/getRoleInfoList";

/**
 *
 * 保存角色信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-177276393
 */
export const postRoleV1Save_mciUserAuthAdminServer = (options: {
  requestBody: RoleBaseReqValidationAddGroup;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBooleanValidationAddGroup>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRoleV1Save_mciUserAuthAdminServer.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRoleV1Save_mciUserAuthAdminServer.backendServiceName,
  );
};

postRoleV1Save_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
postRoleV1Save_mciUserAuthAdminServer.key = "/role/v1/save";

/**
 *
 * 更新角色信息以及角色菜单按钮授权
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201361462
 */
export const postRoleV1Update_mciUserAuthAdminServer = (options: {
  requestBody: RoleInfoUpdateReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postRoleV1Update_mciUserAuthAdminServer.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postRoleV1Update_mciUserAuthAdminServer.backendServiceName,
  );
};

postRoleV1Update_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
postRoleV1Update_mciUserAuthAdminServer.key = "/role/v1/update";

/**
 *
 * 新增分成账户
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398195
 */
export const postSpacDivideConfigAddDivideConfig_mciArm = (options: {
  requestBody: DivideConfigAddReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigAddDivideConfig_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigAddDivideConfig_mciArm.backendServiceName,
  );
};

postSpacDivideConfigAddDivideConfig_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigAddDivideConfig_mciArm.key =
  "/spac/divide-config/add-divide-config";

/**
 *
 * 删除分账配置
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398196
 */
export const postSpacDivideConfigDeleteDivideConfig_mciArm = (options: {
  requestBody: DivideConfigDeleteReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigDeleteDivideConfig_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigDeleteDivideConfig_mciArm.backendServiceName,
  );
};

postSpacDivideConfigDeleteDivideConfig_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigDeleteDivideConfig_mciArm.key =
  "/spac/divide-config/delete-divide-config";

/**
 *
 * 启用分成账户
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238429147
 */
export const postSpacDivideConfigEnableDivideAccount_mciArm = (options: {
  requestBody: DivideConfigEnableReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigEnableDivideAccount_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigEnableDivideAccount_mciArm.backendServiceName,
  );
};

postSpacDivideConfigEnableDivideAccount_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigEnableDivideAccount_mciArm.key =
  "/spac/divide-config/enable-divide-account";

/**
 *
 * 获取选中rbo支持的分账账户名称和分账频率
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238428489
 */
export const postSpacDivideConfigGetDivideAcctNameAndFrequency_mciArm =
  (options: {
    requestBody: RboQueryCondDto;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<ResultRboAcctNameAndFrequencyTypeDto>> => {
    const { configOverride, __mock__, requestBody, headerParams } =
      options || {};

    return Http.postRequest(
      postSpacDivideConfigGetDivideAcctNameAndFrequency_mciArm.key,
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      postSpacDivideConfigGetDivideAcctNameAndFrequency_mciArm.backendServiceName,
    );
  };

postSpacDivideConfigGetDivideAcctNameAndFrequency_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigGetDivideAcctNameAndFrequency_mciArm.key =
  "/spac/divide-config/get-divide-acct-name-and-frequency";

/**
 *
 * 获取授权状态下拉枚举
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238416590
 */
export const postSpacDivideConfigListDivideAuthStatus_mciArm = (options: {
  requestBody: MTSpv2RboBaseDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListNameValuePairRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigListDivideAuthStatus_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigListDivideAuthStatus_mciArm.backendServiceName,
  );
};

postSpacDivideConfigListDivideAuthStatus_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigListDivideAuthStatus_mciArm.key =
  "/spac/divide-config/list-divide-auth-status";

/**
 *
 * 获取支持的分账渠道列表下拉枚举
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238416135
 */
export const postSpacDivideConfigListDivideChannels_mciArm = (options: {
  requestBody: MTSpv2RboBaseDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListChannelInfoDto>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigListDivideChannels_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigListDivideChannels_mciArm.backendServiceName,
  );
};

postSpacDivideConfigListDivideChannels_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigListDivideChannels_mciArm.key =
  "/spac/divide-config/list-divide-channels";

/**
 *
 * 获取启用状态下拉枚举
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238416591
 */
export const postSpacDivideConfigListDivideEnableStatus_mciArm = (options: {
  requestBody: MTSpv2RboBaseDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListNameValuePairRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigListDivideEnableStatus_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigListDivideEnableStatus_mciArm.backendServiceName,
  );
};

postSpacDivideConfigListDivideEnableStatus_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigListDivideEnableStatus_mciArm.key =
  "/spac/divide-config/list-divide-enable-status";

/**
 *
 * 获取分账频率下拉枚举
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238416136
 */
export const postSpacDivideConfigListDivideFrequency_mciArm = (options: {
  requestBody: MTSpv2RboBaseDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultListNameValuePairRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigListDivideFrequency_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigListDivideFrequency_mciArm.backendServiceName,
  );
};

postSpacDivideConfigListDivideFrequency_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigListDivideFrequency_mciArm.key =
  "/spac/divide-config/list-divide-frequency";

/**
 *
 * 分页查询分账账户
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398198
 */
export const postSpacDivideConfigPageQueryDivideConfig_mciArm = (options: {
  requestBody: DivideConfigPageQueryReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<
  SwaggerResponse<ResultSimplePageMybatisImplDivideConfigPageQueryDto>
> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigPageQueryDivideConfig_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigPageQueryDivideConfig_mciArm.backendServiceName,
  );
};

postSpacDivideConfigPageQueryDivideConfig_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigPageQueryDivideConfig_mciArm.key =
  "/spac/divide-config/page-query-divide-config";

/**
 *
 * 根据rbo查询当前已经启用的账户
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398193
 */
export const postSpacDivideConfigQueryEnabledAccount_mciArm = (options: {
  requestBody: RboQueryCondDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultAcctBaseInfo>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigQueryEnabledAccount_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigQueryEnabledAccount_mciArm.backendServiceName,
  );
};

postSpacDivideConfigQueryEnabledAccount_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigQueryEnabledAccount_mciArm.key =
  "/spac/divide-config/query-enabled-account";

/**
 *
 * 刷新资金流验收报告  --- 定时任务
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-240013336
 */
export const postSpacDivideConfigRefreshFundFlowAcceptance_mciArm = (options: {
  requestBody: FundFlowAcceptanceRefreshReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigRefreshFundFlowAcceptance_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigRefreshFundFlowAcceptance_mciArm.backendServiceName,
  );
};

postSpacDivideConfigRefreshFundFlowAcceptance_mciArm.backendServiceName =
  "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigRefreshFundFlowAcceptance_mciArm.key =
  "/spac/divide-config/refresh-fund-flow-acceptance";

/**
 *
 * 触发账户授权
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398199
 */
export const postSpacDivideConfigTriggerAccountAuth_mciArm = (options: {
  requestBody: DivideConfigAuthReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultDivideConfigAuthRsp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigTriggerAccountAuth_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigTriggerAccountAuth_mciArm.backendServiceName,
  );
};

postSpacDivideConfigTriggerAccountAuth_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigTriggerAccountAuth_mciArm.key =
  "/spac/divide-config/trigger-account-auth";

/**
 *
 * 更新授权状态  --- 定时任务
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398201
 */
export const postSpacDivideConfigUpdateAuthStatus_mciArm = (options: {
  requestBody: AuthStatusUpdateDto;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigUpdateAuthStatus_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigUpdateAuthStatus_mciArm.backendServiceName,
  );
};

postSpacDivideConfigUpdateAuthStatus_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigUpdateAuthStatus_mciArm.key =
  "/spac/divide-config/update-auth-status";

/**
 *
 * 更新分账配置
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-238398197
 */
export const postSpacDivideConfigUpdateDivideConfig_mciArm = (options: {
  requestBody: DivideConfigModifyReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacDivideConfigUpdateDivideConfig_mciArm.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacDivideConfigUpdateDivideConfig_mciArm.backendServiceName,
  );
};

postSpacDivideConfigUpdateDivideConfig_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacDivideConfigUpdateDivideConfig_mciArm.key =
  "/spac/divide-config/update-divide-config";

/**
 *
 * 创建spac
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233809
 */
export const postSpacMtCreateSpac_mcexpOperation = (options: {
  requestBody: CreateSpacReq;
  headerParams?: { "X-User-Info": string; client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultString>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacMtCreateSpac_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtCreateSpac_mcexpOperation.backendServiceName,
  );
};

postSpacMtCreateSpac_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpacMtCreateSpac_mcexpOperation.key = "/spac/mt/createSpac";

/**
 *
 * 创建spac sponsor
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233805
 */
export const postSpacMtCreateSponsor_mcexpOperation = (options: {
  requestBody: CreateSpacSponsorReq;
  headerParams?: { "X-User-Info": string; client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultString>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacMtCreateSponsor_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtCreateSponsor_mcexpOperation.backendServiceName,
  );
};

postSpacMtCreateSponsor_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpacMtCreateSponsor_mcexpOperation.key = "/spac/mt/createSponsor";

/**
 *
 * 初始态修改spac
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233810
 */
export const postSpacMtModifySpac_mcexpOperation = (options: {
  requestBody: ModifySpacReq;
  headerParams?: { "X-User-Info": string; client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultString>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacMtModifySpac_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtModifySpac_mcexpOperation.backendServiceName,
  );
};

postSpacMtModifySpac_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpacMtModifySpac_mcexpOperation.key = "/spac/mt/modifySpac";

/**
 *
 * 初始态 编辑spac sponsor
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233806
 */
export const postSpacMtModifySpacSponsor_mcexpOperation = (options: {
  requestBody: ModifySpacSponsorReq;
  headerParams?: { "X-User-Info": string; client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResult>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacMtModifySpacSponsor_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtModifySpacSponsor_mcexpOperation.backendServiceName,
  );
};

postSpacMtModifySpacSponsor_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postSpacMtModifySpacSponsor_mcexpOperation.key = "/spac/mt/modifySpacSponsor";

/**
 *
 * 查询spac设立的表单(MT端使用)
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242672559
 */
export const postSpacMtQuerySpacReq_mcexpOperation = (options: {
  requestBody: MtQuerySpacReq;
  queryParams?: PostSpacMtQuerySpacReqQueryParams;
  headerParams?: { "X-User-Info": string; client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultPageResultSpacInfoV2>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postSpacMtQuerySpacReq_mcexpOperation.key,
    queryParams,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtQuerySpacReq_mcexpOperation.backendServiceName,
  );
};

postSpacMtQuerySpacReq_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpacMtQuerySpacReq_mcexpOperation.key = "/spac/mt/querySpacReq";

/**
 *
 * 查询spac sponsor MT端使用
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233808
 */
export const postSpacMtQuerySpacSponsor_mcexpOperation = (options: {
  requestBody: MtQuerySpacSponsorReq;
  queryParams?: PostSpacMtQuerySpacSponsorQueryParams;
  headerParams?: { "X-User-Info": string; client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultPageResultSpacSponsorBo>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postSpacMtQuerySpacSponsor_mcexpOperation.key,
    queryParams,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtQuerySpacSponsor_mcexpOperation.backendServiceName,
  );
};

postSpacMtQuerySpacSponsor_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postSpacMtQuerySpacSponsor_mcexpOperation.key = "/spac/mt/querySpacSponsor";

/**
 *
 * 提交spac设立表单
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233811
 */
export const postSpacMtSubmitSpac_mcexpOperation = (options: {
  requestBody: SubmitSpacReq;
  headerParams?: {
    "X-Forwarded-For": string;
    "X-User-Info": string;
    client: string;
  };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResult>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacMtSubmitSpac_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtSubmitSpac_mcexpOperation.backendServiceName,
  );
};

postSpacMtSubmitSpac_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpacMtSubmitSpac_mcexpOperation.key = "/spac/mt/submitSpac";

/**
 *
 * 提交spac sponsor
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-242233807
 */
export const postSpacMtSubmitSpacSponsor_mcexpOperation = (options: {
  requestBody: SubmitSpacSponsorReq;
  headerParams?: {
    "X-Forwarded-For": string;
    "X-User-Info": string;
    client: string;
  };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResult>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postSpacMtSubmitSpacSponsor_mcexpOperation.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacMtSubmitSpacSponsor_mcexpOperation.backendServiceName,
  );
};

postSpacMtSubmitSpacSponsor_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postSpacMtSubmitSpacSponsor_mcexpOperation.key = "/spac/mt/submitSpacSponsor";

/**
 *
 * 审核通过之后调用该接口，推送数据到交易所
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-210903592
 */
export const postSpacPushToExchangeV1_mcexpOperation = (
  options: {
    queryParams?: PostSpacPushToExchangeV1QueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postSpacPushToExchangeV1_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacPushToExchangeV1_mcexpOperation.backendServiceName,
  );
};

postSpacPushToExchangeV1_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpacPushToExchangeV1_mcexpOperation.key = "/spac/pushToExchange/v1";

/**
 *
 * 查询spac设立的表单(运营管理台使用)
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243512377
 */
export const postSpacQuerySpacForOperation_mcexpOperation = (options: {
  requestBody: OperationQuerySpacReq;
  queryParams?: PostSpacQuerySpacForOperationQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultPageResultSpacInfoV2>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postSpacQuerySpacForOperation_mcexpOperation.key,
    queryParams,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacQuerySpacForOperation_mcexpOperation.backendServiceName,
  );
};

postSpacQuerySpacForOperation_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postSpacQuerySpacForOperation_mcexpOperation.key =
  "/spac/querySpacForOperation";

/**
 *
 * 查询spac sponsor 信息 （管理台使用）
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-243512376
 */
export const postSpacQuerySpacSponsorForOperation_mcexpOperation = (options: {
  requestBody: OptionQuerySpacSponsorReq;
  queryParams?: PostSpacQuerySpacSponsorForOperationQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<CommonResultPageResultSpacSponsorBo>> => {
  const { configOverride, __mock__, requestBody, queryParams, headerParams } =
    options || {};

  return Http.postRequest(
    postSpacQuerySpacSponsorForOperation_mcexpOperation.key,
    queryParams,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacQuerySpacSponsorForOperation_mcexpOperation.backendServiceName,
  );
};

postSpacQuerySpacSponsorForOperation_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
postSpacQuerySpacSponsorForOperation_mcexpOperation.key =
  "/spac/querySpacSponsorForOperation";

/**
 *
 * 分账结果查询-dev1
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-235684197
 */
export const postSpacSpvDivideReturn_mciArm = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postSpacSpvDivideReturn_mciArm.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpacSpvDivideReturn_mciArm.backendServiceName,
  );
};

postSpacSpvDivideReturn_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpacSpvDivideReturn_mciArm.key = "/spac/spv/divide-return";

/**
 *
 * 查询spv信息列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-225353362
 */
export const postSpvInfoQuerySpvInfoMap_mciArm = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultMapListString>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postSpvInfoQuerySpvInfoMap_mciArm.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpvInfoQuerySpvInfoMap_mciArm.backendServiceName,
  );
};

postSpvInfoQuerySpvInfoMap_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpvInfoQuerySpvInfoMap_mciArm.key = "/spvInfo/querySpvInfoMap";

/**
 *
 * 查询spvcode与spvName列表
 * 文档地址：https://app.apifox.com/web/project/809138/apis/api-240287812
 */
export const postSpvInfoQuerySpvNameList_mciArm = (
  options: {
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<ResultMapString>> => {
  const { configOverride, __mock__, headerParams } = options || {};

  return Http.postRequest(
    postSpvInfoQuerySpvNameList_mciArm.key,
    undefined,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpvInfoQuerySpvNameList_mciArm.backendServiceName,
  );
};

postSpvInfoQuerySpvNameList_mciArm.backendServiceName = "mci-arm";
/** Key is end point string without base url */
postSpvInfoQuerySpvNameList_mciArm.key = "/spvInfo/querySpvNameList";

/**
 *
 * 审核通过之后调用该接口，推送数据到交易所
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-208994322
 */
export const postSpvPushToExchangeV1_mcexpOperation = (
  options: {
    queryParams?: PostSpvPushToExchangeV1QueryParams;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  } | void,
): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.postRequest(
    postSpvPushToExchangeV1_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postSpvPushToExchangeV1_mcexpOperation.backendServiceName,
  );
};

postSpvPushToExchangeV1_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
postSpvPushToExchangeV1_mcexpOperation.key = "/spv/pushToExchange/v1";

/**
 *
 * test
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-183606334
 */
export const postUserTest_exKernelAdmin = (options: {
  requestBody: DepositTransferData;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<{ [x in string | number]: any }>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postUserTest_exKernelAdmin.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postUserTest_exKernelAdmin.backendServiceName,
  );
};

postUserTest_exKernelAdmin.backendServiceName = "ex-kernel-admin";
/** Key is end point string without base url */
postUserTest_exKernelAdmin.key = "/user/test";

/**
 *
 * 获取用户基本信息列表
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201653085
 */
export const postUserV1GetUserBaseInfoList_mciUserAuthAdminServer = (options: {
  requestBody: UserBaseInfoReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultSimplePageMybatisImplUserBaseInfoResp>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postUserV1GetUserBaseInfoList_mciUserAuthAdminServer.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postUserV1GetUserBaseInfoList_mciUserAuthAdminServer.backendServiceName,
  );
};

postUserV1GetUserBaseInfoList_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
postUserV1GetUserBaseInfoList_mciUserAuthAdminServer.key =
  "/user/v1/getUserBaseInfoList";

/**
 *
 * 用户角色授权接口
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-201874145
 */
export const postUserV1SaveUserRole_mciUserAuthAdminServer = (options: {
  requestBody: UserRoleSaveReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<ResultBoolean>> => {
  const { configOverride, __mock__, requestBody, headerParams } = options || {};

  return Http.postRequest(
    postUserV1SaveUserRole_mciUserAuthAdminServer.key,
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    postUserV1SaveUserRole_mciUserAuthAdminServer.backendServiceName,
  );
};

postUserV1SaveUserRole_mciUserAuthAdminServer.backendServiceName =
  "mci-user-auth-admin-server";
/** Key is end point string without base url */
postUserV1SaveUserRole_mciUserAuthAdminServer.key = "/user/v1/saveUserRole";

/**
 *
 * dro下架
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184917157
 */
export const putAdminV1DroOffShelf_mcexpOperation = (options: {
  queryParams: PutAdminV1DroOffShelfQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.putRequest(
    putAdminV1DroOffShelf_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putAdminV1DroOffShelf_mcexpOperation.backendServiceName,
  );
};

putAdminV1DroOffShelf_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
putAdminV1DroOffShelf_mcexpOperation.key = "/admin/v1/dro/offShelf";

/**
 *
 * sendDroSpec
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-184852443
 */
export const putAdminV1DroSendDroSpec_mcexpOperation = (options: {
  queryParams: PutAdminV1DroSendDroSpecQueryParams;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, queryParams, headerParams } = options || {};

  return Http.putRequest(
    putAdminV1DroSendDroSpec_mcexpOperation.key,
    queryParams,
    undefined,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putAdminV1DroSendDroSpec_mcexpOperation.backendServiceName,
  );
};

putAdminV1DroSendDroSpec_mcexpOperation.backendServiceName = "mcexp-operation";
/** Key is end point string without base url */
putAdminV1DroSendDroSpec_mcexpOperation.key = "/admin/v1/dro/sendDroSpec";

/**
 *
 * 更新申请的主数据
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517159
 */
export const putApiV1ShelfDroAppliesAppId_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  requestBody: DroAppUpdateReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(putApiV1ShelfDroAppliesAppId_mcexpOperation.key, pathParams),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppId_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppId_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppId_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}";

/**
 *
 * 上传申请的品牌基本数据
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517154
 */
export const putApiV1ShelfDroAppliesAppIdBrandBase_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * 申请id
     */
    appId: string;
  };
  requestBody: DroAppBrandBaseReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(
      putApiV1ShelfDroAppliesAppIdBrandBase_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppIdBrandBase_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppIdBrandBase_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdBrandBase_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/brandBase";

/**
 *
 * 上传申请的品牌投资数据
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517155
 */
export const putApiV1ShelfDroAppliesAppIdBrandInvest_mcexpOperation =
  (options: {
    pathParams: {
      /**
       *
       * 申请id
       */
      appId: string;
    };
    requestBody: DroAppBrandInvestReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, pathParams, requestBody, headerParams } =
      options || {};

    return Http.putRequest(
      template(
        putApiV1ShelfDroAppliesAppIdBrandInvest_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      putApiV1ShelfDroAppliesAppIdBrandInvest_mcexpOperation.backendServiceName,
    );
  };

putApiV1ShelfDroAppliesAppIdBrandInvest_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdBrandInvest_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/brandInvest";

/**
 *
 * 上传申请的品牌经营数据
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517156
 */
export const putApiV1ShelfDroAppliesAppIdBrandManage_mcexpOperation =
  (options: {
    pathParams: {
      /**
       *
       * 申请id
       */
      appId: string;
    };
    requestBody: DroAppBrandManageReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, pathParams, requestBody, headerParams } =
      options || {};

    return Http.putRequest(
      template(
        putApiV1ShelfDroAppliesAppIdBrandManage_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      putApiV1ShelfDroAppliesAppIdBrandManage_mcexpOperation.backendServiceName,
    );
  };

putApiV1ShelfDroAppliesAppIdBrandManage_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdBrandManage_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/brandManage";

/**
 *
 * 从arm拿数据
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517158
 */
export const putApiV1ShelfDroAppliesAppIdBrandSystem_mcexpOperation =
  (options: {
    pathParams: {
      /**
       *
       * 申请id
       */
      appId: string;
    };
    requestBody: DroAppBrandSystemReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, pathParams, requestBody, headerParams } =
      options || {};

    return Http.putRequest(
      template(
        putApiV1ShelfDroAppliesAppIdBrandSystem_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      putApiV1ShelfDroAppliesAppIdBrandSystem_mcexpOperation.backendServiceName,
    );
  };

putApiV1ShelfDroAppliesAppIdBrandSystem_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdBrandSystem_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/brandSystem";

/**
 *
 * 上传申请的投前营业额
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517153
 */
export const putApiV1ShelfDroAppliesAppIdIncome_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * 申请id
     */
    appId: string;
  };
  requestBody: DroAppIncomeReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(putApiV1ShelfDroAppliesAppIdIncome_mcexpOperation.key, pathParams),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppIdIncome_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppIdIncome_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdIncome_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/income";

/**
 *
 * 上传申请的合约pcf
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517152
 */
export const putApiV1ShelfDroAppliesAppIdPcf_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * 申请id
     */
    appId: string;
  };
  requestBody: DroAppPcfReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(putApiV1ShelfDroAppliesAppIdPcf_mcexpOperation.key, pathParams),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppIdPcf_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppIdPcf_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdPcf_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/pcf";

/**
 *
 * 上传申请的门店联营方案
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517151
 */
export const putApiV1ShelfDroAppliesAppIdStoreAssociate_mcexpOperation =
  (options: {
    pathParams: {
      /**
       *
       * 申请id
       */
      appId: string;
    };
    requestBody: DroAppStoreAssociateReq;
    headerParams?: { client: string };
    configOverride?: AxiosRequestConfigWithExtra;
    __mock__?: boolean;
  }): Promise<SwaggerResponse<Result>> => {
    const { configOverride, __mock__, pathParams, requestBody, headerParams } =
      options || {};

    return Http.putRequest(
      template(
        putApiV1ShelfDroAppliesAppIdStoreAssociate_mcexpOperation.key,
        pathParams,
      ),
      undefined,
      requestBody,
      _CONSTANT1,
      overrideConfig(
        {
          headers: {
            ..._CONSTANT0,
            ...(headerParams || {}),
          },
        },
        { ...configOverride, __mock__ },
      ),
      putApiV1ShelfDroAppliesAppIdStoreAssociate_mcexpOperation.backendServiceName,
    );
  };

putApiV1ShelfDroAppliesAppIdStoreAssociate_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdStoreAssociate_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/storeAssociate";

/**
 *
 * 上传申请的门店基础信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517149
 */
export const putApiV1ShelfDroAppliesAppIdStoreBase_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * 申请id
     */
    appId: string;
  };
  requestBody: DroAppStoreBaseReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(
      putApiV1ShelfDroAppliesAppIdStoreBase_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppIdStoreBase_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppIdStoreBase_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdStoreBase_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/storeBase";

/**
 *
 * 上传门店文件
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517157
 */
export const putApiV1ShelfDroAppliesAppIdStoreFile_mcexpOperation = (options: {
  pathParams: {
    appId: string;
  };
  requestBody: DroAppStoreFileReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(
      putApiV1ShelfDroAppliesAppIdStoreFile_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppIdStoreFile_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppIdStoreFile_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdStoreFile_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/storeFile";

/**
 *
 * 上传申请的门店经营信息
 * 文档地址：https://app.apifox.com/web/project/4491517/apis/api-178517150
 */
export const putApiV1ShelfDroAppliesAppIdStoreModel_mcexpOperation = (options: {
  pathParams: {
    /**
     *
     * 申请id
     */
    appId: string;
  };
  requestBody: DroAppStoreModelReq;
  headerParams?: { client: string };
  configOverride?: AxiosRequestConfigWithExtra;
  __mock__?: boolean;
}): Promise<SwaggerResponse<Result>> => {
  const { configOverride, __mock__, pathParams, requestBody, headerParams } =
    options || {};

  return Http.putRequest(
    template(
      putApiV1ShelfDroAppliesAppIdStoreModel_mcexpOperation.key,
      pathParams,
    ),
    undefined,
    requestBody,
    _CONSTANT1,
    overrideConfig(
      {
        headers: {
          ..._CONSTANT0,
          ...(headerParams || {}),
        },
      },
      { ...configOverride, __mock__ },
    ),
    putApiV1ShelfDroAppliesAppIdStoreModel_mcexpOperation.backendServiceName,
  );
};

putApiV1ShelfDroAppliesAppIdStoreModel_mcexpOperation.backendServiceName =
  "mcexp-operation";
/** Key is end point string without base url */
putApiV1ShelfDroAppliesAppIdStoreModel_mcexpOperation.key =
  "/api/v1/shelf/dro/applies/{appId}/storeModel";

export const servicesMap = {
  getAcceptanceReportExportAcceptanceReport_mciArm,
  getAccountBaseQueryAccountInfo_mciPayFinance,
  getAccountBaseQueryAccountInfoDetails_mciPayFinance,
  getAccountBaseQueryBankName_mciPayFinance,
  getAdminOperationLogExport_exKernelAdmin,
  getAdminOperationLogQuery_exKernelAdmin,
  getAdminRepoInvestNeedAddSignature_mcexpOperation,
  getAdminRepoInvestRepoInvestApprovalList_mcexpOperation,
  getAdminUserBaseinfo_exKernelAdmin,
  getAdminV1FilesFileId_mcexpOperation,
  getAdminV1FilesList_mcexpOperation,
  getAdminV1InvestFailMsgDicList_mcexpOperation,
  getAdminV1InvestPeriodOperateList_mcexpOperation,
  getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation,
  getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation,
  getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation,
  getAdminV1ShelfDroAppliesAppIdIncome_mcexpOperation,
  getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation,
  getApiV1DroArmUpdateDroShareStatus_mcexpOperation,
  getApiV1DroDroCode_mcexpOperation,
  getApiV1DroSyncDro_mcexpOperation,
  getApiV1DroSyncIpoStatus_mcexpOperation,
  getApiV1InvestTestGenerate_mcexpOperation,
  getApiV1MailSendInvestListMail_mcexpOperation,
  getApiV1MailSendInvestResultMail_mcexpOperation,
  getApiV1ShelfDroAppliesAppId_mcexpOperation,
  getApiV1ShelfFilesDownload_mcexpOperation,
  getContractGetContractChannelRelationOrgId_mcexpOperation,
  getContractGetContractEntityByIdId_mcexpOperation,
  getContractListDivideChannelByOrgIdOrgId_mcexpOperation,
  getContractListSubjectInfo_mcexpOperation,
  getCurrencyExchangeGetShareOrderInfo_exKernelAdmin,
  getDictionaryQuery_mciDict,
  getDroSpecsCode_exKernelAdmin,
  getDroSpecsUser_exKernelAdmin,
  getEnumBatchQuery_mciDict,
  getEnumQuery_mciDict,
  getFileGetFullPath_mciArm,
  getFilesDownload_exKernelAdmin,
  getFilesUrl_exKernelAdmin,
  getFundFundTranferGetArmAccountInfoList_exKernelAdmin,
  getHsbcDepositGetMemberShipList_exKernelAdmin,
  getHsbcDepositGetTradingAccountList_exKernelAdmin,
  getHsbcDepositGetTradingAccountToList_exKernelAdmin,
  getHsbcDepositGetTradingBillNoList_exKernelAdmin,
  getMailContentId_exKernelAdmin,
  getMailContentSendId_exKernelAdmin,
  getMailInfoDroAo_exKernelAdmin,
  getMailInfoDroIo_exKernelAdmin,
  getMailInfoDroSo_exKernelAdmin,
  getMailInfoDrpIo_exKernelAdmin,
  getMailInfoDrpIoPledge_exKernelAdmin,
  getMailInfoDrpReinvest_exKernelAdmin,
  getMailInfoDrpReinvestPledge_exKernelAdmin,
  getMailInfoDrpSo_exKernelAdmin,
  getMailInfoNoticeNormalEnd_exKernelAdmin,
  getMailInfoNoticeReplaceStore_exKernelAdmin,
  getMailInfoNoticeStoreClosure_exKernelAdmin,
  getMailInfoNoticeTerminationCooperation_exKernelAdmin,
  getMailInfoSave_exKernelAdmin,
  getNouserContractSignflownotifyRedo_mcexpOperation,
  getNouserDictionaryMapping_exKernelAdmin,
  getNouserOaSyncDepartmentUser_mciUserAuthAdminServer,
  getNouserOssDefaultPolicy_mciToolbox,
  getOperationLogExport_exKernelAdmin,
  getOperationLogQuery_exKernelAdmin,
  getOssPolicyByDir_mciToolbox,
  getOssV2BucketAccessUrl_mciToolbox,
  getOssV2DefaultPolicy_mciToolbox,
  getPayFinanceChannelPaymentTaskDownloadReceipt_mciPayFinance,
  getPayFinanceChannelPaymentTaskPayReturn_mciPayFinance,
  getPayFinanceFinancingBatchCalculate_mciPayFinance,
  getPayJobsCmbInvestQueryTransDetail_mciPayFinance,
  getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer,
  getSpacQuery_mcexpOperation,
  getSpacQueryOperator_mcexpOperation,
  getSpvQuery_mcexpOperation,
  getSpvQueryOperator_mcexpOperation,
  getSysRoleList_mciUserAuthAdminServer,
  getSysSystemList_mciUserAuthAdminServer,
  getSysSystemNameSystemCode_mciUserAuthAdminServer,
  getSysV1GetSystemMenuAuthVisibleList_mciUserAuthAdminServer,
  getSysV1GetSystemMenuList_mciUserAuthAdminServer,
  getUserBaseinfo_exKernelAdmin,
  getUserV1BatchGetUserByUserIds_mciUserAuthAdminServer,
  getUserV1GetBaseInfo_mciUserAuthAdminServer,
  getUserV1GetRoleByUser_mciUserAuthAdminServer,
  getUserV1GetRoleByUserId_mciUserAuthAdminServer,
  getUserV1GetUserByRole_mciUserAuthAdminServer,
  getUserV1GetUserRoleInfo_mciUserAuthAdminServer,
  postAcceptanceReportAddInfoFlowAcceptanceReport_mciArm,
  postAcceptanceReportQueryArmAcceptanceReportStatus_mciArm,
  postAcceptanceReportQueryInfoFlowAcceptanceReportList_mciArm,
  postAcceptanceReportUpdateInfoFlowAcceptanceReport_mciArm,
  postAccountBaseDeleteAccountInfo_mciPayFinance,
  postAccountBaseDeleteAttachFiles_mciPayFinance,
  postAccountBaseExportExcel_mciPayFinance,
  postAccountBaseModifyAccountInfo_mciPayFinance,
  postAccountBaseModifyRecordStatus_mciPayFinance,
  postAccountBaseQueryAcctNameList_mciPayFinance,
  postAccountBaseUpdateBatchRecordStatus_mciPayFinance,
  postAccountBaseUpdateUploadAttachments_mciPayFinance,
  postAdminRepoInvestDetail_mcexpOperation,
  postAdminRepoInvestExport_mcexpOperation,
  postAdminRepoInvestList_mcexpOperation,
  postAdminRepoInvestRetry_mcexpOperation,
  postAdminRepoInvestRetryBatch_mcexpOperation,
  postAdminUserTest_exKernelAdmin,
  postAdminV1DroDoList_mcexpOperation,
  postAdminV1DroExport_mcexpOperation,
  postAdminV1DroSendDroSpec_mcexpOperation,
  postAdminV1InvestDroList_mcexpOperation,
  postAdminV1InvestPeriodBatchConfirm_mcexpOperation,
  postAdminV1InvestPeriodBatchRetry_mcexpOperation,
  postAdminV1InvestPeriodList_mcexpOperation,
  postAdminV1InvestPeriodUpdateInvestStatus_mcexpOperation,
  postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation,
  postAdminV1OrderBatchBatchOrderList_mcexpOperation,
  postAdminV1OrderBatchBatchOrderListExport_mcexpOperation,
  postAdminV1OrderBatchBatchOrderSum_mcexpOperation,
  postAdminV1OrderBatchBatchOrderUpload_mcexpOperation,
  postAdminV1OrderBatchBlockNewOrder_mcexpOperation,
  postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation,
  postAdminV1OrderBatchCancelBlockTrade_mcexpOperation,
  postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation,
  postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation,
  postAdminV1OrderBatchNewOrder_mcexpOperation,
  postAdminV1OrderBlockTradeListExport_mcexpOperation,
  postAdminV1OrderBlockTradeOrderList_mcexpOperation,
  postAdminV1OrderCancel_mcexpOperation,
  postAdminV1OrderDirectList_mcexpOperation,
  postAdminV1OrderDirectListExport_mcexpOperation,
  postAdminV1OrderManualBatch_mcexpOperation,
  postAdminV1OrderManualBatchCancel_mcexpOperation,
  postAdminV1OrderManualBatchCheck_mcexpOperation,
  postAdminV1OrderManualBatchRevocation_mcexpOperation,
  postAdminV1OrderManualBlockTradeBatch_mcexpOperation,
  postAdminV1OrderManualBlockTradeList_mcexpOperation,
  postAdminV1OrderManualList_mcexpOperation,
  postAdminV1OrderMatchList_mcexpOperation,
  postAdminV1OrderMatchListExport_mcexpOperation,
  postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation,
  postAdminV1ShelfDroAppliesDoList_mcexpOperation,
  postAdminV1ShelfDroAppliesExport_mcexpOperation,
  postApiV1DroArmListShareRule_mcexpOperation,
  postApiV1DroArmListStatusInfo_mcexpOperation,
  postApiV1DroArmSubmitAssociateEnd_mcexpOperation,
  postApiV1DroDoCapture_mcexpOperation,
  postApiV1DroDoFunding_mcexpOperation,
  postApiV1DroOnShelfSchedule_mcexpOperation,
  postApiV1GetContractChannelInfo_mcexpOperation,
  postApiV1InvestCallback_mcexpOperation,
  postApiV1InvestScheduleRiskCheck_mcexpOperation,
  postApiV1InvestScheduleUpdateInvestStatus_mcexpOperation,
  postApiV1ShelfDroApplies_mcexpOperation,
  postApiV1ShelfDroAppliesAppIdDoDelete_mcexpOperation,
  postApiV1ShelfDroAppliesAppIdDoEdit_mcexpOperation,
  postApiV1ShelfDroAppliesAppIdDoSubmit_mcexpOperation,
  postApiV1ShelfFilesUpload_mcexpOperation,
  postApprove_mcexpOperation,
  postChannelPaymentManageQueryDetail_mciPayFinance,
  postConfigAudit_exKernelAdmin,
  postConfigBatchAdd_exKernelAdmin,
  postConfigBatchDel_exKernelAdmin,
  postConfigEditColumn_exKernelAdmin,
  postConfigGetConfigTableColumns_exKernelAdmin,
  postConfigGetConfigTableInfo_exKernelAdmin,
  postConfigGetList_exKernelAdmin,
  postConfigGetRecheckRecord_exKernelAdmin,
  postContractGetHistoryRecord_mcexpOperation,
  postContractList_mcexpOperation,
  postContractSave_mcexpOperation,
  postContractSignatureV1InitiateSigning_mcexpOperation,
  postContractUpdate_mcexpOperation,
  postContractUpdateContractChannelRelation_mcexpOperation,
  postContractV1GenerateIoApplicationContract_mcexpOperation,
  postContractV1GenerateJointOperationContract_mcexpOperation,
  postContractV1GenerateRevenueProtocol_mcexpOperation,
  postCurrencyExchangeAddManualCurrencyExchange_exKernelAdmin,
  postCurrencyExchangeList_exKernelAdmin,
  postCurrencyExchangeUpdate_exKernelAdmin,
  postDecisionDecision1793913430569328642Execute_mcexpOperation,
  postDepositInfoExportDepositInfo_mciArm,
  postDepositInfoQueryDepositInfoList_mciArm,
  postDepositInfoReviewDepositInfo_mciArm,
  postDepositInfoSaveAndParseHsbcDepositInfo_mciArm,
  postDroShelveOffShelve_exKernelAdmin,
  postDroShelveOnExport_exKernelAdmin,
  postDroShelveOnList_exKernelAdmin,
  postDroShelvePendingExport_exKernelAdmin,
  postDroShelvePendingList_exKernelAdmin,
  postFileUpload_mciArm,
  postFilesDelete_exKernelAdmin,
  postFilesUpload_exKernelAdmin,
  postFlowAdd_mciArm,
  postFlowApprove_mciArm,
  postFlowExport_mciArm,
  postFlowList_mciArm,
  postFlowPushAcf_mciArm,
  postFlowSpvAdd_mciArm,
  postFlowSpvList_mciArm,
  postFlowUpdateFlow_mciArm,
  postFundFundTranferExportFundTransferBill_exKernelAdmin,
  postFundFundTranferFundTransferBillList_exKernelAdmin,
  postFundFundTranferSave_exKernelAdmin,
  postFundFundTranferUpdate_exKernelAdmin,
  postHsbcDepositDepositReviewList_exKernelAdmin,
  postHsbcDepositExportDepositFlow_exKernelAdmin,
  postHsbcDepositImportDepositFlow_exKernelAdmin,
  postHsbcDepositSendDepositMail_exKernelAdmin,
  postHsbcDepositUpdateById_exKernelAdmin,
  postHsbcDepositUpdateTradingAccount_exKernelAdmin,
  postMailContentAddAttachment_exKernelAdmin,
  postMailContentDelete_exKernelAdmin,
  postMailContentDeleteAttachment_exKernelAdmin,
  postMailContentList_exKernelAdmin,
  postMailContentSendBatch_exKernelAdmin,
  postMailContentSendSimple_exKernelAdmin,
  postMailContentSendTemplate_exKernelAdmin,
  postMailContentUpdate_exKernelAdmin,
  postMciArmSpacSpvDoDivideWithdraw_mciArm,
  postMemberAccountRelationSaveOrUpdate_exKernelAdmin,
  postMemberAuthorisedPeopleSaveOrUpdate_exKernelAdmin,
  postNouserContractV3SignFlowNotify_mcexpOperation,
  postNouserOssBucketAccessUrlV2_mciToolbox,
  postOpEntEntInfo12765_mciPayFinance,
  postOssV2BucketAccessUrl_mciToolbox,
  postOssV2FileDelete_mciToolbox,
  postOssV2ImageModeration_mciToolbox,
  postOssV2TextModeration_mciToolbox,
  postPayFinanceChannelPaymentTaskDoPay_mciPayFinance,
  postPayJobsFinancePushByTime_mciPayFinance,
  postPdfCreate_exKernelAdmin,
  postRbcEsignSigningCompletionCallback_mcexpOperation,
  postRbcPushToExchangeV1_mcexpOperation,
  postRbcQuery_mcexpOperation,
  postRboIncomeInfoExport_mciArm,
  postRboIncomeInfoManualUploadRboIncomeInfo_mciArm,
  postRboIncomeInfoQueryRboIncomeInfoList_mciArm,
  postRboIncomeInfoUploadRboIncomeInfo_mciArm,
  postRboShareIncomeDownShareIncomeTemplate_mciArm,
  postRboShareIncomeGetTotalAmountByRboAndBusinessDate_mciArm,
  postRboShareIncomeManualUploadRboShareIncomeInfo_mciArm,
  postRboShareIncomeQueryRboShareIncomeByBusinessDate_mciArm,
  postRboShareIncomeQueryRboShareIncomeList_mciArm,
  postRelationshipBinding_mciArm,
  postRelationshipQueryBankMasterAccount_mciArm,
  postRelationshipQueryVaInfo_mciArm,
  postRoleV1GetRoleInfoList_mciUserAuthAdminServer,
  postRoleV1Save_mciUserAuthAdminServer,
  postRoleV1Update_mciUserAuthAdminServer,
  postSpacDivideConfigAddDivideConfig_mciArm,
  postSpacDivideConfigDeleteDivideConfig_mciArm,
  postSpacDivideConfigEnableDivideAccount_mciArm,
  postSpacDivideConfigGetDivideAcctNameAndFrequency_mciArm,
  postSpacDivideConfigListDivideAuthStatus_mciArm,
  postSpacDivideConfigListDivideChannels_mciArm,
  postSpacDivideConfigListDivideEnableStatus_mciArm,
  postSpacDivideConfigListDivideFrequency_mciArm,
  postSpacDivideConfigPageQueryDivideConfig_mciArm,
  postSpacDivideConfigQueryEnabledAccount_mciArm,
  postSpacDivideConfigRefreshFundFlowAcceptance_mciArm,
  postSpacDivideConfigTriggerAccountAuth_mciArm,
  postSpacDivideConfigUpdateAuthStatus_mciArm,
  postSpacDivideConfigUpdateDivideConfig_mciArm,
  postSpacMtCreateSpac_mcexpOperation,
  postSpacMtCreateSponsor_mcexpOperation,
  postSpacMtModifySpac_mcexpOperation,
  postSpacMtModifySpacSponsor_mcexpOperation,
  postSpacMtQuerySpacReq_mcexpOperation,
  postSpacMtQuerySpacSponsor_mcexpOperation,
  postSpacMtSubmitSpac_mcexpOperation,
  postSpacMtSubmitSpacSponsor_mcexpOperation,
  postSpacPushToExchangeV1_mcexpOperation,
  postSpacQuerySpacForOperation_mcexpOperation,
  postSpacQuerySpacSponsorForOperation_mcexpOperation,
  postSpacSpvDivideReturn_mciArm,
  postSpvInfoQuerySpvInfoMap_mciArm,
  postSpvInfoQuerySpvNameList_mciArm,
  postSpvPushToExchangeV1_mcexpOperation,
  postUserTest_exKernelAdmin,
  postUserV1GetUserBaseInfoList_mciUserAuthAdminServer,
  postUserV1SaveUserRole_mciUserAuthAdminServer,
  putAdminV1DroOffShelf_mcexpOperation,
  putAdminV1DroSendDroSpec_mcexpOperation,
  putApiV1ShelfDroAppliesAppId_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdBrandBase_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdBrandInvest_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdBrandManage_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdBrandSystem_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdIncome_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdPcf_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdStoreAssociate_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdStoreBase_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdStoreFile_mcexpOperation,
  putApiV1ShelfDroAppliesAppIdStoreModel_mcexpOperation,
};
export type ServicesMap = typeof servicesMap;
export type ServiceName = keyof ServicesMap;
export const _CONSTANT0 = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
export const _CONSTANT1 = [{ bearer: [] }];
export const _CONSTANT2 = {
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};
export const _CONSTANT3 = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};
export const _CONSTANT4 = [];
