import type { LogOption, LogType } from './type';

export class LogUtil {
  static init() {
    if (typeof window !== 'undefined') {
      if (window.Log) {
        console.warn('LogUtil has been initialized');
        return;
      }

      window.Log = new LogUtil();
    }
  }

  private maxTimesMap = new Map<string, number>();

  static DefaultOption: Required<LogOption> = {
    maxTimes: {
      count: 1,
      allType: false,
    },
  };

  defaultOption: Required<LogOption>;

  constructor(option?: LogOption) {
    this.defaultOption = { ...LogUtil.DefaultOption, ...option };
  }

  private tryMaxTimes(msg: string, maxTimes: number) {
    const times = this.maxTimesMap.get(msg) || 0;
    if (times >= maxTimes) {
      return false;
    }
    this.maxTimesMap.set(msg, times + 1);
    return true;
  }

  private _log(type: LogType, msg: any, option?: LogOption) {
    const optionMerged = { ...this.defaultOption, ...option };

    let printMsg: any = msg;

    const maxTimesConfig =
      typeof optionMerged.maxTimes === 'object'
        ? optionMerged.maxTimes
        : { count: optionMerged.maxTimes === false ? 0 : optionMerged.maxTimes };
    const maxTimes = maxTimesConfig.count;

    if (maxTimes > 0) {
      const isStr = typeof msg === 'string';

      let needCheck = isStr;
      if (!isStr) {
        if (maxTimesConfig.allType) {
          printMsg = JSON.stringify(msg);
        } else {
          needCheck = false;
        }
      }

      if (needCheck && !this.tryMaxTimes(printMsg, maxTimes)) {
        return;
      }
    }

    console[type](printMsg);
  }

  // TODO: console.经常支持多个参数的， 是否支持，然后之类剔除掉 options 简化使用，如果需要定制就自行创建一个
  log(msg: any, option?: LogOption) {
    this._log('log', msg, option);
  }

  info(msg: any, option?: LogOption) {
    this._log('info', msg, option);
  }

  warn(msg: any, option?: LogOption) {
    this._log('warn', msg, option);
  }

  error(msg: any, option?: LogOption) {
    this._log('error', msg, option);
  }

  // TODO: Log.verbose in debug
}

declare global {
  var Log: LogUtil;
}
