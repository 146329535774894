import { ExclamationCircleFilled } from '@ant-design/icons';
import { Popover, Flex, Button } from 'antd';
import { useState, useEffect, FC, PropsWithChildren } from 'react';

export interface IMciPopOverProps {
  onConfirm?: () => void;
  title?: string;
  description?: string;
  cancelText?: string;
  confirmText?: string;
  triggerText?: string;
  disabled?: boolean;
  buttonHeight?: number | string;
}

// TODO: 从 mci-design-system 搬运过来的，那个公共库还没有完全跑起来
export const PopoverConfirm: FC<PropsWithChildren<IMciPopOverProps>> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const close = () => {
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  useEffect(function () {
    document.body.addEventListener('click', close);
    return () => {
      document.body.removeEventListener('click', close);
    };
  }, []);

  const title = props.title ?? '提示';
  const description = props.description ?? '';
  const cancelText = props.cancelText ?? '取消';
  const confirmText = props.confirmText ?? '确定';
  const triggerText = props.triggerText ?? '点击';

  return (
    <Popover
      title={
        <Flex align="center" gap={4}>
          <ExclamationCircleFilled style={{ color: '#faad14' }} />
          {title}
        </Flex>
      }
      content={
        <Flex vertical gap={16}>
          <div style={{ whiteSpace: 'pre-wrap' }}>{description}</div>
          <Flex align="center" justify="flex-end" gap={8}>
            <Button size="small" type="default" onClick={close}>
              {cancelText}
            </Button>
            <Button
              size="small"
              type="primary"
              onClick={() => {
                props?.onConfirm?.();
                close();
              }}>
              {confirmText}
            </Button>
          </Flex>
        </Flex>
      }
      trigger="click"
      open={isOpen}>
      {props.children ?? (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            open();
          }}
          disabled={props.disabled}
          type="link"
          // 默认会分配 32px 的高度，但是有的操作列没有，会造成高度对齐不了，设置 'auto' 可以对齐文字高度
          style={{ padding: 0, height: props.buttonHeight }}>
          {triggerText}
        </Button>
      )}
    </Popover>
  );
};
