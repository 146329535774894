import { isEmpty, merge } from 'lodash';
import { createContext, createElement, useContext } from 'react';
import { ExportAction } from '../mci-protable/actionRenderer';
const builtinRootProviderCtx = {
    toolBarActionMap: {
        export: ExportAction,
    },
    userInfo: null,
};
const pageProviderCtx = {};
const RootProviderCtx = createContext(builtinRootProviderCtx);
const PageProviderCtx = createContext({});
export const MciRootProProvider = (props) => {
    const { children, ...restProps } = props;
    const finalRootProviderCtx = merge(builtinRootProviderCtx, restProps);
    return createElement(RootProviderCtx.Provider, {
        value: finalRootProviderCtx,
    }, children);
};
export const MciPageProProvider = (pageProps) => {
    const rootCtx = useContext(RootProviderCtx) || {};
    const { children, ...restPageProps } = pageProps;
    if (rootCtx) {
        merge(pageProviderCtx, rootCtx);
    }
    Object.entries(restPageProps).forEach(([prop, value]) => {
        if (rootCtx[prop]) {
            Object.keys(rootCtx[prop]).forEach((key) => {
                if (value[key]) {
                    console.warn(`MciPageProProvider中的 ${prop} -> ${key} 与MciRootProProvider中的 ${prop} -> ${key} 重复`);
                }
                pageProviderCtx[prop][key] = value[key] || rootCtx[prop][key];
            });
        }
        else {
            pageProviderCtx[prop] = value;
        }
    });
    return createElement(PageProviderCtx.Provider, {
        value: pageProviderCtx,
    }, children);
};
export const useMciProProvider = () => {
    const rootCtx = useContext(RootProviderCtx);
    const pageCtx = useContext(PageProviderCtx);
    const ctx = isEmpty(pageCtx) ? rootCtx : pageCtx;
    if (!ctx) {
        throw new Error('请在MciPageProProvider中使用useMciProProvider');
    }
    return ctx;
};
