import { ProTable, ProTableProps, ActionType } from '@ant-design/pro-components';
import { sleep } from '@mci-fe/utils';
import { TabsProps, Tabs, Button, Tooltip } from 'antd';
import { FC, useRef, useState } from 'react';
import { OrderManualCancel } from './OrderManualCancel';
import { OrderManualOrder } from './OrderManualOrder';
import { postAdminV1OrderManualList_mcexpOperation } from '@/api/services';
import { columns, options } from './columns';

/**
 * 订单管理-手工下单-IOAOSO
 */
export const OrderManualFromIOAOSO: FC = () => {
  const [loading, setLoading] = useState(false);

  const [openManualCancel, setOpenManualCancel] = useState(false);
  const [batchType, setBatchType] = useState('canCancel');
  const [openManualOrder, setOpenManualOrder] = useState(false);
  const [currPageSize, setCurrPageSize] = useState(10);

  const refTable = useRef<ActionType>();
  const tableLoading = loading;

  // TODO: ts type
  const selectedRows = useRef<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [tooltipColumns, setTooltipColumns] = useState<any[]>([]);

  const clearSelectedRows = () => {
    // TODO: 有一个 actionRef 貌似也可以清空，看看是否可以
    setSelectedRowKeys([]);
  };

  const request: ProTableProps<any, any>['request'] = async (params) => {
    setLoading(true);

    // string 转 int
    if (params.auditStatusList) {
      params.auditStatusList = params.auditStatusList.map((item: string) => parseInt(item));
    }

    let error = '';
    const resp = await postAdminV1OrderManualList_mcexpOperation({
      requestBody: {
        page: params.current,
        size: params.pageSize,
        createTimeStart: params.createTime?.[0],
        createTimeEnd: params.createTime?.[1],
        batchId: params.batchId,
        auditStatusList: params.auditStatusList,
        identificationName: params.identificationName,
        tradingAccountCode: params.tradingAccountCode,
        productCodeList: params.productCodeList,
        productDeal: params.productDeal,
        orderCodeList: params.orderCodeList,
      },
    }).catch((e) => (error = e.message));

    setLoading(false);

    const newColumns = columns.map((item: any) => {
      if (item.dataIndex === 'orderStatus') {
        return {
          ...item,
          render: (text: string, record: any) => {
            return record.orderStatus === '-1' ? (
              <Tooltip placement="topLeft" title={record.orderMsg || '暂无原因'}>
                <span>{text}</span>
              </Tooltip>
            ) : (
              <span>{text}</span>
            );
          },
        };
      }
      return item;
    });
    setTooltipColumns(newColumns);

    if (error) {
      return {
        data: [],
        total: 0,
        success: true,
      };
    }

    if (resp?.data?.content?.length > 0) {
      resp.data.content = resp.data.content.map((item: any) => {
        return {
          ...item,
          orderStatus: item.orderStatus?.toString(),
        };
      });
    }

    return {
      data: resp?.data?.content || [],
      total: resp?.data?.page!.totalElements || 0,
      success: true,
    };
  };

  // 打开批量操作弹窗
  const onBatchClick = (type: string) => {
    setOpenManualCancel(true);
    setBatchType(type);
  };

  // 关闭批量操作弹窗
  const closeManualCancel = () => {
    setOpenManualCancel(false);
    setBatchType('');
  };

  // 关闭批量下单弹窗
  const closeManualOrder = () => {
    setOpenManualOrder(false);
  };

  // 刷新表格
  const refreshTable = () => {
    clearSelectedRows();
    refTable?.current?.reload();
  };

  const toolBarRender: ProTableProps<any, any>['toolBarRender'] = (actions, { selectedRows }) => {
    const haveSelected = selectedRows && selectedRows.length > 0;

    return [
      <Button disabled={!haveSelected} onClick={() => onBatchClick('canCancel')} loading={tableLoading} key="cancel">
        批量取消
      </Button>,
      <Button disabled={!haveSelected} onClick={() => onBatchClick('canAudit')} loading={tableLoading} key="audit">
        批量审批
      </Button>,
      <Button disabled={!haveSelected} onClick={() => onBatchClick('canRevoke')} loading={tableLoading} key="revoke">
        批量撤单
      </Button>,
      <Button onClick={() => setOpenManualOrder(true)} loading={tableLoading} key="order">
        批量下单
      </Button>,
    ];
  };

  return (
    <>
      <ProTable
        actionRef={refTable}
        cardBordered
        columns={tooltipColumns}
        size="small"
        scroll={{ x: 2800 }}
        tableAlertRender={false}
        rowKey="id"
        toolBarRender={toolBarRender}
        search={{
          defaultCollapsed: false,
          labelWidth: 'auto',
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (keys, rows) => {
            setSelectedRowKeys(keys);
            selectedRows.current = rows;
          },
        }}
        request={request}
        pagination={{
          pageSize: currPageSize,
          onShowSizeChange: (current, selectedSize) => {
            setCurrPageSize(selectedSize);
          },
          pageSizeOptions: [10, 20, 50, 100, 200, 500],
        }}
      />
      {openManualCancel && (
        <OrderManualCancel
          type={batchType}
          selectedRows={selectedRows.current}
          onCancel={closeManualCancel}
          refreshTable={refreshTable}
        />
      )}
      {openManualOrder && <OrderManualOrder onCancel={closeManualOrder} refreshTable={refreshTable} />}
    </>
  );
};
