export const TEST_CONST = '这是放常量的地方';
/** 超时时间 20s */
export const AXIOS_TIMEOUT = 20 * 1000;

// CREATED
// FINANCE_AUDIT
// OPERATION_AUDIT
// COMPLETED

// 已创建
// 财务审核
// 运营审核
// 处理完成

export const EXCHANGE_STATUS_MAP = {
  CREATED: '已创建',
  FINANCE_AUDIT: '财务审核',
  OPERATION_AUDIT: '运营审核',
  COMPLETED: '处理完成',
};

// 枚举值
// CURRENCY_EXCHANGE_SWAP_IN
// CURRENCY_EXCHANGE_SWAP_OUT
// 说明
// 换汇-换入
// 换汇-换出

export const EXCHANGE_TYPE_MAP = {
  换入: 'IN',
  换出: 'OUT',
};
// 枚举值
// TRADING
// NON_TRADING
// 说明
// 交易类
// 非交易类
export const TRADING_TYPE_ENUM = {
  交易类: 'TRADING',
  非交易类: 'NON_TRADING',
};

export const ENV_KEY = '__PRIVATE_EXMT_OPS_ENV__';
export const RESOLVED_ENV_KEY = '__PRIVATE_EXMT_OPS_RESOLVED_ENV__';
export const INIT_ENV_QUERY_KEY = '__init_env__';
