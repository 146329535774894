import { getFilesUrl_exKernelAdmin, postFilesUpload_exKernelAdmin } from '@/api/services';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, message } from 'antd';
import { UploadChangeParam, UploadProps } from 'antd/es/upload';
import { UploadFile } from 'antd/lib/upload';
import { FC, useEffect, useState } from 'react';

export type EmailUploadFile = UploadFile & {
  fileName?: string;
  fileType?: string;
  filePath?: string;
};

export interface EmailUploadProps {
  value?: EmailUploadFile[];
  onChange?: (value: EmailUploadFile[]) => void;
  onRemove?: UploadProps['onRemove'];
  initialValue?: EmailUploadFile[];
  disabled?: boolean;
  limit?: {
    fileSizeMB?: number;
  };
  maxCount?: number;
}

const EmailUpload: React.FC<any> = (props: EmailUploadProps) => {
  const [fileList, setFileList] = useState<EmailUploadFile[]>(props.value ?? props.initialValue ?? []);
  const [messageApi, contextHolder] = message.useMessage();
  const maxCount = props.maxCount ?? 0; // 0 代表不限制
  const uploadDisabled = props.disabled || (maxCount > 0 && fileList.length >= maxCount);

  const updateFileList = (fileList: any) => {
    setFileList(fileList);
    props.onChange?.(fileList);
  };

  const fetchFilelUrl = async (filePath: string) => {
    const duration = 3600 * 1000 * 24;
    return getFilesUrl_exKernelAdmin({
      queryParams: { duration, filePath },
    });
  };

  const customRequest: UploadProps['customRequest'] = async (options: any) => {
    const uid = options.file.uid;
    const res = await postFilesUpload_exKernelAdmin({
      requestBody: {
        file: options.file,
      },
    });

    // 更新后端的额外数据，提交时候会用到
    let newFileList = [...fileList];
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      // 添加时候已经存在这个记录了，只是 status 是 uploading
      if (item.uid === uid) {
        item.fileName = res.data!.fileName!;
        item.fileType = res.data!.fileType!;
        item.filePath = res.data!.filePath!;
        break;
      }
    }
    updateFileList(newFileList);

    // TODO: 后端为什么还会约定 data 还是可选的
    const filePath = res.data!.filePath!;
    const resUrl = await fetchFilelUrl(filePath);

    newFileList = [...fileList];
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      if (item.uid === uid) {
        item.url = resUrl.data;
        item.status = 'done'; // 需要手动扭转状态，因为是自定义上传
        break;
      }
    }
    updateFileList(newFileList);
  };

  const onValueChange = async () => {
    // 后端下发的默认数据只是 filePath，不是真实的 url，进来需要触发一次请求
    const currentFileList = [...(props.value || [])];

    const newFileList = await Promise.all(
      currentFileList.map(async (item): Promise<EmailUploadFile> => {
        if (item.url) return item;

        if (!item.filePath) {
          // 有 uplading 说明是用户自行添加的，等待状态扭转
          if (item.status !== 'uploading') {
            console.error('no filePath', item);
          }
          return item;
        }

        const res = await fetchFilelUrl(item.filePath!);
        // TODO: deal with bad case

        // 如果是外部传入的，没有值，默认分配
        const name = item.name || item.fileName!;
        const uid = item.uid || item.filePath!;
        return { ...item, url: res.data, name, uid, status: 'done' };
      }),
    );

    updateFileList(newFileList);
  };

  useEffect(() => {
    onValueChange();
  }, [props.value]);

  const handleRemove: UploadProps['onRemove'] = (file: EmailUploadFile) => {
    if (props.onRemove) {
      return props.onRemove(file);
    }
    return true;
  };

  return (
    <>
      <Upload
        name="file"
        customRequest={customRequest}
        onChange={(info: UploadChangeParam<UploadFile<any>>) => {
          updateFileList(info.fileList);
        }}
        beforeUpload={(file) => {
          let fileSizeMB = props.limit?.fileSizeMB ?? 10; // 默认也做一个限制
          if (fileSizeMB > 0) {
            const realFileSizeMB = file.size / 1024 / 1024;
            if (realFileSizeMB > fileSizeMB) {
              const errMsg = `上传文件过的大，当前文件大小为 ${realFileSizeMB.toFixed(2)}MB，限制为 ${fileSizeMB}MB`;
              messageApi.error(errMsg);
              return Upload.LIST_IGNORE;
            }
          }
        }}
        disabled={props.disabled}
        // 返回true时，会修改原始数据
        onRemove={handleRemove}
        maxCount={maxCount}
        fileList={fileList}>
        <Button disabled={uploadDisabled} icon={<UploadOutlined />}>
          Click to Upload
        </Button>
      </Upload>
      {contextHolder}
    </>
  );
};

export default EmailUpload;
