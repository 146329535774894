import { BetaSchemaForm, ProFormColumnsType, ProFormLayoutType } from '@ant-design/pro-components';
import '@wangeditor/editor/dist/css/style.css';
import { Button, FormInstance, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor';
import { Editor, Toolbar } from '@wangeditor/editor-for-react';
import { sleep } from '@mci-fe/utils';
import EmailWangEditorDangerous from './EmailWangEditorDangerous';
import { WangEditorUtils } from './WangEditorUtils';
import { wangEditorConfig } from './wang-editor-config';

type EditorProps = Parameters<typeof Editor>[0];

export interface EmailWangEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  initialValue?: string;
  disabled?: boolean;
  preprocess?: boolean; // 默认 true
}

const DEBUG = true;
const DEBUG_MAX_STR_LEN = 50;

const processHtml = (html: string) => {
  if (DEBUG) console.log('【wang】processHtml: ', html.slice(0, DEBUG_MAX_STR_LEN));
  let str = html;
  str = WangEditorUtils.removeEmptyAndCommentNodes(str);
  str = WangEditorUtils.makeStrongInside(str);
  // str = WangEditorUtils.normalizeStyle(str);
  // str = WangEditorUtils.makeCamelCase(str);
  // str = WangEditorUtils.makeCamelCaseByRegExp(str);
  return str;
};

const EmailWangEditor = (props: EmailWangEditorProps) => {
  const [editor, setEditor] = useState<IDomEditor | null>(null);
  const waitForPropValue = useRef(true);
  const preprocess = (waitForPropValue.current && props.preprocess) ?? true;

  const emptyHtml = WangEditorUtils.defaultEmptyHtml;
  // [空白] undefined和空字符串，内容不会设置生效，需要手动指定换行符
  // TODO: props.initialValue 不是这么用的，只是第一次和 reset 时候生效
  const value = props.value || emptyHtml;

  const html = preprocess ? processHtml(value) : value;
  const onChangeRef = useRef<EditorProps['onChange'] | null>(null);
  const isNotifyOnChange = useRef(false);
  const isNotifyOnValueChange = useRef(false);

  if (DEBUG) {
    console.log('【wang】render: ', html.slice(0, DEBUG_MAX_STR_LEN));
  }

  // 读取数据
  useEffect(() => {
    if (!editor) return;

    /**
     * 阻止初始值的回显
     *
     * 第一次进来使用默认值的时候，尽可能不触发 onChange
     * 比如antd form onChange 会触发表单校验
     *
     * 不触发 onChange 的代价是内部是 <p><br></p> ，外部是 undefined
     * 表单意义可以理解为等价
     */
    if (waitForPropValue.current && html === emptyHtml) return;
    waitForPropValue.current = false;

    /**
     * [阻止改变回显]
     *
     * 这里的回刷仅限于外部 props.value 的改变，编辑器内部 onChange 不再回显
     * 其实也是合理的，比如编辑器改变触发外部 valuechange，外部 valuechange 其实没必要继续反向修改编辑器了
     *
     * 但是主要解决的问题是，编辑器回显不对称的问题，当编辑器有 table 的时候
     * 输入 A，会得到 A + <p><br></p>，每次都会增加一个空行，导致编辑器不断增加空行，造成死循环
     *
     * 阻止回显还有一个问题，就是这里的 dangerouslyInsertHtml 会改变 selection 位置
     *
     * 其实还有一些细节，编辑器输入 rowspan，但是输出一定是 rowSpan 变成驼峰
     * 但是经过 DOMParser，驼峰又会变成全小写
     */
    if (isNotifyOnChange.current) {
      if (DEBUG) {
        console.log('【wang】cancel value by onChange, ', html.slice(0, DEBUG_MAX_STR_LEN));
      }

      isNotifyOnChange.current = false;
      return;
    }

    // - 当内容的改变源于内部编辑时候，跳过，也防止额外触发一次 onChange
    //   更重要的是防止 dangerouslyInsertHtml 导致内容重刷光标移动了
    //   NOTE: 编辑器阻止回显后，这个已经不重要了
    // const currHtml = editor.getHtml();
    // if (currHtml === html) {
    //   console.log('【editor setHtml】cancel by same\n');
    //   return;
    // }

    if (DEBUG) {
      console.log('【wang】value set: ', html.slice(0, DEBUG_MAX_STR_LEN));
    }
    isNotifyOnValueChange.current = true;
    editor.setHtml(html);
  }, [html, editor]);

  // 禁用
  useEffect(() => {
    // 注意，editor 是延迟加载的，所以这里需要判断
    if (editor == null) return;
    props.disabled ? editor.disable() : editor.enable();
  }, [props.disabled, editor]);

  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = {
    excludeKeys: [
      // 默认不支持媒体类型，如果有时间可以支持
      'group-image',
      'group-video',
      'table',
      'code',
      'fullScreen',
    ],
  };

  const onChange: EditorProps['onChange'] = (editor: IDomEditor) => {
    /**
     * 延迟等待上游值
     *
     * 因为上游值不是立即生效的（等待获取 editor）
     * 而且如果设置 value 或者 defaultValue，会触发一次 onChange，导致上游值被替换
     */
    if (waitForPropValue.current) {
      if (DEBUG) {
        console.log('【wang】onChange wait value');
      }
      return;
    }
    // if (supressNotify.current) return;

    /**
     * 阻止 value 通知 onChange
     *
     * 在表单场景中，value 变化通常是请求加载的，但是这个时候我们不希望触发 onChange来触发表单校验
     */
    if (isNotifyOnValueChange.current) {
      if (DEBUG) {
        console.log('【wang】onChange cancel by value change');
      }
      isNotifyOnValueChange.current = false;
      return;
    }

    // - 经过测试，这里会偷偷帮你压缩一下，外部通常格式化的内容都会在这里被压
    const currHtml = editor!.getHtml();

    if (DEBUG) {
      console.log('【wang】onChange: ', currHtml.slice(0, DEBUG_MAX_STR_LEN));
    }

    // - 内部设置相同的内容，如 <p><br></p>，也会触发 onChange
    //   但是 notify 会阻止自动回显，但是 useEffect diff 会不通过了，会丢失一次内容设置
    if (currHtml !== html) {
      const notNotify = props.onChange?.(currHtml);
      isNotifyOnChange.current = !notNotify;
    }
  };
  // - editor 只会获取第一次 onChange 的引用，会导致闭包拿到 props.value 永远是旧的，用 ref 保持最新
  onChangeRef.current = onChange;

  // 及时销毁 editor ，重要！
  useEffect(() => {
    return () => {
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  return (
    <>
      <div style={{ position: 'relative', border: '1px solid #ccc', zIndex: 100 }}>
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: '1px solid #ccc' }}
        />
        <Editor
          defaultConfig={wangEditorConfig}
          // - 需要手动控制 onChange 的触发时机，结合表单校验
          // value={html}
          onCreated={(editor) => {
            setEditor(editor);
          }}
          onChange={(editor) => {
            onChangeRef.current!(editor);
          }}
          mode="default"
          // TODO: height 过小会有警告，
          // 编辑区域高度 < 300px 这可能会导致 modal hoverbar 定位异常 Textarea height < 300px . This may be cause modal and hoverbar position error r(1)
          style={{ height: '200px', overflowY: 'hidden' }}
        />
      </div>
    </>
  );
};

// export default EmailWangEditor;

// - 暂时先用后者版本，因为样式保留更多，不确定还有什么 bug
export default EmailWangEditorDangerous;
