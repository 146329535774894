/**
 * You can modify this file
 *
 * @version 6
 *
 */
import Axios, {
  AxiosRequestConfig,
  AxiosError,
  AxiosResponse,
  AxiosInstance,
} from "axios";
//@ts-ignore
import qs from "qs";
import { getAxiosBaseUrl, AppMode } from "../config/api.config";
import { getInterceptorByServiceName, AXIOS_TIMEOUT } from "./interceptors";

function toPascalCase(str: string): string {
  return `${str.substring(0, 1).toUpperCase()}${str.substring(1)}`;
}
function replaceWithUpper(str: string, sp: string) {
  let pointArray = str.split(sp);
  pointArray = pointArray.map((point) => toPascalCase(point));

  return pointArray.join("");
}

function formatServiceName2CaptialCase(serviceName: string) {
  ["/", "-", "_", "."].forEach((str) => {
    serviceName = replaceWithUpper(serviceName, str);
  });

  return serviceName.slice(0, 1).toLowerCase() + serviceName.slice(1);
}

const baseConfig: AxiosRequestConfig = {
  paramsSerializer: (param) => qs.stringify(param, { indices: false }),
  timeout: AXIOS_TIMEOUT,
};

let axiosInstance_exKernelAdmin: AxiosInstance;

function getAxiosInstance_exKernelAdmin(security: Security): AxiosInstance {
  if (!axiosInstance_exKernelAdmin) {
    axiosInstance_exKernelAdmin = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "ex-kernel-admin",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName("ex-kernel-admin");

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_exKernelAdmin.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_exKernelAdmin.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_exKernelAdmin;
}

let axiosInstance_mciToolbox: AxiosInstance;

function getAxiosInstance_mciToolbox(security: Security): AxiosInstance {
  if (!axiosInstance_mciToolbox) {
    axiosInstance_mciToolbox = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "mci-toolbox",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName("mci-toolbox");

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_mciToolbox.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_mciToolbox.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_mciToolbox;
}

let axiosInstance_mcexpOperation: AxiosInstance;

function getAxiosInstance_mcexpOperation(security: Security): AxiosInstance {
  if (!axiosInstance_mcexpOperation) {
    axiosInstance_mcexpOperation = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "mcexp-operation",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName("mcexp-operation");

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_mcexpOperation.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_mcexpOperation.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_mcexpOperation;
}

let axiosInstance_mciDict: AxiosInstance;

function getAxiosInstance_mciDict(security: Security): AxiosInstance {
  if (!axiosInstance_mciDict) {
    axiosInstance_mciDict = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "mci-dict",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName("mci-dict");

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_mciDict.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_mciDict.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_mciDict;
}

let axiosInstance_mciUserAuthAdminServer: AxiosInstance;

function getAxiosInstance_mciUserAuthAdminServer(
  security: Security,
): AxiosInstance {
  if (!axiosInstance_mciUserAuthAdminServer) {
    axiosInstance_mciUserAuthAdminServer = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "mci-user-auth-admin-server",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName(
      "mci-user-auth-admin-server",
    );

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_mciUserAuthAdminServer.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_mciUserAuthAdminServer.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_mciUserAuthAdminServer;
}

let axiosInstance_mciArm: AxiosInstance;

function getAxiosInstance_mciArm(security: Security): AxiosInstance {
  if (!axiosInstance_mciArm) {
    axiosInstance_mciArm = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "mci-arm",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName("mci-arm");

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_mciArm.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_mciArm.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_mciArm;
}

let axiosInstance_mciPayFinance: AxiosInstance;

function getAxiosInstance_mciPayFinance(security: Security): AxiosInstance {
  if (!axiosInstance_mciPayFinance) {
    axiosInstance_mciPayFinance = Axios.create({
      ...baseConfig,
      baseURL: getAxiosBaseUrl(
        "mci-pay-finance",
        process.env.REACT_APP_MODE as AppMode,
        process.env.SERVER_NAME!,
      ),
    });

    const interceptors = getInterceptorByServiceName("mci-pay-finance");

    interceptors.forEach((interceptor) => {
      // Response interceptor
      if (interceptor.response) {
        axiosInstance_mciPayFinance.interceptors.response.use(
          interceptor.response.onFulfilled || null,
          interceptor.response.onRejected || null,
        );
      }

      // ًًRequest interceptor
      if (interceptor.request) {
        axiosInstance_mciPayFinance.interceptors.request.use(
          interceptor.request.onFulfilled || null,
          interceptor.request.onRejected || null,
        );
      }
    });
  }

  return axiosInstance_mciPayFinance;
}

const instanceMap = {
  exKernelAdmin: getAxiosInstance_exKernelAdmin,
  mciToolbox: getAxiosInstance_mciToolbox,
  mcexpOperation: getAxiosInstance_mcexpOperation,
  mciDict: getAxiosInstance_mciDict,
  mciUserAuthAdminServer: getAxiosInstance_mciUserAuthAdminServer,
  mciArm: getAxiosInstance_mciArm,
  mciPayFinance: getAxiosInstance_mciPayFinance,
} as { [serviceName: string]: (security: Security) => AxiosInstance };

function getAxiosInstance(security: Security, serviceName: string) {
  const _serviceName = formatServiceName2CaptialCase(serviceName);
  return instanceMap[_serviceName](security);
}

class RequestError extends Error {
  constructor(
    public message: string,
    public status?: number,
    public response?: AxiosResponse,
  ) {
    super(message);
  }

  isApiException = true;

  static isRequestError(error: any): error is RequestError {
    return error.isApiException;
  }
}

export type Security = any[] | undefined;

export type SwaggerResponse<R> = R;

export { getAxiosInstance, RequestError };
