import useMciRequest from '@/api/useMciRequest';
import { Space, App } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleAuthorization } from './RoleAuthorization';
import { ActionType } from '@ant-design/pro-components';
import { access } from '@mci-fe/user-auth';

export const UserOpBtns = (props: { record: any; action: any; ctx: any }) => {
  const { record } = props;
  const { message } = App.useApp();
  const navigate = useNavigate();
  const [moidfyOpen, setModifyOpen] = useState(false);
  const [channelOpen, setChannelOpen] = useState(false);

  // TOOD: 操作列的 action 和 protable 的 action 有点重名了
  const tableAction: ActionType = props.ctx?.actionRef.current;

  const closeDialog = () => {
    setModifyOpen(false);
    setChannelOpen(false);
  };

  // -- modify --
  const onClickModify = () => {
    setModifyOpen(true);
  };

  const onModifyFinish = () => {
    message.success('授权完成');
    tableAction?.reload();
    closeDialog();
  };

  const onModifyCancel = () => {
    closeDialog();
  };

  return (
    <Space>
      {/* TODO: 使用 a 标签一直有一个警告，但是用 span 又没有 antd 的样式 */}

      {access.saveUserRole && <a onClick={onClickModify}>角色授权</a>}

      {moidfyOpen && (
        <RoleAuthorization
          isModalOpen={moidfyOpen}
          record={record}
          onFinish={onModifyFinish}
          onCancel={onModifyCancel}
        />
      )}

      {/* TEST */}
      {/* {record.id === 0 && <ContractModify />} */}
      {/* {record.id === 0 && <ContractChannel />} */}
    </Space>
  );
};
