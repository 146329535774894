import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { BetaSchemaForm, ProFormInstance, ProFormColumnsType } from '@ant-design/pro-components';
import { Checkbox } from 'antd';
import {
  getUserV1GetRoleByUserId_mciUserAuthAdminServer,
  getSysRoleList_mciUserAuthAdminServer,
  postUserV1SaveUserRole_mciUserAuthAdminServer,
} from '@/api/services';
import { RoleInfoResp, SystemRoleResp, RoleInfoDto, UserBaseInfoResp } from '@/api/types';

import type { GetProp } from 'antd';

export interface ContractModifyProps {
  // 新增时候需要跳转到渠道，之类带过去
  onFinish?: (contractEntity?: any) => void;
  onCancel?: () => void;
  isModalOpen: boolean;
  record: UserBaseInfoResp;
}

export const RoleAuthorization: FC<ContractModifyProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<ProFormInstance>();

  const [sysRoleList, setSysRoleList] = useState<RoleInfoResp[]>([]);
  const [userRoleInfo, setUserRoleInfo] = useState<RoleInfoDto[]>([]);
  const [chooseRoleCode, setChooseRoleCode] = useState<string[]>([]);

  const handleFinish = async (values: any) => {
    console.log('handleFinish', values);
    saveUserRole();
  };

  const optionsWithRoleList = useMemo(() => {
    return sysRoleList.map((item) => ({
      label: item.roleName as string,
      value: item.roleCode as string,
    }));
  }, [sysRoleList]);

  const defaultRoleValueMemo = useMemo(() => {
    let roleCodeSet = new Set<string>();
    if (userRoleInfo && userRoleInfo.length > 0) {
      for (let i = 0; i < userRoleInfo.length; i++) {
        roleCodeSet.add(userRoleInfo[i]?.roleId ?? '');
      }
    }

    return Array.from(roleCodeSet);
  }, [userRoleInfo]);

  const onChange = (checkedValues: string[]) => {
    setChooseRoleCode(checkedValues);
  };

  const columns: ProFormColumnsType<any>[] = [
    // {
    //   title: '角色编号',
    //   dataIndex: ['roleCode'],
    //   fieldProps: {
    //     // disabled: true,
    //     placeholder: '请输入角色编号',
    //   },
    // },
    {
      title: '',
      colProps: { span: 24 },
      // valueType: 'checkbox',
      // readonly: true,
      // formItemProps: {
      //   colon: false,
      //   hidden: true,
      // },
      renderFormItem: (schema) => (
        <>
          <Checkbox.Group
            options={optionsWithRoleList}
            defaultValue={defaultRoleValueMemo}
            key={JSON.stringify(defaultRoleValueMemo)}
            onChange={onChange}
          />
        </>
      ),
    },
  ];

  async function getUserRoleInfo() {
    setLoading(true);
    try {
      const { data } = await getUserV1GetRoleByUserId_mciUserAuthAdminServer({
        queryParams: { userId: props.record.userId ?? '' },
      });

      let currentSysRoleList: RoleInfoDto[] = data?.filter((item) => item.systemCode === '10000') ?? [];
      if (currentSysRoleList && currentSysRoleList?.length > 0) {
        setUserRoleInfo(currentSysRoleList);
      }
    } catch (error) {}
    setLoading(false);
  }

  async function getSysRoleList() {
    setLoading(true);
    try {
      const { data } = await getSysRoleList_mciUserAuthAdminServer();

      const currentSysRole: SystemRoleResp | undefined =
        data && data.length > 0 ? data.find((item) => item.systemCode === '10000') : {};
      setSysRoleList(currentSysRole?.roleInfos ?? []);
    } catch (error) {}
    setLoading(false);
  }

  async function saveUserRole() {
    setLoading(true);
    let error = '';
    await postUserV1SaveUserRole_mciUserAuthAdminServer({
      requestBody: {
        systemCode: '10000',
        userId: props.record.userId ?? '',
        roleIds: chooseRoleCode,
      },
    }).catch((e) => {
      error = e.message;
    });

    setLoading(false);
    if (!error) {
      props.onFinish?.();
    }
  }

  useEffect(() => {
    getSysRoleList();
    getUserRoleInfo();
  }, []);

  useEffect(
    function syncOwnedRoles() {
      if (Array.isArray(defaultRoleValueMemo) && defaultRoleValueMemo.length > 0) {
        setChooseRoleCode(defaultRoleValueMemo);
      }
    },
    [defaultRoleValueMemo],
  );

  return (
    <BetaSchemaForm
      title={'角色授权'}
      formRef={formRef}
      layout="horizontal"
      layoutType="ModalForm"
      style={{ marginTop: 20 }}
      // labelCol="auto"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 12 }}
      colProps={{ span: 12 }}
      // submitter={{}}
      loading={loading}
      open={true}
      grid={true}
      rowProps={{ gutter: 24 }}
      columns={columns}
      // initialValues={initialValues}
      onFinish={handleFinish}
      modalProps={{
        onCancel: () => {
          props.onCancel?.();
        },
      }}
    />
  );
};
