import { IEditorConfig } from '@wangeditor/editor';

const defaultFontFamily = [
  '黑体',
  {
    name: '仿宋',
    value: '仿宋',
  },
  '楷体',
  '标楷体',
  '华文仿宋',
  '华文楷体',
  {
    name: '宋体',
    value: '宋体',
  },
  '微软雅黑',
  'Arial',
  'Tahoma',
  'Verdana',
  'Times New Roman',
  'Courier New',
];

const fontFamilyList = [...defaultFontFamily, 'Calibri'];

/** wang编辑器配置 */
export const wangEditorConfig: Partial<IEditorConfig> = {
  placeholder: '请输入内容...',
  // scroll: false,
  autoFocus: false,

  MENU_CONF: {
    fontSize: {
      // - 字体需要配置了尺寸才能用，否则会直接剔除掉那个标签
      fontSizeList: Array.from({ length: 50 }, (_, i) => i + 1 + 'px'),
    },
    fontFamily: {
      fontFamilyList,
    },
  },
};
