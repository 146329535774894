const MAP_STATUS_2_TEXT = {
  0: '待提交',
  1: '待审核',
  2: '审核通过',
  3: '已拒绝',
  4: '驳回',
};
export function AuditStatusName({ record, value }: { value: any; record: any }) {
  if (typeof record.auditStatus !== 'number') return <span>--</span>;

  return <span>{MAP_STATUS_2_TEXT[record.auditStatus as keyof typeof MAP_STATUS_2_TEXT]}</span>;
}
