import { Button, Modal } from 'antd';
import { FC } from 'react';

export interface EmailEditConfirmProps {
  loading?: boolean;
  onCancel?: () => void;
  onOk?: () => void;
}

export const EmailEditConfirm: FC<EmailEditConfirmProps> = (props) => {
  const [modal, contextHolder] = Modal.useModal();

  return (
    <>
      <Button
        loading={props.loading}
        onClick={() => {
          modal.confirm({
            title: '注意',
            content: '如你需要修改邮件正文中的数据，请留意同步邮件内的数据，确认编辑邮件内容？',
            onOk: props.onOk,
          });
        }}>
        编辑
      </Button>
      {contextHolder}
    </>
  );
};
