import useMciRequest from '@/api/useMciRequest';
import { BetaSchemaForm, ProFormColumnsType } from '@ant-design/pro-components';
import { FormInstance } from 'antd/lib';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import EmailUpload, { EmailUploadFile } from './EmailUpload';
import EmailWangEditor from './EmailWangEditor';
import { Button, Modal, Space, Spin, message } from 'antd';
import { EmailEditConfirm } from './EmailEditConfirm';
import { postMailContentDeleteAttachment_exKernelAdmin, postMailContentUpdate_exKernelAdmin } from '@/api/services';
import { MailContentDetailDTO } from '@/api/types';
import { mock_mailInfo, mock_query, mock_mailAttachmentDelete } from './test/mock';
import { WangEditorUtils } from './WangEditorUtils';
import { downloadString } from '@/utils/oss-util';

export interface EmailAuditProps {
  id: string;
  open: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  viewMode?: boolean;
}

interface MailInfo {
  hasDocType: boolean;
  hasHtml: boolean;
  hasBody: boolean;
  styleText: string;
}

const DEBUG_HTML = false;

const EmailAudit = (props: EmailAuditProps) => {
  const [isEditUnlock, setIsEditUnlock] = useState(false);
  const formRef = useRef<FormInstance>();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [modalApi, modalContextHolder] = Modal.useModal();

  const mailInfoRef = useRef<MailInfo>({ hasDocType: false, hasHtml: false, hasBody: false, styleText: '' });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const {
    data,
    loading: dataLoading,
    run,
  } = useMciRequest('getMailContentId_exKernelAdmin', undefined, {
    manual: true,
  });
  useEffect(() => {
    // TODO: api 参数放到两个地方了，感觉 run 不需要传递参数合适一些
    if (props.id && props.open) {
      run({
        pathParams: {
          id: props.id,
        },
      });
    }
    // TODO: 重复打开是否要刷新数据？
  }, [props.id, props.open]);

  // data = mock_mailInfo;

  const loading = dataLoading || submitLoading || isRemoving;
  const viewMode = props.viewMode || false;
  const canEdit = !viewMode && isEditUnlock && !(dataLoading || submitLoading); // 提交，和获取数据时候禁止编辑
  const disabled = viewMode ? false : !canEdit; // 只读模式不要禁用
  const readonly = viewMode;

  const removeFile = async (file: EmailUploadFile) => {
    setIsRemoving(true);

    // make loading
    let newAttachment: EmailUploadFile[] = [...(formRef.current?.getFieldValue('mailAttachment') || [])];
    for (let i = 0; i < newAttachment.length; i++) {
      if (newAttachment[i].filePath === file.filePath) {
        newAttachment[i].status = 'uploading';
        break;
      }
    }
    formRef.current?.setFieldsValue({ mailAttachment: newAttachment });

    let errMsg = '';
    try {
      const res = await postMailContentDeleteAttachment_exKernelAdmin({
        requestBody: {
          id: props.id,
          mailAttachment: {
            fileName: file.fileName,
            fileType: file.fileType,
            filePath: file.filePath,
          },
        },
      });

      // - [Mock]
      // const res = await new Promise<any>((resolve) => {
      //   console.warn('[mock] delete file');
      //   setTimeout(() => {
      //     resolve(mock_mailAttachmentDelete);
      //   }, 200);
      // });

      if (res.code !== '0000') {
        errMsg = res.message!;
      }
    } catch (error) {
      errMsg = error + '';
    }

    if (errMsg) {
      messageApi.error(`删除失败: ${errMsg}`);
    } else {
      const mailAttachment = formRef.current?.getFieldValue('mailAttachment') || [];
      const newAttachment = mailAttachment.filter((item: any) => item.filePath !== file.filePath);

      // TODO: 验证
      formRef.current!.setFieldsValue({
        mailAttachment: newAttachment,
      });
    }
    setIsRemoving(false);
  };

  const handleRemoveFile = (file: EmailUploadFile) => {
    if (isRemoving) {
      messageApi.error('正在删除中，请稍后');
      return false;
    }
    modalApi.confirm({
      title: '提示',
      content: '确认删除该附件？此行为不可撤销！',
      onOk: () => {
        removeFile(file);
      },
    });
    return false; // 通过异步删除
  };

  const columns: ProFormColumnsType[] = [
    // 下面这三个默认不能修改，都是只读的
    {
      title: '会员名称',
      dataIndex: 'memberName',
      colProps: { span: 8 },
      readonly: true,
    },
    {
      title: '生成时间',
      dataIndex: 'createTime',
      valueType: 'dateTime',
      colProps: { span: 8 },
      readonly: true,
    },
    {
      title: '关联交易账户ID',
      dataIndex: 'tradingAccountCode',
      colProps: { span: 8 },
      readonly: true,
    },
    {
      title: '收件地址',
      dataIndex: 'toMail',
      valueType: 'select',
      fieldProps: {
        placeholder: '多个编码之间用空格或者英文分号分隔',
        mode: 'tags',
        tokenSeparators: [' ', ';'],
        open: false,
        disabled,
      },
      // TODO: readonly 模式有点丑，看看能否变成 tag 模式
      readonly,
      formItemProps: {
        // tooltip: '多个编码之间用空格或者英文分号分隔',
        required: false,
        rules: [
          {
            required: true,
            message: '请输入收件地址',
          },
          {
            validator: async (rule, value) => {
              if (!value || !value.length) return;
              const reg = /[\w.]+@[\w.]+/; // TODO: 弄一个更严格的？
              for (let i = 0; i < value.length; i++) {
                if (!reg.test(value[i])) {
                  return Promise.reject('请输入正确的邮箱地址');
                }
              }
            },
          },
        ],
      },
    },
    {
      title: '主题',
      dataIndex: 'mailTitle',
      fieldProps: {
        placeholder: '请输入邮件主题',
        disabled,
      },
      readonly,
      formItemProps: {
        required: false,
        rules: [
          {
            required: true,
            message: '请输入邮件主题',
          },
        ],
      },
    },
    {
      title: '附件',
      dataIndex: 'mailAttachment',
      // TODO: 动态加载会导致布局突然变化，这里需要优化
      renderFormItem: (schema, config, form) => {
        return <EmailUpload {...schema} onRemove={handleRemoveFile} maxCount={5} />;
      },
      fieldProps: {
        disabled: readonly || disabled,
      },
      // readonly, // 自定义渲染，不需要 readonly，内容不会渲染 renderFormItem
    },
    {
      title: '正文',
      dataIndex: 'mailContent',
      renderFormItem: (schema, config, form) => {
        return <EmailWangEditor {...schema} />;
      },
      fieldProps: {
        disabled: readonly || disabled,
      },
      formItemProps: {
        required: false,
        rules: [
          {
            async validator(rule, value) {
              if (WangEditorUtils.isEmptyHtml(value)) {
                return Promise.reject('请输入邮件内容');
              }

              // - 这个限制做在编辑器内不合适，因为 html 不能被夹断，解析会有问题
              // - 暂时关闭了，因为 html 本身也有很多符号，不好判断，很容易超出，而且内容有的来自后端的
              // const MAX_EMAIL_LEN = 20000;
              // if (value.length > MAX_EMAIL_LEN) {
              //   console.log('mail value.length', value.length);
              //   return Promise.reject(`邮件内容不能超过${MAX_EMAIL_LEN}个字符`);
              // }
            },
          },
        ],
      },
    },
  ];

  useEffect(() => {
    if (data) {
      const mailContent = data.data?.mailContent! || '';

      if (DEBUG_HTML) {
        console.log('fetch mailContent', mailContent);
        // downloadString(mailContent, 'mail-fetch.html');
      }

      const curr = mailInfoRef.current;
      const domParser = new DOMParser();
      const doc = domParser.parseFromString(mailContent, 'text/html');

      // 需要注意点
      // 1. 移除注释节点，不然编辑器转为很多垃圾数据
      // 2. 不能有不闭合的标签，如 img
      // 3. div自动给转换成 p
      // 4. 顶部最好都是 p，除非只有一个节点，不能单独有一个 strong
      // 5. 如果顶部节点不是 p，会自动包裹一个
      // 6. 不能很好的识别，DOCTYPE，html，body，最好 unwrap 掉

      curr.hasDocType = mailContent.includes('<!DOCTYPE html>');
      curr.hasHtml = mailContent.includes('<html>');
      curr.hasBody = mailContent.includes('<body>');

      // -- extract style --
      // NOTE: 主要解决 table 行内样式丢失问题，wangeditor会直接干掉，因此只能加到style上了
      //       目前只支持一个 style 标签
      let styleText = '';
      const style = doc.querySelector('style');
      if (style) {
        styleText = style.innerHTML;
      }
      curr.styleText = styleText;

      let newMailContent = doc.body.innerHTML;

      formRef.current?.setFieldsValue({
        ...data.data,
        mailContent: newMailContent,
      });
    }
  }, [data]);

  const handleSubmit = (opts: { onOk?: () => void }) => {
    modalApi.confirm({
      title: '提示',
      content: '确认发送该邮件？',
      onOk: () => {
        opts.onOk?.();
      },
      maskClosable: true,
    });
  };

  return (
    <>
      <BetaSchemaForm
        loading={loading}
        title={'交易确认邮件'}
        open={props.open}
        layoutType="ModalForm"
        layout="horizontal"
        columns={columns as any}
        // colProps={{ span: 24 }}
        grid={true}
        // TODO: 如何单独控制每个子项
        labelCol={{ style: { width: 80 } }}
        // TODO: 还有一个 form 字段，这个字段是做什么的
        formRef={formRef}
        labelWrap={true}
        onOpenChange={(state) => {
          if (state === false) {
            props.onClose();
          }
        }}
        submitter={{
          searchConfig: {
            submitText: '通过并发送',
          },
          resetButtonProps: {
            loading,
          },
          render: (props, dom) => {
            if (viewMode) return [];
            const EditButton = !isEditUnlock && (
              <EmailEditConfirm key="edit" loading={loading} onOk={() => setIsEditUnlock(true)} />
            );

            const _resetProps = props.resetButtonProps as any;
            const resetProps = {
              disabled: _resetProps.disabled,
              onClick: _resetProps.onClick,
              // preventDefault: _resetProps.preventDefault,
            };
            const _subimtProps = props.submitButtonProps as any;
            const submitProps = {
              loading: _subimtProps.loading,
              // onClick: _subimtProps.onClick,
              onClick: () => {
                handleSubmit({
                  onOk: props.submit,
                });
              },
            };
            const ResetButton = (
              <Button key="reset" {...resetProps}>
                关闭
              </Button>
            );
            const SubmitButton = (
              <Button key="submit" {...submitProps} type="primary">
                提交
              </Button>
            );
            return (
              <>
                <Space>{[EditButton, ResetButton, SubmitButton]}</Space>
              </>
            );
          },
        }}
        onFinish={async (values: MailContentDetailDTO) => {
          if (viewMode) {
            props.onClose();
            return;
          }

          const mailAttachment = values.mailAttachment!.map((item: any) => {
            // 省略了一些不必要的 upload 信息

            if (!item.filePath) {
              throw new Error('文件路径不存在');
            }

            return {
              fileName: item.fileName,
              filePath: item.filePath,
              fileType: item.fileType,
            };
          });

          setSubmitLoading(true);

          let mailContent = values.mailContent;
          if (mailInfoRef.current.hasBody) {
            mailContent = `<body>${mailContent}</body>`;
          }
          if (mailInfoRef.current.styleText) {
            mailContent = `<head><style>${mailInfoRef.current.styleText}</style></head>${mailContent}`;
          }
          if (mailInfoRef.current.hasHtml) {
            mailContent = `<html>${mailContent}</html>`;
          }
          if (mailInfoRef.current.hasDocType) {
            mailContent = `<!DOCTYPE html>${mailContent}`;
          }

          let error = '';
          const requestBody = {
            id: props.id,
            toMail: values.toMail,
            mailTitle: values.mailTitle,
            mailAttachment,
            mailContent: mailContent,
          };

          if (DEBUG_HTML) {
            // downloadString(mailContent!, 'mail-update.html');
            console.log('submit mailContent', mailContent);
            setSubmitLoading(false);
            return;
          }

          const resp = await postMailContentUpdate_exKernelAdmin({
            requestBody,
          }).catch((e) => (error = e.message));

          setSubmitLoading(false);
          if (!error) {
            messageApi.success('邮件已成功通过并发送');
            props.onClose();
            props.onSubmit?.();
          }
        }}
        // TODO: 有 bug，期望内部内容滚动目前样式有点问题
        modalProps={{
          // style: {
          //   // background: 'red',
          //   overflow: 'auto',
          //   height: 'calc(100vh - 100px)',
          // },

          // - 没有解编辑可以点击蒙层关闭
          maskClosable: viewMode || !isEditUnlock,
        }}
      />
      {messageContextHolder}
      {modalContextHolder}
    </>
  );
};

export default EmailAudit;
