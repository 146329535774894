import { useEffect } from "react";
import { useRequest } from "ahooks";
import type { Options } from "ahooks/es/useRequest/src/types";
import { AXIOS_TIMEOUT } from "./interceptors";
import { servicesMap } from "./services";
import type { ServiceName, ServicesMap } from "./services";

type ServiceParams<S extends ServiceName> = Parameters<ServicesMap[S]>[0];
type ServiceResult<S extends ServiceName> =
  ReturnType<ServicesMap[S]> extends Promise<infer R> ? R : never;
type UseRequestOptions<S extends ServiceName> = Options<
  ServiceResult<S>,
  [ServiceParams<S>]
>;
type OtherOptions = {
  isJoinGlobalLoading?: boolean;
  loadingHintText?: string;
};
export type RequestOptions<S extends ServiceName> = UseRequestOptions<S> &
  OtherOptions;

function showGlobalLoading(fullApiUrl: string, hintText?: string) {
  if (window.mci_global_loading_modal) {
    window.mci_global_loading_modal.request2Show(fullApiUrl, hintText);
  }
}

function hideGlobalLoading(fullApiUrl: string) {
  if (window.mci_global_loading_modal) {
    window.mci_global_loading_modal.request2Hide(fullApiUrl);
  }
}

/**
 * serviceMap的二次封装
 *
 * @desc 主要功能
 * 1. 全局loading
 * 2. aHook 的全部功能（loading，manual，data)
 *
 */
export default function useMciRequest<S extends ServiceName>(
  serviceName: S,
  serviceParams?: ServiceParams<S>,
  requestOptions?: RequestOptions<S>,
) {
  const {
    isJoinGlobalLoading = true,
    loadingHintText,
    ...useRequestOptions
  } = requestOptions || {};

  const fullApiUrl = `${servicesMap[serviceName].backendServiceName}${servicesMap[serviceName].key}`;
  const serviceHandler = servicesMap[serviceName];

  const { loading, ...restProps } = useRequest((...params: any[]) => {
    const finalParams =
      Array.isArray(params) && params.length > 0 ? params : [serviceParams];
    //@ts-ignore
    return serviceHandler.apply(null, finalParams) as Promise<ServiceResult<S>>;
  }, useRequestOptions);

  useEffect(
    function () {
      if (isJoinGlobalLoading) {
        if (loading) {
          showGlobalLoading(fullApiUrl, loadingHintText);
          // 做个兜底处理，无论发生了什么情况，AXIOS_TIMEOUT 后就关闭 loading
          setTimeout(() => {
            hideGlobalLoading(fullApiUrl);
          }, AXIOS_TIMEOUT);
        } else {
          hideGlobalLoading(fullApiUrl);
        }

        return () => {
          hideGlobalLoading(fullApiUrl);
        };
      }
    },
    [loading, fullApiUrl, isJoinGlobalLoading, loadingHintText],
  );

  return {
    loading,
    ...restProps,
  };
}
