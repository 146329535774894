import { ProColumns } from '@ant-design/pro-components';

/**
 * 批量弹窗表格字段
 */

// 汇总字段
export const columnsSummary: ProColumns<any>[] = [
  {
    title: '序号',
    dataIndex: 'index',
    valueType: 'indexBorder',
    width: 80,
  },
  {
    title: '交易账号',
    dataIndex: 'tradingAccountCode',
    width: 120,
  },
  {
    title: '币种',
    dataIndex: 'currency',
    width: 120,
  },
  {
    title: '交易类别/方向',
    dataIndex: 'orderDirection',
    width: 120,
    render: (_, record) => {
      let str = `${record.productType === 1 ? 'DRO' : 'DRP'}
                  -${record.offeringStage === 1 ? 'IO' : record.offeringStage === 2 ? 'AO' : 'SO'}
                  -${record.orderDirection === 0 ? '卖' : '买'}`;
      return str;
    },
  },
  {
    title: '总金额',
    dataIndex: 'totalMoney',
    width: 120,
    valueType: {
      type: 'money',
      moneySymbol: false,
      precision: 2,
    },
  },
  {
    title: '总笔数',
    dataIndex: 'totalStrokes',
    width: 120,
  },
];

// IOAOSO——明细字段
export const columnsDetail: ProColumns<any>[] = [
  {
    title: '序号',
    dataIndex: 'index',
    valueType: 'indexBorder',
    width: 80,
    fixed: 'left',
  },
  {
    title: '批次号',
    dataIndex: 'batchId',
    width: 120,
  },
  {
    title: '订单号',
    dataIndex: 'orderCode',
    width: 120,
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
    width: 120,
  },
  {
    title: '会员名称',
    dataIndex: 'identificationName',
    width: 120,
  },
  {
    title: '产品分类',
    dataIndex: 'productType',
    width: 120,
    valueEnum: {
      1: { text: 'DRO' },
      2: { text: 'DRP' },
    },
  },
  {
    title: '份额',
    dataIndex: 'shares',
    width: 120,
  },
  {
    title: '单价',
    dataIndex: 'price',
    width: 120,
  },
  {
    title: '审批状态',
    dataIndex: 'auditStatus',
    valueEnum: {
      0: '待审批',
      1: '通过',
      2: '审批驳回',
      3: '取消',
    },
    width: 120,
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    valueEnum: {
      '-1': '下单失败',
      '1': '有效',
      '5': '取消',
      '4': '未成交',
      '2': '部分成交',
      '3': '全部成交',
    },
    width: 120,
  },
];

// 大宗交易——明细字段
export const columnsBatchDetail: ProColumns<any>[] = [
  {
    title: '序号',
    dataIndex: 'index',
    valueType: 'indexBorder',
    width: 80,
    fixed: 'left',
  },
  {
    title: '批次号',
    dataIndex: 'batchId',
    width: 120,
  },
  {
    title: '订单号',
    dataIndex: 'orderCodeName',
    width: 120,
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
    width: 120,
  },
  {
    title: '卖方会员名称',
    dataIndex: 'identificationNameSell',
    width: 120,
  },
  {
    title: '产品分类',
    dataIndex: 'productType',
    width: 120,
    valueEnum: {
      1: { text: 'DRO' },
      2: { text: 'DRP' },
    },
  },
  {
    title: '份额',
    dataIndex: 'shares',
    width: 120,
  },
  {
    title: '金额',
    dataIndex: 'amount',
    width: 120,
  },
  {
    title: '买方会员名称',
    dataIndex: 'identificationNameBuy',
    width: 120,
  },
  {
    title: '审批状态',
    dataIndex: 'auditStatus',
    valueEnum: {
      0: '待审批',
      1: '通过',
      2: '审批驳回',
      3: '取消',
    },
    width: 120,
  },
  {
    title: '订单状态',
    dataIndex: 'orderStatus',
    valueEnum: {
      '-1': '下单失败',
      '1': '有效',
      '5': '取消',
      '4': '未成交',
      '2': '部分成交',
      '3': '全部成交',
    },
    width: 120,
  },
];

// IOAO——批量下单-校验字段
export const columnsIOAO: ProColumns<any>[] = [
  {
    title: '序号',
    dataIndex: 'index',
    valueType: 'indexBorder',
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
  },
  {
    title: '会员ID',
    dataIndex: 'memberCode',
  },
  {
    title: '交易账号',
    dataIndex: 'tradingAccountCode',
  },
  {
    title: '份额',
    dataIndex: 'shares',
  },
];

// SO——批量下单-校验字段
export const columnsSO: ProColumns<any>[] = [
  {
    title: '序号',
    dataIndex: 'index',
    valueType: 'indexBorder',
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
  },
  {
    title: '会员ID',
    dataIndex: 'memberCode',
  },
  {
    title: '交易账号',
    dataIndex: 'tradingAccountCode',
  },
  {
    title: '交易方向',
    dataIndex: 'orderDirection',
    width: 100,
    valueEnum: {
      0: '卖',
      1: '买',
    },
  },
  {
    title: '单价',
    dataIndex: 'price',
  },
  {
    title: '份额',
    dataIndex: 'shares',
  },
];

// 大宗交易——批量下单-校验字段
export const columnsBatch: ProColumns<any>[] = [
  {
    title: '卖方',
    dataIndex: 'sold',
    children: [
      {
        title: '序号',
        dataIndex: 'index',
        valueType: 'indexBorder',
        width: 80,
      },
      {
        title: '产品编码',
        dataIndex: 'productCode',
        width: 120,
      },
      {
        title: '会员ID',
        dataIndex: 'memberCodeSell',
        width: 120,
      },
      {
        title: '交易账号',
        dataIndex: 'tradingAccountCodeSell',
        width: 120,
      },
      {
        title: '金额',
        // TODO: 不含手续费，要去对一下这个到底是什么
        dataIndex: 'amount',
        width: 100,
      },
      {
        title: '份额',
        dataIndex: 'shares',
        width: 100,
      },
    ],
  },
  {
    title: '买方',
    dataIndex: 'buy',
    children: [
      {
        title: '会员ID',
        dataIndex: 'memberCodeBuy',
        width: 120,
      },
      {
        title: '交易账号',
        dataIndex: 'tradingAccountCodeBuy',
        width: 120,
      },
    ],
  },
];
