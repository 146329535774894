import { FundingInvestResp } from '@/api/types';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

export interface InvestDetailBtnProps {
  record?: FundingInvestResp;
}

export const InvestDetailBtn: FC<InvestDetailBtnProps> = (props) => {
  const navigate = useNavigate();

  const onClickDetail = () => {
    // console.log('record', record);
    navigate(`/investmentManagement/periodList?exDroCode=${props.record?.exDroCode}`);
  };

  return <a onClick={onClickDetail}>详情</a>;
};
