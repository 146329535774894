import { Button } from 'antd';
import { useState } from 'react';
import EmailAudit from './EmailAudit';
import { access } from '@mci-fe/user-auth';

export const EmailAuditButton = (props: { record: any; action: any; ctx: any }) => {
  const [open, setOpen] = useState(false);
  const { record } = props;

  const handleOnSubmit = () => {
    props.ctx.actionRef.current?.reload();
  };

  const viewMode = props.action?.nameRendererProps?.viewMode;
  const buttonText = viewMode ? '查看' : '审核';
  const type = props.action?.nameRendererProps?.type;
  // 关闭后，id 重置，刷新组件
  const id = open ? record.id : '';

  const canView = () => {
    if (type=== 'sent' && access.sentDetail ) {
      return true;
    }
    if (type=== 'toSent' && access.toBeSentAudit) {
      return true;
    }
    return false;
  };

  return (
    <>
      {canView() && (
        <Button
          onClick={() => {
            setOpen(true);
          }}
          style={{ padding: 0 }}
          type="link">
          {buttonText}
        </Button>
      )}

      {/* <EmailAudit id={id} open={open} viewMode={viewMode} onClose={() => setOpen(false)} onSubmit={handleOnSubmit} /> */}

      {/* TODO: 关闭后内容状态需要全部重置了（输入表单，富文本滚动），但是表单状态会保留，设置 id 变化也无效 */}
      {!!id && (
        <EmailAudit id={id} open={true} viewMode={viewMode} onClose={() => setOpen(false)} onSubmit={handleOnSubmit} />
      )}
    </>
  );
};
