import { AccountInfoRsp, ContractEntityInfoSaveReq, ContractEntityInfoRsp, ContractEntity } from '@/api/types';
import {
  BetaSchemaForm,
  ProColumns,
  ProColumnsValueType,
  ProFormColumnsType,
  ProFormInstance,
} from '@ant-design/pro-components';
import { App, Divider, SelectProps, Spin } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { mock_contractInfo, mock_contractInfoDetail } from '../__mock__';
import { sleep } from '@mci-fe/utils';
import {
  getContractGetContractEntityByIdId_mcexpOperation,
  getContractListSubjectInfo_mcexpOperation,
  postContractSave_mcexpOperation,
  postContractUpdateContractChannelRelation_mcexpOperation,
  postContractUpdate_mcexpOperation,
} from '@/api/services';
import { contract_ent_type_enum } from '../../type';

export interface ContractModifyProps {
  // 列表数据，如果传递了从列表项目进来的修改，否则是新增
  record?: ContractEntity;

  // 新增时候需要跳转到渠道，之类带过去
  onFinish?: (contractEntity?: ContractEntity) => void;
  onCancel?: () => void;
}

/**
 * 修改签约主体信息
 *
 * TODO: 这个名字不太合适，因为新增也在这个范畴，看修改下
 *
 * TODO: 控制台警告
 * Warning: [antd: Select] `bordered` is deprecated. Please use `variant` instead.
 * Warning: [antd: DatePicker.RangePicker] `bordered` is deprecated. Please use `variant` instead.
 */
export const ContractModify: FC<ContractModifyProps> = (props) => {
  const isCreate = props.record === undefined;

  const [contractInfoList, setContractInfoList] = useState<ContractEntityInfoRsp[]>([]);
  const orgIdOptions = useRef<SelectProps['options']>([]);

  const [orgId, setOrgId] = useState<string>();
  const [entAddressType, setEntAddressType] = useState<number>();
  const formRef = useRef<ProFormInstance>();
  const [loading, setLoading] = useState(false);
  const { enumDict: contractEntType } = useEnum('CONTRACT_ENT_TYPE');

  const { message } = App.useApp();

  const contractInfo: ContractEntityInfoRsp | undefined = orgId
    ? contractInfoList.find((item) => item.orgId === orgId)
    : undefined;

  // 银行账户列表
  const accountOptions: SelectProps['options'] = [];
  if (contractInfo && entAddressType !== undefined) {
    for (let i = 0; i < contractInfo.accountInfoList!.length; i++) {
      const item = contractInfo.accountInfoList![i];
      if (item.entAddressType === entAddressType) {
        accountOptions.push({
          value: item.bankName,
          label: item.bankName,
        });
      }
    }
  }

  const isForeignAdress = entAddressType === 1;

  /** 新增的输入提示 */
  const fetchContractInfo = async () => {
    setLoading(true);

    const resp = await getContractListSubjectInfo_mcexpOperation();

    // -- mock --
    // await sleep(200);
    // const resp = mock_contractInfo;
    // console.warn('mock');

    setLoading(false);

    // 是否保留已经存在的主体编码，禁用展示
    const preserveExist = true;

    let options: SelectProps['options'];
    let hasFreeOrgId: boolean;
    if (preserveExist) {
      options = resp.data!.map((item) => ({
        value: item.orgId,
        label: item.orgId,
        disabled: item.exist === 1,
      }));
      options.sort((a, b) => (a.disabled ? 1 : -1));

      hasFreeOrgId = options.some((item) => !item.disabled);
    } else {
      options = resp
        .data!.filter((item) => item.exist !== 1)
        .map((item) => ({
          value: item.orgId,
          label: item.orgId,
        }));

      hasFreeOrgId = options.length > 0;
    }

    if (!hasFreeOrgId) {
      message.error('查询不到可用的主体编码，请联系管理员');
    }

    setContractInfoList(resp.data!);
    orgIdOptions.current = options;
  };

  /** 修改回显 */
  const fetchContractDetail = async () => {
    setLoading(true);

    const resp = await getContractGetContractEntityByIdId_mcexpOperation({
      pathParams: {
        id: props.record!.id!,
      },
    });

    // -- mock --
    // await sleep(200);
    // const resp = mock_contractInfoDetail;
    // console.warn('mock');

    setLoading(false);

    const data = resp.data!;
    const contractEntity = data.contractEntity!;
    const entBankAccount = data.entBankAccount!;

    setEntAddressType(contractEntity.entAddressType);

    formRef.current!.setFieldsValue({
      contractEntitySaveReq: {
        orgId: contractEntity.orgId,
        entAddressType: contractEntity.entAddressType,
        entType: contractEntity.entType,
        entName: contractEntity.entName,
        entCreditCode: contractEntity.entCreditCode,
        entAddress: contractEntity.entAddress,
        principal: contractEntity.principal,
      },
      entBankAccountSaveReq: {
        bankName: entBankAccount.bankName,
        bankAccountName: entBankAccount.bankAccountName,
        bankAccountCode: entBankAccount.bankAccountCode,
        subBankName: entBankAccount.subBankName,
        unionBankNo: entBankAccount.unionBankNo,
      },
    });
  };

  useEffect(() => {
    isCreate ? fetchContractInfo() : fetchContractDetail();
  }, []);

  // TEST
  // (window as any).formRef = formRef;

  // select 默认还会过滤value，这里仅过滤 label
  const filterLabel: SelectProps['filterOption'] = (input, option) => {
    if (!option) return false;
    return (option.label as string)!.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  };

  const clearBankForm = () => {
    formRef.current!.setFieldsValue({
      entBankAccountSaveReq: {
        bankName: undefined,
        bankAccountName: undefined,
        bankAccountCode: undefined,
        subBankName: undefined,
        unionBankNo: undefined,
      },
    });
  };

  const handleOrgIdChange = (value: string) => {
    clearBankForm();
    setOrgId(value);
  };

  const handleAddressTypeChange = (value: number) => {
    clearBankForm();
    setEntAddressType(+value);
  };

  const handleBankNameChange = (value: string) => {
    if (value) {
      const accountInfo: AccountInfoRsp = contractInfo!.accountInfoList!.find((item) => item.bankName === value)!;
      formRef.current!.setFieldsValue({
        entBankAccountSaveReq: {
          bankAccountName: accountInfo.bankAccountName,
          bankAccountCode: accountInfo.bankAccountCode,
          subBankName: accountInfo.subBankName,
          unionBankNo: accountInfo.unionBankNo,
        },
      });
    } else {
      clearBankForm();
    }
  };

  const handleFinish = async (values: any) => {
    if (isCreate) {
      setLoading(true);

      let error = '';
      const resp = await postContractSave_mcexpOperation({
        requestBody: {
          contractEntitySaveReq: {
            ...values.contractEntitySaveReq,
          },
          entBankAccountSaveReq: {
            ...values.entBankAccountSaveReq,
          },
        },
      }).catch((e) => {
        error = e.message;
      });

      // -- mock --
      // await sleep(500);
      // console.warn('mock');
      setLoading(false);

      if (!error) {
        props.onFinish?.(resp!.data!);
      }
    } else {
      setLoading(true);

      let error = '';
      const resp = await postContractUpdate_mcexpOperation({
        requestBody: {
          contractEntityUpdateReq: {
            id: props.record!.id!,
            ...values.contractEntitySaveReq,
          },

          // - 这个更新的目前是无法修改的
          // entBankAccountUpdateReq: {
          //   id: props.record!.id!,
          //   ...values.entBankAccountSaveReq,
          // },
        },
      }).catch((e) => {
        error = e.message;
      });

      // -- mock --
      // await sleep(500);
      // console.warn('mock');

      setLoading(false);
      if (!error) {
        props.onFinish?.();
      }
    }
    return true;
  };

  useEffect(() => {
    // TODO: useState 的问题，因为 contractInfo 还是旧值
    formRef.current!.setFieldsValue({
      contractEntitySaveReq: {
        entName: orgId ? contractInfo!.entName : undefined,
      },

      entBankAccountSaveReq: {
        bankName: undefined,
        bankAccountName: undefined,
        bankAccountCode: undefined,
        subBankName: undefined,
        unionBankNo: undefined,
      },
    });
  }, [orgId]);

  const columns: ProFormColumnsType<ContractEntityInfoSaveReq>[] = [
    {
      title: '主体编码',
      dataIndex: ['contractEntitySaveReq', 'orgId'],
      valueType: 'select',
      fieldProps: {
        options: orgIdOptions.current,
        showSearch: true,
        // - 产品要求
        // placeholder: '请选择主体编码',
        placeholder: '请选择ARM的ORG ID，如不清楚请联系财务部门韦杰',
        filterOption: filterLabel,
        onChange: handleOrgIdChange,
        disabled: !isCreate,
      },
      formItemProps: {
        rules: [{ required: true, message: '请选择主体编码' }],
      },
      colProps: { span: 24 },
    },
    {
      title: '所属地区类型',
      dataIndex: ['contractEntitySaveReq', 'entAddressType'],
      valueType: 'select',
      valueEnum: {
        0: '境内',
        1: '境外',
      },
      // 内部类型错误，其实建议返回 undefined，这样才会展示 placeholder
      convertValue: (value: any) => (value !== undefined ? value + '' : undefined) as any,
      transform: (value: any) => +value,
      fieldProps: {
        onChange: handleAddressTypeChange,
        disabled: !isCreate,
        // placeholder: '请选择所属地区类型',
      },
      formItemProps: {
        rules: [{ required: true, message: '请选择所属地区类型' }],
      },
    },
    {
      title: '企业类型',
      dataIndex: ['contractEntitySaveReq', 'entType'],
      valueType: 'select',
      valueEnum: contractEntType,
      formItemProps: {
        rules: [{ required: true, message: '请选择企业类型' }],
      },
    },
    {
      title: '企业名称',
      dataIndex: ['contractEntitySaveReq', 'entName'],
      tooltip: '根据主体编码自动识别',
      fieldProps: {
        disabled: true,
      },
      formItemProps: {
        rules: [{ required: true, message: '请输入企业名称' }],
      },
    },
    {
      title: '统一社会信用代码/公司代码',
      dataIndex: ['contractEntitySaveReq', 'entCreditCode'],
      formItemProps: {
        rules: [{ required: true, message: '请输入统一社会信用代码/公司代码' }],
      },
    },
    {
      title: '地址',
      dataIndex: ['contractEntitySaveReq', 'entAddress'],
      formItemProps: {
        rules: [{ required: true, message: '请输入地址' }],
      },
    },
    {
      title: '法定代表人/负责人',
      dataIndex: ['contractEntitySaveReq', 'principal'],
      formItemProps: {
        rules: [{ required: true, message: '请输入法定代表人/负责人' }],
      },
    },
    {
      dataIndex: '_divider',
      render: () => <Divider />,
      readonly: true,
      colProps: { span: 24 },
    },
    {
      title: '所属银行',
      dataIndex: ['entBankAccountSaveReq', 'bankName'],
      valueType: 'select',
      fieldProps: {
        options: accountOptions,
        showSearch: true,
        placeholder: '请选择所属银行',
        filterOption: filterLabel,
        onChange: handleBankNameChange,
        disabled: isCreate ? !orgId || entAddressType === undefined : true,
      },
      tooltip: '请先选择主体编码和境内外类型，如无内容请联系管理员',
      formItemProps: {
        rules: [{ required: true, message: '请选择所属银行' }],
      },
    },
    {
      title: '账户名称',
      dataIndex: ['entBankAccountSaveReq', 'bankAccountName'],
      fieldProps: {
        disabled: true,
      },
    },
    {
      title: '银行帐号',
      dataIndex: ['entBankAccountSaveReq', 'bankAccountCode'],
      fieldProps: {
        disabled: true,
      },
    },
    {
      title: '所属支行',
      dataIndex: ['entBankAccountSaveReq', 'subBankName'],
      fieldProps: {
        disabled: true,
      },
      hideInForm: isForeignAdress,
    },
    {
      title: isForeignAdress ? 'Swift Code' : '联行号',
      dataIndex: ['entBankAccountSaveReq', 'unionBankNo'],
      fieldProps: {
        disabled: true,
      },
    },
    {
      // BetaSchemaForm footer 不生效，只能别扭用 columns 了
      title: <div style={{ color: 'grey' }}>温馨提示：新增企业主体，提交后需维护关联渠道。</div>,
      colProps: { span: 24 },
      readonly: true,
      formItemProps: {
        colon: false,
        hidden: !isCreate,
      },
      render: () => <></>,
    },
  ];

  return (
    <BetaSchemaForm
      title={isCreate ? '新增企业主体' : '修改企业主体'}
      formRef={formRef}
      layout="horizontal"
      layoutType="ModalForm"
      style={{ marginTop: 20 }}
      // labelCol="auto"
      // labelCol={{ span: 4 }}
      // wrapperCol={{ span: 12 }}
      colProps={{ span: 12 }}
      // submitter={{}}
      loading={loading}
      open={true}
      grid={true}
      rowProps={{ gutter: 24 }}
      columns={columns}
      // initialValues={initialValues}
      onFinish={handleFinish}
      modalProps={{
        onCancel: () => {
          props.onCancel?.();
        },
      }}
    />
  );
};
