import { Space, Tooltip, Typography } from 'antd';
import { ReactNode } from 'react';

export function InvestRiskFormatter({ record, value }: { value: React.ReactNode; record: any }) {
  const items: ReactNode[] = [];
  const investRiskMsg = record.investRiskMsg;

  if (!investRiskMsg) {
    return <span>--</span>;
  }

  for (const { code, msg, pass } of investRiskMsg!) {
    items.push(
      <Typography.Text key={code} type={pass ? 'success' : 'danger'}>
        {code}: {msg}
      </Typography.Text>,
    );

    // use Tag
    // items.push(
    //   <Tag key={code} color={pass ? 'success' : 'error'}>
    //     {code}: {msg}
    //   </Tag>,
    // );
  }
  return <Space direction="vertical">{items}</Space>;
}
