import { getEnumBatchQuery_mciDict } from '@/api/services';
import { QueryEnumResp, ResultListQueryEnumResp } from '@/api/types';
import { useEffect, useMemo, useState } from 'react';

export const useSpvCurrency = () => {
  const [data, setData] = useState<QueryEnumResp[]>([]);
  const getCurrencyData = () => {
    getEnumBatchQuery_mciDict({
      queryParams: {
        types: ['CURRENCY'],
      },
    }).then((res: ResultListQueryEnumResp) => {
      setData(res?.data || []);
    });
  };
  const currencyData = useMemo(() => {
    let dataList = data;
    const list = dataList.map((item) => {
      return {
        ...item,
        value: item?.enumValue,
        label: item?.enumDesc,
      };
    });
    return {
      list,
    };
  }, [data]);

  useEffect(() => {
    getCurrencyData();
  }, []);
  return {
    currencyData,
  };
};
