import { forwardRef } from 'react';
import * as React from 'react';
import { createElement } from 'react';
import moment from 'moment';
import { get, set, has } from 'lodash';
function convertProps(props, list, mapper) {
    const out = {};
    list.forEach(key => {
        if (has(props, key)) {
            set(out, key, mapper(get(props, key), key));
        }
    });
    return out;
}
export function withWrap(Comp) {
    return forwardRef((props, ref) => {
        return createElement(Comp, { ...props, ref: ref });
    });
}
export function withSingleChild(Comp, needsConvert = ['children']) {
    return (props) => {
        const convertedProps = convertProps(props, needsConvert, prop => {
            let node = React.Children.toArray(prop)[0];
            if (node === null || typeof node !== 'object') {
                node = createElement("div", null, node);
            }
            return node;
        });
        return createElement(Comp, { ...props, ...convertedProps });
    };
}
export function withSingleFunctionChild(Comp) {
    return (props) => {
        const { children } = props;
        let node;
        if (typeof children === 'function') {
            node = children;
        }
        if (Array.isArray(children) &&
            children.length === 1 &&
            typeof children[0] === 'function') {
            node = children[0];
        }
        if (node) {
            return createElement(Comp, { ...props }, node);
        }
        return createElement("div", null, children);
    };
}
export function withMomentProps(Comp, needsConvert = ['value', 'defaultValue']) {
    return (props) => {
        const convertedProps = convertProps(props, needsConvert, prop => {
            if (prop) {
                if (Array.isArray(prop)) {
                    return prop.map(v => (moment.isMoment(v) ? v : moment(v)));
                }
                return moment.isMoment(prop) ? prop : moment(prop);
            }
        });
        return createElement(Comp, { ...props, ...convertedProps });
    };
}
