export const contract_ent_type_enum = {
  LIMITED_COMPANY: '有限责任公司',
  INDIVIDUAL_BUSINESS: '个人独资企业',
  INDIVIDUAL_INDUSTRIAL: '个体工商户',
  PARTNERSHIP: '合伙企业',
  BRANCH: '分公司或分支机构',
};

// 交易所系统code
export const systemCode = '10000';
