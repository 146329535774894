import { isAbsUrl, parseOssFileName } from '@/utils/oss-util';
import { UploadOutlined } from '@ant-design/icons';
import { getOSSBucketAccessUrl, uploadToOSS } from '@mci/design-system/components/WebFileUpload/utils';
import { Button, Flex, Space, Typography, Upload, UploadFile, UploadProps } from 'antd';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { FC, ReactNode, useState } from 'react';

export interface FileUploadProps {
  value?: UploadFileExtItem | UploadFileExtItem[];
  // 注意上传会触发两次 onChange，一次是loading，一次是done
  onChange?: (value: UploadFileExt[], file: UploadFileExt) => void;
  allowMultiple?: boolean;
  maxCount?: number;
  uploadText?: string;
  disabled?: boolean;
  error?: string;
  description?: string | ReactNode;
}

export type UploadFileExt = UploadFile & {
  filePath?: string;
};

export type UploadFileExtItem = string | UploadFileExt;

/**
 * 文件上传组件
 *
 * 支持 onChange value 协议
 * 注意，其实 value 是一个 initial 的作用，暂时不做二次响应了，感觉不太需要
 *
 * 支持公司内的 oss 上传
 */
export const FileUpload: FC<FileUploadProps> = (props) => {
  const allowMultiple = props.allowMultiple ?? true;
  const uploadDisabled = props.disabled ?? false;

  const uploadText = props.uploadText ?? '上传';
  const maxCount = allowMultiple ? props.maxCount : 1;

  const [uploading, setUploading] = useState(false);

  // TODO: 很多代码需要抽取
  const processFileExtItem = (item: UploadFileExtItem): UploadFileExt => {
    let name = '';
    let isAbs = false;
    if (typeof item === 'string') {
      isAbs = isAbsUrl(item);
      name = parseOssFileName(item);

      if (isAbs) {
        return {
          uid: item,
          name,
          status: 'done',
          url: item,
        };
      } else {
        return {
          uid: item,
          name,
          status: 'done',
          // upload done 不等于一定预览，只是说文件已经在 oss 了
          // 目前临时访问会过期的资源每次preview都需要重新获取
          filePath: item,
        };
      }
    }

    // filePath 和 url 不是一个概念，不要混用
    if (item.url) {
      name = item.name || parseOssFileName(item.url);

      return {
        ...item,
        status: 'done',
        name,
      };
    } else if (item.filePath) {
      name = item.name || parseOssFileName(item.filePath);

      return {
        ...item,
        status: 'done',
        name,
      };
    } else {
      return {} as any;
    }
  };

  const [fileList, setFileList] = useState<UploadFileExt[]>(() => {
    if (!props.value) return [];

    let result: UploadFileExt[];

    if (typeof props.value === 'string') {
      // return [processFileExtItem(props.value)];
      result = [processFileExtItem(props.value)];
    }

    if (Array.isArray(props.value)) {
      result = props.value.map((item) => processFileExtItem(item));
    } else {
      console.error('[Upload] value 类型错误', props.value);
      result = [];
    }

    if (!allowMultiple && result.length > 1) {
      console.error('[Upload] value 不能是多个', props.value);
      result = result.slice(0, 1);
    }

    return result;
  });

  const updateFileList = (fileList: UploadFileExt[], file: UploadFileExt) => {
    setFileList(fileList);
    props.onChange?.(fileList, file);
  };

  const customRequest: UploadProps['customRequest'] = async (options) => {
    setUploading(true);

    // console.log('customRequest', options.file);

    const res = await uploadToOSS({
      file: options.file as File,
    });

    // TODO: error case

    setUploading(false);

    // TODO: 要考虑并行的问题
    let newFileList = [...fileList];
    let file: UploadFileExt;
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      if (item.uid === (options.file as RcFile).uid) {
        item.filePath = res.filePath;
        item.status = 'done';
        file = item;
        break;
      }
    }
    if (file!) {
      updateFileList(newFileList, file!);
    } else {
      console.error('uploadOss 后找不到对应的file');
    }
  };

  // TODO: 按钮加载，禁用，readonly 等等，禁用时候也不能删除 fileItem
  // TODO: 删除行为，是否要删除对应的OSS，但是呢如果是外部手动传递进来的，又不能删除，否则第二次用就找不到了

  // TODO: 预览

  // TODO: 限制文件大小
  // const beforeUpload: UploadProps['beforeUpload'] = (file) => {
  //   let fileSizeMB = props.limit?.fileSizeMB ?? 10; // 默认也做一个限制
  //   if (fileSizeMB > 0) {
  //     const realFileSizeMB = file.size / 1024 / 1024;
  //     if (realFileSizeMB > fileSizeMB) {
  //       const errMsg = `上传文件过的大，当前文件大小为 ${realFileSizeMB.toFixed(2)}MB，限制为 ${fileSizeMB}MB`;
  //       messageApi.error(errMsg);
  //       return Upload.LIST_IGNORE;
  //     }
  //   }
  // };

  const getFooter = () => {
    const error = props.error;
    const description = props.description;

    // TEST
    // const error = '文件上传错误';
    // const description = '温馨提示：模板文件名称及字段请勿修改，请上传对应类型文件，系统会自动判断交易类型';

    let descNode: ReactNode;
    if (description) {
      if (typeof description === 'string') {
        descNode = (
          <Typography.Text type="secondary" style={{ fontSize: 12 }}>
            {description}
          </Typography.Text>
        );
      } else {
        descNode = description;
      }
    }

    return (
      <Space direction="vertical" style={{ width: '100%' }} size="small">
        {error && <Typography.Text type="danger">{error}</Typography.Text>}
        {descNode}
      </Space>
    );
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Upload
          name="file"
          customRequest={customRequest}
          onChange={(info) => {
            updateFileList(info.fileList, info.file);
          }}
          maxCount={maxCount}
          // disabled={props.disabled}
          // 返回true时，会修改原始数据
          // onRemove={handleRemove}
          // maxCount={maxCount}
          fileList={fileList}>
          <Button disabled={uploadDisabled} icon={<UploadOutlined />}>
            {uploadText}
          </Button>
        </Upload>
        {getFooter()}
      </Space>
    </>
  );
};
