import { ResultListQueryEnumResp, AssociateShareRuleStepVo, QueryEnumResp } from '@/api/types';
import { previewFileColumnRender } from '@/components/PreviewFile';
import { ENV_KEY, RESOLVED_ENV_KEY, INIT_ENV_QUERY_KEY } from '@/constants';
import { queryUtil } from '@/utils/query-util';

export function resolveServerName() {
  let serverName = process.env.SERVER_NAME || '';
  if (queryUtil.isDebug) {
    const urlEnv = new URLSearchParams(window.location.search).get(INIT_ENV_QUERY_KEY);
    // 首次进入页面，sessionStorage 并没有储存 ENV_KEY ，这种情况要从  url 中读取的环境变量
    serverName = sessionStorage.getItem(ENV_KEY) || urlEnv || serverName;
    if (!sessionStorage.getItem(ENV_KEY) && urlEnv) {
      sessionStorage.setItem(ENV_KEY, urlEnv);
    }
  }
  // 因为 api.config.ts 文件当前不支持导入（会导致 `npm run api`失败），所以通过 sessionStorage 来跟它通讯
  // TODO: 从源头上去解决问题：要么 api.config.ts 文件支持导入，要么 getAxiosBaseUrl 函数得到的是已经 resolved 的 serverName
  sessionStorage.setItem(RESOLVED_ENV_KEY, serverName);
  return serverName;
}

export function getKCUrl() {
  let serverName = resolveServerName();

  if (['localMock', 'remoteMock'].includes(serverName) || !serverName) {
    serverName = 'stg1';
  }

  return serverName === 'prod' ? 'https://kc.microconnect.cn/auth' : `https://kc.mcexp-${serverName}.mcisaas.com/auth`;
}

export function enumMapPostTransformer(res: ResultListQueryEnumResp) {
  if (!res || !res.data) return res;
  const data = res.data!.reduce(
    (acc, item) => {
      const type = item.type as string;
      if (!acc[type]) {
        acc[type] = {};
      }
      acc[item.type!][item.enumValue!] = item.enumDesc!;
      return acc;
    },
    {} as {
      [enumType: string]: {
        [enumValue: string]: string;
      };
    },
  );

  console.log(4444, { ...res, data });
  return { ...res, data };
}

export function enumMapPostTransLabel(data: QueryEnumResp[], type: string, value: any) {
  return data.filter((e) => e?.type === type)?.find((i) => i.enumValue === value)?.enumDesc;
}

export function shipDescriptionItemWithKey(
  items: { label: string; value: any; type?: 'text' | 'preview'; hideLable?: boolean }[],
) {
  return items.map((item) => {
    const type = item.type || 'text';
    const children = type === 'preview' ? previewFileColumnRender(item.value) : item.value || '--';
    let label = item.hideLable ? '' : item.label;
    return { key: item.label, label, children };
  });
}

export function desensitize(input: any, mode: 1 | 2): string {
  if (typeof input !== 'string') return input;

  switch (mode) {
    case 1:
      // 只保留字符串首字符，其他都是星号
      return input[0] + '*'.repeat(input.length - 1);
    case 2:
      // 保留首尾各一个字符，其他都是星号
      if (input.length === 1) return input; // 只有一个字符的情况下，无法保留首尾两个字符，只能返回原字符
      return input[0] + '*'.repeat(input.length - 2) + input[input.length - 1];
    default:
      throw new Error('Invalid mode. Mode should be 1 or 2.');
  }
}

export function reshapeStepRateList(list: AssociateShareRuleStepVo[], rate_beforeRepayment: string) {
  type Item = { [key: string]: string };
  let stepSharePlanRateItem_beforeRepayment: Item = {};
  let stepSharePlanRateItem_afterRepayment: Item = {};
  const result = [];

  for (const item of list) {
    stepSharePlanRateItem_beforeRepayment[`[${item.begin} , ${item.end}]`] = rate_beforeRepayment;
    stepSharePlanRateItem_afterRepayment[`[${item.begin} , ${item.end}]`] =
      (Number(item.rate || 0) * 100).toFixed(1) + '%';
    result.push(stepSharePlanRateItem_beforeRepayment);
    result.push(stepSharePlanRateItem_afterRepayment);
  }

  return result;
}

export function extraColumnConfigList(config: { [key: string]: string }) {
  return Object.entries(config).map(([key, value]) => ({
    title: key,
    dataIndex: key,
  }));
}

export function extractHttpMethodFromServiceName(serviceName: string) {
  if (!serviceName) {
    return null;
  }
  const pattern = /^(post|get|put|delete|patch|head)\w*/i;
  const match = serviceName.match(pattern);
  return match ? match[1].toLowerCase() : null;
}

export function isEmptyPlainJSObject(obj: { [key: string]: any }) {
  return Object.prototype.toString.call(obj) === '[object Object]' && Object.keys(obj).length === 0;
}

export function removeEmptyFields(obj: { [key: string]: any }) {
  const newObj: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
      newObj[key] = obj[key];
    }
  }
  return newObj;
}

export function getAppModeFromServerName(serverName: string) {
  if (serverName === 'localMock' || serverName === 'remoteMock') {
    return 'local';
  } else if (serverName.startsWith('dev')) {
    return 'development';
  } else if (serverName.startsWith('stg')) {
    return 'staging';
  } else if (serverName.startsWith('uat')) {
    return 'uat';
  } else if (serverName.startsWith('prod')) {
    return 'production';
  }
  throw new Error(`Unknown server name: ${serverName}`);
}
