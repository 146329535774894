import { createElement, useState } from 'react';
import { Button } from 'antd';
import { extractHttpMethodFromServiceName } from '../utils';
function validateContentDisposition(contentDispositionHeader) {
    const regex = /^.*?\bfilename=(["'])(.*?)\1.*$/;
    return regex.test(contentDispositionHeader);
}
function getDownloadFileName(contentDispositionHeader) {
    contentDispositionHeader = decodeURIComponent(contentDispositionHeader);
    if (!validateContentDisposition(contentDispositionHeader)) {
        console.warn('后端返回的 content-disposition header 的值不符合规范 -  必须要有 filename 字段，且filename的值必须使用单引号或者双引号包住');
    }
    const regex = /filename[*]?=(?:["']([^"']*)["']|([^"']+))/i;
    const match = contentDispositionHeader.match(regex);
    let fileName = '';
    if (match) {
        fileName = match[1] || match[2];
    }
    if (typeof fileName === 'string') {
        if (fileName.includes('/')) {
            const segments = fileName.split('/');
            fileName = segments[segments.length - 1];
        }
    }
    return fileName;
}
export function ExportAction({ apiMap, searchForm, serviceName, btnText, accessible, }) {
    const [loading, setLoading] = useState(false);
    const { page, size, ...restForm } = searchForm;
    const httpMethod = extractHttpMethodFromServiceName(serviceName);
    if (accessible === false) {
        return null;
    }
    return (createElement(Button, { loading: loading, onClick: async () => {
            setLoading(true);
            apiMap[serviceName]({
                [httpMethod === 'get' ? 'queryParams' : 'requestBody']: restForm,
                configOverride: {
                    responseType: 'blob',
                },
            })
                .then((response) => {
                const fileName = getDownloadFileName(response.headers['content-disposition'] || response.headers['Content-Disposition']);
                const blob = new Blob([response.data]);
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.download = fileName || `导出的数据-${Date.now()}.xlsx`;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(downloadUrl);
                document.body.removeChild(link);
                setLoading(false);
            })
                .catch((error) => {
                setLoading(false);
                console.error('Error while downloading file:', error);
            });
        } }, btnText || '导出'));
}
