import { ProDescriptionsItemProps } from '@ant-design/pro-components';

/**
 * 撤单详情字段
 */

// IO订单
export const IOAOColumns = [
  {
    title: '会员名称',
    dataIndex: 'identificationName',
  },
  {
    title: '订单号',
    dataIndex: 'orderCode',
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
  },
  {
    title: '交易类型',
    dataIndex: 'transactionType',
    valueEnum: {
      0: '首次发行买入',
      1: '循环投资买入',
    },
  },
  {
    title: '撮合状态',
    dataIndex: 'captureStatus',
    valueEnum: {
      1: '待撮合',
      2: '交易中（部分撮合）',
      3: '已撮合',
      4: '撮合失败',
      5: '--'
    },
  },
  {
    title: '下单份额',
    dataIndex: 'buyShares',
  },
  {
    title: '下单金额',
    dataIndex: 'buyMoney',
    valueType: {
      type: 'money',
      moneySymbol: false,
      precision: 2,
    },
  },
  {
    title: '成交份额',
    dataIndex: 'confirmShares',
  },
  {
    title: '可撤单份额',
    dataIndex: 'orderCancel',
    render: (_, record) => {
      return Number(record.buyShares) - Number(record.confirmShares);
    },
  },
] satisfies ProDescriptionsItemProps[];

// SO订单
export const SOColumns = [
  {
    title: '会员名称',
    dataIndex: 'identificationName',
  },
  {
    title: '订单号',
    dataIndex: 'orderCode',
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
  },
  {
    title: '撮合状态',
    dataIndex: 'captureStatus',
    valueEnum: {
      1: '待撮合',
      2: '交易中（部分撮合）',
      3: '已撮合',
      4: '撮合失败',
      5: '--'
    },
  },
  {
    title: '交易方向',
    dataIndex: 'orderDirection',
    valueType: 'select',
    valueEnum: {
      0: '卖',
      1: '买',
    },
  },
  {
    title: '下单份额',
    dataIndex: 'totalShares',
  },
  {
    title: '下单金额',
    dataIndex: 'buyMoney',
    valueType: {
      type: 'money',
      moneySymbol: false,
      precision: 2,
    },
  },
  {
    title: '成交份额',
    dataIndex: 'confirmShares',
  },
  {
    title: '可撤单份额',
    dataIndex: 'orderCancel',
    render: (_, record) => {
      return Number(record.totalShares) - Number(record.confirmShares);
    },
  },
] satisfies ProDescriptionsItemProps[];

// 大宗交易订单
export const BlockColumns = [
  {
    title: '卖方会员名称',
    dataIndex: 'identificationNameSell',
  },
  {
    title: '买方会员名称',
    dataIndex: 'identificationNameBuy',
  },
  {
    title: '订单号',
    dataIndex: 'orderCode',
  },
  {
    title: '产品编码',
    dataIndex: 'productCode',
  },
  {
    title: '撮合状态',
    dataIndex: 'captureStatus',
    valueEnum: {
      1: '待撮合',
      2: '交易中（部分撮合）',
      3: '已撮合',
      4: '撮合失败',
      5: '--'
    },
  },
  {
    title: '下单份额',
    dataIndex: 'shares',
  },
  {
    title: '下单金额',
    dataIndex: 'amount',
    valueType: {
      type: 'money',
      moneySymbol: false,
      precision: 2,
    },
  },
] satisfies ProDescriptionsItemProps[];