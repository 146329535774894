import { Space, Popover } from 'antd';
import { formatter } from '@/utils/formatter';
import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { DROContext } from '@/pages/Order/dro/OrderDRO';
import { DRPContext } from '@/pages/Order/drp/OrderDRP';

/**
 * DRO表格字段tooltip，同一个表格有多个，则第一个是OrderSumToolTip，第二个是OrderSumTwoToolTip，以此类推
 *
 */

// 根据路由判断dro和drp的Provider的传值
const useTabValue = () => {
  const currentLocation = useLocation();
  const routerName = currentLocation.pathname;
  const TabValueDro = useContext(DROContext);
  const TabValueDrp = useContext(DRPContext);
  const TabValue =
    routerName === '/orderManagement/dro'
      ? TabValueDro?.TabValue
      : routerName === '/orderManagement/drp'
        ? TabValueDrp?.TabValue
        : '';
  return TabValue;
};

export function OrderSumToolTip({ record, value }: { value: React.ReactNode; record: any }) {
  const TabValue = useTabValue();
  // 根据tabName选择content字段
  // TODO: 目前写成feeStr
  const recordName =
    TabValue === 'FromIOAO'
      ? record.feeStr
      : TabValue === 'FromSO'
        ? record.feeStr
        : TabValue === 'FromBlock'
          ? record.sellConfirmTotalMoneyStr
          : '';
  if (!recordName) return <span>--</span>;
  const orderSumObj = JSON.parse(recordName);
  let sum = 0;

  // 遍历对象的值，格式化保留两位小数并计算总和
  Object.keys(orderSumObj).forEach((key) => {
    const value = parseFloat(orderSumObj[key]).toFixed(2);
    sum += parseFloat(value);
    const formattedValue = formatter.moneyToFixed(orderSumObj[key], 2);
    orderSumObj[key] = formattedValue; // 更新对象中的值为格式化后的值
  });

  return (
    <Popover
      content={
        <div style={{ maxHeight: 200, minWidth: 200, padding: 8, overflowY: 'scroll' }}>
          <Space direction="vertical">
            {Object.keys(orderSumObj).map((key) => {
              return (
                <div key={key}>
                  <span style={{ marginRight: 10 }}>{key}:</span>
                  <span>{orderSumObj[key]}</span>
                </div>
              );
            })}
          </Space>
        </div>
      }
      trigger="hover">
      <span>{formatter.moneyToFixed(sum, 2)}</span>
    </Popover>
  );
}

export function OrderSumTwoToolTip({ record, value }: { value: React.ReactNode; record: any }) {
  const TabValue = useTabValue();
  // TODO: 目前写成confirmTotalMoneyStr
  const recordName =
    TabValue === 'FromIOAO'
      ? record.confirmTotalMoneyStr
      : TabValue === 'FromSO'
        ? record.confirmTotalMoneyStr
        : TabValue === 'FromBlock'
          ? record.buyConfirmTotalMoneyStr
          : '';
  if (!recordName) return <span>--</span>;
  const transactionObj = JSON.parse(recordName);
  let sum = 0;

  // 遍历对象的值，格式化保留两位小数并计算总和
  Object.keys(transactionObj).forEach((key) => {
    const value = parseFloat(transactionObj[key]).toFixed(2);
    sum += parseFloat(value);
    const formattedValue = formatter.moneyToFixed(transactionObj[key], 2);
    transactionObj[key] = formattedValue; // 更新对象中的值为格式化后的值
  });

  return (
    <Popover
      content={
        <div style={{ maxHeight: 200, minWidth: 200, padding: 8, overflowY: 'scroll' }}>
          <Space direction="vertical">
            {Object.keys(transactionObj).map((key) => {
              return (
                <div key={key}>
                  <span style={{ marginRight: 10 }}>{key}:</span>
                  <span>{transactionObj[key]}</span>
                </div>
              );
            })}
          </Space>
        </div>
      }
      trigger="hover">
      <span>{formatter.moneyToFixed(sum, 2)}</span>
    </Popover>
  );
}
