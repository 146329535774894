import { getHsbcDepositGetTradingAccountList_exKernelAdmin } from '@/api/services';
import { QueryEnumResp } from '@/api/types';
import { useEffect, useMemo, useState } from 'react';

export const useAccount = () => {
  const [data, setData] = useState<any>([]);
  const getCurrencyData = () => {
    getHsbcDepositGetTradingAccountList_exKernelAdmin().then((res: any) => {
      setData(res?.data || []);
    });
  };
  const accountData = useMemo(() => {
    const list = data.map((item:any) => {
      return {
        ...item,
        value: item?.tradingAccount || '',
        label: item?.tradingAccount || ''
      };
    });
    return {
      list,
    };
  }, [data]);

  useEffect(() => {
    getCurrencyData();
  }, []);
  return {
    accountData,
  };
};
