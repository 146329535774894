import useMciRequest from '@/api/useMciRequest';
import { Descriptions, Divider, Spin, Table } from 'antd';
import { useParams } from 'react-router-dom';
import { shipDescriptionItemWithKey } from '@/utils';
import { DescriptionsTitle } from '@/components/DescriptionsTitle';
import { ProTable } from '@ant-design/pro-components';

export default function BrandInfo(props: { appId: string }) {
  const { appId } = props;
  const { loading, data: resp } = useMciRequest(
    'getAdminV1ShelfDroAppliesAppIdBrandInfo_mcexpOperation',
    {
      pathParams: {
        appId: appId!,
      },
    },
    {
      isJoinGlobalLoading: false,
    },
  );

  const {
    id,
    name,
    code,
    industryCode,
    sectorCode,
    categoryCode,
    subjectNature,
    brandLicensor,
    firstShopOpenDate,
    brandDevelopmentHistory,
    mainBusinessIntroduction,
    otherBrands,
    logoFileList,
    ownershipFileList,
    brandLicenseFileList,
    acknowledgmentFileList,
    brandManage,
    systemList,
    brandInvest,
    industryName,
    sectorName,
    categoryName,
  } = resp?.data || {};

  const {
    macroEnvironmentalRisk,
    categoryTrendRisk,
    brandRisk,
    otherRisk,
    marketEnvironment,
    marketEnvironmentFileList,
    categoryGrowthPotential,
    categoryGrowthPotentialFileList,
    productStrengths,
    riskControlMeasures,
    riskControlMeasuresFileList,
    promoteVideoFileList,
  } = brandInvest || {};

  const {
    totalStoreCnt,
    coverProvinceCnt,
    coverCityCnt,
    topCityDistributionList,
    operateAvgDurationToExisting,
    avgLeaseTerm,
    totalOpenShopCnt,
    avgPayoffPeriod,
    promotionAdvertisingFee,
    totalCloseNumber,
    beforeCloseNumber,
    closeAvgOperatingHours,
    averageSingleStoreInvestment,
    decorationCost,
    equipmentCost,
    otherFee,
    initialPersonnelExpenditure,
    upfrontPromotionCost,
    fireControlRenovationCost,
    otherExpenditure,
    openStoreInfoList,
    newStoreInfoList,
    closeStoreInfoList,
    planAddNum,
    planRetreadNum,
    otherPlan,
  } = brandManage || {};

  const baseInfoList = shipDescriptionItemWithKey([
    { label: '品牌名称', value: name },
    { label: '品牌编码', value: code },
    { label: '品牌类型', value: subjectNature === 0 ? '自有品牌' : '代理品牌' },
    { label: '行业/业态/品类', value: `${industryName || '--'}/${sectorName || '--'}/${categoryName || '--'}` },
    { label: '品牌在营首店开业日期', value: firstShopOpenDate },
    { label: '品牌许可方', value: brandLicensor },
    { label: '品牌发展历程', value: brandDevelopmentHistory },
    { label: '主营业务介绍', value: mainBusinessIntroduction },
    { label: '品牌logo', value: logoFileList, type: 'preview' },
    { label: '品牌所有权证明', value: ownershipFileList, type: 'preview' },
    { label: '品牌授权证明', value: brandLicenseFileList, type: 'preview' },
    { label: '品牌确认书', value: acknowledgmentFileList, type: 'preview' },
  ]);

  const brandManageInfoList1 = [
    { label: '现有门店总数量（家）', value: totalStoreCnt },
    { label: '覆盖省份数', value: coverProvinceCnt },
    { label: '覆盖城市数', value: coverCityCnt },
    { label: '在营门店平均运营时间（月）', value: operateAvgDurationToExisting },
    { label: '新店平均租期（月）', value: avgLeaseTerm },
    { label: '累计开店数量（家）', value: totalOpenShopCnt },
    { label: '单店平均回本期（月）', value: avgPayoffPeriod },
    { label: '门店促销费用占实收的比例（%）', value: totalCloseNumber },
  ];

  const brandManageInfoList2 = [
    // 闭店信息
    { label: '累计闭店数量（家）', value: totalCloseNumber },
    { label: '回本前闭店数量（家）', value: beforeCloseNumber },
    { label: '已关闭门店平均运营时间（月）', value: closeAvgOperatingHours },
  ];

  const brandManageInfoList3 = [
    // 单店初始总投资情况
    { label: '单店初始总投资额（元）', value: averageSingleStoreInvestment },
    { label: '装修成本（元）', value: decorationCost },
    { label: '设备成本（元）', value: equipmentCost },
    { label: '其他（元）', value: otherFee },
  ];

  const brandManageInfoList4 = [
    // 其他支出明细
    { label: '开业前期人员支出（元）', value: initialPersonnelExpenditure },
    { label: '开业前期推广费用（元）', value: upfrontPromotionCost },
    { label: '开业前期消防改造费用（元）', value: fireControlRenovationCost },
    { label: '其他支出（元）', value: otherExpenditure },
  ];

  const digitizationInfoTable = (
    <ProTable
      size="small"
      bordered
      columnEmptyText="--"
      toolBarRender={false}
      search={false}
      columns={[
        {
          title: '系统名称',
          dataIndex: 'name',
        },
        {
          title: '系统类型',
          dataIndex: 'type',
        },
        {
          title: '开始使用时间',
          dataIndex: 'beginDate',
        },
        {
          title: '开发方式',
          dataIndex: 'developMode',
        },
        {
          title: '登录方式',
          dataIndex: 'loginType',
        },
        {
          title: '登录地址',
          dataIndex: 'loginUrl',
          ellipsis: true,
        },
        {
          title: '登录账号',
          dataIndex: 'loginAccount',
        },
      ]}
      dataSource={systemList || []}
      style={{ width: '100%' }}
    />
  );

  const columns = [
    {
      title: '年份',
      dataIndex: 'year',
    },
    {
      title: '类型',
      dataIndex: 'type',
      render(value: 1 | 0) {
        return value === 1 ? '加盟' : '直营';
      },
    },
    {
      title: '数量',
      dataIndex: 'num',
    },
  ];

  const openStoreInfoTable = (
    <Table
      size="small"
      bordered
      columns={columns}
      dataSource={openStoreInfoList || []}
      style={{ width: '100%' }}
      pagination={{ pageSize: 5 }}
    />
  );

  const newStoreInfoTable = (
    <Table
      size="small"
      bordered
      columns={columns}
      dataSource={newStoreInfoList || []}
      style={{ width: '100%' }}
      pagination={{ pageSize: 5 }}
    />
  );

  const closeStoreInfoTable = (
    <Table
      size="small"
      bordered
      columns={columns}
      dataSource={closeStoreInfoList || []}
      style={{ width: '100%' }}
      pagination={{ pageSize: 5 }}
    />
  );

  const topFiveCityColumn = [] as Array<{ title: string; dataIndex: string }>;
  const topFiveCityData = {} as Record<string, any>;
  const hasTopFiveCityListData = Array.isArray(topCityDistributionList);

  if (hasTopFiveCityListData) {
    for (const cityItem of topCityDistributionList) {
      topFiveCityColumn.push({
        title: cityItem.cityName!,
        dataIndex: cityItem.cityCode!,
      });
      topFiveCityData[cityItem.cityCode!] = cityItem.num!;
    }
    topFiveCityColumn.unshift({
      title: '城市',
      dataIndex: 'count',
    });
    topFiveCityData.count = '数量';
  }

  const topFiveCityTable = (
    <Table
      size="small"
      bordered
      columns={topFiveCityColumn}
      dataSource={[topFiveCityData]}
      style={{ width: '100%' }}
      pagination={false}
    />
  );

  const storeManagementInfo = [
    { label: '当年在营门店数量（家）', value: openStoreInfoTable },
    { label: '当年新开门店数量（家）', value: newStoreInfoTable },
    { label: '当年关闭门店数量（家）', value: closeStoreInfoTable },
  ];

  const riskEvaluationInfo = shipDescriptionItemWithKey([
    {
      label: '宏观环境风险',
      value: macroEnvironmentalRisk,
    },
    {
      label: '品类趋势风险',
      value: categoryTrendRisk,
    },
    {
      label: '品牌风险',
      value: brandRisk,
    },
    {
      label: '其他风险',
      value: otherRisk,
    },
  ]);

  const highlightEvaluationInfo = shipDescriptionItemWithKey([
    {
      label: '推介视频',
      value: promoteVideoFileList,
      type: 'preview',
    },
  ]);

  return (
    <Spin spinning={loading}>
      <Descriptions title="基础信息" items={baseInfoList} column={2} />
      <DescriptionsTitle title="门店经营信息" />
      <Divider orientation="left" plain>
        品牌现状
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(brandManageInfoList1)} column={2} />
      <Descriptions
        items={shipDescriptionItemWithKey([
          {
            label: '门店数量最多的五个城市',
            value: hasTopFiveCityListData ? topFiveCityTable : '--',
          },
        ])}
        column={1}
        layout={hasTopFiveCityListData ? 'vertical' : 'horizontal'}
      />
      <Divider orientation="left" plain>
        闭店信息
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(brandManageInfoList2)} column={2} />
      <Divider orientation="left" plain>
        开关店信息
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(storeManagementInfo)} column={1} layout="vertical" />

      <Descriptions
        title="数字化信息"
        items={[
          {
            label: '',
            children: digitizationInfoTable || '--',
          },
        ]}
        column={1}
      />
      <DescriptionsTitle title="投资评估" />
      <Descriptions items={highlightEvaluationInfo} column={1} />
    </Spin>
  );
}
