import { ProTable, ProColumns, ProForm, ProFormTextArea, ProFormSelect } from '@ant-design/pro-components';
import type { ProFormInstance } from '@ant-design/pro-components';
import { Flex, Modal, Typography, Spin, Space, Divider, message, Button } from 'antd';
import {
  postAdminV1OrderManualBatch_mcexpOperation,
  postAdminV1OrderManualBatchCheck_mcexpOperation,
  postAdminV1OrderManualBatchCancel_mcexpOperation,
  postAdminV1OrderManualBatchRevocation_mcexpOperation,
  postAdminV1OrderManualBlockTradeBatch_mcexpOperation,
  postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation,
  postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation,
  postAdminV1OrderBatchCancelBlockTrade_mcexpOperation,
} from '@/api/services';
import { DateIncomePcf, ExManualOrderRsp } from '@/api/types';
import { FC, useState, useEffect, useRef, useContext } from 'react';
import { uuidCreate } from '@/utils/formatter';
import { ManualContext } from '@/pages/Order/manual/OrderManual';
import { columnsSummary, columnsDetail, columnsBatchDetail } from './modalColumns';

interface IManualIOAOSOProps {
  type: string;
  selectedRows?: any;
  refreshTable?: () => void;
  onCancel?: () => void;
}

/**
 * 订单系统-手工下单-批量审批、取消、撤单
 */

export const OrderManualCancel: FC<IManualIOAOSOProps> = (props) => {
  const { TabValue } = useContext(ManualContext);
  const { Text } = Typography;

  const type = props.type || 'canCancel';

  const formRef = useRef<ProFormInstance>();

  const [canCancelNum, setCanCancelNum] = useState<number>(0);
  const [selectedRowsFilter, setSelectedRowsFilter] = useState<ExManualOrderRsp[]>([]);
  const [showTips, setShowTips] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const requestSummary: React.ComponentProps<typeof ProTable>['request'] = async (params) => {
    // let codeList = extractList('orderCode');
    let codeList = extractList('id');
    if (!codeList || codeList.length === 0) return { data: [], total: 0, success: true };
    let error = '';
    let resp;
    if (TabValue === 'IOAOSO') {
      resp = await postAdminV1OrderManualBatch_mcexpOperation({
        requestBody: {
          idList: codeList,
        },
      }).catch((e) => (error = e.message));
    } else if (TabValue === 'BlockTrade') {
      resp = await postAdminV1OrderManualBlockTradeBatch_mcexpOperation({
        requestBody: {
          idList: codeList,
        },
      }).catch((e) => (error = e.message));
    }

    setCanCancelNum(resp?.data?.length || 0);
    if (error) {
      return {
        data: [],
        total: 0,
        success: true,
      };
    }

    return {
      data: resp?.data || [],
      total: resp?.data?.length || 0,
      success: true,
    };
  };

  const requestDetail: React.ComponentProps<typeof ProTable>['request'] = async (params) => {
    return {
      data: selectedRowsFilter,
      total: selectedRowsFilter.length,
      success: true,
    };
  };

  const extractList = (filterType: string) => {
    if (!selectedRowsFilter) return [];
  
    const filteredItems = selectedRowsFilter.filter((item: any) => item[filterType] != null);
  
    return filteredItems.map((item: any) => item[filterType]);
  };

  const buildAuditParams = (values: any) => ({
    txId: uuidCreate(),
    idList: extractList('id'),
    auditRemark: values.auditRemark,
    auditStatus: values.auditStatus,
  });

  // 批量审批
  const handleAudit = async () => {
    try {
      await formRef.current!.validateFields();
    } catch (e: any) {
      const msg = e.errorFields[0].errors[0];
      if (msg) message.error(msg);
      return;
    }

    const values = formRef.current!.getFieldsValue();
    const params = buildAuditParams(values);

    let error = '';
    if (TabValue === 'IOAOSO') {
      await postAdminV1OrderManualBatchCheck_mcexpOperation({ requestBody: params }).catch((e: any) => {
        error = e.message;
      });
    } else if (TabValue === 'BlockTrade') {
      await postAdminV1OrderBatchManualBlockTradeBatchCheck_mcexpOperation({ requestBody: params }).catch((e: any) => {
        error = e.message;
      });
    }
    return error;
  };

  // 批量取消/撤单
  const handleCancelOrRevoke = async () => {
    const idList = extractList('id');
    const codeList = extractList('orderCode');

    let error = '';
    
    if (TabValue === 'IOAOSO') {
      if (type === 'canCancel') {
        await postAdminV1OrderManualBatchCancel_mcexpOperation({ requestBody: { idList: idList } }).catch((e: any) => {
          error = e.message;
        });
      } else if (type === 'canRevoke') {
        await postAdminV1OrderManualBatchRevocation_mcexpOperation({ requestBody: { orderCodeList: codeList } }).catch((e: any) => {
          error = e.message;
        });
      }
    } else if (TabValue === 'BlockTrade') {
      if (type === 'canCancel') {
        await postAdminV1OrderBatchCancelBlockTrade_mcexpOperation({ requestBody: { idList: idList } }).catch(
          (e: any) => {
            error = e.message;
          },
        );
      } else if (type === 'canRevoke') {
        await postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation({ requestBody: { orderCodeList: codeList } }).catch((e: any) => {
          error = e.message;
        });
      }
    }
    return error;
  };

  const handleOk = async () => {
    let errors;
    if (type === 'canAudit') {
      errors = await handleAudit();
    } else {
      errors = await handleCancelOrRevoke();
    }
    if (!errors && errors !== undefined) {
      props.onCancel?.();
      props.refreshTable?.();
    }
  };

  const filterSelectedRows = (type: string) => {
    let dataList = [];
    if (type === 'canRevoke') {
      dataList = props.selectedRows.filter(
        (item: any) => item.auditStatus === 1 && item.orderStatus === '1',
      );
    } else {
      dataList = props.selectedRows.filter((item: ExManualOrderRsp) => item.auditStatus === 0);
    }
    setSelectedRowsFilter(dataList);
  };

  const showText = (type: string) => {
    let text;

    switch (type) {
      case 'canCancel':
        text = '温馨提示：审批状态为“待审批”时可取消';
        break;
      case 'canAudit':
        text = '温馨提示：请核对过明细后再提交！';
        break;
      case 'canRevoke':
        text = '温馨提示：已审批通过且订单状态为“有效”的订单可全额撤单';
        break;
      default:
        text = '温馨提示：审批状态为“待审批”时可取消';
    }

    setShowTips(text);
  };

  const showTitle = (type: string) => {
    let title;

    switch (type) {
      case 'canCancel':
        title = '取消';
        break;
      case 'canAudit':
        title = '审批';
        break;
      case 'canRevoke':
        title = '撤单';
        break;
      default:
        title = '取消';
    }

    setTitle(title);
  };

  useEffect(() => {
    filterSelectedRows(type);
    showTitle(type);
    showText(type);
  }, [type]);

  return (
    <Modal
      open={true}
      title={`批量${title}`}
      centered
      width={800}
      onCancel={() => {
        props.onCancel?.();
      }}
      footer={(originNode, extra) => {
        return (
          <>
            <Divider />
            <Flex justify="space-between">
              <Text type="secondary">{showTips}</Text>
              <Space>
                <Button
                  type="default"
                  onClick={() => {
                    props.onCancel?.();
                  }}>
                  取消
                </Button>
                <Button type="primary" disabled={canCancelNum === 0} onClick={handleOk}>
                  确定
                </Button>
              </Space>
            </Flex>
          </>
        );
      }}>
      {/* <Spin spinning={loading}> */}
      <Flex justify="center">
        <Text type="secondary">
          共选择{props.selectedRows.length}条数据，可{title}
          {canCancelNum}条数据
        </Text>
      </Flex>
      {/* 汇总表格 */}
      <ProTable
        headerTitle="汇总"
        size="small"
        rowKey="id"
        style={{ marginBottom: 12 }}
        request={requestSummary}
        columns={columnsSummary}
        scroll={{ x: 700, y: 200 }}
        search={false}
        options={false}
        ghost={true}
        pagination={false}
      />
      {/* 明细表格 */}
      <ProTable
        headerTitle="明细"
        size="small"
        rowKey="id"
        request={requestDetail}
        columns={TabValue === 'IOAOSO' ? columnsDetail : columnsBatchDetail}
        scroll={{ x: 700, y: 200 }}
        search={false}
        options={false}
        ghost={true}
        pagination={false}
      />
      {/* 审批意见 */}
      {type === 'canAudit' && (
        <ProForm formRef={formRef} style={{ marginTop: 12 }} submitter={false}>
          <ProFormSelect
            options={[
              {
                value: 2,
                label: '拒绝',
              },
              {
                value: 1,
                label: '同意',
              },
            ]}
            name="auditStatus"
            label="审批意见"
            formItemProps={{
              rules: [
                {
                  required: true,
                  message: '审批意见必填',
                },
              ],
            }}
          />
          <ProFormTextArea
            name="auditRemark"
            label="审批说明"
            formItemProps={{
              rules: [
                {
                  max: 200,
                  message: '审批说明不能超过 200 字',
                },
              ],
            }}
          />
        </ProForm>
      )}
      {/* </Spin> */}
    </Modal>
  );
};
