import { getFilesUrl_exKernelAdmin } from '@/api/services';
import { Image } from 'antd';
import { useEffect, useState } from 'react';

export function PreviewImage({ record, value }: { value: any; record: any }) {
  const [fileList, setFileList] = useState<any[]>([]);

  const fetchFilelUrl = async (filePath: string) => {
    const duration = 3600 * 1000 * 24;
    return getFilesUrl_exKernelAdmin({
      queryParams: { duration, filePath },
    });
  };

  const onValueChange = async () => {
    const currentFileList = [...(record.voucher || [])];

    const newFileList = await Promise.all(
      currentFileList.map(async (item): Promise<any> => {
        if (item?.url) return item;
        const res = await fetchFilelUrl(item!);
        return { url: res.data };
      }),
    );

    setFileList(newFileList as any);
  };

  useEffect(() => {
    onValueChange();
  }, [record.voucher]);

  return (
    <div className='flex gap-4'>
    {
      fileList.map((item) => (
        <Image width={50} key={item?.uid} src={item?.url ?? ''} />
      ))
    }
    </div>
  );
}
