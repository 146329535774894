import {
  getAdminV1FilesFileId_mcexpOperation,
  getOssPolicyByDir_mciToolbox,
  postNouserOssBucketAccessUrlV2_mciToolbox,
  postOssV2FileDelete_mciToolbox,
} from '@/api/services';
import { AdminFileResp } from '@/api/types';
import { FileExcelOutlined, FileImageOutlined, FileOutlined, FilePdfOutlined } from '@ant-design/icons';
import { setConfigProvider } from '@mci/design-system';

// 组件库需要提供的接口信息
export const initUploadProvider = () => {
  setConfigProvider({
    api: {
      getOssPolicyByDir_mciToolbox,
      postOssV2FileDelete_mciToolbox,
      postNouserOssBucketAccessUrlV2_mciToolbox,
    },
  });
};

/*获取完 整oss路径*/
export const getOssInfoByFilePath = async (filePath: string) => {
  const url = await getOSSBucketAccessUrl(filePath);
  return url;
};

// - mci-toolbox 的接口，已经不用了
export const getOSSBucketAccessUrl = async (filePath: string) => {
  const resp = await postNouserOssBucketAccessUrlV2_mciToolbox({
    requestBody: {
      filePath,
    },
  });
  return resp!.data as string;
};

/**
 * 通用聚合的文件信息
 * @param fileId 兼容多种
 *
 * toolbox
 * mci-pub-upload-dev1/public/0c42a2ea72914658a1ba018652412c2bbasketball.png
 *
 * exmtOss（交易所）
 * 1795275191548567553
 *
 * @returns
 */
export const getOssInfoByFileId = async (fileId: string) => {
  // 如果包含 `/`，那么是后端原版的 oss 文件路径，否则是交易所的一个数字哈希
  const isCommonss = fileId.includes('/');

  let data: AdminFileResp;
  if (!isCommonss) {
    const resp = await getAdminV1FilesFileId_mcexpOperation({
      pathParams: {
        fileId,
      },
    });
    data = resp.data!;
  } else {
    const url = await getOSSBucketAccessUrl(fileId);
    data = {
      fileId,
      fileName: parseOssFileName(url),
      ossUrl: url,
    };
  }
  return data;
};

/**
 * 解析 oss 文件名
 * @param url 支持 filekey 以及解析后的绝对路径
 *
 * NOTE: 这是 mci-toolbox 的，admin 自己用新的接口，已经可以获取文件名了
 */
export const parseOssFileName = (url: string) => {
  let fileName = url.split('/').pop() || '';
  let idx = -1;
  if (fileName) {
    idx = fileName.indexOf('?');
    if (idx !== -1) {
      fileName = fileName.slice(0, idx);
    }

    // 剔除减号前面的哈希前缀
    //   eg. url: "mci-pub-upload-dev1/public/202405/91a177f5507d460aa00aea814c3d08fc068-藕田路店.pdf"
    idx = fileName.lastIndexOf('-');
    if (idx !== -1) {
      fileName = fileName.slice(fileName.lastIndexOf('-') + 1);
    }

    // 解码绝对路径用的 url 编码
    //   eg. "https://mci-pub-upload-dev1.oss-cn-shenzhen.aliyuncs.com/public/202405/91a177f5507d460aa00aea814c3d08fc068-%E8%97%95%E7%94%B0%E8%B7%AF%E5%BA%97.pdf?Expires=1716798288&OSSAccessKeyId=LTAI5tDKjMnXVz8d8ZZ9Nejw&Signature=1vz0PFyKPgwXtBDwFiWViiW9un4%3D"
    //   "%E8%97%95%E7%94%B0%E8%B7%AF%E5%BA%97.pdf" => "藕田路店.pdf"
    if (fileName.includes('%')) {
      fileName = decodeURIComponent(fileName);
    }

    // 如果路近有中文，从中文开始
    //   mci-pub-upload-dev1/public/202404/869271f7029b4559b688a07804cf3478源代码.docx => 源代码.docx
    idx = fileName.search(/[\u4e00-\u9fa5]/);
    if (idx !== -1) {
      fileName = fileName.slice(idx);
    }
  }
  return fileName;
};

/**
 * 获取文件后缀
 *
 * @param url 文件路径，支持 filekey
 *
 * 会自动剔除 querystring
 */
export const getFileExt = (url: string) => {
  let ext = url.split('.').pop();
  if (ext) {
    ext = ext.split('?')[0];

    // 有的文件是 JPG 大写的形式，需要统一
    ext = ext.toLowerCase();
  }
  return ext || '';
};

export const isImageUrl = (url: string) => {
  const ext = getFileExt(url);
  return ['jpg', 'jpeg', 'png', 'gif'].includes(ext);
};

export const isDocxUrl = (url: string) => {
  const ext = getFileExt(url);
  // TODO: 预览功能 csv numbers 我都没试过，有空看看是否支持
  // TODO: 边界情况太多了，如果能有一个组件测试工具就更好了
  const docExtList = ['doc', 'docx', 'xlsx', 'xls', 'csv', '.numbers'];
  return docExtList.includes(ext);
};

export const isVideoUrl = (url: string) => {
  const ext = getFileExt(url);
  return ['mp4', 'avi', 'mov', 'ogg', 'wmv', 'webm'].includes(ext);
};

export const isAudioUrl = (url: string) => {
  const ext = getFileExt(url);
  return ['mp3', 'wav', 'ogg'].includes(ext);
};

export const isPdfUrl = (url: string) => {
  const ext = getFileExt(url);
  return ext === 'pdf';
};

export type FileType = 'image' | 'docx' | 'pdf' | 'video' | 'audio' | 'unknown';

export const getFileType = (url: string): FileType => {
  if (isImageUrl(url)) return 'image';
  if (isDocxUrl(url)) return 'docx';
  if (isPdfUrl(url)) return 'pdf';
  if (isVideoUrl(url)) return 'video';
  if (isAudioUrl(url)) return 'audio';
  return 'unknown';
};

export const getFileIcon = (type: FileType) => {
  switch (type) {
    case 'image':
      return <FileImageOutlined />;
    case 'pdf':
      return <FilePdfOutlined />;
    case 'docx':
      return <FileExcelOutlined />;
    default:
      return <FileOutlined />;
  }
};

export const isAbsUrl = (url: string) => {
  return url.includes('://');
};

export const canPreviewFileType = (fileType: FileType) => {
  const canPreviewFileTypes = ['docx', 'video', 'pdf', 'audio'];
  return canPreviewFileTypes.includes(fileType);
};

export const getPreviewDocxUrl = (url: string) => {
  // - 微软的
  // const viewerUrl = 'https://view.officeapps.live.com/op/view.aspx';

  // - 公司内的
  const viewerUrl = 'https://owa.mcisaas.com/op/view.aspx';
  const previewUrl = `${viewerUrl}?src=${decodeURIComponent(url)}`;
  return previewUrl;
};

/**
 * 下载文件
 * @param url 下载地址
 * @param fileName 设置 fileName，注意跨域是无法设置文件名的
 *
 * a标签下载文件并重命名的注意事项
 * https://juejin.cn/post/7172495567300362248
 *
 * 前端实现文件下载的几种方法
 * https://juejin.cn/post/6989907294435688478
 *
 */
export const downloadFile = (url: string, fileName = '') => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.target = '_blank';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * 下载oss文件
 * @param src oss相对路径
 * @param fileName 自定义下载的文件名
 */
export const downloadOssFileAsBlob = async (url: string, fileName?: string) => {
  // const tempFilename = filename || getFileName(src);
  const name = fileName;
  const res = await fetch(url);
  const blob = await res.blob();
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = name!;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};


/**
 * 下载oss文件
 * @param src oss相对路径
 * @param fileName 自定义下载的文件名
 */
export const downloadOssFileAsBlobNoUrl = async (res:any, fileName?: string) => {
  // const tempFilename = filename || getFileName(src);
  const name = fileName;
  const blob = await res.blob();
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = name!;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

/**
 * 下载指定文本
 * @param text
 * @param fileName
 */
export const downloadString = async (text: string, fileName?: string) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  await downloadOssFileAsBlob(url, fileName);
};

export const openFileInNewTab = (url: string) => {
  // 注意，不是所有文件都可以直接在新标签页打开，比如 docx 依然是直接下载的
  window.open(url, '_blank');
};
export const getOssFileName = (url = '') => {
  const [filePath = ''] = url?.split('/').slice(-1) || [];
  let [fileName] = filePath.split('?') || [''];
  if (fileName.length > 32) {
    fileName = fileName.slice(32);
  }
  return fileName;
};
