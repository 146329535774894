import type { ActionType } from '@ant-design/pro-components';
import { ShelfDroApplyQueryResp } from '@/api/types';

export type PathComponentMap = Record<string, React.ReactNode>;

declare global {
  interface Window {
    __POWERED_BY_QIANKUN__: boolean;
    mci_global_loading_modal: {
      request2Show: (fullApiUrl: string, hintText?: string) => void;
      request2Hide: (fullApiUrl: string) => void;
    };
  }
}

export enum AuditStatus {
  DRAFT = 0, // 草稿
  PENDING = 1, // 待审核
  APPROVED = 2, // 审核通过
  REJECTED = 3, // 已拒绝
  RETURNED = 4, // 驳回
}

export interface DROActionProps {
  record: ShelfDroApplyQueryResp;
  ctx: {
    actionRef: React.MutableRefObject<ActionType>;
  };
  action: any;
}
