import { ChannelRsp, ContractChannelRelationReq, ContractEntity } from '@/api/types';
import {
  BetaSchemaForm,
  ProColumnType,
  ProColumns,
  ProDescriptions,
  ProDescriptionsItemProps,
  ProForm,
  ProFormColumnsType,
  ProFormInstance,
  ProTable,
} from '@ant-design/pro-components';
import { App, Flex, Modal, Space, Tag, Typography } from 'antd';
import { FC, useRef, useState } from 'react';
import { mock_channel_bind, mock_channel_pool } from '../__mock__';
import useMciRequest from '@/api/useMciRequest';
import {
  getContractListDivideChannelByOrgIdOrgId_mcexpOperation,
  postContractUpdateContractChannelRelation_mcexpOperation,
} from '@/api/services';
import { sleep } from '@mci-fe/utils';

interface ContractChannelProps {
  record?: ContractEntity;
  onFinish?: () => void;
  onCancel?: () => void;
}

/**
 * 绑定银行渠道
 *
 * 已经绑定过的不能取消
 */
export const ContractChannel: FC<ContractChannelProps> = (props) => {
  const [rowKeys, setRowKeys] = useState<string[]>([]);
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const channelList = useRef<ChannelRsp[]>([]);

  const handleOk = async () => {
    if (!channelList.current) {
      message.error('渠道列表信息不存在');
      return;
    }

    const channelResults: ContractChannelRelationReq[] = [];
    // 后端需要全量提交，包括已经关联了的
    for (let i = 0; i < channelList.current.length; i++) {
      const channel = channelList.current[i];

      // TODO: 逻辑很乱，待整理
      // 默认一开始勾选的不要提交
      if (channel.relateStatus === 1) continue;

      // const relateStatus = channel.relateStatus === 1 ? 1 : rowKeys.includes(channel.channelNo!) ? 1 : 0;
      const relateStatus = rowKeys.includes(channel.channelNo!) ? 1 : 0;

      // 后端说不要提交去勾选的
      if (relateStatus === 0) continue;

      const channelResult: ContractChannelRelationReq = {
        channelName: channel.channelName,
        channelNo: channel.channelNo,
        channelType: channel.channelType,
        orgId: props.record?.orgId || '',
        relateStatus,
      };
      channelResults.push(channelResult);
    }

    setLoading(true);

    let error = '';
    const resp = await postContractUpdateContractChannelRelation_mcexpOperation({
      requestBody: channelResults,
    }).catch((e) => {
      error = e.message;
    });

    // -- mock --
    // await sleep(500);
    // const resp = mock_channel_bind;
    // console.warn('mock');

    setLoading(false);

    // TODO: error case

    if (!error) {
      props.onFinish?.();
    }
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  const columns = [
    {
      title: '主体编码',
      dataIndex: 'orgId',
    },
    {
      title: '所属地区类型',
      dataIndex: 'entAddressType',
      valueType: 'select',
      valueEnum: {
        0: '境内',
        1: '境外',
      },
    },
    {
      title: '企业名称',
      dataIndex: 'entName',
    },
    {
      title: '统一社会信用代码/公司代码',
      dataIndex: 'entCreditCode',
    },
  ] satisfies ProDescriptionsItemProps[];

  const record = props.record || {};

  // -- mock --
  // const record = {
  //   orgId: 'orgId',
  //   entAddressType: 0,
  //   entName: 'entName',
  //   entCreditCode: 'entCreditCode',
  // };

  type dataKeys = (typeof columns)[number]['dataIndex'];

  const dataSource: Record<dataKeys, any> = {
    orgId: record.orgId,
    entAddressType: record.entAddressType,
    entName: record.entName,
    entCreditCode: record.entCreditCode,
  };

  const channelColumns: ProColumns<ChannelRsp>[] = [
    {
      title: '序号',
      dataIndex: 'indexBorder',
      valueType: 'index',
      width: 48,
    },
    {
      title: '渠道ID',
      dataIndex: 'channelNo',
    },
    {
      title: '渠道名称',
      dataIndex: 'channelName',
    },
    {
      title: '渠道类型',
      dataIndex: 'channelType',
    },
    {
      title: '渠道关联关系',
      dataIndex: 'relateStatus',
      valueType: 'select',
      valueEnum: {
        0: '未关联',
        1: '已关联',
      },
      render: (dom, record) => {
        return <Tag color={record.relateStatus === 1 ? 'success' : 'default'}>{dom}</Tag>;
      },
      width: 120,
    },
  ];

  const request: React.ComponentProps<typeof ProTable>['request'] = async (params) => {
    setLoading(true);

    let error = '';
    const resp = await getContractListDivideChannelByOrgIdOrgId_mcexpOperation({
      pathParams: {
        orgId: record.orgId!,
      },
    }).catch((e) => {
      error = e.message;
    });

    // -- mock --
    // await sleep(500);
    // const resp = mock_channel_pool;
    // console.warn('mock');

    setLoading(false);

    // 后端获取不到 arm 那边 channel 信息或直接报错
    if (error) {
      return {};
    }

    const channels = resp!.data!.channels || [];
    const total = channels.length;

    channelList.current = channels;

    return {
      data: channels,
      total,
      success: true,
    };
  };

  return (
    <Modal
      title="关联渠道"
      open={true}
      onOk={handleOk}
      onCancel={handleCancel}
      width={1000}
      confirmLoading={loading}
      okButtonProps={{
        disabled: rowKeys.length === 0,
      }}
      footer={(originNode, extra) => {
        return (
          <Flex justify="space-between" align="center">
            <Typography.Text type='secondary'>温馨提示：已关联渠道，不可二次关联。</Typography.Text>
            <Space>{originNode}</Space>
          </Flex>
        );
      }}>
      <ProDescriptions
        columns={columns}
        dataSource={dataSource}
        style={{ marginTop: 20 }}
        column={2}
        labelStyle={{ marginLeft: 20 }}
      />
      <ProTable
        headerTitle="渠道列表"
        columns={channelColumns}
        search={false}
        options={false}
        pagination={false}
        style={{ marginTop: 20 }}
        tableAlertRender={false}
        loading={loading}
        request={request}
        rowKey="channelNo"
        rowSelection={{
          getCheckboxProps: (record) => ({
            disabled: record.relateStatus === 1,
          }),
          selectedRowKeys: rowKeys,
          onChange: (selectedRowKeys, selectedRows) => {
            setRowKeys(selectedRowKeys as string[]);
          },
        }}
      />
    </Modal>
  );
};
