import { EEnv } from './log/evn';
import { getEnvByHost } from './devtool';


const logServerDomains: Record<string, unknown> = {
  // 目前只部署了DEV和PROD两套环境
  [EEnv.DEV]: 'logan-server.mcp-dev1.mcisaas.com',
  [EEnv.STG]: 'logan-server.mcp-dev1.mcisaas.com',
  [EEnv.UAT]: 'logan-server.mcisaas.com',
  [EEnv.PROD]: 'logan-server.mcisaas.com',
};

// 获取日志上报地址
export function getReportUrlByEnv() {
  const env = getEnvByHost();
  return `https://${logServerDomains[env]}/logan-web/logan/web/upload.json`;
}