import { postAdminV1OrderCancel_mcexpOperation, postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation } from '@/api/services';
import { ExDirectOrderRsp, ExMatchOrderRsp } from '@/api/types';
import { ProDescriptions, ProDescriptionsItemProps } from '@ant-design/pro-components';
import { App, Divider, Flex, Modal, Space, Typography } from 'antd';
import { FC, useState, useContext, useEffect } from 'react';
import { DROContext } from '@/pages/Order/dro/OrderDRO';
import { IOAOColumns, SOColumns, BlockColumns } from './columns';
import { sleep } from '@mci-fe/utils';

interface DROWithdrawProps {
  record?: ExDirectOrderRsp | ExMatchOrderRsp;
  onFinish?: () => void;
  onCancel?: () => void;
}

/**
 * 撤单详情
 *
 * 仅支持未成交份额的全额撤单
 */
export const OrderDroWithdraw: FC<DROWithdrawProps> = (props) => {
  const { TabValue } = useContext(DROContext);
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);
  const [orderCancel, setOrderCancel] = useState<number>(0);
  const [columns, setColumns] = useState<ProDescriptionsItemProps[]>([]);
  const [modalApi, modalContextHolder] = Modal.useModal();

  const handleOk = async () => {
    if (orderCancel <= 0) {
      message.info('可撤单份额为零。');
      return;
    }
    setLoading(true);

    modalApi.confirm({
      title: '提示',
      content: '确认撤销此订单？',
      onOk: async () => {
        if(!record.orderCode) {
          message.error('订单编码不存在。');
        } else {
          let error = '';
          if(TabValue === 'FromBlock') {
            const requestBody = {
              orderCodeList: [record.orderCode!],
            };

            const resp = await postAdminV1OrderBatchManualBlockTradeBatchCancel_mcexpOperation({
              requestBody,
            }).catch((e) => (error = e.message)); 
          } else {
            const requestBody = {
              orderCode: record.orderCode,
              cancelType: TabValue === 'FromSO' ? 3 : 1 // 撤单类型 1-IO 3-SO
            };

            const resp = await postAdminV1OrderCancel_mcexpOperation({
              requestBody,
            }).catch((e) => (error = e.message));
          }
          if (!error) {
            props.onFinish?.();
          }
          setLoading(false);
        }
      },
      onCancel: () => {
        setLoading(false);
      },
      maskClosable: true,
    });
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  const record = props.record || {};

  useEffect(() => {
    let newColumns;
    let isAllowCancel;

    switch (TabValue) {
      case 'FromIOAO':
        newColumns = IOAOColumns;
        isAllowCancel = Number((record as ExDirectOrderRsp).buyShares) - Number((record as ExDirectOrderRsp).confirmShares);
        break;
      case 'FromSO':
        newColumns = SOColumns;
        isAllowCancel = Number((record as ExMatchOrderRsp).totalShares) - Number((record as ExMatchOrderRsp).confirmShares);
        break;
      case 'FromBlock':
        newColumns = BlockColumns;
        isAllowCancel = Number((record as ExDirectOrderRsp).buyShares);
        break;
      default:
        newColumns = IOAOColumns;
        isAllowCancel = Number((record as ExDirectOrderRsp).buyShares) - Number((record as ExDirectOrderRsp).confirmShares);
    }

    setColumns(newColumns);
    setOrderCancel(isAllowCancel);
  }, [TabValue]);

  return (
    <>
      <Modal
        title="撤单"
        open={true}
        onOk={handleOk}
        onCancel={handleCancel}
        width={600}
        confirmLoading={loading}
        // okButtonProps={{
        //   disabled: orderCancel <= 0,
        // }}
        footer={(originNode, extra) => {
          return (
            <>
              <Divider />
              <Flex justify={`${TabValue !== 'FromBlock' ? 'space-between' : 'flex-end'}`} align="center">
                {TabValue !== 'FromBlock' && (
                  <Typography.Text type="secondary">温馨提示：目前仅支持未成交份额的全额撤单。</Typography.Text>
                )}
                <Space>{originNode}</Space>
              </Flex>
            </>
          );
        }}>
        <ProDescriptions
          columns={columns}
          dataSource={record}
          style={{ marginTop: 20, marginLeft: '15%' }}
          column={1}
          labelStyle={{ marginRight: 20, justifyContent: 'flex-end' }}
        />
      </Modal>
      {modalContextHolder}
    </>
  );
};
