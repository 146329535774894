import { Descriptions, Table, Tag } from 'antd';
import type { TableProps } from 'antd';
import { DateIncomePcf } from '@/api/types';
import useMciRequest from '@/api/useMciRequest';
import { useParams } from 'react-router-dom';

export default function PCFCashFlow(props: { appId: string }) {
  const { appId } = props;
  const { loading, data: resp } = useMciRequest(
    'getAdminV1ShelfDroAppliesAppIdPcf_mcexpOperation',
    {
      pathParams: {
        appId: appId!,
      },
    },
    {
      isJoinGlobalLoading: false,
    },
  );
  const { model, incomePcfList } = resp?.data || {};
  const columns: TableProps<DateIncomePcf>['columns'] = [
    {
      title: '联营日',
      dataIndex: 'date',
    },
    {
      title: '预估营业额',
      dataIndex: 'income',
    },
    {
      title: '预估分成比例',
      dataIndex: 'shareRate',
    },
    {
      title: 'PCF总额',
      dataIndex: 'share',
    },
  ];

  return (
    <Descriptions
      layout="vertical"
      column={1}
      items={[
        {
          key: '1',
          label: 'PCF预估模型',
          children: <Tag color="blue">{model || '--'}</Tag>,
        },
        {
          key: '2',
          label: '现金流表格',
          children: (
            <Table
              bordered
              columns={columns}
              dataSource={incomePcfList || []}
              style={{ width: '100%' }}
              loading={loading}
            />
          ),
        },
      ]}
    />
  );
}
