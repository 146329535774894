import { servicesMap } from '@/api/services';
import type { ExMatchOrderRsp } from '@/api/types';
import { MciProTableSchema } from '@mci/lowcode-components/src/components/mci-protable/types';
import { DefaultOptionType } from 'antd/es/select';
import { mock_getList } from './__mock__';
import { time } from 'console';

type ServiceName = keyof typeof servicesMap;

export const pageSchema = {
  version: '1.0.0',
  componentsTree: [
    {
      componentName: 'Page',
      id: 'node_dock8fo1',
      props: {
        ref: 'outerView',
        style: {
          height: '100%',
        },
      },
      fileName: '/',
      dataSource: {
        // nothing
      },
      state: {
        text: {
          type: 'JSExpression',
          value: '"outer"',
        },
        isShowDialog: {
          type: 'JSExpression',
          value: 'false',
        },
      },
      css: 'body {\n  font-size: 12px;\n}\n\n.button {\n  width: 100px;\n  color: #ff00ff\n}',
      methods: {
        testFunc: {
          type: 'JSFunction',
          value: "function testFunc() {\n  console.log('test func');\n}",
        },
        onClick: {
          type: 'JSFunction',
          value: 'function onClick() {\n  alert(123)  }',
        },
        closeDialog: {
          type: 'JSFunction',
          value: 'function closeDialog() {\n  this.setState({\n    isShowDialog: false\n  });\n}',
        },
      },
      originCode:
        'class LowcodeComponent extends Component {\n  state = {\n    "text": "outer",\n    "isShowDialog": false\n  }\n  componentDidMount() {\n    console.log(\'did mount\');\n  }\n  componentWillUnmount() {\n    console.log(\'will unmount\');\n  }\n  testFunc() {\n    console.log(\'test func\');\n  }\n  onClick() {\n    this.setState({\n      isShowDialog: true\n    })\n  }\n  closeDialog() {\n    this.setState({\n      isShowDialog: false\n    })\n  }\n}',
      hidden: false,
      title: '',
      isLocked: false,
      condition: true,
      conditionGroup: '',
      children: [
        {
          componentName: 'MciProTable',
          props: {
            service: {
              query: {
                serviceName: 'postAdminV1OrderMatchList_mcexpOperation',
                // mockRes: mock_getList,
                paramPreprocessor: {
                  type: 'JSFunction',
                  value: function (params: any) {
                    // string 转 int
                    if (params.channelList) {
                      params.channelList = params.channelList.map((item: string) => parseInt(item));
                    }
                    if (params.orderStatusList) {
                      params.orderStatusList = params.orderStatusList.map((item: string) => parseInt(item));
                    }
                    if (params.orderDirectionList) {
                      params.orderDirectionList = params.orderDirectionList.map((item: string) => parseInt(item));
                    }
                    if (params.captureStatusList) {
                      params.captureStatusList = params.captureStatusList.map((item: string) => parseInt(item));
                    }
                    // 产品类型，区分1 dro和2 drp，写死就好
                    params.productType = 1;
                    // antd 的分页参数和后端的不一样，需要转换
                    params.page = params.current;
                    params.size = params.pageSize;
                    delete params.pageSize;
                    delete params.current;
                    return params;
                  }.toString(),
                },
                transformer: {
                  type: 'JSFunction',
                  value: function (res: any) {
                    if(res.data.content?.length > 0) {
                      res.data.content = res.data.content.map((item: any) => {
                        return {
                          ...item,
                          orderStatus: item.orderStatus?.toString(),
                          captureStatus: item.captureStatus?.toString()
                        };
                      });
                    }
                    return { data: res.data.content, success: res.code === '0000', total: res.data.page.totalElements };
                  }.toString(),
                },
                searchConfig: {
                  labelWidth: 120,
                },
              },
              export: {
                serviceName: 'postAdminV1OrderMatchListExport_mcexpOperation',
              },
            },
            columnSetting: [
              {
                dataIndex: 'id',
                title: '序号',
                valueType: 'indexBorder',
                width: 48,
                hideInSearch: true,
                fixed: 'left',
              },
              {
                title: '下单日期',
                dataIndex: 'createTime',
                width: 170,
                hideInSearch: true,
                fixed: 'left',
              },
              {
                title: '订单号',
                dataIndex: 'orderCode',
                width: 150,
                hideInSearch: true,
                ellipsis: true,
                fixed: 'left',
              },
              {
                title: '产品编码',
                dataIndex: 'productCode',
                width: 150,
                hideInSearch: true,
                fixed: 'left',
              },
              {
                title: '渠道',
                dataIndex: 'channel',
                width: 150,
                valueType: 'select',
                valueEnum: {
                  1: { text: 'M-Terminal' },
                  2: { text: '交易平台手工下单' },
                },
                hideInSearch: true,
                fixed: 'left',
              },
              {
                title: '会员ID',
                dataIndex: 'memberCode',
                width: 130,
                hideInSearch: true,
                fixed: 'left',
              },
              {
                title: '会员名称',
                dataIndex: 'identificationName',
                width: 130,
                ellipsis: true,
                hideInSearch: true,
              },
              {
                title: '交易账号',
                dataIndex: 'tradingAccountCode',
                width: 150,
                ellipsis: true,
                hideInSearch: true,
              },
              {
                title: '交易方向',
                dataIndex: 'orderDirection',
                width: 100,
                valueType: 'select',
                valueEnum: {
                  0: '卖',
                  1: '买',
                },
                hideInSearch: true,
              },
              {
                title: '订单状态',
                dataIndex: 'orderStatus',
                width: 120,
                valueType: 'select',
                valueEnum: {
                  '-1': '下单失败',
                  '1': '有效',
                  '5': '取消',
                  '4': '未成交',
                  '2': '部分成交',
                  '3': '全部成交',
                },
                hideInSearch: true,
              },
              {
                title: '撮合状态',
                dataIndex: 'captureStatus',
                width: 120,
                valueType: 'select',
                valueEnum: {
                  '-1': '--',
                  '1': '待撮合',
                  '2': '交易中（部分撮合）',
                  '3': '已撮合',
                  '4': '撮合失败',
                  '5': '--',
                },
                ellipsis: true,
                hideInSearch: true,
              },
              {
                title: '币种',
                dataIndex: 'currency',
                width: 120,
                hideInSearch: true,
              },
              {
                title: '下单份额',
                dataIndex: 'totalShares',
                width: 120,
                hideInSearch: true,
              },
              {
                title: '下单价格',
                dataIndex: 'bidPrice',
                width: 120,
                valueType: {
                  type: 'money',
                  moneySymbol: false,
                  precision: 2,
                },
                hideInSearch: true,
              },
              {
                title: '下单金额',
                dataIndex: 'buyMoney',
                width: 120,
                valueType: {
                  type: 'money',
                  moneySymbol: false,
                  precision: 2,
                },
                hideInSearch: true,
              },
              {
                title: '下单总费用',
                dataIndex: 'fee',
                width: 120,
                valueType: {
                  type: 'money',
                  moneySymbol: false,
                  precision: 2,
                },
                // TODO: 暂未开发
                // cellRenderer: 'OrderSumToolTip',
                hideInSearch: true,
              },
              {
                title: '订单冻结金额',
                dataIndex: 'frozenShare',
                width: 120,
                valueType: {
                  type: 'money',
                  moneySymbol: false,
                  precision: 2,
                },
                hideInSearch: true,
              },
              {
                title: '成交份额',
                dataIndex: 'confirmShares',
                width: 120,
                hideInSearch: true,
              },
              // {
              //   title: '成交价格',
              //   dataIndex: 'confirmPrice',
              //   width: 120,
              //   valueType: {
              //     type: 'money',
              //     moneySymbol: false,
              //     precision: 2,
              //   },
              //   hideInSearch: true,
              // },
              // {
              //   title: '成交金额',
              //   dataIndex: 'confirmMoney',
              //   width: 120,
              //   valueType: {
              //     type: 'money',
              //     moneySymbol: false,
              //     precision: 2,
              //   },
              //   hideInSearch: true,
              // },
              // {
              //   title: '成交总费用',
              //   dataIndex: 'confirmTotalMoney',
              //   width: 120,
              //   valueType: {
              //     type: 'money',
              //     moneySymbol: false,
              //     precision: 2,
              //   },
              //   // TODO: 暂未开发
              //   // cellRenderer: 'OrderSumTwoToolTip',
              //   hideInSearch: true,
              // },
              {
                title: '成交时间',
                dataIndex: 'confirmTime',
                width: 170,
                hideInSearch: true,
              },
              // search
              {
                dataIndex: 'createTimeRange',
                title: '下单日期',
                valueType: 'dateRange',
                hideInTable: true,
                initialValue: [new Date().toISOString().split('T')[0], new Date().toISOString().split('T')[0]],
                transform: (value: any) => {
                  return {
                    createTimeStart: value[0],
                    createTimeEnd: value[1],
                  };
                },
              },
              {
                title: '订单号',
                dataIndex: 'orderCode',
                hideInTable: true,
              },
              {
                title: '渠道',
                dataIndex: 'channelList',
                valueType: 'select',
                valueEnum: {
                  1: 'M-Terminal',
                  2: '交易平台手工下单',
                },
                fieldProps: {
                  mode: 'multiple',
                  filterSort: (a: DefaultOptionType, b: DefaultOptionType) => {
                    return Number(a.value) - Number(b.value);
                  },
                },
                hideInTable: true,
              },
              {
                title: '会员名称',
                dataIndex: 'identificationName',
                hideInTable: true,
              },
              {
                title: '交易账号',
                dataIndex: 'tradingAccountCode',
                hideInTable: true,
              },
              {
                title: '订单状态',
                dataIndex: 'orderStatusList',
                valueType: 'select',
                valueEnum: {
                  '-1': '下单失败',
                  '1': '有效',
                  '5': '取消',
                  '4': '未成交',
                  '2': '部分成交',
                  '3': '全部成交',
                },
                fieldProps: {
                  mode: 'multiple',
                },
                hideInTable: true,
              },
              {
                title: '产品编码',
                dataIndex: 'productCodeList',
                hideInTable: true,
                valueType: 'select',
                fieldProps: {
                  placeholder: '多个编码之间用空格或者英文逗号分隔',
                  mode: 'tags',
                  tokenSeparators: [' ', ','],
                  open: false,
                },
                ellipsis: true,
              },
              {
                title: '交易方向',
                dataIndex: 'orderDirectionList',
                valueType: 'select',
                valueEnum: {
                  0: '卖',
                  1: '买',
                },
                fieldProps: {
                  mode: 'multiple',
                },
                hideInTable: true,
              },
              {
                title: '撮合状态',
                dataIndex: 'captureStatusList',
                valueType: 'select',
                valueEnum: {
                  1: '待撮合',
                  2: '部分撮合',
                  3: '已撮合',
                  4: '撮合失败',
                },
                fieldProps: {
                  mode: 'multiple',
                },
                hideInTable: true,
              },
              {
                title: '操作',
                dataIndex: '__action__',
                valueType: 'option',
                width: 100,
                actions: [
                  {
                    nameRenderer: 'OrderDroOpBtns',
                  },
                ],
                fixed: 'right',
                hideInSearch: true,
              },
            ],

            // TODO: 暂时隐藏导出
            // toolBarActions: ['export'],
            tableSetting: {
              title: '',
              pageSize: 10,
              scrollX: 1600,
            },
            // TODO: 这里的 columns 的 dataIndex 如果能支持到这个字段就好了
          } as MciProTableSchema<ServiceName, ExMatchOrderRsp>,
        },
      ],
    },
  ],
  i18n: {},
};
