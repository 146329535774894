import { FileAddOutlined, LoadingOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Select } from 'antd';
import { forwardRef, useState } from 'react';

type FieldType = {
  pageName: string;
  menuText: string;
  devMode: 'scheme' | 'tsx';
};

export default forwardRef<HTMLDivElement, { children: React.ReactNode; prefixPath: string }>(
  function VirtualMenuItem(props, ref) {
    const [loading, setLoading] = useState(false);
    const [modal2Open, setModal2Open] = useState(false);
    const [formInstance] = Form.useForm<FieldType>();

    const requestDevServer2GenerateMenu = ({ pageName, menuText, devMode }: FieldType) => {
      const apiUrl = new URL('/request2generateMenu', 'http://localhost:8082');
      apiUrl.searchParams.append('prefixPath', props.prefixPath);
      apiUrl.searchParams.append('pageName', pageName);
      apiUrl.searchParams.append('menuText', menuText);
      apiUrl.searchParams.append('devMode', devMode);
      setLoading(true);
      fetch(apiUrl, {
        method: 'get'
      })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    };

    const handleSubmit = async () => {
      try {
        const formData = await formInstance.validateFields();
        requestDevServer2GenerateMenu(formData);
      } catch (error) {
        console.log(error);
      }
    };

    return (
      <>
        <div ref={ref} className="flex items-center" onClick={() => setModal2Open(true)}>
          <div className="mr-4">{loading ? <LoadingOutlined /> : <FileAddOutlined />}</div> {props.children}
        </div>
        <Modal
          title="添加新菜单"
          centered
          open={modal2Open}
          onOk={handleSubmit}
          onCancel={() => setModal2Open(false)}
          confirmLoading={loading}>
          <Form
            className="mt-1 text-12"
            form={formInstance}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}>
            <Form.Item<FieldType> label="开发模式" name="devMode" rules={[{ required: true, message: '必填' }]}>
              <Select
                placeholder="选择开发模式"
                options={[
                  { label: 'Tsx开发', value: 'dev' },
                  { label: 'LowCode Scheme', value: 'scheme' }
                ]}></Select>
            </Form.Item>
            <Form.Item<FieldType> label="菜单名称" name="menuText" rules={[{ required: true, message: '必填' }]}>
              <Input placeholder="请输入中文菜单名称" />
            </Form.Item>
            <Form.Item<FieldType> label="路由组件名" name="pageName" rules={[{ required: true, message: '必填' }]}>
              <Input placeholder="请输入小驼峰格式的名称" />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
);
