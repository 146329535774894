import React, { Component } from 'react';
import { Tabs as OriginalTabs } from 'antd';
import { warning } from '../../utils/warning';
class Tabs extends Component {
    state = {
        activeKey: this.props.activeKey ?? this.props.defaultActiveKey,
    };
    _handleChange = (key) => {
        this.setActiveKey(key);
    };
    setActiveKey = (key) => {
        const { onChange = () => { } } = this.props;
        this.setState({ activeKey: key });
        onChange(key);
    };
    getActiveKey = () => {
        return this.state.activeKey;
    };
    render() {
        const { props } = this;
        const { activeKey } = this.state;
        const { children } = props;
        let items = props.items;
        if (!props.items && Array.isArray(children) && children.length > 0) {
            warning('Tabs.TabPane is deprecated. Please use `items` directly.');
            items = children.map((node) => {
                if (React.isValidElement(node)) {
                    const { key, props } = node;
                    const { tab, ...restProps } = props || {};
                    const item = {
                        key: String(key),
                        ...restProps,
                        label: tab,
                    };
                    return item;
                }
                return null;
            }).filter(Boolean);
        }
        return (createElement(OriginalTabs, { ...props, items: items, activeKey: activeKey, onChange: this._handleChange }));
    }
}
Tabs.TabPane = OriginalTabs.TabPane;
export default Tabs;
