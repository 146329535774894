import {
  getUserV1GetBaseInfo_mciUserAuthAdminServer,
  getUserV1GetUserRoleInfo_mciUserAuthAdminServer,
  servicesMap,
} from '@/api/services';
import useMciRequest from '@/api/useMciRequest';
import GlobalLoadingModal from '@/components/GlobalLoadingModal';
import App from '@/components/Layout';
import SendEmailsInBatch from '@/components/SendEmailsInBatch';
import {
  AuditStatusName,
  EmailsFormatter,
  FranchiseMode,
  ManualConfirmationList,
  TaskFormatter,
  OrderSumToolTip,
  OrderSumTwoToolTip,
  ToTimeFormat,
  ToTradingAccount,
  BalanceFrom,
  PreviewImage,
} from '@/components/tableCellFormatter';
import { EmailAuditButton } from '@/pages/ToBeSentEmail/EmailAuditButton';
import { getKCUrl } from '@/utils';
import { ANTD_THEME } from '@mci-fe/app-config.theme';
import { initKeycloak, logout } from '@mci-fe/keycloak';
import { MciRootProProvider } from '@mci/lowcode-components/src';
import { App as AntdApp, Button, ConfigProvider, Result, Spin, Flex, Space } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { initUserAuth } from '@mci-fe/user-auth';
import { SensorUtil } from '@mci-fe/tools.sensor';
import { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import DROAuditAction from './components/actions/DROAuditAction';
import DispatchExchange from './components/actions/DispatchExchange';
import EditFlow from './components/actions/EditFlow';
import EditFundFlow from './components/actions/EditFundFlow';
import FundAllocationDetail from './components/actions/FundAllocationDetail';
import OffShelf from './components/actions/OffShelf';
import Jump2DetailAction from './components/actions/Jump2DetailAction';
import { accessConfig } from './config/access';
import ContractAddBtn from './pages/Contract/ContractList/components/ContractAddBtn';
import { ContractOpBtns } from './pages/Contract/ContractList/components/ContractOpBtns';
import { UserOpBtns } from './pages/RolesManage/UserManage/UserManageList/components/UserOpBtns';
import { ImportFlow } from '@/components/toolBarActions/ImportFlow';
import { ImportSPVFlow } from '@/components/toolBarActions/ImportSPVFlow';
import { ImportFundFlow } from '@/components/toolBarActions/ImportFundFlow';
import { DepositAuditButton } from './pages/Deposit/member/DepositAuditButton';
import { FundAllocationApproval } from './pages/Deposit/fund/Approval';
import { InvestDetailBtn } from './pages/Invsestment/InvestmentList/components/InvestDetailBtn';
import { InvestRiskFormatter } from './pages/Invsestment/InvestmentPeriodList/components/InvestRiskFormatter';
import { OrderDroOpBtns } from './pages/Order/dro/OrderDROFromIOAO/components/OrderDroOpBtns';
import { OrderDrpOpBtns } from './pages/Order/drp/OrderDRPFromIO/components/OrderDrpOpBtns';
import LogUtil from './utils/log';
import { EnumUtil } from './utils/enum-util/enum-util';
import { initUploadProvider } from './utils/oss-util';
import Logan, { log } from '@mci-fe/logan-web';
import { getReportUrlByEnv } from './utils/log-util';
import { queryUtil } from './utils/query-util';
import { RoleManageBtn } from './pages/RolesManage/RoleManage/RoleManageList/components/RoleManageBtn';
import RoleManageAddBtn from './pages/RolesManage/RoleManage/RoleManageList/components/RoleManageAddBtn';
import { SPVDepositAuditButton } from './pages/Deposit/spv/SPVDepositAuditButton';
import SpvFiles from './pages/Deposit/spv/SpvFiles';
import Devtool from '@/components/devtool/FloatButton';

let root: ReactDOM.Root;
// TODO: 这个是不是用一个通用的名字就可以了，比如每个项目都叫做 app
const id = 'private-exmt-ops-root';
let node = document.getElementById(id);
if (!node) {
  node = document.createElement('div');
  node.id = id;
  document.body.appendChild(node);
}
root = ReactDOM.createRoot(node);

function renderApp(UserAuthProvider?: React.FC<{ children: ReactNode }>) {
  LogUtil.init();
  EnumUtil.init();

  SensorUtil.init({
    // TODO: 需要一个公共类来管理，保留一些公共的query，否则已切换就没了
    show_log: queryUtil.debugSensor,
    // show_log: process.env.NODE_ENV === 'development' || queryUtil.debugSensor,
  });
  if (queryUtil.debugSensor) {
    (window as any).sensors = SensorUtil.sensors;
  }

  initUploadProvider();

  getReportUrlByEnv();
  Logan.initConfig({
    reportUrl: getReportUrlByEnv(),
  });

  const isDev = process.env.NODE_ENV === 'development';

  root.render(
    // TODO: 可以优化到根据环境判定，localhost 模式可以不要这个前缀
    // TODO: 读取 package.json
    <BrowserRouter basename={isDev ? '/' : '/workbench/private-exmt-ops'}>
      <ConfigProvider
        theme={{
          hashed: false,
          token: ANTD_THEME,
        }}>
        <AntdApp>
          {queryUtil.isDebug && <Devtool />}
          <MciRootProProvider
            apiMap={servicesMap}
            cellRendererMap={{
              EmptyFormatter: (value: any, record: any) => value || '--',
              TaskFormatter: (value: any, record: any) => <TaskFormatter value={value} record={record} />,
              EmailsFormatter: (value, record) => <EmailsFormatter value={value} record={record} />,
              InvestRiskFormatter: (value, record) => <InvestRiskFormatter value={value} record={record} />,
              FranchiseMode: (value, record) => <FranchiseMode value={value} record={record} />,
              ManualConfirmationList: (value, record) => <ManualConfirmationList value={value} record={record} />,
              AuditStatusName: (value, record) => <AuditStatusName value={value} record={record} />,
              BalanceFrom: (value, record) => <BalanceFrom value={value} record={record} />,
              PreviewImage: (value, record) => <PreviewImage value={value} record={record} />,
              SpvFiles: (value, record) => <SpvFiles value={value} record={record} />,
              OrderSumToolTip: (value, record) => <OrderSumToolTip value={value} record={record} />,
              OrderSumTwoToolTip: (value, record) => <OrderSumTwoToolTip value={value} record={record} />,
              ToTimeFormat: (value, record) => <ToTimeFormat value={value} record={record} />,
              ToTradingAccount: (value, record) => <ToTradingAccount value={value} record={record} />,
            }}
            hookMap={{
              useMciRequest,
            }}
            rowSelectionActionMap={{
              SendEmailsInBatch,
            }}
            toolBarActionMap={{
              ContractAddBtn,
              ImportFlow,
              RoleManageAddBtn,
              ImportSPVFlow,
              ImportFundFlow,
            }}
            actionMap={{
              EmailAuditButton: ({ record, action, ctx }) => (
                <EmailAuditButton key="email-audit" record={record} action={action} ctx={ctx} />
              ),
              DepositAuditButton: ({ record, action, ctx }) => (
                <DepositAuditButton key="deposit-audit" record={record} action={action} ctx={ctx} />
              ),
              ContractOpBtns: ({ record, action, ctx }) => (
                <ContractOpBtns key="contract-op-btns" record={record} action={action} ctx={ctx} />
              ),
              UserOpBtns: ({ record, action, ctx }) => (
                <UserOpBtns key="user-op-btns" record={record} action={action} ctx={ctx} />
              ),
              DROAuditAction: ({ record, action, ctx }) => (
                <DROAuditAction key="dro-audit-action" action={action} record={record} ctx={ctx} />
              ),
              DispatchExchange: ({ record, action, ctx }) => (
                <DispatchExchange key="dispatch-exchange" action={action} record={record} ctx={ctx} />
              ),
              InvestDetailBtn: ({ record, action, ctx }) => (
                <InvestDetailBtn key="investment-detail-btn" record={record} />
              ),
              Jump2DetailAction: ({ record, ctx, action }) => (
                <Jump2DetailAction key="jump2-detail-action" action={action} record={record} ctx={ctx} />
              ),
              OrderDroOpBtns: ({ record, action, ctx }) => (
                <OrderDroOpBtns key="orderDro-op-btns" record={record} action={action} ctx={ctx} />
              ),
              OrderDrpOpBtns: ({ record, action, ctx }) => (
                <OrderDrpOpBtns key="orderDrp-op-btns" record={record} action={action} ctx={ctx} />
              ),
              EditFlow: ({ record, action, ctx }) => <EditFlow key="edit-flow" record={record} ctx={ctx} />,
              EditFundFlow: ({ record, action, ctx }) => (
                <EditFundFlow key="edit-fund-flow" record={record} ctx={ctx} />
              ),
              OffShelf: ({ record, action, ctx }) => <OffShelf key="off-shelf" record={record} ctx={ctx} />,
              RoleManageBtn: ({ record, action, ctx }) => (
                <RoleManageBtn key="role-manage-btn" record={record} action={action} ctx={ctx} />
              ),
              SPVDepositAuditButton: ({ record, action, ctx }) => (
                <SPVDepositAuditButton key="deposit-audit" record={record} action={action} ctx={ctx} />
              ),
              FundAllocationApproval: ({ record, action, ctx }) => (
                <FundAllocationApproval key="fund-allocation-approval" record={record} action={action} ctx={ctx} />
              ),
              FundAllocationDetail: ({ record, action, ctx }) => (
                <FundAllocationDetail key="fund-allocation-detail" record={record} action={action} ctx={ctx} />
              ),
            }}>
            {UserAuthProvider ? (
              <UserAuthProvider>
                <App />
              </UserAuthProvider>
            ) : (
              <App />
            )}
          </MciRootProProvider>
        </AntdApp>
        <GlobalLoadingModal
          ref={(instance: any) => {
            window.mci_global_loading_modal = instance;
          }}
        />
      </ConfigProvider>
    </BrowserRouter>,
  );
}

const Loading = (props: { hintText?: string }) => {
  return (
    <ConfigProvider
      theme={{
        hashed: false,
        token: ANTD_THEME,
      }}>
      <AntdApp>
        <Flex className="h-screen w-screen fixed left-[0] top-[0]" align="center" justify="center" vertical gap={16}>
          <Spin size="large" /> {props.hintText || '加载中...'}
        </Flex>
      </AntdApp>
    </ConfigProvider>
  );
};

// TODO: env 每次改动后都要重启服务器才能生效，如果可以实时更新就好了
// TODO: env 增加一个 Typescript IntelliSense，在有限的 key 中选择

// TODO: 暂时不能跳过，源码很多边界没有考虑到，会报错
// 1. refreshToken
// 2. getToken
(async () => {
  // 打印构建时间，方便及时发现构建缓存问题
  const titleStyle = `color: white; background: ${ANTD_THEME.colorPrimary}; font-weight: bold; font-size: 12px`;
  const sensorProjectName = process.env.REACT_APP_SONSOR_PROJECT_NAME || process.env.REACT_APP_NAME;
  console.log('%c BUILD TIME ', titleStyle, process.env.BUILD_TIME);
  console.log('%c REACT_APP_MODE ', titleStyle, process.env.REACT_APP_MODE);
  console.log('%c REACT_APP_NAME ', titleStyle, process.env.REACT_APP_NAME);
  console.log('%c NODE_ENV ', titleStyle, process.env.NODE_ENV);
  console.log('%c REACT_APP_SONSOR_PROJECT_NAME ', titleStyle, sensorProjectName);

  if (process.env.REACT_APP_DISABLE_KC === 'yes') {
    console.log('[kc] skipped');
    renderApp();
  } else {
    const kcConfig: Parameters<typeof initKeycloak>[0] = {
      url: getKCUrl(),
      realm: process.env.REACT_APP_KC_REALM!,
      clientId: process.env.REACT_APP_KC_CLIENT_ID!,
    };
    console.log('[kc] initKeycloak: ', kcConfig);

    root.render(<Loading hintText="KC 初始化中..." />);
    try {
      await initKeycloak(kcConfig);

      try {
        root.render(<Loading hintText="获取用户信息&权限中..." />);

        const { UserAuthProvider, isBannedUser, userInfo, authInfo } = await initUserAuth(
          {
            user: getUserV1GetBaseInfo_mciUserAuthAdminServer,
            auth: () =>
              getUserV1GetUserRoleInfo_mciUserAuthAdminServer({
                queryParams: {
                  // 交易所运营后台的权限系统编码为 “10000”
                  systemCode: '10000',
                },
              }),
          },
          accessConfig,
        );

        if (process.env.NODE_ENV === 'development') {
          console.log('[user] ', { userInfo, authInfo, isBannedUser });
        }

        SensorUtil.sensors.login(userInfo!.data!.userId!);

        if (isBannedUser) {
          root.render(
            <Result
              className="mt-200"
              status={403}
              title="账户没有权限或者已被禁用"
              subTitle="请通过企业微信联系管理员「李立川」或者「吴超」"
              // TODO: deeplink 是一个好主意，但是 “wxwork://open” 出来的效果不好，择日再研究
              extra={
                <Button
                  type="primary"
                  onClick={() => {
                    logout();
                  }}>
                  重新登录
                </Button>
              }
            />,
          );
        } else {
          renderApp(UserAuthProvider);
        }
      } catch (e: any) {
        root.render(
          <Result
            className="mt-200"
            status={403}
            title="获取用户&权限信息失败"
            subTitle={e?.message || '原因未知'}
            extra={
              // TODO: 很多重复的代码，有空重构一下
              <Space>
                <Button
                  type="primary"
                  onClick={() => {
                    window.location.reload();
                  }}>
                  刷新重试
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    logout();
                  }}>
                  重新登录
                </Button>
              </Space>
            }
          />,
        );
      }
    } catch (e: any) {
      root.render(
        <Result
          className="mt-200"
          status={403}
          title="Keycloak init failed"
          subTitle={e?.error || e?.message || '原因未知'}
          extra={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  window.location.reload();
                }}>
                刷新重试
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  logout();
                }}>
                重新登录
              </Button>
            </Space>
          }
        />,
      );
    }
  }
})();
