import { FC, useState, useEffect, useContext } from 'react';
import {
  getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation,
  postAdminV1OrderBatchBatchOrderUpload_mcexpOperation,
  postAdminV1OrderBatchNewOrder_mcexpOperation,
  postAdminV1OrderBatchBlockNewOrder_mcexpOperation
} from '@/api/services';
import { ExTemplateType } from '@/api/types';
import { OrderManualCheckList } from './OrderManualCheckList';
import { ManualContext } from '@/pages/Order/manual/OrderManual';
import { FileUpload, FileUploadProps, UploadFileExt } from '@/components/FileUpload';
import { PreviewFile, getPreviewFileColumnRenderWithOptions, previewFileColumnRender } from '@/components/PreviewFile';
import { ProDescriptions, ProForm, ProFormItem, ProFormText, ProTable, ProFormSelect } from '@ant-design/pro-components';
import { Flex, Modal, Space, Typography } from 'antd';
import { setConfigProvider } from '@mci/design-system';
import { WebFileUpload } from '@mci/design-system';
import { uuidCreate } from '@/utils/formatter';
import { sleep } from '@mci-fe/utils';
import dayjs from 'dayjs';

/**
 * 订单系统-手工下单-批量下单
 */

interface IManualOrderProps {
  refreshTable?: () => void;
  onCancel?: () => void;
}

export const OrderManualOrder: FC<IManualOrderProps> = (props) => {
  const { TabValue } = useContext(ManualContext);
  const [templatesList, setTemplatesList] = useState<ExTemplateType[]>([]);
  const [fileError, setFileError] = useState<string>('');
  // TODO: ts type
  const [fileValid, setfileValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [batchId, setBatchId] = useState<string>('');
  const [isPass, setIsPass] = useState<number>(0);
  const [transactionOptions, setTransactionOptions] = useState<any[]>([]);
  const [transactionType, setTransactionType] = useState<string>('');
  const [transactionName, setTransactionName] = useState<string>('');

  const onOk = async () => {
    let error = '';
    let resp;
    if (TabValue === 'IOAOSO') {
      resp = await postAdminV1OrderBatchNewOrder_mcexpOperation({
        requestBody: {
          source: 'JYS',
          txId: uuidCreate(),
          batchId: batchId,
        },
      }).catch((e) => (error = e.message));
    } else {
      resp = await postAdminV1OrderBatchBlockNewOrder_mcexpOperation({
        requestBody: {
          source: 'JYS',
          txId: uuidCreate(),
          batchId: batchId,
        },
      }).catch((e) => (error = e.message));
    }
    if (!error) {
      props.onCancel?.();
      props.refreshTable?.();
    }
  };

  const onChange: FileUploadProps['onChange'] = async (fileList, file) => {
    setFileError('');
    setfileValid(false);

    if (file.status === 'uploading') {
      setLoading(true);
      return;
    }

    if (file.status === 'done') {
      // Check file valid
      await sleep(200);

      setBatchId('');

      let errorMsg = '';
      // 文件格式判断
      const fileNameDivision = file?.name.split('.');
      const fileType = fileNameDivision[fileNameDivision.length - 1];
      const acceptList = ['xlsx', 'xls', 'xlsm', 'xltx', 'xltm', 'csv'];
      if(!acceptList.includes(fileType)) {
        errorMsg = '文件格式错误，请上传正确的文件。仅支持 xlsx、xls、xlsm、xltx、xltm、csv格式。';
      }

      if (errorMsg) {
        setFileError(errorMsg);
        setLoading(false);
      } else {
        // 进一步检查
        await sleep(200);

        let error = '';
        const formData = new FormData();
        formData.append('id', transactionType);
        formData.append('file', file?.originFileObj!);
        const resp = await postAdminV1OrderBatchBatchOrderUpload_mcexpOperation({
          requestBody: formData,
        } as any).catch((e) => (error = e.message));
        if (!error && resp.data) {
          setIsPass(resp.data.pass);
          setBatchId(resp.data.batchId);

          const selectedOption = transactionOptions.find(item => item.value === transactionType);
          setTransactionName(selectedOption.label);
          // 检查是否全部通过 0不通过，1 通过
          if (resp.data.pass === 1) {
            setfileValid(true);
          }
        }

        setLoading(false);
      }
    }
  };

  const templateFilePaths = async () => {
    let error = '';
    const type = TabValue === 'IOAOSO' ? 1 : 2;
    const resp = await getAdminV1OrderBatchBatchOrderTemplateList_mcexpOperation({
      queryParams: {
        templateType: type,
      },
    }).catch((e) => (error = e.message));
    if (!error && resp.data) {
      const optionsList = resp.data.map((item: any) => {
        return {
          value: item.id,
          label: item.selectName
        };
      });
      setTransactionOptions(optionsList);
      setTemplatesList(resp.data);
    }
  };

  useEffect(() => {
    (async () => {
      templateFilePaths();
    })();
  }, []);

  const templates = templatesList?.map((path: ExTemplateType) => {
    return <PreviewFile key={path.id} url={path.url} fileName={path.name} />;
  });

  const uploadDesc = (
    <Flex vertical gap="small" style={{ marginTop: 8 }}>
      <Space size="middle" wrap>
        {templates}
      </Space>
      <Typography.Text type="secondary" style={{ fontSize: 12 }}>
        温馨提示：模板文件名称及字段请勿修改，请上传对应类型文件
      </Typography.Text>
    </Flex>
  );

  return (
    <Modal
      open={true}
      title="批量下单"
      width={680}
      centered
      style={{ minWidth: 600 }}
      onCancel={() => {
        props.onCancel?.();
      }}
      onOk={onOk}
      okButtonProps={{ disabled: !fileValid, loading }}>
      {/* TODO: ProForm<xxx> 可以加入 ts 的泛型 */}
      <ProForm
        style={{ marginTop: 20 }}
        submitter={false}
        layout="horizontal"
        initialValues={{
          date: dayjs().format('YYYY-MM-DD'),
        }}>
        <ProFormText name="date" label="日期" readonly />
        <ProFormSelect
          options={transactionOptions}
          name="transactionType"
          label="交易类型"
          fieldProps={{
            value: transactionType,
            onChange: (e) => {
              setTransactionType(e);
            }
          }}
          formItemProps={{
            rules: [
              {
                required: true,
                message: '交易类型必选',
              },
            ],
          }}
        />
        <ProFormItem name="file" label="文件上传" wrapperCol={{ span: 24 }} style={{ marginLeft: 12 }}>
          <FileUpload allowMultiple={false} disabled={!transactionType} onChange={onChange} description={uploadDesc} error={fileError} />
        </ProFormItem>
      </ProForm>

      {batchId && <OrderManualCheckList transactionType={transactionName} batchId={batchId} pass={isPass} />}
    </Modal>
  );
};