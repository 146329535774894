import { ReactComponent as OperatingLogIcon } from '@/assets/operating-log.svg';
import { ReactComponent as UploadIcon } from '@/assets/upload.svg';
import {
  ContractDetail,
  ContractList,
  DroLiseted,
  DroLisetedV1_5,
  DroToBeListing,
  DroToBeListingV1_5,
  InvestList,
  InvestPeriodList,
  OperationLog,
  OrderDRO,
  OrderDRP,
  SQLAudit,
  SQLEdit,
  SentEmail,
  ToBeSentEmail,
  Welcome,
  RoleManageList,
  UserManageList,
  ArmValidation,
  SpvVirtualAccount,
  MemberDeposit,
  SpvDeposit,
  ExchangeRates,
  FundAllocation
} from '@/pages';

import { OrderManual } from '@/pages/Order/manual/OrderManual';
import { shipAddMenuItemTrigger } from '@/utils/devtool';
import { queryUtil } from '@/utils/query-util';
import {
  SolutionOutlined,
  DatabaseOutlined,
  MoneyCollectOutlined,
  TransactionOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { ProLayoutProps } from '@ant-design/pro-components/es';

export const routeMap: ProLayoutProps['route'] = {
  path: '/',
  // TODO: 读取 package.json
  // TODO: 整个项目很多重复的名字定义 exmt-ops，有空优化一下
  name: 'private-exmt-ops',
  component: <Welcome />,
  routes: [
    {
      path: 'listing-management',
      name: '上架管理',
      icon: <UploadIcon />,
      routes: [
        {
          path: 'dro',
          name: 'DRO',
          routes: [
            {
              path: 'to-be-listing',
              name: '待上架',
              component: <DroToBeListing />,
              authCode: ['1000007'],
            },
            {
              path: 'listed',
              name: '已上架',
              component: <DroLiseted />,
              authCode: ['1000008'],
            },
            {
              path: 'to-be-listing-v1-5',
              name: '待上架 v1.5（旧）',
              component: <DroToBeListingV1_5 />,
              authCode: ['1000007'],
            },
            {
              path: 'listed-v1-5',
              name: '已上架 v1.5（旧）',
              component: <DroLisetedV1_5 />,
              authCode: ['1000008'],
            },
          ],
        },
      ],
      authCode: ['1000001'],
    },
    {
      path: 'operationLog',
      name: '操作日志',
      icon: <OperatingLogIcon />,
      component: <OperationLog />,
      // @NOTE: 应「测试人员 + 后端开发者」的要求，当前这一期需要隐藏这个菜单
      hideInMenu: true,
    },
    {
      path: 'emailManagement',
      name: '邮件管理',
      icon: <OperatingLogIcon />,
      routes: [
        {
          path: 'toBeSent',
          name: '待发送邮件',
          icon: <OperatingLogIcon />,
          component: <ToBeSentEmail />,
          authCode: ['1000009'],
        },
        {
          path: 'sent',
          name: '已发送邮件',
          icon: <OperatingLogIcon />,
          component: <SentEmail />,
          authCode: ['1000010'],
        },
      ],
      authCode: ['1000002'],
    },
    {
      path: 'fundManagement',
      name: '资金管理',
      icon: <MoneyCollectOutlined />,
      routes: [
        {
          path: 'member-deposit',
          name: '客户出入金',
          component: <MemberDeposit />,
          authCode: ['1000017'],
        },
        {
          path: 'spv-deposit',
          name: 'SPV 出入金',
          component: <SpvDeposit />,
          authCode: ['1000021'],
        },
        {
          path: 'spv-va-income',
          name: 'SPV VA 入金',
          component: <SpvVirtualAccount />,
          authCode: ['1000022'],
        },
        {
          path: 'exchangeRates',
          name: '换汇列表',
          component: <ExchangeRates />,
          authCode: ['1000023'],
        },
        {
          path: 'fundAllocation',
          name: '资金调拨',
          component: <FundAllocation />,
          authCode: ['1000024'],
        },
      ],
      authCode: ['1000003'],
    },
    {
      path: 'contractManagement',
      name: '签约主体管理',
      icon: <UserOutlined />,
      routes: [
        {
          path: 'list',
          name: '签约主体',
          component: <ContractList />,
          authCode: ['1000011'],
        },
        // TODO: detail 应该是 list 后的，面包屑规律是这样的
        {
          path: 'detail/:id',
          name: '签约主体详情',
          component: <ContractDetail />,
          hideInMenu: true,
        },
      ],
      authCode: ['1000004'],
    },
    {
      path: 'rolesManage',
      name: '系统管理',
      icon: <UserOutlined />,
      authCode: ['1000014'],
      routes: [
        {
          path: 'user',
          name: '用户管理',
          component: <UserManageList />,
          authCode: ['1000015'],
        },
        {
          path: 'role',
          name: '角色管理',
          component: <RoleManageList />,
          authCode: ['1000016'],
        },
      ],
    },

    {
      path: 'investmentManagement',
      name: '出资管理',
      icon: <TransactionOutlined />,
      routes: [
        {
          path: 'list',
          name: 'DRO列表',
          component: <InvestList />,
          authCode: ['1000006'],
        },
        {
          path: 'periodList',
          name: '分期管理',
          component: <InvestPeriodList />,
          authCode: ['1000013'],
        },
      ],
      authCode: ['1000005'],
    },
    {
      path: 'orderManagement',
      name: '订单管理',
      icon: <SolutionOutlined />,
      routes: [
        {
          path: 'dro',
          name: 'DRO',
          component: <OrderDRO />,
        },
        {
          path: 'drp',
          name: 'DRP',
          component: <OrderDRP />,
        },
        {
          path: 'manual',
          name: '手工下单',
          component: <OrderManual />,
        },
      ],
    },
    {
      path: 'ArmManagement',
      name: 'ARM验收管理',
      icon: <SolutionOutlined />,
      routes: [
        {
          path: 'arm',
          name: 'ARM验收',
          component: <ArmValidation />,
          authCode: ['1000025'],
        },
      ],
      authCode: ['1000025'],
    },

    queryUtil.sql && {
      path: 'sql',
      name: '数据编辑',
      icon: <DatabaseOutlined />,
      // TODO: 合适时候隐藏起来，记得增加权限
      // hideInMenu: true,
      routes: [
        {
          path: 'edit',
          name: '修改',
          component: <SQLEdit />,
        },
        {
          path: 'audit',
          name: '审核',
          component: <SQLAudit />,
        },
      ],
    },
  ],
};

const layoutConfig = {
  route: process.env.NODE_ENV !== 'production' ? shipAddMenuItemTrigger(routeMap, []) : routeMap,
};

export default layoutConfig;
