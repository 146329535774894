// 资金场景
export const BUSINESS_TYPE_MAP = {
  '1': '充值',
  '2': '提款',
  '3': '券商资金划拨',
  '4': '出资付款',
};

export const BUSINESS_TYPE_MAP_IMPORT = {
  '1': '充值',
  // '2': '提款',
  // '3': '券商资金划拨',
  '4': '出资付款',
};

// 出入金类型
export const FUND_TYPE_MAP = {
  '0': '出金',
  '1': '入金',
};

// 数据来源类型
export const DATA_SOURCE_MAP = {
  '0': '系统生成',
  '1': '人工导入',
};

// 出入金状态
export const DEPOSIT_STATUS_MAP = {
  '2': '处理中',
  '3': '失败',
  '4': '成功',
};

//  客户类型
export const CUSTOMER_TYPE_MAP = {
  '0': '会员',
  '2': 'SPAC',
};

//  数据核验状态
export const DATA_VERIFICATION_STATUS_MAP = {
  '-1': '不通过',
  '1': '通过',
};

// 审批状态
export const APPROVAL_STATUS_MAP = {
  '-1': '审批不通过',
  '0': '待审批',
  '1': '审批通过',
};
