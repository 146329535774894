import { createElement } from 'react';
import { forwardRef } from 'react';
import { Button as OriginalButton } from 'antd';
const Button = (props, ref) => {
    const innerProps = {};
    if (!props.href?.trim() || props.__designMode === 'design') {
        innerProps.href = undefined;
    }
    return createElement(OriginalButton, { ...props, ...innerProps, ref: ref });
};
export default forwardRef(Button);
