import { Component, createRef } from 'react';
import { Form as OriginalForm } from 'antd';
import { withSingleFunctionChild } from '../../utils/hoc';
class Form extends Component {
    formRef = createRef();
    componentDidUpdate(prevProps) {
        const { values: prevValues } = prevProps;
        const { values: currentValues } = this.props;
        if (prevValues !== currentValues) {
            if (currentValues && Object.keys(currentValues).length > 0) {
                this.formRef.current.setFieldsValue(currentValues);
            }
            else {
                this.formRef.current.resetFields();
            }
        }
    }
    componentDidMount() {
        const { values } = this.props;
        Object.defineProperties(this, Object.keys(this.formRef.current).reduce((out, key) => {
            const property = this.formRef.current[key];
            let getter = () => property;
            if (typeof property === 'function') {
                getter = () => property.bind(this.formRef.current);
            }
            out[key] = { get: getter };
            return out;
        }, {}));
        if (values) {
            this.formRef.current.setFieldsValue(values);
        }
    }
    render() {
        const { values, ...rest } = this.props;
        return createElement(OriginalForm, { ref: this.formRef, ...rest });
    }
}
Form.Item = (props) => {
    const { name, requiredobj, typeobj, patternobj, lenobj, validator } = props;
    const rules = [];
    if (requiredobj && requiredobj.required) {
        rules.push(requiredobj);
    }
    if (typeobj && typeobj.type) {
        rules.push(typeobj);
    }
    if (patternobj && patternobj.pattern) {
        rules.push(patternobj);
    }
    if (lenobj && (lenobj.max || lenobj.min)) {
        rules.push(lenobj);
    }
    if (validator && typeof validator === 'function') {
        rules.push({
            validator: (_, value) => validator(value),
        });
    }
    const namePath = typeof name === 'string' && name.indexOf('.') > 0 ? name.split('.') : name;
    const { children, ...other } = props;
    let node = children;
    if (Array.isArray(children) && children.length === 1) {
        node = children[0];
    }
    return (createElement(OriginalForm.Item, { ...other, name: namePath, rules: rules }, node));
};
Form.List = withSingleFunctionChild(OriginalForm.List);
export default Form;
