import { getFileGetFullPath_mciArm, getFilesUrl_exKernelAdmin, postFilesUpload_exKernelAdmin, postFileUpload_mciArm, postFlowAdd_mciArm } from '@/api/services';
import { DatePicker, Form, Input, InputNumber, Modal, Select, App, Upload, UploadProps, UploadFile, Image, Button } from 'antd';
import { useSpvCurrency } from '@/hooks/useSpvCurrency';
import { useAccount } from '@/hooks/useAccount';
import { DepositReviewInfo } from '@/api/types';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PlusOutlined } from '@ant-design/icons';

type TModalFlowProps = {
  rowData?: DepositReviewInfo;
  isShowModal?: boolean;
  modalTitle: string;
  closeEvent: (type?: string) => void;
};

export const ModalSPVFlow = (props: TModalFlowProps) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const { isShowModal, rowData, modalTitle, closeEvent } = props;
  const [modalForm] = Form.useForm();
  const { currencyData } = useSpvCurrency();
  const { accountData } = useAccount();
  const { message } = App.useApp();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const handleOk = () => {
    modalForm
      .validateFields()
      .then(() => {
        let { currency, depositTime, ...rest } = modalForm.getFieldsValue();

        if (depositTime) {
          depositTime = dayjs(depositTime).format('YYYY-MM-DD');
        }

        if ((currency as string).toUpperCase() === 'CNY') {
          currency = 'RMB';
        }
        // 删除businessDate
        delete rest.businessDate;
        postFlowAdd_mciArm({
          requestBody: {
            depositTime,
            currency,
            ...rest,
            filePaths: fileList.map((item: any) => item.filePath),
          },
        })
          .then(() => {
            message.success('导入入金流水成功');
            closeEvent && closeEvent('success');
          })
          .catch(() => {});
      })
      .catch(() => {});
  };
  const closeModal = (type?: string) => {
    closeEvent && closeEvent(type);
  };
  const handleCancel = () => {
    closeModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const accountChange = (val: string) => {
    let targetList = accountData.list.filter((item: any) => item.value === val);
    modalForm.setFieldValue('virtualAccount', targetList[0]?.virtualAccount || '');
  };
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const updateFileList = (fileList: any) => {
    setFileList(fileList);
    modalForm.setFieldsValue({
      filePaths: fileList,
    });
  };
  const fetchFilelUrl = async (filePath: string) => {
    return getFileGetFullPath_mciArm({
      queryParams: { filePath },
    });
  };

  const customRequest: UploadProps['customRequest'] = async (options: any) => {
    const uid = options.file.uid;
    const res = await postFileUpload_mciArm({
      queryParams: {
        filePathType: 'INFORMATION',
      },
      requestBody: {
        file: options.file,
      },
    });
    const currentFileList = [{ ...res.data, uid }];
    let newFileList = [...fileList, ...currentFileList];
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      if (item.uid === uid) {
        item.fileName = res.data!.fileName!;
        item.name = res.data!.fileName!;
        // item.fileType = res.data!.fileType!;
        item.filePath = res.data!.filePath!;
        break;
      }
    }
    updateFileList(newFileList);

    const filePath = res.data!.filePath!;
    const resUrl = await fetchFilelUrl(filePath);

    newFileList = [...fileList, ...currentFileList];
    for (let i = 0; i < newFileList.length; i++) {
      const item = newFileList[i];
      if (item.uid === uid) {
        item.url = resUrl.data;
        item.status = 'done';
        break;
      }
    }
    updateFileList(newFileList);
  };
  const handlePreview = async (file: UploadFile) => {
    setPreviewImage(file.url ?? '');
    setPreviewOpen(true);
  };

  return (
    <Modal title={modalTitle} open={isShowModal} onOk={handleOk} onCancel={handleCancel}>
      <Form {...formItemLayout} style={{ maxWidth: 600 }} form={modalForm}>
        <Form.Item label="RBO code" name="rboCode" rules={[{ required: true, message: '请选择' }]}>
          {/* 后面改成Select */}
          <Input />
        </Form.Item>
        <Form.Item label="交易账户" name="tradingAccount" rules={[{ required: true, message: '请选择' }]}>
          <Select onChange={accountChange} options={accountData.list || []} showSearch filterOption={filterOption} />
        </Form.Item>
        <Form.Item label="银行主账户" name="bankMasterAccount">
          <Input />
        </Form.Item>
        <Form.Item label="虚拟账户" name="virtualAccount">
          <Input disabled={true} />
        </Form.Item>

        <Form.Item label="入金日期" name="depositTime" rules={[{ required: true, message: '请选择' }]}>
          <DatePicker style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="入金金额" name="depositAmount" rules={[{ required: true, message: '请输入' }]}>
          <InputNumber style={{ width: '100%' }} min={0} precision={2} />
        </Form.Item>
        <Form.Item label="币种" name="currency" rules={[{ required: true, message: '请选择' }]}>
          <Select options={currencyData.list || []} />
        </Form.Item>
        <Form.Item label="打款方银行账户" name="payerAccount">
          <Input />
        </Form.Item>
        <Form.Item label="打款方银行" name="payerBank">
          {/* 后面改成Select */}
          <Input />
        </Form.Item>
        {/* 隐藏字段，用于存储起止日期 */}
        <Form.Item name="businessDateStart" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="businessDateEnd" hidden>
          <Input />
        </Form.Item>
        <Form.Item label="收入营业日期" name="businessDate">
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={(dates) => {
              if (dates) {
                // 更新两个字段
                modalForm.setFieldsValue({
                  businessDateStart: dates[0]?.format('YYYY-MM-DD'),
                  businessDateEnd: dates[1]?.format('YYYY-MM-DD'),
                });
              } else {
                // 清空两个字段
                modalForm.setFieldsValue({
                  businessDateStart: undefined,
                  businessDateEnd: undefined,
                });
              }
            }}
          />
        </Form.Item>
        <Form.Item label="上传付款凭证" name="filePaths" dependencies={['fileList']}>
          <Upload
            customRequest={customRequest}
            onRemove={(file) => {
              updateFileList(fileList?.filter((f: any) => f.uid !== file.uid));
            }}
            fileList={fileList}
            onPreview={handlePreview}
            maxCount={5}>
            <Button type="link">点击上传</Button>
          </Upload>
          {/* {previewImage && (
            <Image
              wrapperStyle={{ display: 'none' }}
              preview={{
                visible: previewOpen,
                onVisibleChange: (visible) => setPreviewOpen(visible),
                afterOpenChange: (visible) => !visible && setPreviewImage(''),
              }}
              src={previewImage}
            />
          )} */}
        </Form.Item>
      </Form>
    </Modal>
  );
};
