import Logan from "@mci-fe/logan-web";
import { getKCToken, logout } from "@mci-fe/keycloak";
import { message } from "antd";
import type {
  AxiosError,
  AxiosInterceptorManager,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { mockInterceptorFactory } from "@mci-fe/mocker/dist/helpers";
import { LogTypes } from "../utils/log/type";
import { RequestError } from "./config";
import type { AxiosRequestConfigWithExtra } from "./types";

export const AXIOS_TIMEOUT = 20 * 1000;

type RequestInterceptorUse = Parameters<
  AxiosInterceptorManager<InternalAxiosRequestConfig>["use"]
>;
type ResponseInterceptorUse = Parameters<
  AxiosInterceptorManager<AxiosResponse>["use"]
>;

type AxiosInterceptor = {
  request?: {
    onFulfilled?: RequestInterceptorUse[0];
    onRejected?: RequestInterceptorUse[1];
  };
  response?: {
    onFulfilled?: ResponseInterceptorUse[0];
    onRejected?: ResponseInterceptorUse[1];
  };
};

function isHttpRequestSuccess(status: number) {
  return status >= 200 && status < 300;
}

// 测试人员说这个前缀不够友好，暂时先不用
// const hintPrefix = '请求出错了：';
const hintPrefix = "";

function mapErrorCodeToMessage(statusCode: number) {
  const errorMessages = {
    400: "客户端请求参数格式不符合后端要求",
    401: "身份验证失败，请重新登录",
    403: "禁止访问，服务器拒绝了您的请求",
    404: "您请求的资源不存在或者找不到",
    405: "请求方法不被服务器支持",
    408: "请求超时，服务器等候请求时发生超时",
    409: "请求导致资源冲突",
    413: "请求体的大小超过了服务器的限制",
    414: "请求的 URI 太长，服务器无法处理",
    415: "服务器不支持请求中所包含的媒体类型",
    500: "服务器内部错误",
    501: "服务器不支持当前请求所需要的某个功能",
    502: "网关错误",
    503: "服务暂不可用，请稍后再试",
    504: "网关超时",
    505: "HTTP 版本不受支持",
  };

  type StatusCode = keyof typeof errorMessages;
  const baseMsg = errorMessages[statusCode as StatusCode] || "未知错误";
  return `${hintPrefix}${statusCode} - ${baseMsg}`;
}

function broadcastImplement(isSuccess: boolean, msg?: string) {
  if (isSuccess) {
    message.success(msg);
  } else {
    message.error(msg);
  }
}

function broadcast(options: {
  isSuccess: boolean;
  isShowHint?: boolean;
  successHint?: string;
  errorHint?: string;
}) {
  const { isSuccess, isShowHint = true, successHint, errorHint } = options;
  if (isShowHint) {
    const message = isSuccess ? successHint : errorHint;
    if (message) {
      broadcastImplement(isSuccess, message);
    }
  }
}

const defaultInterceptor: AxiosInterceptor = {
  response: {
    onFulfilled: async (response: AxiosResponse): Promise<any> => {
      // console.log('response:', response);

      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      const requestConfig = response.config;
      const { isShowHint, successHint, errorHint } =
        requestConfig as AxiosRequestConfigWithExtra;
      if (isHttpRequestSuccess(response.status)) {
        // TODO: 这种判断方式并不能准确地识别是文件下载请求或者是普通的 json 请求
        if (requestConfig.responseType === "blob") {
          return response;
        } else {
          if (response.data.code === "0000") {
            broadcast({ isSuccess: true, isShowHint, successHint });
            return response.data;
          } else {
            // @note: 这里 throw 的错误对象不会被 use（）的第二参数 - 错误处理函数所捕获
            // 拿后端的业务性错误提示语作为最终的错误提示语
            const error = new RequestError(
              `${hintPrefix}${response.data.message}`,
              response.status,
              response,
            );
            broadcast({
              isSuccess: false,
              isShowHint,
              errorHint: errorHint || error.message,
            });
            return Promise.reject(error);
          }
        }
      }
    },
    onRejected: (error: AxiosError) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { isShowHint = true, errorHint } =
        error.config as AxiosRequestConfigWithExtra;
      const status = error.response?.status;
      if (status && !isHttpRequestSuccess(status)) {
        error.message = mapErrorCodeToMessage(status);
      } else if (error.name === "AxiosError") {
        error.message = `${hintPrefix}${error.message}`;
      }
      broadcast({
        isSuccess: false,
        isShowHint,
        errorHint: errorHint || error.message,
      });
      if (status === 401) {
        logout();
      }
      return Promise.reject(error);
    },
  },
  request: {
    onFulfilled: async (requestConfig) => {
      Logan.log(
        `request url: ${requestConfig.url}, params: ${JSON.stringify(requestConfig.params)}`,
        LogTypes.Request,
      );
      // Do something before request is sent
      /** Example on how to add authorization based on security */
      const token = await getKCToken();
      if (token) {
        requestConfig.headers.authorization = `Bearer ${token}`;
      }
      requestConfig.headers.client = process.env.REACT_APP_KC_CLIENT_ID;

      return requestConfig;
    },
    onRejected: (error) => {
      Logan.log(`request error: ${JSON.stringify(error)}`, LogTypes.Error);
      // Do something with request error
      return Promise.reject(error);
    },
  },
};

export function getInterceptorByServiceName(
  serviceName: string,
): AxiosInterceptor[] {
  const interceptors = [defaultInterceptor];
  if (process.env.NODE_ENV === "development") {
    const mockServer = `${process.env.REACT_APP_MOCK_SERVER_HOST}:${process.env.REACT_APP_MOCK_SERVER_PORT}`;
    //@ts-ignore
    interceptors.unshift(mockInterceptorFactory(mockServer));
  }
  return interceptors;
}
