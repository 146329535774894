import { App, Button } from 'antd';
import { useState, MutableRefObject } from 'react';
import { RoleModify } from './RoleModify';
import { ActionType } from '@ant-design/pro-components';
import { ContractEntity } from '@/api/types';

export default function RoleManageAddBtn({ actionRef }: { actionRef: MutableRefObject<ActionType> }) {
  const [open, setOpen] = useState(false);
  const { message } = App.useApp();

  const onFinish = () => {
    message.success('新增成功');
    actionRef.current?.reload();
    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
        }}>
        新增
      </Button>
      {/* TODO: 现在 cmd + click 代码跳转每次要有两个，看能否优化下  */}
      {open && <RoleModify onFinish={onFinish} onCancel={onCancel} />}
    </>
  );
}
