import type { RowSelectionAction } from '@mci/lowcode-components/src/components/config-provider/mci';
import { Button, App } from 'antd';
import useMciRequest from '@/api/useMciRequest';

const SendEmailsInBatch: RowSelectionAction = ({ selectedRows, clearAllSelected, reload }) => {
  const { loading, runAsync } = useMciRequest('postMailContentSendBatch_exKernelAdmin', undefined, {
    manual: true,
  });
  const { modal, message } = App.useApp();

  return (
    <Button
      size="small"
      type="primary"
      loading={loading}
      onClick={() => {
        modal.confirm({
          title: '重要提示',
          content: `确认要批量发送共${selectedRows.length}封邮件吗？`,
          onOk() {
            runAsync({
              requestBody: {
                idList: selectedRows.map((_) => _.id),
              },
            }).then(() => {
              message.success('批量发送邮件成功');
              clearAllSelected();
              reload && reload();
            });
          },
          okText: '确定',
          cancelText: '取消',
        });
      }}>
      批量通过并发送
    </Button>
  );
};

export default SendEmailsInBatch;
