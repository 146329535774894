import {
  postHsbcDepositImportDepositFlow_exKernelAdmin,
  postHsbcDepositUpdateTradingAccount_exKernelAdmin,
} from '@/api/services';
import { DatePicker, Form, Input, InputNumber, Modal, Select, App } from 'antd';
import { useCurrency } from '@/hooks/useCurrency';
import { useAccount } from '@/hooks/useAccount'; 
import { DepositReviewInfo } from '@/api/types';
import { BUSINESS_TYPE_MAP_IMPORT, FUND_TYPE_MAP } from '@/pages/Deposit/member/constants';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useMciRequest from '@/api/useMciRequest';

type TModalFlowProps = {
  rowData?: DepositReviewInfo;
  isShowModal?: boolean;
  modalTitle: string;
  closeEvent: (type?: string) => void;
};

export const ModalFlow = (props: TModalFlowProps) => {
  const { isShowModal, rowData, modalTitle, closeEvent } = props;
  const [modalForm] = Form.useForm();
  const { currencyList } = useCurrency({ labelKey: 'label', valueKey: 'label' });
  const { accountData } = useAccount();
  const { message } = App.useApp();
  const [fundType, setFundType] = useState<any>();
  const [businessType, setBusinessType] = useState<any>();
  const [payeeAcctList, setPayeeAcctList] = useState<{label: string; value: string;}[]>([]);
  const { data } = useMciRequest('getHsbcDepositGetTradingAccountToList_exKernelAdmin');

  const getSelectOptions = (type: 'BUSINESS_TYPE_MAP_IMPORT' | 'FUND_TYPE_MAP') => {
    const options = type === 'BUSINESS_TYPE_MAP_IMPORT' ? BUSINESS_TYPE_MAP_IMPORT : FUND_TYPE_MAP;
    let list = [] as any;
    Object.entries(options).forEach(([key, value]) => {
      list.push({
        label: value,
        value: key
      });
    });

  
    if (type === 'BUSINESS_TYPE_MAP_IMPORT' && (fundType !== undefined && fundType !== '')) {
      if (fundType === '0') { // 出金
        list = list.filter((item: { label: string; value: string; })=> item.value !== '1');
      } 
      if (fundType === '1') { // 入金
        list = list.filter((item: { label: string; value: string; })=> item.value === '1');
      }
    }

    return list;
  };

  const onFormValuesChange = (changedValues: any) => {
    if ('fundType' in changedValues) {
      setFundType(changedValues.fundType);  // 更新 fundType 的值
    }
    if ('businessType' in changedValues) {
      setBusinessType(changedValues.businessType);  // 更新 fundType 的值
    }
  };

  const handleOk = () => {
    const data = modalForm.getFieldsValue();
    modalForm
      .validateFields()
      .then(() => {
        const depositDate = dayjs(data.depositDate).format('YYYY-MM-DD');
        if (rowData?.id) {
          postHsbcDepositUpdateTradingAccount_exKernelAdmin({
            requestBody: {
              tradingAccount:data.tradingAccount,
              virtualAccount:data.virtualAccount,
              id: rowData.id,
            },
          })
            .then(() => {
              message.success('操作成功');
              closeEvent && closeEvent('success');
            })
            .catch(() => {});
        } else {
          postHsbcDepositImportDepositFlow_exKernelAdmin({
            requestBody: {
              ...data,
              depositDate: depositDate,
            },
          })
            .then(() => {
              message.success('操作成功');
              closeEvent && closeEvent('success');
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  };
  const closeModal = (type?: string) => {
    closeEvent && closeEvent(type);
  };
  const handleCancel = () => {
    closeModal();
  };
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };
  const accountChange = (val:string)=>{
    let targetList = accountData.list.filter((item:any)=> item.value === val);
    modalForm.setFieldValue('virtualAccount',targetList[0]?.virtualAccount || '');
  };
  const fundTypeChange = (val:string)=>{
    modalForm.setFieldValue('fundType', val);
    modalForm.setFieldValue('businessType', '');
  };
  const businessTypeChange = (val:string)=>{
    modalForm.setFieldValue('businessType', val);
  };
  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    
  useEffect(() => {
    if (rowData) {
      modalForm.setFieldsValue({
        ...rowData,
        fundType: rowData.fundType?.toString(),
        businessType: rowData.businessType?.toString(),
      });
    }
    if (rowData?.depositDate) {
      modalForm.setFieldValue('depositDate', dayjs(rowData?.depositDate));
    }
    setBusinessType(rowData?.businessType);
  }, []);

  useEffect(()=> {
    if (data?.data?.length) {
      const list = data.data.map((item)=> ({
        label: item.tradingAccount!,
        value: item.tradingAccount!,
      }));
      setPayeeAcctList(list);
    } 
  }, [data]);

  return (
    <Modal title={modalTitle} open={isShowModal} onOk={handleOk} onCancel={handleCancel}>
      <Form {...formItemLayout} style={{ maxWidth: 600 }} form={modalForm} onValuesChange={onFormValuesChange}>
        <Form.Item label="出入金" name="fundType"  rules={[{ required: true, message: '请选择' }]}>
          <Select disabled={Boolean(rowData?.id)} onChange={fundTypeChange} options={getSelectOptions('FUND_TYPE_MAP')} showSearch filterOption={filterOption} />
        </Form.Item>
        <Form.Item label="资金场景" name="businessType"  rules={[{ required: true, message: '请选择' }]}>
          <Select disabled={Boolean(rowData?.id)} onChange={businessTypeChange} options={getSelectOptions('BUSINESS_TYPE_MAP_IMPORT')} showSearch filterOption={filterOption} />
        </Form.Item>
        <Form.Item label="交易账户" name="tradingAccount" rules={[{ required: true, message: '请选择' }]}>
          <Select onChange={accountChange} options={accountData.list || []} showSearch filterOption={filterOption} />
        </Form.Item>
        {
          businessType?.toString() === '3' && (
            <Form.Item label="转入交易账户" name="tradingAccountTo" rules={[{ required: true, message: '请选择' }]}>
              <Select onChange={accountChange} options={payeeAcctList} showSearch filterOption={filterOption} />
            </Form.Item>
          )
        }
        <Form.Item label="银行主账户" name="bankAccount">
          <Input disabled={Boolean(rowData?.id)} />
        </Form.Item>
        <Form.Item label="VA账户" name="virtualAccount">
          <Input disabled={true} />
        </Form.Item>

        <Form.Item label="入金日期" name="depositDate" rules={[{ required: true, message: '请选择' }]}>
          <DatePicker disabled={Boolean(rowData?.id)} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item label="金额" name="amount" rules={[{ required: true, message: '请输入' }]}>
          <InputNumber disabled={Boolean(rowData?.id)} style={{ width: '100%' }}min={0} precision={2} />
        </Form.Item>
        <Form.Item label="币种" name="currency" rules={[{ required: true, message: '请选择' }]}>
          <Select disabled={Boolean(rowData?.id)} options={currencyList ?? []} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
