import { Flex, Tree } from 'antd';
import { SetStateAction, useEffect, useState } from 'react';

export interface EmailWangEditorProps {
  value?: string[];
  onChange?: (value: any) => void;
  treeData?: any[];
}

const TreeAccess = (props: EmailWangEditorProps) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>();
  const [expandedKeys, setExpandedKeys] = useState<string[]>();
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const treeData = props.treeData;
  const onCheck = (checkedKeys: any, info: any) => {
    setSelectedKeys(checkedKeys.checked);
    props.onChange?.(checkedKeys.checked);
    setAutoExpandParent(true);
  };
  const onExpand = (expandedKeysValue: any) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(true);
  };

  useEffect(() => {
    setSelectedKeys(props.value);
    setExpandedKeys(props.value);
  }, [props.value]);

  return (
    <>
      <div className="role-access-tree">
        <div>权限设置</div>
        <Flex vertical>
          {treeData?.length && (
            <Tree
              checkable
              checkStrictly
              fieldNames={{
                title: 'authName',
                key: 'authCode',
              }}
              onCheck={onCheck}
              multiple
              treeData={treeData[0].children}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              autoExpandParent={autoExpandParent}
              selectedKeys={selectedKeys}
              checkedKeys={selectedKeys}
            />
          )}
        </Flex>
      </div>
    </>
  );
};

// export default EmailWangEditor;

// - 暂时先用后者版本，因为样式保留更多，不确定还有什么 bug
export default TreeAccess;
