import { FloatButton } from 'antd';
import { SettingOutlined, SwapOutlined } from '@ant-design/icons';
import EnvSelectModal from '@/components/devtool/EnvSelectModal';
import { useState } from 'react';
import { ENV_KEY } from '@/constants';
import { resolveServerName } from '@/utils';

export default function FloatSettingButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const defaultEnv = resolveServerName();

  const handleEnvChange = (selectedEnv: string) => {
    sessionStorage.setItem(ENV_KEY, selectedEnv);
    const url = new URL(window.location.href);
    url.searchParams.set('__debug__', '1');
    window.location.href = url.toString();
  };

  return (
    <>
      <FloatButton.Group trigger="hover" type="primary" style={{ insetInlineEnd: 94 }} icon={<SettingOutlined />}>
        <FloatButton icon={<SwapOutlined />} tooltip="切换接口环境" onClick={() => setIsModalOpen(true)} />
      </FloatButton.Group>
      <EnvSelectModal
        defaultEnv={defaultEnv}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        onOk={handleEnvChange}
      />
    </>
  );
}
