class QueryUtil {
  isDebug = false;
  sql = false;
  mockMode = false;
  debugSensor = false;

  params = new URLSearchParams(window.location.search);

  // 有些地方是全局变量，项目还没启动就要访问了，所以一开始就要 init 一下
  constructor() {
    this.init();
  }

  private init() {
    this.isDebug = this.bool('__debug__');
    this.sql = this.bool('sql');
    this.mockMode = this.bool('mockMode');
    this.debugSensor = this.bool('debugSensor');
  }

  int(key: string) {
    const val = this.params.get(key);
    if (val && +val > 0) {
      return +val;
    }
    return 0;
  }

  bool(key: string) {
    return this.int(key) > 0;
  }
}

export const queryUtil = new QueryUtil();
