import { Space, Tag, Popover } from 'antd';

export function ManualConfirmationList({ record, value }: { value: string[]; record: any }) {
  if (!record.manualConfirmationList) return <span>--</span>;

  return (
    <Popover
      title="已命中的提示项："
      content={
        <Space direction="vertical">
          {record.manualConfirmationList.map((item: string, index: number) => {
            return (
              <Tag color="warning" key={index}>
                {index + 1}. {item}
              </Tag>
            );
          })}
        </Space>
      }
      trigger="hover">
      <a>{value.length} 项</a>
    </Popover>
  );
}
