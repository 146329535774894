import { ProColumns, ProTable } from '@ant-design/pro-components';
import { sleep } from '@mci-fe/utils';
import { Tabs, TabsProps } from 'antd';
import { createContext, FC, useState } from 'react';
import OrderDROFromIOAO from './OrderDROFromIOAO';
import OrderDROFromSO from './OrderDROFromSO';
import OrderDROFromBlock from './OrderDROFromBlock';

interface IDroContext {
  TabValue: string;
}

export const DROContext = createContext<IDroContext>({
  TabValue: 'FromIOAO',
});

export const OrderDRO: FC = () => {
  const [currentDroTab, setCurrentDroTab] = useState('FromIOAO');
  const items: TabsProps['items'] = [
    {
      key: 'FromIOAO',
      label: 'IO/AO订单',
      children: <OrderDROFromIOAO />,
    },
    {
      key: 'FromSO',
      label: 'SO订单',
      children: <OrderDROFromSO />,
    },
    {
      key: 'FromBlock',
      label: '大宗交易订单',
      children: <OrderDROFromBlock />,
    },
  ];

  const onChange = (key: string) => {
    setCurrentDroTab(key);
  };

  return (
    <DROContext.Provider value={{TabValue: currentDroTab}}>
      {/* TODO: Tab切换的时候若是需要初始化页面就添加destroyInactiveTabPane属性，destroyInactiveTabPane={true} */}
      <Tabs defaultActiveKey="FromIOAO" items={items} onChange={onChange} />
    </DROContext.Provider>
  );
};
