import useMciRequest from '@/api/useMciRequest';
import { Space, App } from 'antd';
import { PopoverConfirm } from './PopoverConfirm';
import { access } from '@mci-fe/user-auth';

export const DepositAuditButton = (props: { record: any; action: any; ctx: any }) => {
  const { record } = props;
  const { runAsync } = useMciRequest(
    'postHsbcDepositUpdateById_exKernelAdmin',
    {
      requestBody: {
        id: record?.id,
      },
    },
    { manual: true },
  );
  // access.canDepositAuditButton
  const { message } = App.useApp();
  /**
   * 执行确认
   *
   * ok 转换为 1 通过，-1 不通过
   */
  const handleConfirm = async (ok: boolean) => {
    // TODO: manual 模式其是参数也不应该放到这里的感觉，有点重复了
    let error: string = '';
    // 状态 -1审核不通过 1审核通过 2审核中 3入金失败
    await runAsync({
      requestBody: {
        id: record?.id,
        status: ok ? 1 : -1,
        roleId: record?.roleId,
      },
    }).catch((e) => {
      error = e.message;
    });

    if (error) {
      return;
    }

    message.success('处理成功');

    props.ctx.actionRef.current?.reload();
  };

  // [审核状态] -1 审核失败, 0 待审核, 1 审核完成
  const canAudit = record?.reviewStatus === 0;
  // 重试 3入金失败
  const canRetry = record?.depositStatus === 3 && record?.fundType === 1 && record?.reviewStatus === 1;

  //  当前节点与角色是否相同
  const isSameFinance = () => {
    if (access.isFinance && record.roleId === '10000004') {
      return true;
    }
    return false;
  };

  const isSameOperation = () => {
    if (access.isOperate && record.roleId === '10000001') {
      return true;
    }
    return false;
  };

  //通过不通过按钮可见条件
  const getCanAuditStaTus = () => {
    if (access.canDepositAuditButton && canAudit && (isSameFinance() || isSameOperation())) return true;
    return false;
  };

  const getCanEntry = () => {
    if (access.isOperate && access.canDepositAuditButton && canRetry) {
      return true;
    }
    return false;
  };

  return (
    <>
      {getCanAuditStaTus() && (
        <Space>
          <PopoverConfirm
            onConfirm={() => {
              handleConfirm(true);
            }}
            triggerText="通过"
            description="确定通过？"
            buttonHeight="auto"
          />
          <PopoverConfirm
            onConfirm={() => {
              handleConfirm(false);
            }}
            triggerText="不通过"
            description="确定不通过？"
            buttonHeight="auto"
          />
        </Space>
      )}
      {getCanEntry() && (
        <Space>
          <PopoverConfirm
            onConfirm={() => {
              handleConfirm(true);
            }}
            triggerText="重试"
            description="确定重试？"
            buttonHeight="auto"
          />
        </Space>
      )}
    </>
  );
};
