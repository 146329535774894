export type LogMaxTimesOption =
  | number
  | false
  | {
      count: number;
      allType?: boolean;
    };

export type LogType = 'log' | 'info' | 'warn' | 'error';

export interface LogOption {
  maxTimes?: LogMaxTimesOption;
}

export enum LogTypes {
  Request = 1,
  Error = 2,
  Custom = 3,
}

