import { Descriptions, Divider, Space, Table, Tag, Tooltip, Button, App, Spin } from 'antd';
import useMciRequest from '@/api/useMciRequest';
import { useParams } from 'react-router-dom';
import { shipDescriptionItemWithKey, desensitize, reshapeStepRateList, extraColumnConfigList } from '@/utils';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { DescriptionsTitle } from '@/components/DescriptionsTitle';
import { useDroStatus } from '@/hooks/useDroStatus';
import { enumMapPostTransLabel } from '@/utils';

function PrincipalInfo(props: { rawInfoList: Array<{ label: string; value: any; desensitizeMode?: 1 | 2 }> }) {
  const { rawInfoList } = props;
  const [isShowRawInfo, setIsShowRawInfo] = useState(false);

  const finalInfoList = rawInfoList.map((item) => {
    return {
      label: item.label,
      value:
        (!isShowRawInfo && item.desensitizeMode ? desensitize(item.value, item.desensitizeMode) : item.value) || '--',
    };
  });



  return (
    <>
      <Divider orientation="left" plain>
        {/* TODO: 这个眼睛频繁点击会选中后续的文字，很奇怪 */}
        <Space>
          法定代表人/实控人信息
          {isShowRawInfo ? (
            <EyeOutlined onClick={() => setIsShowRawInfo(false)} />
          ) : (
            <EyeInvisibleOutlined onClick={() => setIsShowRawInfo(true)} />
          )}
        </Space>
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(finalInfoList)} column={2} />
    </>
  );
}

export default function DRCInfo(props: { appId: string }) {
  const { appId } = props;
  const { modal } = App.useApp();
  const { loading, data: resp } = useMciRequest(
    'getAdminV1ShelfDroAppliesAppIdDrcInfo_mcexpOperation',
    {
      pathParams: {
        appId: appId!,
      },
    },
    {
      isJoinGlobalLoading: false,
    },
  );
  const { data } = useDroStatus({
    // 发行方式  非标资产
    params: ['DRO_LISTING_TYPE', 'DRO_ASSET_TYPE'],
  });


  const {
    id,
    drcType,
    drcCode,
    contractNo,
    armListingEntId,
    armListingEntReportId,
    tenantId,
    stage,
    auditStatus,
    captureStatus,
    fundingStatus,
    statusMsg,
    exitStatus,
    droCode,
    exDroCode,
    ipoListingDate,
    ipoCaptureDate,
    ipoBeginDate,
    ipoEndDate,
    applySubmitTime,
    applyReplyEndDate,
    applyAuditPassDate,
    planFundingDate,
    planAssociateBeginDate,
    planAssociateEndDate,
    fundingDate,
    associateBeginDate,
    associateEndDate,
    currency,
    contractEntId,
    storeBase,
    storeModel,
    storeAssociate,
    storeFile,
    saScheme,
    listingType,
    assetType
  } = resp?.data || {};

  const {
    name,
    licenseFile,
    franchiseMode,
    openingStatus,
    opeingDate,
    entName,
    entCreditCode,
    entType,
    addrProvinceCode,
    addrDetail,
    addrCityCode,
    addrDistrictCode,
    addrCoordinateValue,
    principalName,
    principalCertificateType,
    principalCertificateNo,
    principalIsController,
    controllerName,
    controllerCertificateType,
    controllerCertificateNo,
    controllerEmail,
    controllerAddress,
    groundsReceiveBankName,
    groundsReceiveBankCode,
    groundsReceiveAccountName,
    groundsReceiveAccountNo,
    headEntName,
    headEntCreditCode,
    headEntType,
    addrSimple,
  } = storeBase || {};

  const {
    originalInvestment,
    deposit,
    usableArea,
    avgMonthlyMaterialCost,
    avgMonthlyGrossProfit,
    avgMonthlyPaidMoney,
    avgMonthlyConfirmedMoney,
    avgMonthlyRent,
    avgMonthlyPersonnelCost,
    avgMonthlyPersonnelBaseSalary,
    avgMonthlyEnergyCost,
    ltmNetProfit,
    netProfitDeductDepreciationAndAmortization,
    rentBeginDate,
    rentEndDate,
    storeModel: _storeModel,
  } = storeModel || {};

  const { planBeginDate, planEndDate, investAmount, investRowList, shareRuleType, shareRule, planAssociateDays } =
    storeAssociate || {};

  const {
    licenseFileList,
    rentContractFileList,
    bankStatementFileList,
    fireFightFileList,
    addressReportAndPredictFileList,
    brandLicenseFileList,
    storePhotoFileList,
    storeQualificationFileList,
  } = storeFile || {};

  const {
    type,
    bankCode,
    bankName,
    channelCode,
    channelName,
    cashierSystemName,
    listingEntName,
    listingEntId,
    reportId,
  } = saScheme || {};

  const drcInfo = [
    { label: '挂牌方式', value: drcType === 0 ? '首发' : '增发' },
    { label: 'DRC编码', value: drcCode },
    { label: '联营协议编号', value: contractNo },
    { label: '币种', value: currency },
    //listingType
    { label: '发行方式', value: enumMapPostTransLabel(data,'DRO_LISTING_TYPE', String(listingType))},
    //assetType
    { label: '非标资产', value: enumMapPostTransLabel(data,'DRO_ASSET_TYPE', String(assetType))},
  ];

  const MAP_COMPANY_TYPE_2_TEXT = {
    1: '有限责任公司',
    2: '个体户或个人独资企业',
    3: '合伙企业',
    4: '分公司或分支机构',
  };

  const storeSummary = [
    { label: '门店名称', value: name },
    { label: '门店类型', value: franchiseMode === 1 ? '直营' : '加盟' },
    { label: '企业名称', value: entName },
    {
      label: '企业类型',
      value: entType ? MAP_COMPANY_TYPE_2_TEXT[entType as keyof typeof MAP_COMPANY_TYPE_2_TEXT] : '--',
    },
    { label: '统一社会信用代码', value: entCreditCode },
    { label: '开业时间', value: opeingDate },
    { label: '租约', value: `${rentBeginDate || '--'} 至 ${rentEndDate || '--'}` },
  ];

  const storeAddress = [
    { label: '省/市/县/区', value: addrSimple },
    { label: '详细地址', value: addrDetail },
    { label: '经纬度', value: addrCoordinateValue },
  ];

  const legalPerson = [
    { label: '法定代表人姓名', value: principalName, desensitizeMode: 1 as const },
    { label: '法定代表人证件类型', value: principalCertificateType === 1 ? '身份证' : '护照' },
    { label: '法定代表人证件号', value: principalCertificateNo, desensitizeMode: 2 as const },
    { label: '实控人姓名', value: controllerName, desensitizeMode: 1 as const },
    { label: '实控人证件号', value: controllerCertificateNo, desensitizeMode: 2 as const },
    { label: '实控人地址', value: controllerAddress, desensitizeMode: 1 as const },
  ];

  // TODO: 布局有点问题，加个控制台右侧， 会挤压到一行一个文字
  const companyInfo = [
    { label: '总公司统一社会信用代码', value: headEntCreditCode },
    { label: '总公司名称', value: headEntName },
  ];

  const companyAccountInfo = [
    { label: '账户名', value: groundsReceiveAccountName },
    { label: '银行账号', value: groundsReceiveAccountNo },
    { label: '开户银行', value: groundsReceiveBankName },
  ];

  const storeModelInfo = [
    { label: '初始投资额（元）', value: originalInvestment },
    { label: '押金（元）', value: deposit },
    { label: '实用面积（平方）', value: usableArea },
    { label: '月均物料成本（元）', value: avgMonthlyMaterialCost },
    { label: '月均毛利润（元）', value: avgMonthlyGrossProfit },
    { label: '预计月均实收金额（元）', value: avgMonthlyPaidMoney },
    { label: '预计月均确认制收入金额（元）', value: avgMonthlyConfirmedMoney },
    { label: '月均房租及物业费（元）', value: avgMonthlyRent },
    { label: '月均总人员费用（元）', value: avgMonthlyPersonnelCost },
    { label: '月均总人员底薪（元）', value: avgMonthlyPersonnelBaseSalary },
    { label: '月均能源费用（元）', value: avgMonthlyEnergyCost },
    { label: '最近12个月净利润（元）', value: ltmNetProfit },
    { label: '净利润中是否已扣减折旧摊', value: netProfitDeductDepreciationAndAmortization === true ? '是' : '否' },
  ];

  // 经营执照
  const relatedAttachmentsInfo = [
    { label: '经营执照', value: licenseFileList, type: 'preview' },
    { label: '租赁合同', value: rentContractFileList, type: 'preview' },
    { label: '银行流水', value: bankStatementFileList, type: 'preview' },
    { label: '选址报告与投资测算', value: addressReportAndPredictFileList, type: 'preview' },
    { label: '门店加盟合同/品牌授权书', value: brandLicenseFileList, type: 'preview' },
    { label: '门店照片', value: storePhotoFileList, type: 'preview' },
    { label: '门店资质', value: storeQualificationFileList, type: 'preview' },
  ] as any; // TODO: 类型问题

  // 分成比例
  const rateStr = shareRule?.rate ? (shareRule?.rate * 100).toFixed(1) + '%' : '--';
  const isFixedPlan = shareRuleType === 'fixRecoveryMultiple';

  // 阶梯分成方案
  const sharePlanInfo_step = [
    { label: '门店名称', value: name },
    { label: '融资额（元）', value: investAmount },
    { label: '联营开始日期', value: planBeginDate },
    { label: '联营期限（天）', value: planAssociateDays },
    { label: '付本前分成比例', value: rateStr },
  ];

  // 固定分成方案
  const sharePlanInfo_fixed = [
    { label: '门店名称', value: name },
    { label: '融资额（元）', value: investAmount },
    { label: '联营开始日期', value: planBeginDate },
    { label: '付本倍数', value: shareRule?.recoveryMultiple || '--' },
    { label: '分成比例', value: rateStr },
  ];

  // 阶梯分成方案数据重塑
  let stepSharePlanRateItem_afterRepayment: { [key: string]: string };
  let stepSharePlanRateItem_beforeRepayment: { [key: string]: string };
  let stepSharePlanRateTableColumns;
  let stepSharePlanRateTableData = [] as { [key: string]: string }[];
  const isValidStepSharePlanData = !isFixedPlan && Array.isArray(shareRule?.steps);

  if (isValidStepSharePlanData) {
    [stepSharePlanRateItem_beforeRepayment, stepSharePlanRateItem_afterRepayment] = reshapeStepRateList(
      shareRule!.steps!,
      rateStr,
    );

    if (stepSharePlanRateItem_beforeRepayment && stepSharePlanRateItem_afterRepayment) {
      stepSharePlanRateTableColumns = [
        {
          title: '付本天数',
          dataIndex: 'title',
        },
        ...extraColumnConfigList(stepSharePlanRateItem_afterRepayment),
      ];
      stepSharePlanRateItem_beforeRepayment.title = '回本前分成比例（%）';
      stepSharePlanRateItem_afterRepayment.title = '回本后分成比例（%）';
      stepSharePlanRateTableData = [stepSharePlanRateItem_beforeRepayment, stepSharePlanRateItem_afterRepayment];
    }
  }

  const saSchemeInfo = [
    { label: '分账方案类型', value: type },
    { label: '分账银行', value: bankName },
    { label: '收入渠道名称', value: channelName },
    { label: '订单收银系统名称', value: cashierSystemName },
    { label: '收银系统中门店名称', value: listingEntName },
    { label: '收银系统中门店ID', value: listingEntId },
    { label: '评估报告ID', value: armListingEntReportId },
  ];

  const investmentListTable = (
    <Table
      bordered
      columns={[
        {
          title: '期数',
          dataIndex: 'index',
          render(value) {
            return `第${value}期`;
          },
        },
        {
          title: '出资比例',
          dataIndex: 'rate',
          render: (value) => {
            const num = Number(value);
            return !isNaN(num) ? `${(num * 100).toFixed(2)}%` : '--';
          },
        },
        {
          title: '出资金额',
          dataIndex: 'amount',
        },
        {
          title: '出资条件',
          dataIndex: 'condition',
          render(value, record) {
            if (!value) return '--';
            return (
              <div>
                {value.length > 50 ? value.slice(0, 50) + '......' : value}
                <Button
                  type="link"
                  onClick={() => {
                    modal.info({
                      title: '出资条件',
                      width: 700,
                      content: value,
                      centered: true,
                      okText: '确定',
                    });
                  }}>
                  点击查看更多
                </Button>
              </div>
            );
          },
          width: 500,
        },
      ]}
      dataSource={investRowList || []}
      style={{ width: '100%' }}
    />
  );
  const storeAssociateInfo = [
    { label: '计划联营开始日', value: planBeginDate },
    { label: '计划联营结束日', value: planEndDate },
    { label: '投资总额（元）', value: investAmount },
  ];

  return (
    <Spin spinning={loading}>
      {/* 基础信息 */}
      <DescriptionsTitle title="基础信息" />
      <Divider orientation="left" plain>
        DRC信息
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(drcInfo)} column={2} />
      <Divider orientation="left" plain>
        门店简介
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(storeSummary)} column={2} />
      <Divider orientation="left" plain>
        实际经营地址
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(storeAddress)} column={2} />
      <PrincipalInfo rawInfoList={legalPerson} />
      <Divider orientation="left" plain>
        总公司信息
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(companyInfo)} column={2} />
      <Divider orientation="left" plain>
        收款账户信息
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(companyAccountInfo)} column={2} />

      {/* 经营信息 */}
      <Descriptions title="经营信息" items={shipDescriptionItemWithKey(storeModelInfo)} column={2} />

      {/* 相关附件 */}
      <Descriptions title="相关附件" items={shipDescriptionItemWithKey(relatedAttachmentsInfo)} column={1} />

      {/* 系统对接 */}
      <DescriptionsTitle title="系统对接" />
      <Divider orientation="left" plain>
        分账方案
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(saSchemeInfo)} column={2} />

      {/* 联营方案 */}
      <DescriptionsTitle title="联营方案" />
      <Divider orientation="left" plain>
        出资方案
      </Divider>
      <Descriptions items={shipDescriptionItemWithKey(storeAssociateInfo)} column={2} />
      <Descriptions
        items={shipDescriptionItemWithKey([{ label: '计划出资列表', value: investmentListTable }])}
        column={1}
        layout="vertical"
      />
      <Divider orientation="left" plain>
        分成方案 <Tag color="success">{isFixedPlan ? '固定分成' : '阶梯分成'}</Tag>
      </Divider>
      {isFixedPlan ? (
        <Descriptions items={shipDescriptionItemWithKey(sharePlanInfo_fixed)} column={2} />
      ) : (
        <>
          <Descriptions items={shipDescriptionItemWithKey(sharePlanInfo_step)} column={2} />
          <Descriptions
            items={[
              {
                label: '阶梯详情',
                children: (
                  <Table
                    bordered
                    columns={stepSharePlanRateTableColumns}
                    dataSource={stepSharePlanRateTableData}
                    style={{ width: '100%' }}
                  />
                ),
              },
            ]}
            column={1}
            layout="vertical"
          />
        </>
      )}
    </Spin>
  );
}
