import { useEffect, useState, useRef, useCallback } from 'react';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { UploadFile, UploadProps, UploadChangeParam } from 'antd/es/upload';
import { getFilesUrl_exKernelAdmin, postFilesUpload_exKernelAdmin } from '@/api/services';

export type TUploadFile = UploadFile & {
  fileName?: string;
  fileType?: string;
  filePath?: string;
};

export interface TUploadProps {
  value?: TUploadFile[];
  onChange?: (value: TUploadFile[]) => void;
  onRemove?: UploadProps['onRemove'];
  initialValue?: TUploadFile[];
  disabled?: boolean;
  limit?: {
    fileSizeMB?: number;
  };
  maxCount?: number;
}

const FileUpload: React.FC<TUploadProps> = (props: TUploadProps) => {
  const [fileList, setFileList] = useState<TUploadFile[]>(props.value ?? props.initialValue ?? []);
  const [messageApi, contextHolder] = message.useMessage();
  const maxCount = props.maxCount ?? 0; // 0代表不限制
  const uploadDisabled = props.disabled || (maxCount > 0 && fileList.length >= maxCount);

  // 用来保存上一次的 props.value，避免重复执行
  const prevPropsValueRef = useRef(props.value);

  // 更新文件列表
  const updateFileList = (newFileList: TUploadFile[]) => {
    setFileList(newFileList);
    props.onChange?.(newFileList);
  };

  // 获取文件的真实URL
  const fetchFilelUrl = async (filePath: string) => {
    const duration = 3600 * 1000 * 24;
    return getFilesUrl_exKernelAdmin({ queryParams: { duration, filePath } });
  };

  // 自定义上传请求
  const customRequest: UploadProps['customRequest'] = async (options: any) => {
    const uid = options.file.uid;
    const res = await postFilesUpload_exKernelAdmin({
      queryParams: { storePath: '/fundTranfer/file' },
      requestBody: { file: options.file },
    });

    // 更新文件信息
    let newFileList = [...fileList];
    newFileList = newFileList.map((item) => {
      if (item.uid === uid) {
        return {
          ...item,
          fileName: res.data!.fileName!,
          fileType: res.data!.fileType!,
          filePath: res.data!.filePath!,
        };
      }
      return item;
    });
    updateFileList(newFileList);

    const filePath = res.data!.filePath!;
    const resUrl = await fetchFilelUrl(filePath);

    newFileList = newFileList.map((item) => {
      if (item.uid === uid) {
        return {
          ...item,
          url: resUrl.data,
          status: 'done', // 手动改变状态为已完成
        };
      }
      return item;
    });
    updateFileList(newFileList);
  };

  // 监听 value 的变化，并更新 fileList
  const onValueChange = useCallback(async () => {
    const currentFileList = [...(props.value || [])];

    const newFileList = await Promise.all(
      currentFileList.map(async (item): Promise<TUploadFile> => {
        if (item.url) return item;

        if (!item.filePath) {
          if (item.status !== 'uploading') {
            console.error('no filePath', item);
          }
          return item;
        }

        const res = await fetchFilelUrl(item.filePath!);
        const name = item.name || item.fileName!;
        const uid = item.uid || item.filePath!;
        return { ...item, url: res.data, name, uid, status: 'done' };
      }),
    );

    updateFileList(newFileList);
  }, [props.value]);

  // 使用 useEffect 来监听 props.value 的变化
  useEffect(() => {
    // 只有当 props.value 与上次的值不同，才触发 onValueChange
    if (JSON.stringify(props.value) !== JSON.stringify(prevPropsValueRef.current)) {
      onValueChange();
      prevPropsValueRef.current = props.value; // 更新 ref 中保存的值
    }
  }, [props.value, onValueChange]);

  const handleRemove: UploadProps['onRemove'] = (file: TUploadFile) => {
    if (props.onRemove) {
      return props.onRemove(file);
    }
    return true;
  };

  return (
    <>
      <Upload
        name="file"
        customRequest={customRequest}
        onChange={(info: UploadChangeParam<UploadFile<any>>) => {
          updateFileList(info.fileList);
        }}
        beforeUpload={(file) => {
          let fileSizeMB = props.limit?.fileSizeMB ?? 10; // 默认限制 10MB
          if (fileSizeMB > 0) {
            const realFileSizeMB = file.size / 1024 / 1024;
            if (realFileSizeMB > fileSizeMB) {
              const errMsg = `上传文件过大，当前文件大小为 ${realFileSizeMB.toFixed(2)}MB，限制为 ${fileSizeMB}MB`;
              messageApi.error(errMsg);
              return Upload.LIST_IGNORE;
            }
          }
        }}
        disabled={props.disabled}
        onRemove={handleRemove}
        maxCount={maxCount}
        fileList={fileList}
      >
        <Button disabled={uploadDisabled} icon={<UploadOutlined />}>
          点击上传
        </Button>
      </Upload>
      {contextHolder}
    </>
  );
};

export default FileUpload;
