import {
  postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation,
  postAdminV1OrderBatchBatchOrderList_mcexpOperation,
  postAdminV1OrderBatchBatchOrderSum_mcexpOperation,
  postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation
} from '@/api/services';
import { Space, Typography } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { ProColumns, ProTable, ProTableProps } from '@ant-design/pro-components';
import { ExclamationCircleOutlined, WarningFilled, CheckCircleFilled } from '@ant-design/icons';
import { ManualContext } from '@/pages/Order/manual/OrderManual';
import { columnsIOAO, columnsSO, columnsBatch } from './modalColumns';
import './index.scss';

export interface OrderManualCheckListProps {
  pass: number;
  batchId: string;
  transactionType: string;
}

/**
 * 订单系统-手工下单-批量下单结果汇总
 */

export const OrderManualCheckList: FC<OrderManualCheckListProps> = (props) => {
  const { TabValue } = useContext(ManualContext);

  const [columns, setColumns] = useState<any>([]);
  const [batchOrderTotal, setBatchOrderTotal] = useState<any>({});

  const request: ProTableProps<any, any>['request'] = async (params) => {
    let error = '';
    let resp;
    if (TabValue === 'IOAOSO') {
      resp = await postAdminV1OrderBatchBatchOrderList_mcexpOperation({
        requestBody: {
          page: params.current,
          size: params.pageSize,
          batchId: props.batchId,
        },
      });
    } else {
      resp = await postAdminV1OrderBatchBlockTradeOrderList_mcexpOperation({
        requestBody: {
          page: params.current,
          size: params.pageSize,
          batchId: props.batchId,
        },
      });
    }

    if (error) {
      return {
        data: [],
        total: 0,
        success: true,
      };
    }

    return {
      data: resp?.data?.content || [],
      total: resp?.data?.page!.totalElements || 0,
      success: true,
    };
  };

  const getOrderTotal = async () => {
    let error = '';
    let resp;
    if (TabValue === 'IOAOSO') {
      resp = await postAdminV1OrderBatchBatchOrderSum_mcexpOperation({
        requestBody: {
          batchId: props.batchId,
        },
      });
    } else {
      resp = await postAdminV1OrderBatchBatchBlockTradeOrderSum_mcexpOperation({
        requestBody: {
          batchId: props.batchId,
        },
      });
    }
    if (!error && resp.data) {
      setBatchOrderTotal(resp.data);
    }
  };

  const explainTemplate = () => {
    const isIOAOSO = TabValue === 'IOAOSO';
    return (
      <>
        <span style={{ marginRight: 20 }}>
          {props.transactionType}
          {!isIOAOSO ? '大宗交易' : ''}
        </span>
        <span style={{ marginRight: 20 }}>下单总条数：{batchOrderTotal?.orderTotal}</span>

        {/* 校验不成功会有校验通过数 */}
        {props.pass === 0 ? (
          <>
            <span style={{ marginRight: 20 }}>校验通过数：{batchOrderTotal?.passTotal}</span>
            <span style={{ marginRight: 12 }}>校验不通过数：{batchOrderTotal?.failTotal}</span>
          </>
        ) : isIOAOSO ? (
          <span style={{ marginRight: 20 }}>会员总数：{batchOrderTotal?.memberTotal}</span>
        ) : (
          <>
            <span style={{ marginRight: 20 }}>卖方会员总数：{batchOrderTotal?.sellMemberTotal}</span>
            <span style={{ marginRight: 20 }}>买方会员总数：{batchOrderTotal?.buyMemberTotal}</span>
          </>
        )}
      </>
    );
  };

  const modifyColumns = (columns: any[], pass: number) => {
    if (pass !== 1) {
      columns.push({
        title: '结果',
        dataIndex: 'remark',
        width: 120,
      });
    }
    return columns;
  };

  useEffect(() => {
    const newColumns =
      TabValue === 'IOAOSO'
        ? props.transactionType.includes('SO')
        ? modifyColumns(columnsSO.slice(), props.pass)
        : modifyColumns(columnsIOAO.slice(), props.pass)
        : modifyColumns(columnsBatch.slice(), props.pass);
    // 更新状态
    setColumns(newColumns);
    // 获取订单汇总情况
    getOrderTotal();
  }, [props.pass, TabValue, props.transactionType]);

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="middle">
      <Typography.Text style={{ fontSize: 16 }} type={`${props.pass === 0 ? 'danger' : 'success'}`}>
        {props.pass === 0 ? (
          <>
            <WarningFilled /> <span>校验失败，请核对!</span>
          </>
        ) : (
          <>
            <CheckCircleFilled /> <span>校验成功!</span>
          </>
        )}
      </Typography.Text>

      <Typography.Text type="secondary" style={{ fontSize: 14 }}>
        {explainTemplate()}
      </Typography.Text>

      <ProTable
        ghost
        bordered
        rowKey="id"
        tableClassName="orderManualCheck"
        search={false}
        options={false}
        scroll={{ x: 700, y: 200 }}
        columns={columns}
        request={request}
        pagination={{
          pageSize: 10,
        }}
      />
    </Space>
  );
};
