import { ReactNode } from 'react';
import VirtualMenuItem from '@/components/VirtualMenuItem';
import PageSkeleton from '@/components/PageSkeleton';
import layoutConfig from '@/config/menu';
import '@/styles/global.css';
import { generatePath2RouteComponentMap } from '@/utils/devtool';
import { LogoutOutlined } from '@ant-design/icons';
import type { ProSettings } from '@ant-design/pro-components';
import { PageContainer, ProConfigProvider, ProLayout } from '@ant-design/pro-components';
import { ConfigProvider, Dropdown, Empty } from 'antd';
import React, { Suspense } from 'react';
import { NavLink, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { logout } from '@mci-fe/keycloak';
import { useUserInfo, authForRoute, authForMenu } from '@mci-fe/user-auth';
import mciLogo1 from '../assets/mci-logo1.png';
import mciLogo2 from '../assets/mci-logo2.png';
import styled from '@emotion/styled';
import { ANTD_THEME } from '@mci-fe/app-config.theme';

const layoutSetting: Partial<ProSettings> = {
  fixSiderbar: true,
  layout: 'mix',
  splitMenus: false,
  contentWidth: 'Fluid',
  siderMenuType: 'sub',
  fixedHeader: true,
};

// @NOTE: 已经尝试过通过定制 ProLayout 的 token 来现实，但是由于 ProLayout 提供的 token 颗粒度不够，所有没法实现「选中菜单高亮显示」的需求
const StyledProLayout = styled(ProLayout)`
  li.ant-menu-item-selected {
    background-color: ${ANTD_THEME.colorPrimary};

    span.mci-layout-base-menu-inline-item-text {
      color: #fff;
    }
  }
`;

const Layout: React.FC = () => {
  const currentLocation = useLocation();
  const { userName } = useUserInfo() || {};

  const menuConfig = authForMenu({
    'private-exmt-ops': layoutConfig.route,
  })['private-exmt-ops'];

  console.log('menuConfig:', menuConfig);

  /**
   * 面包屑渲染
   *
   * 内部类型错了，props 这里只能是 any 了
   *
   * 自定义渲染
   * - 想要根据菜单配置增加一个 icon
   * - 默认的面包屑会刷新网页，体验不太好
   *
   * TODO: 从联营通搬运过来的，有空整理一下
   *
   * TODO: 目前用的是假路由，没有 react-router
   */
  const breadcrumbRender = (props: any, defaultDom: ReactNode) => {
    // 用 props.matchMenus 其实有重复的，因此这里用了做了去重
    // 理论上用 props.breadcrumb.items 可以，没有重复，但是拿不到 icon
    // icon 是路由上面配置的，这里可以同步展示到面包屑上
    // const listWithIcons = uniqBy(props.matchMenus, 'path');
    // const list = listWithIcons.map((item: any, index) => {
    //   const isFirst = index === 0;
    //   // 不能使用配置的 path，那个是可能存在路径参数
    //   // TODO: 首页不知道为什么 breadcrumb.items 是 undefined
    //   const realPath = props.breadcrumb.items?.[index].linkPath ?? item.path;
    //   return (
    //     <div
    //       key={item.path}
    //       className={!isFirst ? 'cursor-pointer' : 'cursor-not-allowed'}
    //       onClick={() => {
    //         // console.log('eq ', props.breadcrumbRender === breadcrumbRender);
    //         // console.log({ props, item, realPath, isFirst });
    //         // 一级路径都不能导航，如果只有一个也不可能出现在面包屑上
    //         !isFirst && navigate(realPath);
    //       }}>
    //       <span className="h-22 mr-4">{item.icon}</span>
    //       <span className={`${item.path === props.currentMenu?.path ? 'font-semibold' : ''}`}>{item.name}</span>
    //     </div>
    //   );
    // });
    // // 如果只有一个路径，就不展示面包屑了
    // if (list.length === 1) return null;
    // return <Space split="/">{list}</Space>;
  };

  return (
    <ProConfigProvider hashed={false}>
      <ConfigProvider
        getTargetContainer={() => {
          return document.getElementById('test-pro-layout') || document.body;
        }}>
        <StyledProLayout
          // TODO: 一个项目只有一个 layout，应该不用设置前缀？
          prefixCls="mci-layout"
          {...{
            route: menuConfig,
          }}
          location={currentLocation}
          siderMenuType="group"
          menuExtraRender={() => {
            if (!menuConfig) {
              return (
                <Empty
                  style={{ marginTop: 350 }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  imageStyle={{ height: 60 }}
                  description={<span style={{ color: 'rgba(0, 0, 0, 0.45)' }}>您没有任何菜单权限</span>}
                />
              );
            } else {
              return null;
            }
          }}
          avatarProps={{
            src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
            size: 'small',
            // TODO: 后端返回的数据结构跟 apifox 客户端上接口定义的不一样，敦促他们去修正
            title: userName,
            render: (props, dom) => {
              return (
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: 'logout',
                        icon: <LogoutOutlined />,
                        label: '退出登录',
                        onClick: () => {
                          logout();
                        },
                      },
                    ],
                  }}>
                  {dom}
                </Dropdown>
              );
            },
          }}
          headerTitleRender={(logo, title, _) => {
            return (
              <>
                <img src={mciLogo1} alt="logo" style={{ height: 20 }} />
                <img src={mciLogo2} alt="logo" style={{ height: 15, marginLeft: 10 }} />
              </>
            );
          }}
          menuFooterRender={(props) => {
            if (props?.collapsed) return undefined;
            return (
              <div
                style={{
                  textAlign: 'center',
                  paddingBlockStart: 12,
                }}>
                {/* TODO: 这里出场会很奇怪的旋转一下 */}
                <div>© 2024 Made by MCI</div>
                <div></div>
              </div>
            );
          }}
          menuItemRender={(item, dom) => {
            if (item.virtual) {
              return <VirtualMenuItem prefixPath={item.prefixPath}>{dom}</VirtualMenuItem>;
            }

            return <NavLink to={item.path || '/welcome'}>{dom}</NavLink>;
          }}
          {...layoutSetting}>
          {/* TODO:暂时关闭，没有结合到 react-router 的 basepath 中，而且访问到根路由会因为没有界面 */}
          <PageContainer breadcrumbRender={false}>
            <Routes>
              <Route path="/" element={<Outlet />}>
                {generatePath2RouteComponentMap(authForRoute(layoutConfig.route)).map(([path, component]) => {
                  return (
                    <Route
                      key={path}
                      index={path === '/'}
                      path={path}
                      element={<Suspense fallback={<PageSkeleton />}>{component}</Suspense>}
                    />
                  );
                })}
                <Route path="*" element={'not fount'} />
              </Route>
            </Routes>
          </PageContainer>
        </StyledProLayout>
      </ConfigProvider>
    </ProConfigProvider>
  );
};

export default Layout;
