import {
  ContractEntityInfoSaveReq,
  SystemRoleInfoResp,
} from '@/api/types';
import {
  BetaSchemaForm,
  ProFormColumnsType,
  ProFormInstance,
} from '@ant-design/pro-components';
import { FC, useEffect, useRef, useState } from 'react';
import {
  getSysV1GetSystemMenuList_mciUserAuthAdminServer,
  postRoleV1Update_mciUserAuthAdminServer,
  getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer,
  postRoleV1Save_mciUserAuthAdminServer,
} from '@/api/services';
import { systemCode } from '../../type';
import TreeAccess from './TreeAccess';

export interface RoleModifyProps {
  // 列表数据，如果传递了从列表项目进来的修改，否则是新增
  record?: SystemRoleInfoResp;

  // 新增时候需要跳转到渠道，之类带过去
  onFinish?: (SystemRoleInfoResp?: SystemRoleInfoResp) => void;
  onCancel?: () => void;
}

/**
 * 修改签约主体信息
 *
 * TODO: 这个名字不太合适，因为新增也在这个范畴，看修改下
 *
 * TODO: 控制台警告
 * Warning: [antd: Select] `bordered` is deprecated. Please use `variant` instead.
 * Warning: [antd: DatePicker.RangePicker] `bordered` is deprecated. Please use `variant` instead.
 */
export const RoleModify: FC<RoleModifyProps> = (props) => {
  const isCreate = props.record === undefined;
  const [roleId, setRoleId] = useState<string>();
  const [detail, setDetail] = useState<any>();
  const formRef = useRef<ProFormInstance>();
  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);

  const getSystemMenuList = async () => {
    const res = await getSysV1GetSystemMenuList_mciUserAuthAdminServer({
      queryParams: {
        systemCode: systemCode,
      },
    });
    setTreeData(dataToTree(res?.data));
  };

  const fetchRoleDetail = async () => {
    setLoading(true);
    const resp = await getRoleV1GetRoleInfoDetails_mciUserAuthAdminServer({
      queryParams: {
        roleId: props.record!.roleId!,
      },
    });
    setLoading(false);
    const data = resp.data!;
    setDetail(data);
    if (!!data) {
      formRef.current!.setFieldsValue({
        ...data,
      });
    }
  };

  const dataToTree = (arr: any) => {
    let data = arr.filter((item: any) => {
      item.children = arr.filter((e: any) => {
        return item.authCode === e.parentCode;
      });
      return !item.parentCode;
    });
    return data;
  };

  useEffect(() => {
    if (!isCreate) {
      getSystemMenuList();
      fetchRoleDetail();
    }
  }, [isCreate, roleId]);

  const handleFinish = async (values: any) => {
    if (isCreate) {
      setLoading(true);

      let error = '';
      const resp = await postRoleV1Save_mciUserAuthAdminServer({
        requestBody: {
          ...values,
          systemCode: systemCode,
        },
      }).catch((e) => {
        error = e.message;
      });
      setLoading(false);
      if (!error) {
        props.onFinish?.();
      }
    } else {
      setLoading(true);
      let error = '';
      const resp = await postRoleV1Update_mciUserAuthAdminServer({
        requestBody: {
          ...values,
          systemCode: systemCode,
        },
      }).catch((e) => {
        error = e.message;
      });
      setLoading(false);
      if (!error) {
        props.onFinish?.();
      }
    }
    return true;
  };

  useEffect(() => {
    
    formRef.current!.setFieldsValue({
      authCodeList: detail?.authCodeList,
    });
  }, [roleId, detail]);

  const columns: ProFormColumnsType<ContractEntityInfoSaveReq>[] = [
    {
      title: '角色编号',
      dataIndex: ['roleId'],
      fieldProps: {
        disabled: true,
        placeholder: '请输入角色编号',
      },
    },
    {
      title: '角色名称',
      dataIndex: ['roleName'],
      formItemProps: {
        rules: [{ required: true, message: '请输入角色名称' }],
      },
      fieldProps: {
        placeholder: '请输入角色名称',
      },
    },
    {
      title: '英文名',
      dataIndex: ['englishName'],
      formItemProps: {
        rules: [{ required: true, message: '请输入英文名' }],
      },
      fieldProps: {
        placeholder: '请输入英文名',
      },
    },
    {
      title: '英文缩写',
      dataIndex: ['abbreviate'],
      formItemProps: {
        rules: [{ required: true, message: '请输入英文缩写' }],
      },
      fieldProps: {
        placeholder: '请输入英文缩写',
      },
    },
  ];

  if (!isCreate) {
    columns.push({
      dataIndex: ['authCodeList'],
      renderFormItem: (schema, config, form) => {
        return <TreeAccess {...schema} treeData={treeData} />;
      },
    });
  }
  return (
    <BetaSchemaForm
      title={isCreate ? '新增角色信息' : '修改角色信息'}
      formRef={formRef}
      layout="horizontal"
      layoutType="ModalForm"
      style={{ marginTop: 20 }}
      colProps={{ span: 12 }}
      loading={loading}
      open={true}
      grid={true}
      rowProps={{ gutter: 24 }}
      columns={columns}
      onFinish={handleFinish}
      modalProps={{
        onCancel: () => {
          props.onCancel?.();
        },
      }}
    />
  );
};
