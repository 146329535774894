class WangEditorUtilsClass {
  defaultEmptyHtml = '<p><br></p>';

  /**
   * 判断是否是空白字符串（包含 \n, \r\n）
   */
  isWhiteSpaceString(str: string) {
    return str.trim() === '';
  }

  /**
   * 是否是空白的 html
   */
  isEmptyHtml(html: string) {
    if (!html) return true;
    if (this.isWhiteSpaceString(html)) return true;

    return html === this.defaultEmptyHtml;
  }

  /**
   * 删除空白注释
   *
   * @desc
   * WangEditor 遇到注释会转换为换行
   * WangEditor 遇到空白字符默认会压缩，为了统一最好压缩下
   */
  removeEmptyAndCommentNodes(str: string) {
    // 防止 undefined 转换为字符串
    if (!str) return '';

    const domParser = new DOMParser();
    const doc = domParser.parseFromString(str, 'text/html');

    const stack: ChildNode[] = [doc.body];
    while (stack.length > 0) {
      const node = stack.shift()!;

      if (node.nodeType === Node.COMMENT_NODE) {
        node.remove();
        continue;
      }

      if (node.nodeType === Node.TEXT_NODE) {
        if (this.isWhiteSpaceString(node.textContent!)) {
          node.remove();
        }
        continue;
      }

      for (let i = 0; i < node.childNodes.length; i++) {
        stack.push(node.childNodes[i]);
      }
    }

    return doc.body.innerHTML;
  }

  /**
   * strong 靠近文本
   *
   * @desc
   * 很多富文本有这个硬性要求
   * WangEditor会删除 strong 内所有 tag，只保留文本
   */
  makeStrongInside(html: string) {
    if (!html) return '';

    const domParser = new DOMParser();
    const doc = domParser.parseFromString(html, 'text/html');

    // traverse find strong tag, take it to all the nestest text node
    const body = doc.body;

    const stack: ChildNode[] = [body];
    while (stack.length > 0) {
      const node = stack.shift()!;

      // not element node
      if (node.nodeType !== Node.ELEMENT_NODE) continue;

      const isStrong = node.nodeName === 'STRONG';
      if (!isStrong) {
        for (let i = 0; i < node.childNodes.length; i++) {
          stack.push(node.childNodes[i]);
        }
        continue;
      }

      const parent = node.parentElement!;
      const strongStack: ChildNode[] = [...node.childNodes];
      while (strongStack.length > 0) {
        const child = strongStack.shift()!;
        // text node 包裹 strong
        if (child.nodeType === Node.TEXT_NODE) {
          // 空白字符串不处理
          if (this.isWhiteSpaceString(child.textContent!)) continue;
          const newStrong = doc.createElement('strong');
          newStrong.textContent = child.textContent;
          child.parentElement!.replaceChild(newStrong, child);
          continue;
        }

        // element node
        if (child.nodeType === Node.ELEMENT_NODE) {
          const isStrong = child.nodeName === 'STRONG';

          // 如果出现 strong 嵌套，直接将其内容提升到上一层
          if (isStrong) {
            for (let i = 0; i < child.childNodes.length; i++) {
              const strongChild = child.childNodes[i];
              strongStack.push(strongChild);
              // child.remove();
              child.parentElement!.insertBefore(strongChild, child);
              i--;
            }
            child.parentElement!.removeChild(child);
            continue;
          }

          for (let i = 0; i < child.childNodes.length; i++) {
            strongStack.push(child.childNodes[i]);
          }
        }
      }

      // 提升一层
      for (let i = 0; i < node.childNodes.length; i++) {
        const child = node.childNodes[i];
        parent.insertBefore(child, node);
        i--;
      }
      parent.removeChild(node);
    }

    return body.innerHTML;
  }
}

export const WangEditorUtils = new WangEditorUtilsClass();
