import { ShelfDroApplyQueryResp } from '@/api/types';
import { ModalForm, ProFormTextArea, ProFormSelect, ProFormDependency } from '@ant-design/pro-components';
import type { ProFormInstance } from '@ant-design/pro-components';
import { Space, Form, Tag, Alert, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { AuditStatus, DROActionProps } from '@/types';
import { postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation } from '@/api/services';
import { access } from '@mci-fe/user-auth';

const ManualConfirmationItems = (props: { value?: ShelfDroApplyQueryResp['manualConfirmationList'] }) => {
  if (!props.value) {
    return <Alert message="没有命中任何的提示项" type="warning" showIcon />;
  }
  return (
    <Space direction="horizontal" style={{ width: '100%', flexWrap: 'wrap' }}>
      {props.value.map((item, index) => {
        return (
          <Tag color="warning" key={index}>
            {index + 1}. {item}
          </Tag>
        );
      })}
    </Space>
  );
};

enum AuditAction {
  AGREE = 0,
  REJECT = 1,
  BACK = 2,
}

export default function DROAuditAction(props: DROActionProps) {
  const { record, ctx } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const formRef = useRef<ProFormInstance>();
  
  // @TEST: 暂时注释
  if (record.auditStatus !== AuditStatus.PENDING || !access.droToBeListingRead) {
    return null;
  }

  return (
    <>
      <a onClick={() => setIsModalOpen(true)}>已阅知</a>
      <ModalForm
        title="DRO 上架信息"
        open={isModalOpen}
        onOpenChange={setIsModalOpen}
        onFinish={async (formData) => {
          await postAdminV1ShelfDroAppliesAppIdDoAudit_mcexpOperation({
            pathParams: {
              appId: record.appId!,
            },
            requestBody: formData,
          });
          message.success('DRO 通过提交成功');
          setTimeout(() => {
            ctx.actionRef.current.reload();
          }, 0);
          return true;
        }}
        modalProps={{ destroyOnClose: true }}
        formRef={formRef}
        initialValues={{
          manualConfirmationList: record.manualConfirmationList,
          auditStatus: '',
          auditInstructions: '',
        }}>
        <Form.Item name="manualConfirmationList" label="提示项">
          <ManualConfirmationItems />
        </Form.Item>

        <ProFormSelect
          options={[
            {
              value: AuditAction.AGREE,
              label: '同意',
            },
            {
              value: AuditAction.REJECT,
              label: '拒绝',
            },
            {
              value: AuditAction.BACK,
              label: '驳回',
            },
          ]}
          name="auditStatus"
          label="意见"
          rules={[
            {
              required: true,
              message: '请选择意见',
            },
          ]}
        />

        <ProFormDependency name={['auditStatus']}>
          {({ auditStatus }) => {
            const isRequired = [AuditAction.REJECT, AuditAction.BACK].includes(Number(auditStatus));

            return (
              <ProFormTextArea
                // @NOTE: 在这里，key 属性是功能相关的，不是性能相关，不要删除
                key={auditStatus}
                name="auditInstructions"
                label="说明"
                placeholder="请输入不超过 500 字的说明"
                rules={[
                  {
                    required: isRequired,
                    message: isRequired ? '拒绝或者驳回必须填写说明' : '',
                  },
                  {
                    validator: (_, value) => {
                      if (value && value.length > 500) {
                        return Promise.reject('说明不能超过500字');
                      }
                      return Promise.resolve(true);
                    },
                  },
                ]}
                fieldProps={{ rows: 6 }}
              />
            );
          }}
        </ProFormDependency>
      </ModalForm>
    </>
  );
}
