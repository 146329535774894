import { PathComponentMap } from '@/types';
import type { ProLayoutProps } from '@ant-design/pro-components/es';
import { EEnv } from './log/evn';

/**
 * 根据 menuConfig 配置生成路径到组件的映射
 * 
 * @returns { [path: string]: React.ReactNode } 路径到组件的映射
 */
export function generatePath2RouteComponentMap(
  menuConfig: ProLayoutProps['route'],
  pathMap: PathComponentMap = {},
  parentPath = '',
) {
  if (!menuConfig) return [];
  const currentPath = menuConfig?.path;
  if (!currentPath) throw new Error('path field is required');

  const fullPathSegment =
    currentPath === '/' ? currentPath : parentPath === '/' ? `/${menuConfig.path}` : `${parentPath}/${currentPath}`;
  if (menuConfig?.path && menuConfig?.component) {
    pathMap[fullPathSegment] = menuConfig.component;
  }

  if (menuConfig?.routes && menuConfig.routes.length > 0) {
    for (const nestedRoute of menuConfig.routes) {
      generatePath2RouteComponentMap(nestedRoute, pathMap, fullPathSegment);
    }
  }

  return Object.entries(pathMap);
}

export function shipAddMenuItemTrigger(menuConfig: ProLayoutProps['route'], prefixPathSegment: string[]): any {
  const parentRoute = menuConfig!;
  const currentMenuItems = parentRoute.routes;

  if (currentMenuItems && currentMenuItems.length > 0) {
    prefixPathSegment = prefixPathSegment.concat(parentRoute.path);
    return {
      ...parentRoute,
      routes: [
        ...currentMenuItems.map((menuItem: any) => shipAddMenuItemTrigger(menuItem, prefixPathSegment)),
        {
          virtual: true,
          path: `/virtual-${Math.random().toString()}`,
          name: '添加一个子菜单',
          prefixPath: prefixPathSegment.join('/').replace('//', '/'),
        },
      ],
    };
  } else {
    return menuConfig;
  }
}

/**
 * 根据当前host获取环境变量
 */
export function getEnvByHost(): string {
  const { host } = window.location;
  // 开发环境
  const ipReg = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
  if (host.includes('-dev') || ipReg.test(host) || host.includes('localhost')) {
    return EEnv.DEV;
  }
  // 测试环境
  if (host.includes('-stg')) {
    return EEnv.STG;
  }
  // 仿真环境
  if (host.includes('-uat')) {
    return EEnv.UAT;
  }

  return EEnv.PROD;
}


