import { TabsProps, Tabs } from 'antd';
import { createContext, useState, FC } from 'react';
import { OrderManualFromIOAOSO } from './components/OrderManualFromIOAOSO';
import { OrderManualFromBlockTrade } from './components/OrderManualFromBlockTrade';
interface IManualContext {
  TabValue: string;
}

export const ManualContext = createContext<IManualContext>({
  TabValue: 'IOAOSO',
});

export const OrderManual: FC = () => {
  const [currentManualTab, setCurrentManualTab] = useState('IOAOSO');
  const items: TabsProps['items'] = [
    {
      key: 'IOAOSO',
      label: 'IO/AO/SO',
      children: <OrderManualFromIOAOSO />,
    },
    {
      key: 'BlockTrade',
      label: '大宗交易',
      children: <OrderManualFromBlockTrade />,
    },
  ];

  const onChange = (key: string) => {
    setCurrentManualTab(key);
  };

  return (
    <ManualContext.Provider value={{TabValue: currentManualTab}}>
      {/* TODO: Tab切换的时候若是需要初始化页面就添加destroyInactiveTabPane属性，destroyInactiveTabPane={true} */}
      <Tabs defaultActiveKey="IOAOSO" items={items} onChange={onChange} />
    </ManualContext.Provider>
  );
};
