import { getEnumBatchQuery_mciDict } from '@/api/services';
import { QueryEnumResp, ResultListQueryEnumResp } from '@/api/types';
import { useEffect, useMemo, useState } from 'react';
interface DroStatusProps {
  params: string[];
}

export const useDroStatus = (props: DroStatusProps) => {
  const { params } = props;
  const [data, setData] = useState<QueryEnumResp[]>([]);
  const getDroStatus = () => {
    getEnumBatchQuery_mciDict({
      queryParams: {
        types: params,
      },
    }).then((res: ResultListQueryEnumResp) => {
      setData(res?.data || []);
    });
  };
  useEffect(() => {
    getDroStatus();
  }, []);

  return {
    data,
  };
};
