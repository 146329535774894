import useMciRequest from '@/api/useMciRequest';
import { Button, Space, App, Modal, Input } from 'antd';
import { useEffect, useState } from 'react';
import { PopoverConfirm } from './PopoverConfirm';
import { AccessConfigType } from '@/config/access';
import { useAccess } from '@mci-fe/user-auth';
import { FlowDetailDto } from '@/api/types';

export const SPVDepositAuditButton = (props: { record: any; action: any; ctx: any }) => {
  const [modal, contextHolder] = Modal.useModal();
  const { id, approveState: currentApproveState } = props.record as FlowDetailDto;
  const { message } = App.useApp();
  const access = useAccess<AccessConfigType>();
  const [rejectReason, setRejectReason] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { run: approve, loading } = useMciRequest('postFlowApprove_mciArm', undefined, {
    manual: true,
    onSuccess: (res) => {
      message.success('审核成功');
      props.ctx.actionRef.current?.reload();
    },
    isJoinGlobalLoading: false,
  });

  const handleConfirm = async (approved: boolean) => {
    // 审批状态: 0-待审批 ; 1-运营审批通过（待财务审批）; 2-财务审批通过（全部通过）; 3-审批不通过
    let nextApproveState: 1 | 2 | 3;

    if (!approved) {
      nextApproveState = 3;
    } else {
      if (access.isOperatorRole) {
        nextApproveState = 1;
      } else if (access.isFinancialRole) {
        nextApproveState = 2;
      } else {
        throw new Error('当前用户权限不足');
      }
    }

    approve({
      requestBody: {
        // TODO: 后端实际接收的id类型为字符串，反推后端去修正类型定义
        //@ts-ignore
        id: id!,
        approveState: nextApproveState,
        rejectReason: rejectReason,
      },
    });
  };

  if (loading) {
    return <div>审核中...</div>;
  }

  const canShow =
    access.canAuditFlow_spv &&
    ((access.isOperatorRole && currentApproveState === 0) || (access.isFinancialRole && currentApproveState === 1));

  if (!canShow) {
    return <span>--</span>;
  }

  const handleOk = () => {
    if (!rejectReason) {
      message.error('请输入驳回理由');
      return;
    }
    handleConfirm(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Space>
        <PopoverConfirm
          onConfirm={() => {
            handleConfirm(true);
          }}
          triggerText="通过"
          description="确定通过这笔入金流水吗？"
          buttonHeight="auto"
        />
        {/* <PopoverConfirm
          onConfirm={() => {
            handleConfirm(false);
          }}
          triggerText="不通过"
          description="确定要驳回这笔入金流水吗？"
          buttonHeight="auto"
        /> */}
        <Button
          type="link"
          onClick={() => {
            setRejectReason('');
            setIsModalOpen(true);
          }}>
          不通过
        </Button>
      </Space>
      <Modal title="提示" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p>确定要驳回这笔入金流水吗？</p>
        <Input.TextArea
          value={rejectReason}
          placeholder="请输入驳回理由"
          autoSize={{ minRows: 3, maxRows: 5 }}
          maxLength={200}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
    </>
  );
};
