import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import BrandInfo from './BrandInfo';
import DRCInfo from './DRCInfo';
import HistoricalRevenue from './HistoricalRevenue';
import PCFCashFlow from './PCFCashFlow';
import PDFContract from './PDFContract';
import styled from '@emotion/styled';

const StickyTabs = styled(Tabs)`
  &.ant-tabs > .ant-tabs-nav {
    position: sticky;
    display: flex;
    flex: none;
    align-items: center;
    top: -24px;
    z-index: 1;
    height: 60px;
    background-color: #f6f7f9;
  }
`;

export default function DroDetail(props: { appId: string }) {
  const { appId } = props;

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'DRC 档案',
      children: <DRCInfo appId={appId} />,
    },
    {
      key: '2',
      label: '品牌档案',
      children: <BrandInfo appId={appId} />,
    },
    {
      key: '3',
      label: '投前营业额',
      children: <HistoricalRevenue appId={appId} />,
    },
    {
      key: '4',
      label: '现金流PCF',
      children: <PCFCashFlow appId={appId} />,
    },
    {
      key: '5',
      label: '合同信息',
      children: <PDFContract appId={appId} />,
    }
  ];
  return <StickyTabs defaultActiveKey="1" items={items} />;
}
