/* eslint-disable no-unused-vars */ import { getConfigProvider } from '../ConfigProvider';
import { getFileName } from '../FileItems/utils';
import { ajax } from './ajax';
export const getOSSPolicyConfig = async (filename, dirName = 'public')=>{
    const config = getConfigProvider();
    const res = await config.api?.getOssPolicyByDir_mciToolbox({
        queryParams: {
            filename,
            dir: dirName
        }
    });
    return res?.data;
};
export const removeOssFileByPath = async (pathKey)=>{
    const pathArr = pathKey.split('/').filter(Boolean);
    const bucket = pathArr[0];
    const pKey = pathArr.slice(1).join('/');
    if (!bucket || !pKey) throw new Error('pathKey is invalid');
    const config = getConfigProvider();
    return await config.api?.postOssV2FileDelete_mciToolbox({
        queryParams: {
            bucket,
            pathKey
        }
    });
};
export const getOSSBucketAccessUrl = async (filePath)=>{
    const config = getConfigProvider();
    const res = await config.api?.postNouserOssBucketAccessUrlV2_mciToolbox({
        requestBody: {
            filePath
        }
    });
    return res?.data;
};
export const getOSSBucketAccessUrlV2 = async (filePath)=>{
    const config = getConfigProvider();
    const res = await config.api?.postNouserOssBucketAccessUrlV2_mciToolbox({
        requestBody: {
            filePath
        }
    });
    return res;
};
export const uploadToOSS = async (params)=>{
    const { file, beforeUpload, afterUpload, dirName } = params;
    let policyConfig = {};
    policyConfig = await getOSSPolicyConfig(file.name, dirName);
    const { host, pathKey, policy, accessid, callback, signature, filePath } = policyConfig;
    await beforeUpload?.(policyConfig);
    const formData = new FormData();
    formData.append('name', pathKey);
    formData.append('key', pathKey);
    formData.append('policy', policy);
    formData.append('filePath', filePath);
    formData.append('OSSAccessKeyId', accessid);
    if (callback) {
        formData.append('callback', callback);
    }
    formData.append('signature', signature);
    /* 只有图片做压缩 */ formData.append('file', file);
    await ajax(host, {
        method: 'POST',
        data: formData,
        timeout: 60e3
    });
    await afterUpload?.(policyConfig);
    return {
        filePath,
        fileName: file.name
    };
};
/**
 * 下载oss文件
 * @param src oss相对路径
 */ export const downloadOssFile = async (src)=>{
    if (downloadOssFileAsBlob) {
        downloadOssFileAsBlob(src);
        return;
    }
    const path = await getOSSBucketAccessUrl(src);
    const filename = getFileName(src);
    const a = document.createElement('a');
    a.href = path;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
/**
 * 获取oss文件名
 * @param url oss相对路径
 * @returns 文件名
 *
 */ export const getOssFileName = (url)=>{
    return getFileName(url);
};
/**
 * 下载oss文件
 * @param src oss相对路径
 * @param filename 自定义下载的文件名
 */ export const downloadOssFileAsBlob = async (src, filename)=>{
    const path = await getOSSBucketAccessUrl(src);
    const tempFilename = filename || getFileName(src);
    const res = await fetch(path);
    const blob = await res.blob();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = tempFilename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
/**
 * 下载oss文件
 *
 * @param src oss相对路径
 * @param filename 自定义下载的文件名
 */ export const downloadOssFileAsBlobV2 = async (src, filename)=>{
    const result = await getOSSBucketAccessUrlV2(src);
    // 下载优化--408显示“网络异常，请重试”
    let path;
    if (result.code === 408) {
        return false;
    } else {
        path = result.data;
    }
    const tempFilename = filename || getFileName(src);
    const res = await fetch(path);
    const blob = await res.blob();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = tempFilename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return true;
};
